var base = require('kiosk/kiosk');

Vue.component('spark-kiosk', {
  mixins: [base],

  /**
   * The component has been created by Vue.
   */
  created() {
    var self = this;

    Bus.$on('sparkHashChanged', function(hash, parameters) {
      $(`${this.pushStateSelector} a[href!="#${hash}"][data-toggle="tab"]`)
        .parent('li')
        .removeClass('active');
      var tab = $(`${this.pushStateSelector} a[href="#${hash}"][data-toggle="tab"]`).parent('li');

      tab.addClass('active');

      return true;
    });
  }
});
