<template>
  <div
    id="email-textarea"
    tabindex="0"
    class="email-textarea"
    @click="emailFocusInput"
    @focus="emailFocusInput"
    @focusout="emailFocusOutInput"
  >
    <div v-for="(email, idx) in emails" :key="email + idx" class="pill">
      <div class="email-container">
        {{ email }}
      </div>
      <button
        type="button "
        class="close"
        @click="
          () => {
            removeEmail(idx);
          }
        "
      >
        &times;
      </button>
    </div>
    <input id="email-invisible-input" v-model="emailInput" class="email-invisible-input" @keyup="keyupEmailInput" @paste="onPaste">
  </div>
</template>

<script>
function resizable(el, factor) {
  var int = Number(factor) || 7.7;
  function resize() {
    el.style.width = (el.value.length + 1) * int + 'px';
  }
  var e = 'keyup,keypress,focus,blur,change'.split(',');
  for (var i in e) el.addEventListener(e[i], resize, false);
  resize();
}

export default {
  name: 'AddUsersEmail',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emailInput: '',
      emails: this.value
    };
  },
  computed: {},

  watch: {
    emails: function(newEmails) {
      this.$emit('on-update-emails', newEmails);
    },
    value: function(newEmails) {
      this.emails = newEmails;
    }
  },

  mounted() {
    resizable(document.getElementById('email-invisible-input'), 10);
    Bus.$on('resetAddUsersForm', this.clearForm);
  },
  methods: {
    emailFocusInput() {
      document.getElementById('email-invisible-input').focus();
      document.getElementById('email-textarea').classList.add('focused');
    },
    emailFocusOutInput() {
      document.getElementById('email-textarea').classList.remove('focused');
      document.getElementById('email-invisible-input').style.width = '100px';
      this.addEmails();
    },
    keyupEmailInput(e) {
      if (e.key == ',') {
        this.addEmails();
      }
    },
    onPaste(e) {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('Text');
      const emailArray = pastedText.split(',');
      emailArray.forEach((email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail.length > 0) {
          this.emails.push(trimmedEmail);
        }
      });
    },
    removeEmail(idx) {
      this.emails.splice(idx, 1);
    },
    addEmails() {
      let arr = this.emailInput.split(',');
      arr.forEach((str) => {
        str = str.trim();
        if (str.length > 0) {
          this.emails.push(str);
        }
      });
      this.emailInput = '';
    },
    clearForm() {
      this.emails = [];
      this.addEmails();
    }
  }
};
</script>

<style>
.email-textarea {
  width: 100%;
  min-height: 56px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  padding: 13px 16px;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
}

.email-invisible-input {
  border: none;
  margin: 7px 0;
  min-width: 100px;
}

.email-invisible-input:focus {
  outline: none;
}

.email-container {
  max-width: 300px;
  overflow-wrap: break-word;
}
</style>
