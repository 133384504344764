import Template from './engagmentPerSchool.html';
import { TYPES, LAYERS } from './mapManager.js';
import EngagementBase from '../../../mixins/engagmentBase';

export default Vue.extend({
  mixins: [EngagementBase],
  props: {
    fairHasPast: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      isRegisteredActive: false,
      isAttendedActive: false
    };
  },
  computed: {
    containerRef() {
      return this.$refs.engagement_map_container;
    },
    mapType() {
      return TYPES.STUDENTS;
    }
  },
  methods: {
    onMapInitComplete() {
      if (this.fairHasPast) {
        this.onAttendedClicked();
      } else {
        this.onRegisteredClicked();
      }
    },
    onRegisteredClicked() {
      this.map.setVisibleLayer(LAYERS.REGISTERED_STUDENTS);
      this.isRegisteredActive = true;
      this.isAttendedActive = false;
    },
    onAttendedClicked() {
      this.map.setVisibleLayer(LAYERS.ATTENDED_STUDENTS);
      this.isAttendedActive = true;
      this.isRegisteredActive = false;
    }
  },
  template: Template
});
