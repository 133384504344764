import axios from 'axios';
import moment from 'moment';
import STATES from '../constants/states';
import Loader from './dashboard/Loader.vue';
import Vue from 'vue';

const OCCURRENCE = 'upcoming';
const LIMIT = '-1';

Vue.component('new-landing', {
  components: {
    Loader,
  },
  props: {
    studentType: {
      type: String,
      default: 'USA',
    },
    useGlobalTime: {
      type: Boolean,
      default: false,
    },
    distances: {
      type: Array,
      default: () => null,
      required: false,
    },
    isOntario: {
      type: Boolean,
      default: false,
      required: true,
    },
    displayLocale: {
      type: String,
      required: true,
    },
    preloadedFairs: {
      type: Array,
      default: () => null,
      required: false,
    },
    allFairs: {
      type: Array,
      default: () => null,
      required: false,
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSelectEventLocationGroup: true,
      showFilterByDistanceGroup: false,
      showSelectAttendeeTypeGroup: false,
      showSelectionRequiredError: false,
      latitude: null,
      longitude: null,
      upcomingFairs: [],
      selectedDistance: null,
      selectedLocation: null,
      selectedEvent: null,
      selectedAttendeeType: null,
      states: STATES,
      selectedFairData: null,
      isStudentsEnabled: false,
      isParentsEnabled: false,
      isProfessionalsEnabled: false,
      locationCheckHasFailed: false,
      tempLoadingUpcomingFairs: [],
      overridePreloadedFairs: false,
      selectDisabled: false,
      locationIsEnabled: false,
    };
  },
  computed: {
    displayFairs() {
      if (!this.overridePreloadedFairs && this.preloadedFairs && this.preloadedFairs.length > 0) {
        return this.preloadedFairs;
      } else if (this.tempLoadingUpcomingFairs && this.tempLoadingUpcomingFairs.length > 0) {
        return this.tempLoadingUpcomingFairs;
      }
      return this.upcomingFairs;
    },
    hidePleaseSelectIndicator() {
      if (!this.overridePreloadedFairs && this.preloadedFairs != null) {
        return this.preloadedFairs.length === 1;
      } else {
        return false;
      }
    },
    hideShowAllEventsButton() {
      if (!this.overridePreloadedFairs && this.preloadedFairs != null && this.preloadedFairs.length > 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    selectedDistance(newDistance) {
      if (newDistance !== null) {
        this.locationIsEnabled = true;
      }
      this.distanceChanged(newDistance);
    },
    selectedLocation(newLocation) {
      this.locationChanged(newLocation);
    },
    selectedEvent(newEvent) {
      this.eventChanged(newEvent);
    },
    selectedAttendeeType(newAttendeeType) {
      this.attendeeTypeChanged(newAttendeeType);
    },
  },
  async mounted() {
    moment.locale(this.displayLocale);
    this.resetState();
    if (this.preloadedFairs && this.preloadedFairs.length > 0) {
      this.showSelectEventLocationGroup = false;
      if (this.preloadedFairs.length === 1) {
        this.selectedEvent = this.preloadedFairs[0].id;
        this.eventChanged(this.preloadedFairs[0].id);
      }
    } else {
      await this.checkGeolocationSupport();
    }
  },
  methods: {
    resetState() {
      this.selectedEvent = null;
      this.selectedFairData = null;
      this.showSelectAttendeeTypeGroup = false;
      this.isStudentsEnabled = false;
      this.isParentsEnabled = false;
      this.isProfessionalsEnabled = false;
      this.selectedAttendeeType = null;
      this.showSelectEventLocationGroup = !this.isOntario && !this.locationIsEnabled;
    },
    eventChanged(newEvent) {
      this.selectedFairData = null;
      this.showSelectAttendeeTypeGroup = newEvent != null;

      if (newEvent) {
        this.selectedFairData = this.displayFairs.find((fair) => fair.id === newEvent);

        const formsArray = this.selectedFairData.forms_array;

        if (this.isGlobal) {
          this.isStudentsEnabled = formsArray.includes('global');
          this.isParentsEnabled = formsArray.includes('parents');
          this.isProfessionalsEnabled = formsArray.includes('professionals');
        } else if (this.isOntario) {
          this.isStudentsEnabled = formsArray.includes('ontario students');
          this.isParentsEnabled = formsArray.includes('ontario parents');
          this.isProfessionalsEnabled = formsArray.includes('ontario counsellors');
        } else {
          this.isStudentsEnabled = formsArray.includes('students');
          this.isParentsEnabled = formsArray.includes('parents');
          this.isProfessionalsEnabled = formsArray.includes('professionals');
        }

        if (this.isStudentsEnabled && !this.isParentsEnabled && !this.isProfessionalsEnabled) {
          this.selectedAttendeeType = 'student';
        } else if (!this.isStudentsEnabled && this.isParentsEnabled && !this.isProfessionalsEnabled) {
          this.selectedAttendeeType = 'parent';
        } else if (!this.isStudentsEnabled && !this.isParentsEnabled && this.isProfessionalsEnabled) {
          this.selectedAttendeeType = 'counselor-professional';
        } else {
          this.selectedAttendeeType = null;
        }
      } else {
        this.resetState();
      }
    },
    validateAndSubmit() {
      if (this.selectedAttendeeType) {
        this.showSelectionRequiredError = false;
        this.$refs.form.submit();
      } else {
        this.showSelectionRequiredError = true;
      }
    },
    async distanceChanged(newDistance) {
      this.resetState();
      this.showSelectEventLocationGroup = false;

      this.tempLoadingUpcomingFairs = this.upcomingFairs;
      this.selectedLocation = null;
      await this.clearAndReloadEvents();
      this.tempLoadingUpcomingFairs = null;
    },
    async locationChanged(newLocation) {
      this.selectedEvent = null;
      await this.clearAndReloadEvents();
      this.tempLoadingUpcomingFairs = null;
    },
    async clearAndReloadEvents() {
      this.selectedFairData = null;
      await this.fetchNearbyEvents(this.selectedDistance);
      if (!this.displayFairs.some((fair) => fair.id === this.selectedEvent)) {
        this.selectedEvent = null;
      }
    },
    attendeeTypeChanged(newAttendeeType) {
      // console.log('Attendee type changed to: ', newAttendeeType);
    },
    async getLocationSuccess(position) {
      this.showFilterByDistanceGroup = true;
      this.showSelectEventLocationGroup = false;
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      await this.fetchNearbyEvents();
    },
    async getLocationError(error) {
      console.error('Location access denied, error: ', error);
      this.showFilterByDistanceGroup = false;
      this.showSelectEventLocationGroup = !this.isOntario;
      this.locationCheckHasFailed = true;
      await this.fetchNearbyEvents(this.selectedDistance);
    },
    async locationButtonPressed() {
      if (this.locationCheckHasFailed) {
        alert('Location permissions have been turned off. Please check your browser settings to turn them back on.');
      } else {
        await this.checkGeolocationSupport();
      }
    },
    async showAllButtonPressed() {
      this.tempLoadingUpcomingFairs = this.upcomingFairs;
      this.overridePreloadedFairs = true;
      this.selectedFairData = null;
      this.selectedEvent = null;
      await this.fetchNearbyEvents();
      this.tempLoadingUpcomingFairs = null;
    },
    async checkGeolocationSupport() {
      if (!navigator.geolocation) {
        console.error('Geolocation is not supported by your browser');
        this.showSelectEventLocationGroup = true;
      } else {
        navigator.geolocation.getCurrentPosition(this.getLocationSuccess, this.getLocationError);
      }
    },
    async fetchNearbyEvents(distance) {
      let url = `/api/v2/fairs/students/index` + `?occurrence=${encodeURIComponent(OCCURRENCE)}` + `&limit=${encodeURIComponent(LIMIT)}`;

      if ((this.latitude == null || this.longitude == null) && (this.selectedLocation == null || this.selectedLocation === 'null')) {
        this.tempLoadingUpcomingFairs = null;
        this.upcomingFairs = this.allFairs;
        return;
      }

      let distanceValue;
      if (this.latitude && this.longitude) {
        distanceValue =
          distance && distance !== 'null'
            ? distance
            : this.upcomingFairs.length > 0 || this.tempLoadingUpcomingFairs.length > 0
              ? 'any'
              : 'unknown';
        url += `&lat=${encodeURIComponent(this.latitude)}`;
        url += `&lng=${encodeURIComponent(this.longitude)}`;
        url += `&radius=${encodeURIComponent(distanceValue)}`;
      }

      url += `&student_type=${encodeURIComponent(this.studentType)}`;

      if (this.selectedLocation && this.selectedLocation !== 'null') {
        url += `&state=${encodeURIComponent(this.selectedLocation)}`;
      }

      this.selectDisabled = true;
      axios
        .get(url)
        .then((success) => {
          this.selectDisabled = false;
          this.tempLoadingUpcomingFairs = null;
          this.upcomingFairs = success.data.data;
          let data = success.data;

          if (this.selectedDistance === null && distanceValue === 'unknown' && data.meta && data.meta.radius) {
            let radius = Number(data.meta.radius);
            for (let i = this.distances.length - 1; i >= 0; i--) {
              if (radius >= this.distances[i].value) {
                this.selectedDistance = this.distances[i].value;
                break;
              }
            }
          }
          if (this.upcomingFairs.length == 1) {
            this.selectedEvent = this.upcomingFairs[0].id;
            this.eventChanged(this.selectedEvent);
          }
        })
        .catch((error) => {
          this.resetState();
          console.error('Error fetching fairs:', error);
        });
    },
    formatStartDate(value, formatString) {
      let dateString = moment(value).format(this.useGlobalTime ? 'DD-MMM-YYYY' : 'MM/DD/YYYY');
      if (this.useGlobalTime) {
        return dateString.replace('.', '');
      }
      return dateString;
    },
  },
});
