import moment from 'moment-timezone';
import Vue from 'vue';
import EventGuideSectionDetailsModal from './dashboard/event-guide/EventGuideSectionDetailsModal.vue';
import EventGuideAddManuallyModal from './dashboard/event-guide/EventGuideAddManuallyModal.vue';
import EventGuideEditInstitutionModal from './dashboard/event-guide/EventGuideEditInstitutionModal.vue';
import EventGuideDeleteAllModal from './dashboard/event-guide/EventGuideDeleteAllModal.vue';
import EventGuideUploadListModal from './dashboard/event-guide/EventGuideUploadListModal.vue';
import QuillEditor from './QuillEditor.vue';
import Snackbar from './Snackbar.vue';
import Cropper from './Cropper.vue';

Vue.component('fair-dashboard-manage-event-guide', {
  components: {
    Loader: require('./dashboard/Loader.vue').default,
    EventGuideSectionDetailsModal,
    EventGuideDeleteAllModal,
    EventGuideUploadListModal,
    EventGuideAddManuallyModal,
    EventGuideEditInstitutionModal,
    QuillEditor,
    Snackbar,
    Cropper,
  },
  mixins: [require('../mixins/file').default],
  data() {
    let fairInfo = typeof window.getFairInfo !== 'undefined' ? window.getFairInfo() : null;
    if (fairInfo == null) {
      throw 'getFairInfo is undefined!';
    }
    const fair = fairInfo ? { ...fairInfo } : {};

    return {
      fair: fair,
      fairParticipants: fair.fair_participants_data,
      fairTitle: this.generateFairTitle(fair),
      loading: false,
      form1: new SparkForm({}),
      form2: new SparkForm({}),
      formHeader: new SparkForm({
        institutions_header: fair.institutions_header,
      }),
      formDisplayName: new SparkForm({
        display_name: fair.display_name,
      }),
      selectedDetailItem: {
        id: null,
        title: '',
        order_column: 0,
        description: '',
      },
      selectedEditItem: {
        id: null,
        name: '',
        email: '',
        location: '',
        updated_at: null,
      },
      search: '',
      bulkType: 'file',
      alertSuccess: false,
      alertMessage: false,
      alertTimeout: null,
      sortColumn: 'name',
      sortDirection: 'asc',
      showCropper1: false,
      showCropper2: false,
      cropperImage1: null,
      cropperImage2: null,
      currentCropIndex: null,
    };
  },
  created() {
    var self = this;

    Bus.$on('uploadedEventGuideParticipants', function () {
      self.getFair();
    });

    Bus.$on('updateHeader', function () {
      self.getFair();
    });

    Bus.$on('updateDisplayName', function () {
      self.getFair();
    });

    Bus.$on('addedPhoto', function () {
      self.showSnackbar('Photo added successfully.');
      self.getFair();
    });

    Bus.$on('removedPhoto', function () {
      self.showSnackbar('Photo removed successfully.');
      self.getFair();
    });

    Bus.$on('updatedEventGuideSection', function () {
      self.showSnackbar('Section updated successfully.');
      self.getFair();
    });

    Bus.$on('addEventGuideParticipant', function() {
      self.showSnackbar('Institution(s) added.');
      self.getFair();
    })

    Bus.$on('updatedEventGuideParticipant', function () {
      self.showSnackbar('Participant updated successfully.');
      self.getFair();
    });

    Bus.$on('canceledEventGuideParticipant', function () {
      self.showSnackbar('Participant updated successfully.');
      self.getFair();
    });

    Bus.$on('deletedEventGuideParticipant', function () {
      self.showSnackbar('Participant deleted.');
      self.getFair();
    });

    Bus.$on('deleteAllEventGuideParticipants', function () {
      self.showSnackbar('All participants deleted.');
      self.getFair();
    });

    Bus.$on('snackbar-closed-nocancel', function () {
      self.hideSnackbar();
    });
  },
  methods: {
    showSnackbar(message, type = 'success') {
      this.alertSuccess = type;
      this.alertMessage = message;

      this.alertTimeout = setTimeout(() => {
        this.hideSnackbar();
      }, 3000);
    },
    hideSnackbar() {
      this.alertSuccess = false;
      this.alertMessage = false;
    },
    /*
     * Get the current user of the application.
     */
    getFair() {
      axios.get('/api/fairs/' + this.fair.id + '/dashboards/refresh')
        .then(response => {
          this.fair = response.data;
          this.filterInstitutions();
        });
    },
    generateFairTitle(fair) {
      let tags = [];

      for (let i = 0; i < fair.tags.length && i < 2; i++) {
        tags.push(fair.tags[i].title);
      }

      let resultName = '';
      tags.forEach((tag) => {
        resultName += tag + ' | ';
      });

      resultName += fair.name;
      return resultName;
    },
    onDetailsModalClicked(item) {
      this.selectedDetailItem = item;
      $('#modal-event-guide-details').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onDeleteAllModalClicked() {
      $('#modal-event-guide-delete-all').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onAddManuallyModalClicked() {
      $('#modal-event-guide-add-manually').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onEditInstitutionModalClicked(item) {
      this.selectedEditItem = item;
      $('#modal-event-guide-edit-institution').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onUploadListModalClicked() {
      this.bulkType = 'file';
      $('#modal-event-guide-upload-list').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onPasteListModalClicked() {
      this.bulkType = 'paste';
      $('#modal-event-guide-upload-list').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    formatDate(date) {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      return new Date(date).toLocaleDateString('en-US', options);
    },
    formatTime(time) {
      return new Date(time).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    },
    async updatePhoto(e, index) {
      e.preventDefault();

      if (!this.$refs[`photo${index}`].files.length) {
        return;
      }

      const file = this.$refs[`photo${index}`].files[0];
      const maxSize = 4194304; // 4MB in bytes (4 * 1024 * 1024)

      if (file.size > maxSize) {
        this.showSnackbar('File size must be less than 4MB.', 'error');
        this.$refs[`photo${index}`].value = ''; // Clear the input
        return;
      }

      try {
        // Create a temporary image to check dimensions
        const img = new Image();
        const imgPromise = new Promise((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error('Failed to load image'));
        });
        img.src = URL.createObjectURL(file);
        
        // Wait for image to load and check dimensions
        const loadedImg = await imgPromise;
        if (loadedImg.width < 96 || loadedImg.height < 96) {
          URL.revokeObjectURL(img.src);
          throw new Error('Image dimensions must be 96x96 pixels or larger');
        }
        URL.revokeObjectURL(img.src);

        // Import the utility function
        const { ensureMinImageSize } = await import('../utils/imageUtils');
        
        // Process the image to ensure minimum size
        const processedImage = await ensureMinImageSize(file);
        
        // Set the processed image for the cropper
        this[`cropperImage${index}`] = processedImage;
        this[`showCropper${index}`] = true;
        this.currentCropIndex = index;
        
        // Add console.log to debug
        console.log('Index:', index);
        console.log('Processed Image:', processedImage);
        console.log('Cropper Image State:', this[`cropperImage${index}`]);
        
        // Show the cropper modal
        $('#modal-photo-crop').modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (error) {
        console.error('Image processing error:', error);
        this[`form${index}`].setErrors({ photo: [error] });
      }
    },
    async saveCrop() {
      const index = this.currentCropIndex;
      if (!index) return;

      try {
        this[`form${index}`].startProcessing();

        // Get the cropper instance (now using a single ref)
        const cropper = this.$refs.cropper;
        if (!cropper) {
          throw new Error('Cropper not found');
        }

        // Get the cropped canvas directly from the cropper
        const croppedData = cropper.getCroppedImage();
        if (!croppedData) {
          throw new Error('Failed to get cropped image');
        }

        // Convert data URL to Blob
        const response = await fetch(croppedData);
        const blob = await response.blob();
        
        // Create FormData with the cropped image
        const formData = new FormData();
        formData.append('photo', blob, `cropped-image-${index}.jpg`);
        
        // Upload the cropped image
        await axios.post(`/api/fairs/${this.fair.id}/dashboards/upload-photo/${index}`, formData);
        
        // Clean up
        this[`showCropper${index}`] = false;
        this[`cropperImage${index}`] = null;
        this.currentCropIndex = null;
        
        // Close modal
        $('#modal-photo-crop').modal('hide');
        
        Bus.$emit('addedPhoto');
        this[`form${index}`].finishProcessing();
      } catch (error) {
        console.error('Upload error:', error);
        this[`form${index}`].setErrors(error.response?.data?.errors || { photo: ['Failed to upload image'] });
      }
    },
    cancelCrop() {
      const index = this.currentCropIndex;
      if (!index) return;

      // Clean up
      this[`showCropper${index}`] = false;
      this[`cropperImage${index}`] = null;
      this.currentCropIndex = null;
      
      // Close modal
      $('#modal-photo-crop').modal('hide');
    },
    removePhoto(e, index) {
      e.preventDefault();

      axios.post(`/api/fairs/${this.fair.id}/dashboards/remove-photo/${index}`).then(
        () => {
          Bus.$emit('removedPhoto');
          this[`form${index}`].finishProcessing();
        },
        (error) => {
          this[`form${index}`].setErrors(error.response.data.errors);
        }
      );
    },
    gatherFormData(index) {
      const data = new FormData();
      data.append('photo', this.$refs[`photo${index}`].files[0]);
      return data;
    },
    updateHeader() {
      this.formHeader.startProcessing();

      axios.post(`/api/fairs/${this.fair.id}/dashboards/update-institutions-header`, this.formHeader).then(
        () => {
          Bus.$emit('updateHeader');

          this.formHeader.finishProcessing();
          this.showSnackbar('Section header updated successfully.');
        },
        (error) => {
          this.formHeader.setErrors(error.response.data.errors);
        }
      );
    },
    updateDisplayName() {
      this.formDisplayName.startProcessing();

      axios.post(`/api/fairs/${this.fair.id}/dashboards/update-display-name`, this.formDisplayName).then(
        () => {
          Bus.$emit('updateDisplayName');

          this.formDisplayName.finishProcessing();
          this.showSnackbar('Display name updated successfully.');
        },
        (error) => {
          this.formDisplayName.setErrors(error.response.data.errors);
        }
      );
    },
    filterInstitutions() {
      this.fairParticipants = this.fair.fair_participants_data.filter((institution) => {
        return institution.name.toLowerCase().includes(this.search.toLowerCase())
          || institution.email.toLowerCase().includes(this.search.toLowerCase())
          || institution.location?.toLowerCase().includes(this.search.toLowerCase());
      }).sort((a, b) => {
        let result = 0;
        if (a[this.sortColumn] < b[this.sortColumn]) {
          result = -1;
        } else if (a[this.sortColumn] > b[this.sortColumn]) {
          result = 1;
        }
        return this.sortDirection === 'asc' ? result : -result;
      });
    },
    sortBy(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
      this.filterInstitutions();
    },
    exportToCsv() {
      const headers = ['Institution', 'Representative Email', 'Location'];
      const csvData = this.fairParticipants.map(participant => [
        participant.name,
        participant.email,
        participant.location || ''
      ]);
      
      // Add headers to the beginning of the data
      csvData.unshift(headers);
      
      // Convert to CSV format
      const csvContent = csvData.map(row => row.map(cell => {
        // Escape quotes and wrap in quotes if contains comma or newline
        const escaped = cell.replace(/"/g, '""');
        return /[,\n"]/.test(cell) ? `"${escaped}"` : cell;
      }).join(',')).join('\n');
      
      // Create blob and download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'participants.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.filterInstitutions();
  },
  watch: {
    search: function () {
      this.filterInstitutions();
    },
    cropperImage1(newVal) {
      console.log('cropperImage1 changed:', newVal ? 'has value' : 'null');
    },
    cropperImage2(newVal) {
      console.log('cropperImage2 changed:', newVal ? 'has value' : 'null');
    },
    currentCropIndex(newVal) {
      console.log('currentCropIndex changed:', newVal);
    }
  },
  computed: {
    /**
     * Calculate the style attribute for the photo preview.
     */
    previewStyle1() {
      if (this.fair.photo_url_1) {
        return `background-image: url(${this.fair.photo_url_1});`;
      }
      return false;
    },

    previewStyle2() {
      if (this.fair.photo_url_2) {
        return `background-image: url(${this.fair.photo_url_2});`;
      }
      return false;
    },

    lastTimeInstitutionsUpdated() {
      let lastTime = false;
      if (this.fair.fair_participants_data && this.fair.fair_participants_data.length > 0) {
        let max = this.fair.fair_participants_data[0].updated_at;
        this.fair.fair_participants_data.forEach((participant) => {
          if (participant.updated_at > max) {
            max = participant.updated_at;
          }
        });
        lastTime = max;
      }
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return lastTime ? moment(lastTime)
        .tz(browserTimezone ?? this.fair.starts_at_timezone)
        .format('MM/DD/YY [at] h:mm A z') : false;
    },

    currentCropperImage() {
      return this.currentCropIndex === 1 ? this.cropperImage1 : this.cropperImage2;
    },

    fairDisplayDate() {
      const DATE_FORMAT = 'dddd, MMMM DD, YYYY';
      const TIME_FORMAT = 'hh:mm A';
      const fair = this.fair;
      const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
      const end = moment(fair.ends_at).tz(fair.starts_at_timezone);
      let date = null;
      let time = null;
      if (start.isSame(end, 'day')) {
        date =  start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT);
      } else {
        date =  start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT);
      }

      if (start.isSame(end, 'day')) {
        time =  start.format(TIME_FORMAT) + ' - ' + end.format('hh:mm A z');
      } else {
        time =  start.format(TIME_FORMAT) + ' - ' + end.format(TIME_FORMAT + ' z');
      }

      return {date, time};
    },
  }
});