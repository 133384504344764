import UpdateTeam from '../mixins/updateTeam';

Vue.component('update-institution', {
  mixins: [UpdateTeam],

  data() {
    return {
      isUSBased: false,
    };
  },

  props: {
    user: {
      type: Object,
      default: null
    },
    regions: {
      type: Array,
      default: null,
    }
  },

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    },

    stateLabelText() {
      return this.form.contact_country_code == 'US' ? '* State' : 'State / Region';
    }
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    const teamPhoneInput = document.querySelector('#institution-phone');
    if (teamPhoneInput != null && window.intlTelInput) {
      window.iti2 = window.intlTelInput(teamPhoneInput, {
        nationalMode: true,
        utilsScript: '/intlTelInput/utils.js'
      });

      const app = this;
      $(() => {
        window.iti2.setCountry(app.team.phone_country_code);
        window.iti2.setNumber(app.team.phone);
      });
    }
    
    this.isUSBased = (this.form.contact_country_code == 'US');
  },

  methods: {
    handleCountryChange(event) {
      this.isUSBased = (event.target.value == 'US');
    }
  }
});
