import { Api } from '../../../dashboard/dashboard-api';
import mapboxgl from '../../Maps';

const LIGHT_BLUE = '#B9F2FE';
const LIGHT_GREEN = '#6AD5CA';
const PURPLE = '#7A489E';
const BACKGROUND_COLORS = {
  REGISTERED: LIGHT_BLUE,
  ATTENDED: PURPLE,
  INSITUTIONS: LIGHT_GREEN
};

export const LAYERS = {
  REGISTERED_STUDENTS: 'registered_students_circles',
  ATTENDED_STUDENTS: 'attended_students_circles',
  INSTITUTIONS: 'institutions_circles'
};

export const TYPES = {
  STUDENTS: 'students',
  INSTITUTIONS: 'institutions'
};

const FAIR_LOCATION_MARKER_LAYER = 'fair_location_marker';
const ENGAGEMENT_SOURCE_ID = 'school_engagement';
const FAIR_LOCATION_SOURCE_ID = 'fair_location';
const SCALE_FACTOR = 3.5;
const MIN_SCALE = 2;
const CursorStyle = {
  FingerPointer: 'pointer',
  Regular: ''
};
function getRandomInRange(min, max) {
  return Math.random() * (max - min) + min;
}
function ifThenElseExpr(condition, trueValue, falseValue) {
  return ['case', ['boolean', condition, false], trueValue, falseValue];
}

export class MapManager {
  constructor(element, fair, zoom, type) {
    this.fair = fair;
    this.element = element;
    this.zoom = zoom;
    this.center = new mapboxgl.LngLat(this.fair.longitude, this.fair.latitude);
    this.map = new mapboxgl.Map({
      container: this.element,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: this.center,
      zoom: this.zoom
    });
    this.type = type;
  }
  async init() {
    await this.loaded();
    await this.onMapLoad();
    if (!this.testing) {
      await this.addFairMarkerSource();
      await this.addFairMarkerLayer();
    }

    await this.addEngagementSource();
    if (this.type === TYPES.STUDENTS) {
      await this.addRegistrationsLayer();
      await this.addAttendedLayer();
    } else {
      await this.addInstitutionsLayer();
    }
    await this.addNavControls();
    await this.addFullScreenMode();
  }

  async onMapLoad() {
    this.map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error, image) => {
      if (error) throw error;

      // Add the image to the map style.
      this.map.addImage('cm', image);

      // Add a data source containing one point feature.
      this.map.addSource('point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [this.fair.longitude, this.fair.latitude]
              },
              properties: this.fair
            }
          ]
        }
      });

      // Add a layer to use the image to represent the data.
      this.map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'point', // reference the data source
        layout: {
          'icon-image': 'cm', // reference the image
          'icon-size': 0.75
        }
      });
    });
  }

  async addFairMarkerSource() {
    this.map.addSource(FAIR_LOCATION_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.center.lng, this.center.lat]
            },
            properties: this.fair
          }
        ]
      }
    });
  }

  async addFairMarkerLayer() {
    const image = await this.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png');
    this.map.addImage('custom-marker', image);
    this.map.addLayer({
      id: FAIR_LOCATION_MARKER_LAYER,
      type: 'symbol',
      source: FAIR_LOCATION_SOURCE_ID,
      layout: {
        'icon-image': 'custom-marker',
        // get the title name from the source's "title" property
        'text-field': ['get', 'name'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [0, 1.25],
        'text-anchor': 'top'
      }
    });
    if (!this.testing) {
      this.map.setLayoutProperty(FAIR_LOCATION_MARKER_LAYER, 'visibility', 'none');
    }
  }

  _makeDummyEngagementFeature(index) {
    const latitude = getRandomInRange(-2, 2) + this.fair.latitude;
    const longitude = getRandomInRange(-2, 2) + this.fair.longitude;
    const registered = Math.ceil(getRandomInRange(20, 150));
    const attended = Math.floor(getRandomInRange(5, registered));

    return {
      type: 'Feature',
      id: index,
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude]
      },
      properties: {
        fair_id: this.fair.id,
        id: index,
        registered: registered,
        attended: attended,
        CEEB: '000020',
        name: `Dummy School ${index}`,
        address_line1: 'POST OFFICE BOX 2609',
        address_line2: '',
        municipality: 'PAGO PAGO  SAMOA',
        locality: null,
        region: 'AS',
        postal_code: '96799',
        country_code: null,
        longitude: longitude,
        latitude: latitude
      }
    };
  }
  get testing() {
    return (
      window['ENABLE_TESTING_ENGAGEMENT_DATA_GENERATION'] === 1 ||
      window['ENABLE_TESTING_ENGAGEMENT_DATA_GENERATION'] === '1' ||
      window['ENABLE_TESTING_ENGAGEMENT_DATA_GENERATION'] === true ||
      window['ENABLE_TESTING_ENGAGEMENT_DATA_GENERATION'] === 'true'
    );
  }
  async getFakeSchoolEngagementFeatures() {
    const count = Math.ceil(getRandomInRange(15, 45));
    const features = new Array(count).fill(0).map((v, idx) => this._makeDummyEngagementFeature(idx));
    const bbox = this.center.toBounds(0.25);
    for (let feature of features) {
      bbox.extend(feature.geometry.coordinates);
    }
    return Promise.resolve({
      type: 'FeatureCollection',
      bbox: [bbox.getEast(), bbox.getNorth(), bbox.getWest(), bbox.getSouth()],
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
      },
      features: features
    });
  }

  async addEngagementSource() {
    const geojson = await (this.testing ? this.getFakeSchoolEngagementFeatures() : this.getEngagementFeatures());
    this.map.addSource(ENGAGEMENT_SOURCE_ID, {
      type: 'geojson',
      data: geojson
    });
    this.map.fitBounds(geojson.bbox, { padding: 50 });
  }
  async getEngagementFeatures() {
    let result = await (this.type === TYPES.INSTITUTIONS ? this.getInstitutionsEngagement() : this.getSchoolsEngagement());
    return result.data;
  }

  async getSchoolsEngagement() {
    let result = await Api.fairs(this.fair.id).dashboard.schools.engagement();
    result.data.features.map((item) => {
      item.properties.hasAttended = item.properties.attended > 0;
      item.properties.hasRegistered = item.properties.registered > 0;
      return item;
    });
    return result;
  }

  async getInstitutionsEngagement() {
    let result = await Api.fairs(this.fair.id).dashboard.institutions.engagement();
    result.data.features.map((item) => {
      item.properties.hasScanned = item.properties.students_scanned > 0;
      return item;
    });
    return result;
  }

  async addRegistrationsLayer() {
    await this.addEngagementLayer(
      LAYERS.REGISTERED_STUDENTS,
      ENGAGEMENT_SOURCE_ID,
      'registered',
      BACKGROUND_COLORS.REGISTERED,
      'hasRegistered'
    );
    this.map.setLayoutProperty(LAYERS.REGISTERED_STUDENTS, 'visibility', 'none');
    this.addMouseCursorEvents(LAYERS.REGISTERED_STUDENTS);
  }
  async addAttendedLayer() {
    await this.addEngagementLayer(LAYERS.ATTENDED_STUDENTS, ENGAGEMENT_SOURCE_ID, 'attended', BACKGROUND_COLORS.ATTENDED, 'hasAttended');
    this.map.setLayoutProperty(LAYERS.ATTENDED_STUDENTS, 'visibility', 'none');
    this.addMouseCursorEvents(LAYERS.ATTENDED_STUDENTS);
  }
  async addInstitutionsLayer() {
    await this.addEngagementLayer(
      LAYERS.INSTITUTIONS,
      ENGAGEMENT_SOURCE_ID,
      'students_scanned',
      BACKGROUND_COLORS.INSITUTIONS,
      'hasScanned'
    );
    this.map.setLayoutProperty(LAYERS.INSTITUTIONS, 'visibility', 'none');
    this.addMouseCursorEvents(LAYERS.INSTITUTIONS);
  }

  async addNavControls() {
    this.map.addControl(new mapboxgl.NavigationControl());
  }

  async addFullScreenMode() {
    this.map.addControl(new mapboxgl.FullscreenControl());
  }

  addMouseCursorEvents(layer_id) {
    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.on('mouseenter', layer_id, () => {
      this.map.getCanvas().style.cursor = CursorStyle.FingerPointer;
    });

    this.map.on('mousemove', layer_id, () => {
      this.map.getCanvas().style.cursor = CursorStyle.FingerPointer;
    });

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', layer_id, () => {
      this.map.getCanvas().style.cursor = CursorStyle.Regular;
    });
    this.map.on('click', layer_id, (e) => {
      if (
        (layer_id === LAYERS.REGISTERED_STUDENTS || layer_id === LAYERS.ATTENDED_STUDENTS || layer_id === LAYERS.INSTITUTIONS) &&
        e.features[0]
      ) {
        this.circleClicked(e.lngLat, e.features[0]);
      }
    });
  }
  getPopupHtml(props) {
    if (this.type === TYPES.STUDENTS) {
      return `<div class="map-popup">
				<div class="marker-row marker-title">${props.name}</div>
				<div class="marker-row"><span class="data-title">Registered: </span> <span class="data-value">${props.registered}</span></div>
				<div class="marker-row" style="margin-top:4px; margin-bottom:12px;"><span class="data-title">Attended: </span> <span class="data-value">${
          props.attended
        }</span></div>
				</div>`;
    } else {
      return `<div class="map-popup">
			<div class="marker-row marker-title">${props.name}</div>
			<div class="marker-row"><span class="data-title">Scanned: </span> <span class="data-value">${props.students_scanned}</span></div>
			</div>`;
    }
  }
  updateFeatureState(feature, state) {
    const featureSearch = {
      source: ENGAGEMENT_SOURCE_ID,
      id: feature.id
    };
    const previousState = this.map.getFeatureState(featureSearch);
    const newState = Object.assign(previousState, state);
    this.map.setFeatureState(featureSearch, newState);
  }
  circleClicked(position, feature) {
    // Clicking the map will call the dismiss of the current popup (if there is one).
    // That then triggers the close method defined below. If we call 'updateFeatureState' here first,
    // the close method will trigger and set the selected state to 'false' after we set it to 'true'.
    // Therefore we do this on next tick, to ensure the previous 'close' method has completed.
    Vue.nextTick(() => {
      this.updateFeatureState(feature, { selected: true });
      new mapboxgl.Popup()
        .setLngLat(position)
        .setHTML(this.getPopupHtml(feature.properties))
        .on('close', () => this.updateFeatureState(feature, { selected: false }))
        .addTo(this.map);
    });
  }
  setVisibleLayer(id) {
    const visibilityOf = (layer) => (layer === id ? 'visible' : 'none');
    if (this.type === TYPES.STUDENTS) {
      this.map.setLayoutProperty(LAYERS.REGISTERED_STUDENTS, 'visibility', visibilityOf(LAYERS.REGISTERED_STUDENTS));
      this.map.setLayoutProperty(LAYERS.ATTENDED_STUDENTS, 'visibility', visibilityOf(LAYERS.ATTENDED_STUDENTS));
    } else {
      this.map.setLayoutProperty(LAYERS.INSTITUTIONS, 'visibility', visibilityOf(LAYERS.INSTITUTIONS));
    }
  }
  async addEngagementLayer(layerId, sourceId, dataPoint, color, filter) {
    this.map.addLayer({
      id: layerId,
      type: 'circle',
      source: sourceId,
      paint: {
        'circle-color': color,
        'circle-radius': ['*', SCALE_FACTOR, ['+', ['round', ['sqrt', ['/', ['get', dataPoint], Math.PI]]], MIN_SCALE]],
        'circle-opacity': ifThenElseExpr(['feature-state', 'selected'], 1.0, 0.48),
        'circle-stroke-width': 3,
        'circle-stroke-color': color
      },
      filter: ['get', filter]
    });
  }

  loaded() {
    return new Promise((resolve, reject) => {
      this.map.on('load', () => {
        resolve();
      });
    });
  }
  loadImage(url) {
    return new Promise((resolve, reject) => {
      this.map.loadImage(url, (error, image) => {
        if (error) {
          reject(error);
        } else {
          resolve(image);
        }
      });
    });
  }
}
