Vue.component('register-profile', {
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      form: new SparkForm({
        first_name: '',
        last_name: '',
        title: '',
        contact_email: '',
        country_code: 'US',
        phone: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        snapchat: ''
      })
    };
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.form.title = this.user.title;
    this.form.contact_email = this.user.contact_email || this.user.email;
    this.form.phone = this.user.phone;
    this.form.facebook = this.user.facebook;
    this.form.twitter = this.user.twitter;
    this.form.youtube = this.user.youtube;
    this.form.instagram = this.user.instagram;
    this.form.snapchat = this.user.snapchat;
  },

  methods: {
    register() {
      if (this.form.phone) {
        let intlPhoneInput = $('#phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.country_code = countryData.iso2.toUpperCase();
        this.form.phone = number;
      }

      Spark.post('/register/step/4', this.form).then((response) => {
        window.location = response.redirect;
      });
    }
  }
});
