import Pagination from '../pagination';
import Template from './visitScanList.html';

export default Vue.extend({
  components: { Pagination },
  props: ['user', 'currentTeam', 'visit', 'backQueryParam'],
  data() {
    return {
      visitScans: null,
      fetchingVisitScans: false,
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      noStudentsMessage: null
    };
  },
  computed: {
    url() {
      return '/';
    },
    hasVisitScans() {
      if (this.visitScans == null) {
        return false;
      }

      return this.visitScans.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchVisitScans();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.visitScans === null) {
        this.fetchVisitScans();
      }
    });
  },
  created() {
    const self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = pageNumber;
    });
  },
  methods: {
    fetchVisitScans() {
      this.fetchingVisitScans = true;
      axios.get(this.url).then(
        (success) => {
          this.fetchingVisitScans = false;
          this.visitScans = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetchingVisitScans = false;
          this.errors = error.data.errors;
        }
      );
    }
  },
  template: Template
});
