import BaseVisitList from './visitList';

export default BaseVisitList.extend({
  props: ['query', 'order', 'createdByUserId'],
  methods: {
    fetchVisits() {
      if (this.query == '') {
        return;
      }

      let url =
        '/api/visits?limit=25' +
        '&occurrence=' +
        this.occurrence +
        '&search=' +
        this.query +
        '&page=' +
        this.pagination.current_page +
        '&order=' +
        this.order;

      if (this.createdByUserId !== 'any') {
        url += '&created_by_user_id=' + this.createdByUserId;
      }

      this.fetching = true;
      this.error = null;

      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.visits = success.data.data;
          this.pagination = success.data.meta.pagination;
          Bus.$emit('searchCompleted');
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    }
  },
  created() {
    const self = this;

    Bus.$on('performSearch', function() {
      self.pagination.current_page = 1;
      self.fetchVisits();
    });
  }
});
