<template>
  <div>
    <snackbar :show="alertSuccess" :message="alertMessage" />
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-hidden="true" @click="closeModal">
            &times;
          </button>
          <h3 class="modal-title">
            Share
          </h3>
        </div>

        <div class="modal-body">
          <div class="panel-body">
            <template v-if="isAdmin">
              <div class="invite-header">
                <p>Invite to Dashboard</p>
                <p class="subtitle">
                  List multiple email addresses separated by commas.
                </p>
              </div>
              <div class="invite-detail-container">
                <add-users-textarea
                  :button-title="'SEND INVITATIONS'"
                  :is-within-modal="true"
                  :permission-types="permissionTypes"
                  @invite-users="inviteUsers"
                />
              </div>
            </template>

            <p class="custom-margin">
              <strong>Pending Invites</strong>
            </p>
            <add-users-list
              :is-admin="isAdmin"
              :is-pending-users="true"
              @showAlertSuccess="showAlertSuccess"
              @resendInvite="resendInvite"
              @editUser="editUser"
            />
            <p class="custom-margin">
              <strong>People with Access</strong>
            </p>
            <add-users-list :is-admin="isAdmin" @showAlertSuccess="showAlertSuccess" @resendInvite="resendInvite" @editUser="editUser" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import AddUsersTextarea from './AddUsersTextarea.vue';
import AddUsersList from './AddUsersList.vue';
import Snackbar from '../../Snackbar.vue';

export default {
  name: 'ManageUsersPage',

  components: { AddUsersTextarea, AddUsersList, Snackbar },

  props: {
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    permissionTypes: {
      type: Array,
      default: () => [],
      required: false
    }
  },

  data() {
    return {
      loading: true,
      alertSuccess: false,
      alertMessage: 'Institution(s) added.'
    };
  },

  methods: {
    inviteUsers(emails, permissionType, schools) {
      this.alertSuccess = false;
      Api.fairs(window['fair_id'])
        .dashboard.inviteUsers(emails, permissionType, schools)
        .then((resp) => {
          this.alertSuccess = true;
          if (resp.data.errors && resp.data.errors.length > 0) {
            this.alertMessage = resp.data.errors[0].detail;
          } else {
            this.alertMessage = 'Institution(s) added.';
            Bus.$emit('resetAddUsersForm');
          }
        });
    },
    resendInvite(fairUserId) {
      this.alertSuccess = false;
      Api.fairs(window['fair_id'])
        .dashboard.resendInvite(fairUserId)
        .then(() => {
          this.alertSuccess = true;
          this.alertMessage = 'Invitation resent.';
        });
    },
    editUser(fairUser) {
      Bus.$emit('showEditFairUserPage', fairUser);
    },
    showAlertSuccess(message) {
      this.alertSuccess = true;
      this.alertMessage = message;
    },
    closeModal() {
      if (!this.isAdmin) {
        $('#modal-add-users-to-dashboard').modal('hide');
      }
      Bus.$emit('closeUsersModal');
    }
  }
};
</script>

<style scoped>
.custom-margin {
  margin-top: 20px;
  margin-bottom: 12px;
}

.invite-header {
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.042px;
}

.invite-header .subtitle {
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.invite-detail-container {
  background-color: #f3f4f6;
  border-radius: 4px;
  gap: 16px;
  margin-top: 20px;
  padding: 16px;
}

.invite-detail-container .textarea-container {
  margin-top: 0px;
}
</style>

<style>
.invite-detail-container .textarea-container label {
  font-family: 'Inter';
  font-size: 14px;
  font-style: 'normal';
  font-weight: 500;
  line-height: 20px;
}
</style>
