import ScanDateFilter from '../filters/date.MM.DD.YY';
import ScanTimeFilter from '../filters/time.hh.mm.A';

Vue.filter('scanDate', ScanDateFilter);
Vue.filter('scanTime', ScanTimeFilter);

Vue.component('user-fair-student', {
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userFairStudent: userFairStudent, // set on views/user-fair-students/show
      scanner: scanner, // set on views/user-fair-students/show
      changesHaveBeenMade: false,
      updating: false
    };
  },
  computed: {
    currentUserHasScannedStudent() {
      return this.scanner.id === this.user.id;
    },
    rankedStars() {
      return this.userFairStudent.rating;
    },
    unrankedStars() {
      let stars = 3 - this.rankedStars;

      if (stars < 0) {
        stars = 0;
      }

      return stars;
    },
    talkedToStudent() {
      return !this.talkedToParent;
    },
    talkedToParent() {
      return this.userFairStudent.parent;
    },
    userFairStudentSync() {
      return {
        rating: this.userFairStudent.rating,
        notes: this.userFairStudent.notes,
        follow_up: this.userFairStudent.follow_up,
        parent: this.userFairStudent.parent
      };
    }
  },
  watch: {
    // 'userFairStudent.notes': function() {
    //     this.resizeTextarea();
    // },
    userFairStudentSync() {
      if (this.updating) {
        return;
      }

      this.changesHaveBeenMade = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeTextarea();
    });
  },
  methods: {
    incrementRanking(starIndex) {
      if (!this.currentUserHasScannedStudent) {
        return;
      }
      this.userFairStudent.rating = this.userFairStudent.rating + (starIndex + 1);
    },
    decrementRanking(starIndex) {
      if (!this.currentUserHasScannedStudent) {
        return;
      }
      this.userFairStudent.rating = starIndex + 1;
    },
    resizeTextarea() {
      $.each($('textarea[data-autoresize]'), function() {
        var offset = this.offsetHeight - this.clientHeight;

        var resizeTextarea = (el) => {
          $(el)
            .css('height', 'auto')
            .css('height', el.scrollHeight + offset);
        };

        resizeTextarea(this);

        $(this)
          .on('keyup input', () => {
            resizeTextarea(this);
          })
          .removeAttr('data-autoresize');
      });
    },
    toggleFollowUp() {
      if (!this.currentUserHasScannedStudent) {
        return;
      }
      this.userFairStudent.follow_up = !this.userFairStudent.follow_up;
    },
    toggleParentStudent() {
      if (!this.currentUserHasScannedStudent) {
        return;
      }
      this.userFairStudent.parent = !this.userFairStudent.parent;
    },
    updateUserFairStudent() {
      if (!this.changesHaveBeenMade || !this.currentUserHasScannedStudent) {
        return;
      }

      this.updating = true;
      axios.patch('/api/v2/user-fair-students/' + this.userFairStudent.id, this.userFairStudentSync).then(
        (success) => {
          this.handleSuccessfulUpdate(success.data);
        },
        (error) => {
          this.updating = false;
          this.changesHaveBeenMade = false;
        }
      );
    },
    handleSuccessfulUpdate(response) {
      this.userFairStudent = response.data;
      swal('Done', 'Your changes have been saved!', 'success');
      this.$nextTick(() => {
        this.changesHaveBeenMade = false;
        this.updating = false;
      });
    }
  }
});
