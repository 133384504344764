Vue.component('fair-tags', {
  data() {
    return {
      tags: [],
      fetching: false,
      error: undefined,
      editingTag: undefined,
      newTag: undefined,
      creating: false
    };
  },

  mounted() {
    this.fetchTags();
  },

  methods: {
    fetchTags() {
      this.fetching = true;
      axios.get('/api/fair-tags?scope=all').then(
        (success) => {
          this.fetching = false;
          this.tags = success.data.data;
        },
        (error) => {
          this.fetching = false;
          this.error = error.data.errors;
        }
      );
    },
    create() {
      this.newTag = { title: '' };
    },
    confirmCreate() {
      this.error = undefined;
      this.creating = true;
      axios.post('/api/fair-tags/', this.newTag).then(
        (success) => {
          this.newTag = undefined;
          this.fetchTags();
          this.creating = false;
        },
        (error) => {
          this.error = error.data.errors;
          this.creating = false;
        }
      );
    },
    cancelCreate() {
      this.newTag = undefined;
    },
    edit(tag) {
      this.editingTag = tag;
    },
    save(tag) {
      this.error = undefined;
      axios.patch('/api/fair-tags/' + tag.id, tag).then(
        (success) => {
          this.editingTag = undefined;
        },
        (error) => {
          this.error = error.data.errors;
        }
      );
    },
    cancel(tag) {
      this.editingTag = undefined;
      this.fetchTags();
    },
    deleteTag(tag) {
      var _this = this;
      this.error = undefined;
      swal(
        {
          title: 'Delete Tag?',
          text:
            'Are you sure you want to delete the tag "' +
            tag.title +
            '?" All tagged fairs will have this tag removed. This operation cannot be undone.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Delete',
          closeOnConfirm: true,
          closeOnCancel: true,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (isConfirm) {
            axios.delete('/api/fair-tags/' + tag.id).then(
              (success) => {
                _this.editingTag = undefined;
                _this.fetchTags();
              },
              (error) => {
                _this.error = error.data.errors;
              }
            );
          }
        }
      );
    }
  }
});
