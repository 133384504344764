import Pagination from '../pagination';
import Template from './userFairStudentList.html';

export default Vue.extend({
  components: { Pagination },
  props: {
    user: {
      type: Object,
      default: null
    },
    currentTeam: {
      type: Object,
      default: null
    },
    fair: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userFairStudents: null,
      fetchingStudents: false,
      exporting: false,
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      noStudentsMessage: 'No students have been scanned for this fair yet'
    };
  },
  computed: {
    url() {
      return '/';
    },
    hasScannedStudents() {
      if (this.userFairStudents == null) {
        return false;
      }

      return this.userFairStudents.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchUserFairStudents();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.userFairStudents === null) {
        this.fetchUserFairStudents();
      }
    });
  },
  created() {
    var self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = pageNumber;
    });
  },
  methods: {
    fetchUserFairStudents() {
      this.fetchingStudents = true;
      axios.get(this.url).then(
        (success) => {
          this.fetchingStudents = false;
          this.userFairStudents = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetchingStudents = false;
          this.errors = error.data.errors;
        }
      );
    }
  },
  template: Template
});
