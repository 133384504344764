import Template from './index.html';

export default Vue.extend({
  props: {
    fair: {
      type: Object,
      default: null
    }
  },
  computed: {
    dashboardCustomText() {
      return this.fair.dashboard_custom_text;
    }
  },
  template: Template
});
