import { ApiSurface } from './api';

const BASE_URL = '/api/';

const TEAM_REQUESTS_BASE = (teamId) => {
  return `${BASE_URL}teams/${teamId}/team_requests`;
};

const TEAM_REQUESTS_LIST = (teamId, limit = 50) => {
  return `${TEAM_REQUESTS_BASE(teamId)}?limit=${limit}`;
};

/**
 * The paginated result meta data
 * @typedef {Object} ResultPaginationInfo
 * @property {number} current_page - the 1-based current page number
 * @property {number} per_page - the number of items per page passed to the request
 * @property {number} page_count - the total number of pages available
 * @property {number} total_count - the total number of items available
 */
/**
 * user object
 * @typedef {Object} User
 * @property {string} id - the id of the user
 * @property {string} name - the name of the user
 */
/**
 * request table entry
 * @typedef {Object} TeamRequest
 * @property {User} user_creator - an object containing the info of the user that created the request
 * @property {string} user_creator_id - the id of the user who created the request
 * @property {string} user_responder_id - the id of the user who received the request
 * @property {string} team_id - the id of the team that the user wants to join
 * @property {string} id - the id for the school
 * @property {string} status - the status of the request
 */
/**
 * The result of a schools table request
 * @typedef {Object} TeamRequestsListResult
 * @property {boolean} success - was the query successful
 * @property {TeamRequest[]} data - the list of requests
 * @property {ResultPaginationInfo} meta - the meta data about the result
 */

export class TeamRequestApiSurface extends ApiSurface {
  constructor(axiosHttp) {
    super(axiosHttp);
    /** @param {number} team_id - the team_id for the current request */
    this.teams_requests = (team_id) => {
      return {
        /**
         * @returns {Promise<TeamRequestsListResult>} the school details rows for the specified args
         */
        list: () => {
          return this._request({
            method: 'get',
            url: TEAM_REQUESTS_LIST(encodeURI(team_id))
          });
        },
        create: (data) => {
          return this._request({
            method: 'post',
            url: TEAM_REQUESTS_BASE(encodeURI(team_id)),
            data
          });
        },
        /**
         * @param {array|null} data - the body of the request
         * @returns {Promise} async
         */
        approve: (data) => {
          return this._request({
            method: 'patch',
            url: TEAM_REQUESTS_BASE(encodeURI(team_id)),
            data
          });
        },
        /**
         * @returns {Promise} async
         */
        reject: () => {
          return this._request({
            method: 'delete',
            url: TEAM_REQUESTS_BASE(encodeURI(team_id))
          });
        }
      };
    };
  }
}

export const TeamRequestApi = new TeamRequestApiSurface(window.axios);
