<template>
  <div>
    <snackbar :show="alertSuccess" :message="alertMessage" />
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-hidden="true" @click="goBack">
            &times;
          </button>
          <h3 class="modal-title">
            Update Access
          </h3>
        </div>

        <div class="modal-body">
          <div class="panel-body">
            <div class="invite-header">
              <p class="user-header-name">
                {{ userName }}
              </p>
              <p class="user-header-detail">
                {{ userDetail }}
              </p>
              <p class="user-header-detail">
                {{ userSubDetail }}
              </p>
            </div>

            <div>
              <label>Access</label>
              <br>
              <add-users-permission-select
                v-model="permissionType"
                class="permission-selector"
                :options="permissionTypes"
                :default-selection="defaultPermissionType"
              />
              <p class="invite-help-text">
                Learn about access <a href="https://www.strivescan.com/dashboard-permissions" target="_blank">here.</a>
              </p>
            </div>

            <div v-if="showSchoolPicker">
              <p class="custom-margin">
                <strong>Select School(s)</strong>
              </p>
              <add-users-school :schools="schools" @on-update-schools="updateSchools" />

              <div v-show="hasSchoolsError" class="error-block">
                <span class="material-icons">error_outline</span>
                <span>{{ schoolsError }}</span>
              </div>
            </div>

            <div class="btn-container">
              <button class="btn btn-secondary" @click="cancelChanges">
                CANCEL
              </button>
              <button class="btn btn-primary" @click="saveChanges">
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Api } from '../../../dashboard/dashboard-api';
import AddUsersPermissionSelect from './AddUsersPermissionSelect.vue';
import AddUsersSchool from './AddUsersSchool.vue';

import { DASHBOARD_PERMISSIONS } from '../../../constants/permissions';

export default {
  name: 'EditUserPage',

  components: { AddUsersSchool, AddUsersPermissionSelect },

  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    permissionTypes: {
      type: Array,
      default: () => [],
      required: false
    },
    fairUser: {
      type: Object,
      default: null,
      required: false
    }
  },

  data() {
    return {
      permissionType: null,
      schools: [],
      alertSuccess: false,
      alertMessage: 'Access updated.',
      submitted: false
    };
  },

  computed: {
    showSchoolPicker() {
      return this.permissionType && this.permissionType.name === DASHBOARD_PERMISSIONS['school'];
    },

    defaultPermissionType() {
      if (this.fairUser) {
        return this.fairUser.permission;
      }
      return null;
    },

    fairUserId() {
      return this.fairUser.id;
    },

    userId() {
      if (this.fairUser) {
        return this.fairUser.user.id;
      }
      return null;
    },

    userName() {
      if (this.fairUser && this.fairUser.user && this.fairUser.user.registered_profile === 1) {
        return this.fairUser.user.first_name + ' ' + this.fairUser.user.last_name;
      }
      return 'Pending Access';
    },

    userDetail() {
      if (this.fairUser && this.fairUser.user) {
        if (this.fairUser.user.registered_profile === 1) {
          return this.getUserTitle(this.fairUser.user);
        } else {
          return this.fairUser.user.email;
        }
      }
      return 'N/A';
    },

    userSubDetail() {
      // TODO: Add user sub detail
      if (this.fairUser && this.fairUser.user && this.fairUser.user.registered_profile === 1) {
        return this.fairUser.user.email;
      }
      return null;
    },

    schoolsError() {
      let hasSchoolField = (this.permissionType && this.permissionType.name === DASHBOARD_PERMISSIONS['school']) || !this.permissionType;

      if (hasSchoolField && this.schools && this.schools.length < 1) {
        return 'This field is required.';
      }
      return null;
    },
    hasSchoolsError() {
      return this.schoolsError && this.submitted;
    }
  },

  watch: {
    fairUser: function() {
      this.refreshUserPermissionType();
      this.fetchSchools();
    }
  },

  mounted() {
    this.refreshUserPermissionType();
  },

  methods: {
    fetchSchools() {
      if (!this.userId || (this.fairUser && this.permissionType.name !== DASHBOARD_PERMISSIONS['school'])) {
        this.loading = false;
        return;
      }
      this.loading = true;
      Api.fairs(window['fair_id'])
        .dashboard.listUserSchools(this.userId)
        .then((resp) => {
          this.schools = resp.data.data;
          this.loading = false;
        });
    },

    refreshUserPermissionType() {
      if (this.fairUser) {
        this.permissionType = this.fairUser.permission;
      }
    },

    updateSchools(schools) {
      this.schools = schools;
    },

    cancelChanges: function() {
      this.clearFields();
      this.goBack();
    },

    saveChanges: function() {
      this.submitted = true;
      if (this.error || this.schoolsError) {
        return;
      }

      let newAccess = this.permissionType.id;
      let schoolIds = this.schools.map(function(school) {
        return school.id;
      });
      let params = {
        permission_type: newAccess,
        school_ids: schoolIds
      };

      axios.patch('/api/fair-users/' + this.fairUser.id, params).then(
        (success) => {
          this.alertSuccess = true;
          setTimeout(() => {
            Bus.$emit('resetAddUsersForm');
            this.goBack();
          }, 1000);
        },
        (error) => {
          console.log('/api/fair-users/ error: ', error);
        }
      );
    },

    clearFields: function() {
      this.permissionType = null;
      this.schools = [];
    },

    goBack: function() {
      if (this.fairUser.user_id === window['user_id']) {
        location.reload();
      }
      this.alertSuccess = false;
      this.clearFields();
      Bus.$emit('cancelEditFairUser');
    },

    getUserTitle(user) {
      let result = '';
      if (user.registered_profile) {
        if (user.team && user.team.team_type_id == 1) {
          result += user.team.name;
        } else {
          if (user.school.school) {
            result += user.school.school + ', ';
          } else if (user.organization) {
            result += user.organization + ', ';
          }
        }
        if (user.title) {
          result += user.title;
        }
        return result;
      } else {
        return user.email;
      }
    }
  }
};
</script>

<style scoped>
.custom-margin {
  margin-top: 20px;
  margin-bottom: 12px;
}

.user-header-name {
  color: #181b26;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.user-header-detail {
  color: rgba(17, 24, 39, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.permission-selector {
  margin-bottom: 6px;
}

.btn {
  margin-left: 15px;
}

.btn-container {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}

.btn-secondary {
  color: #662c8f;
  background: none;
  outline: none !important;
}

.error-block {
  color: #c8472d;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 0;
}

.error-block .material-icons {
  font-size: 18px !important;
  vertical-align: middle;
}
</style>
