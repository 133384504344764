import { has, get, debounce } from 'lodash';
import Pagination from './PaginationVariant.vue';
import Loader from './dashboard/Loader.vue';

Vue.component('user-invoices', {
    components: { Pagination, Loader },

    data() {
        return {
            isLoading: true,
            invoices: [],
            sort: 'desc',
            search: '',
            date: '',
            pagination: {
                current_page: 1,
                page_count: 1,
                per_page: 20,
                total_count: 1
            },
        }
    },

    computed: {
        hasInvoices() {
            return this.invoices.length > 0;
        }
    },
    
    watch: {
        'pagination.current_page': function() {
            this.loadData();
        },
        'search': debounce(function() {
            this.loadData();
        }, 500),
        'date': debounce(function() {
            this.loadData();
        }, 500)
    },

    mounted() {
        this.loadData();
    },

    created() {
        var self = this;
        Bus.$on('currentPageUpdated', function(pageNumber) {
            self.pagination.current_page = Number(pageNumber);
        });
    },

    methods: {
        loadData() {
            this.isLoading = true;

            let url = '/api/v2/invoices?sort=' + this.sort + '&page=' + this.pagination.current_page;

            if (this.date) {
                url += '&date=' + this.date;
            }

            if (this.search) {
                url += '&search=' + this.search;
            }

            axios
                .get(url)
                .then(({data}) => {
                    this.invoices = data.data;
                    this.pagination = data.meta.pagination;
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlert('Error', 'Failed to load event regsitrations!');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        getInvoiceName(invoice) {
            return get(invoice, 'description', 'No Description');
        },

        getFormattedDate(transaction) {
            const centralTime = moment
                .unix(transaction.created)
                .tz('America/Chicago') // Use the correct timezone
                .utcOffset(-5, true); // UTC-6 for CST, UTC-5 for CDT

            return centralTime.format('MM-DD-YY');
        }
    }
});