import Template from './InstitutionCard.html';
import Snackbar from '../Snackbar.vue';

export default {
  template: Template,
  components: { Snackbar },
  name: 'institution-card',
  props: {
    name: {
      type: String,
      default: null
    },
    municipality: {
      type: String,
      default: null
    },
    region: {
      type: String,
      default: null
    },
    country: {
      type: String,
      default: null
    },
    website: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    hashCreatedAt: {
      type: String,
      default: null
    },
    hashStudentId: {
      type: String,
      default: null
    },
    fairId: {
      type: Number,
      default: null
    },
    teamId: {
      type: Number,
      default: null
    },
    regions: {
      type: Array,
      default: [],
    },
    imageUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      cancelSendAlertIsPending: false,
      sendTimer: null,
      updatedStatus: null,
      currentLocale,
      innerWidth
    };
  },
  computed: {
    cityStateCountry() {
      return `${this.municipality}, ${this.region && this.region !== '--' ? this.getRegionNameByAbbr(this.region) + ', ' : ''}${this.country}`;
    },
    displayStatus() {
      if (this.updatedStatus && this.updatedStatus == 'sent') {
        return this.updatedStatus;
      } else if (this.cancelSendAlertIsPending || this.isLoading) {
        return 'sent';
      } else {
        return this.status;
      }
    },
    undoToken() {
      return this.fairId + '-' + this.teamId;
    },
    alertTitle() {
      return this.$translate('Information submitted to') + ' ' + this.name;
    },
    websiteURL() {
      if (this.website && this.website.startsWith('http')) {
        return this.website;
      }
      return 'https://' + this.website;
    }
  },
  created() {
    let vm = this;
    Bus.$on('snackbar-undo-pressed', (data) => {
      if (data === vm.undoToken) {
        vm.cancelSendAlertIsPending = false;
        clearTimeout(vm.sendTimer);
      }
    });
  },
  methods: {
    get_string_for_locale(field) {
      switch (field) {
        case 'view-website':
          return this.$translate('View Website');
        case 'send-my-info':
          return this.$translate('SEND MY INFO');
        case 'info-sent':
          return this.$translate('INFO SENT');
        case 'scanned':
          return this.$translate('SCANNED');
      }
    },
    requestSendMyInfo() {
      if (this.isLoading || this.cancelSendAlertIsPending) {
        return;
      }
      this.cancelSendAlertIsPending = true;
      let vm = this;
      this.sendTimer = setTimeout(function () {
        vm.runSendMyInfoNetworkCall();
      }, 5000);
    },
    runSendMyInfoNetworkCall() {
      if (!this.cancelSendAlertIsPending || this.isLoading) {
        return;
      }
      this.cancelSendAlertIsPending = false;
      this.isLoading = true;
      let url = '/api/connections/' + this.hashCreatedAt + '/' + this.hashStudentId + '/' + this.teamId + '/' + this.fairId;
      axios
        .post(url)
        .then((success) => {
          this.didEndNetworkRequest();
          let data = success.data.data;
          this.updatedStatus = 'sent';
        })
        .catch((error) => {
          this.didEndNetworkRequest();
        });
    },
    didEndNetworkRequest() {
      this.isLoading = false;
    },
    cancelSendMyInfo() {
      if (!this.cancelSendAlertIsPending) {
        return;
      }
    },
    getRegionNameByAbbr(region) {
      const foundRegion = this.regions.find(r => r.abbreviation === region);
      return foundRegion ? foundRegion.region_name : region;
    },
  }
};
