<template>
  <div>
    <div v-show="fetching" class="panel panel-default">
      <div class="panel-body text-center">
        <i class="fa fa-spinner fa-spin fa-3x color-primary" />
      </div>
    </div>
    <div v-show="!fetching && !hasUploads" class="panel panel-default">
      <div class="panel-body">
        No Connect Fairs created
      </div>
    </div>

    <div v-show="!fetching && hasUploads" class="panel panel-default p-md">
      <h4 class="m-t-none">
        Created
      </h4>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Title</th>
            <th>Created at</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="upload in uploads" :key="upload.id">
            <td>{{ upload.title }}</td>
            <td>{{ upload.uploaded_at }}</td>
            <td>
              <a :href="upload.rendered_file">{{ upload.rendered_file }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="!fetching && hasUploads" class="text-center">
      <pagination :total-pages="pagination.page_count" :current-page="pagination.current_page" />
    </div>
  </div>
</template>
<script>
import Pagination from './pagination';

export default Vue.extend({
  components: { Pagination },
  props: [],
  data() {
    return {
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      noUploadsMessage: 'There are no uploads to display',
      fetching: false,
      uploads: null
    };
  },
  computed: {
    hasUploads() {
      if (this.uploads == null) {
        return false;
      }

      return this.uploads.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
    Bus.$on('reload-connect-fair-history', () => {
      this.loadData();
    });
  },
  created() {
    var self = this;
    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = Number(pageNumber);
    });
  },
  methods: {
    loadData() {
      this.fetching = true;
      axios.get('/api/connect-fair?page=' + this.pagination.current_page).then(
        (success) => {
          this.fetching = false;
          this.uploads = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    }
  }
});
</script>
