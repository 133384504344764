<template>
  <div>
    <div id="modal-add-users-to-dashboard" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <manage-users-page v-show="showManageUsersPage" :key="isAdmin" :is-admin="isAdmin" :permission-types="permissionTypes" />
      <edit-user-page v-show="!showManageUsersPage" :is-admin="isAdmin" :permission-types="permissionTypes" :fair-user="selectedFairUser" />
    </div>
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import ManageUsersPage from './ManageUsersPage.vue';
import EditUserPage from './EditUserPage.vue';

export default {
  name: 'AddUsersToDashboardModal',

  components: { ManageUsersPage, EditUserPage },

  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedFairUser: null,
      showEditUserPage: false,
      permissionTypes: []
    };
  },

  computed: {
    showManageUsersPage: function() {
      return this.selectedFairUser == null;
    }
  },

  mounted() {
    this.fetchPermissionTypes();
    Bus.$on('showEditFairUserPage', (fairUser) => {
      this.selectedFairUser = fairUser;
    });
    Bus.$on('cancelEditFairUser', this.cancelEditFairUser);
  },

  methods: {
    fetchPermissionTypes() {
      this.loading = true;
      Api.fairs(window['fair_id'])
        .dashboard.permissions()
        .then((resp) => {
          this.permissionTypes = resp.data.data;
          this.loading = false;
        });
    },

    cancelEditFairUser() {
      this.selectedFairUser = null;
    }
  }
};
</script>

<style>
.pill {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 999px;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.pill .close {
  margin-left: 6px;
  margin-top: -2px;
  font-size: 21px;
}
</style>
