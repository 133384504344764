const { default: axios } = require("axios");
import debounce from 'lodash/debounce';

Vue.component('spark-update-team-org', {
  props: ['user', 'team'],
  computed: {
  },
  methods: {
  },
  data() {
    return {
      form: new SparkForm({
        organization_type: this.team.organization_type ?? '',
        college_scorecard_dataset_id: this.team.college_scorecard_dataset_id,
      }),
      query: '',
      datasets: [],
      searching: false,
      college_scorecard_dataset: this.team.college_scorecard_dataset,
      debouncedSearch: debounce(function() {
        this.search();
      }, 300),
    };
  },

  methods: {
    update() {
      Spark.put(`/settings/teams/${this.team.id}/organization`, this.form)
        .then(() => {
          Bus.$emit('updateCurrentTeam');
        })
        .catch((error) => {
          console.error(error);
        });
    },
    search() {
      this.searching = true;
      axios.get(`/college-scorecard-datasets/search?query=${this.query}`)
        .then((response) => {
          this.searching = false;
          this.datasets = response.data;
        })
        .catch((error) => {
          this.searching = false;
          console.error(error);
        });
    },
    selectCollege(college) {
      this.form.college_scorecard_dataset_id = college.id;
      this.college_scorecard_dataset = college;
      this.query = '';
      this.datasets = [];
    },
    change() {
      this.form.college_scorecard_dataset_id = null;
      this.college_scorecard_dataset = null;
    },
  },

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    },
  },

  watch: {
    query() {
      if (this.query.length > 2) {
        this.debouncedSearch();
      } else {
        this.datasets = [];
      }
    },
  }
});
