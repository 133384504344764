export default {
  name: 'qr-save-as-button',
  // template: Template,
  props: {
    url: {
      type: String,
      default: null
    }
  },
  methods: {
    saveQRCode() {
      console.log('#saveQRCode QRSaveAsButton url:' + this.url);
      if (this.url) {
        const FileSaver = require('file-saver');
        FileSaver.saveAs(this.url, 'qrcode.png');
      }
    }
  }
};

{
  /* <style scoped>
    .download-your-barcode {
        margin-top: 16px;
    }

    .fa.fa-download {
        position: relative;
        top: 5px;
    }

    .text {
        flex: 1 0 auto;
        font-weight: bold;
    }
</style> */
}

{
  /* <template>
  <div class="download-your-barcode" @click="saveQRCode">
    <a href="#"><div class="text">@lang('download-your-barcode')</div></a>
  </div>
</template> */
}
