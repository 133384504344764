<template>
  <div id="textarea" :class="{ 'textarea-container': true, disabled, 'has-error': hasError }">
    <label>Recipient email(s)</label>
    <div tabindex="0" class="textarea" @click="focusInput" @focus="focusInput" @focusout="focusOutInput">
      <div v-for="(email, idx) in emails" :key="email + idx" class="pill">
        <div class="email-container">
          {{ email }}
        </div>
        <button
          type="button "
          class="close"
          @click="
            () => {
              removeEmail(idx);
            }
          "
        >
          &times;
        </button>
      </div>
      <input id="invisible-input" v-model="emailInput" class="invisible-input" @keyup="keyupEmailInput" @paste="onPaste">
    </div>
    <span v-show="hasError" class="help-block">
      <span class="material-icons">error_outline</span>
      <span>{{ error }}</span>
    </span>
    <div class="btn-container">
      <button class="btn btn-primary" @click="inviteUsers">
        {{ buttonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import { EMAIL_VALIDATION } from '../../constants/validations';

function resizable(el, factor) {
  var int = Number(factor) || 7.7;
  function resize() {
    el.style.width = (el.value.length + 1) * int + 'px';
  }
  var e = 'keyup,keypress,focus,blur,change'.split(',');
  for (var i in e) el.addEventListener(e[i], resize, false);
  resize();
}

export default {
  name: 'MissedConnectionsUsersTextarea',
  components: {},
  props: {
    buttonTitle: {
      type: String,
      default: ''
    },
    isWithinModal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      emails: [],
      emailInput: '',
      submitted: false
    };
  },
  computed: {
    error() {
      if (this.emails.length === 0) {
        return 'This field is required.';
      } else {
        for (let i = 0; i < this.emails.length; i++) {
          if (!EMAIL_VALIDATION.test(this.emails[i])) {
            return 'One or more email address is invalid.';
          }
        }
      }
      return null;
    },
    hasError() {
      return this.error && this.submitted;
    }
  },
  mounted() {
    resizable(document.getElementById('invisible-input'), 9);
    Bus.$on('resetAddUsersForm', this.clearForm);
    if (this.isWithinModal) {
      Bus.$on('closeUsersModal', this.closeModalAlert);
    }
  },
  methods: {
    focusInput() {
      document.getElementById('invisible-input').focus();
      document.getElementById('textarea').classList.add('focused');
    },
    focusOutInput() {
      document.getElementById('textarea').classList.remove('focused');
      document.getElementById('invisible-input').style.width = '10px';
      this.addEmails();
    },
    keyupEmailInput(e) {
      if (e.key == ',') {
        this.addEmails();
      }
    },
    onPaste(e) {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('Text');
      const emailArray = pastedText.split(',');
      emailArray.forEach((email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail.length > 0) {
          this.emails.push(trimmedEmail);
        }
      });
    },
    removeEmail(idx) {
      this.emails.splice(idx, 1);
    },
    addEmails() {
      let arr = this.emailInput.split(',');
      arr.forEach((str) => {
        str = str.trim();
        if (str.length > 0) {
          this.emails.push(str);
        }
      });
      this.emailInput = '';
    },
    inviteUsers() {
      this.submitted = true;
      if (!this.error) {
        this.disabled = true;
        this.$emit('invite-users', this.emails);
      }
    },
    clearForm() {
      this.emails = [];
      this.disabled = false;
      this.submitted = false;
    },
    closeModalAlert() {
      if (this.emails.length === 0) {
        this.closeModal();
      } else {
        Bus.$emit('open-custom-dialog', '', 'Discard unsent invitation(s)?', 'DISCARD', 'CANCEL', true, () => {
          this.closeModal();
        });
      }
    },
    closeModal() {
      this.clearForm();
      $('#modal-add-users-to-dashboard').modal('hide');
    }
  }
};
</script>

<style>
.pill {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 999px;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.pill .close {
  margin-left: 6px;
  margin-top: -2px;
  font-size: 21px;
}
.textarea {
  width: 100%;
  min-height: 56px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  padding: 13px 16px;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
}
.textarea-container.focused .textarea {
  border: 2px solid #662c8f;
  padding: 12px 16px;
}

.textarea-container.has-error .textarea {
  border: 1px solid #c8472d;
}

.textarea-container.focused label {
  color: #662c8f;
}
.textarea-container {
  padding: 16px;
  background: #f3f4f6;
  border-radius: 4px;
  margin-top: 10px;
}

.invisible-input {
  border: none;
  margin: 7px 0;
  min-width: 10px;
}
.invisible-input:focus {
  outline: none;
}
.btn-container {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}
label {
  font-weight: 500;
}
.textarea-container.disabled {
  pointer-events: none;
}
.textarea-container.disabled .pill {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.26);
}
.textarea-container.disabled .pill .close {
  opacity: 0.3;
}
.textarea-container.disabled .btn-primary {
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.26);
  outline: none !important;
}

.email-container {
  max-width: 300px;
  overflow-wrap: break-word;
}
</style>
