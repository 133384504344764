<template>
  <div>
    <snackbar :show="alertSuccess" :message="alertMessage" />

    <div id="modal-view-exports-history" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="closeModal">
              &times;
            </button>
            <h3 class="modal-title">
              Export Fair Data
            </h3>
          </div>

          <div class="modal-body">
            <div class="panel-body">
              <p class="info-text">
                Select the data you would like to export. A secure link to download it will be emailed to <strong>{{ userEmail }}</strong>. It may take several minutes to generate.
              </p>

              <div class="export-options">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="export-label">
                        Registrations (by attendee)
                      </td>
                      <td>
                        <button
                          class="btn export-btn"
                          :disabled="isLoadingRegistrationsByAttendee"
                          type="button"
                          @click="onEmailRegistrationsByAttendeeClicked"
                        >
                          EMAIL LINK
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="export-label">
                        Scans (by attendee)
                      </td>
                      <td>
                        <button
                          class="btn export-btn"
                          :disabled="isLoadingScansByAttendee"
                          type="button"
                          @click="onEmailScansByAttendeeClicked"
                        >
                          EMAIL LINK
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="export-label">
                        Scans (by institution)
                      </td>
                      <td>
                        <button
                          class="btn export-btn"
                          :disabled="isLoadingScansByInstitution"
                          type="button"
                          @click="onEmailScansByInstitutionClicked"
                        >
                          EMAIL LINK
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="export-label">
                        Connections (by attendee)
                      </td>
                      <td>
                        <button
                          class="btn export-btn"
                          :disabled="isLoadingConnectionsByAttendee"
                          type="button"
                          @click="onEmailConnectionsByAttendeeClicked"
                        >
                          EMAIL LINK
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="export-label">
                        Connections (by institution)
                      </td>
                      <td>
                        <button
                          class="btn export-btn"
                          :disabled="isLoadingConnectionsByInstitution"
                          type="button"
                          @click="onEmailConnectionsByInstitutionClicked"
                        >
                          EMAIL LINK
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="export-expansion">
                <div class="expansion-panel">
                  <custom-accordion :header-title="'EXPORT HISTORY'" :initial-state="0">
                    <div v-if="exports.length > 0">
                      <p class="info-text">
                        Displaying most recent 50 downloads
                      </p>
                      <div class="custom-list">
                        <div v-for="item in exports" :key="item.id" class="custom-list__item">
                          <p class="custom-list__item-small">
                            {{ formattedDate(item.exported_at, item.exported_at_timezone) }}
                          </p>
                          <p>{{ item.name }} downloaded {{ item.export_type }} <span v-if="item.schools">for {{ item.schools.map(s => s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(' ')).join(', ') }}</span> - {{ item.exported_records }} records</p>
                        </div>
                      </div>
                    </div>
                    <list-empty-state v-if="exports.length === 0" :no-border="false" title="There is no export activity for this fair.">
                      <template slot="icon">
                        <img src="/img/empty.svg">
                      </template>
                    </list-empty-state>
                  </custom-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import dateDisplay from '../../../mixins/dateDisplay';
import ListEmptyState from '../../ListEmptyState.vue';

const EMPTY_EXPORT_TITLE = 'Nothing to Download';
const EMPTY_EXPORT_SCANS_MESSAGE = 'There are no scanned students to download for this fair.';
const EMPTY_EXPORT_CONNECTIONS_MESSAGE = 'There are no connections to download for this fair.';
const TOO_LARGE_EXPORT_TITLE = 'Export Too Large';
const TOO_LARGE_EXPORT_MESSAGE = 'This export is too large to download directly. Please contact support.';

export default {
  name: 'ViewExportsHistoryModal',
  components: { ListEmptyState },
  mixins: [dateDisplay],
  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      alertSuccess: false,
      alertMessage: '',
      isLoadingRegistrationsByAttendee: false,
      isLoadingScansByAttendee: false,
      isLoadingConnectionsByAttendee: false,
      isLoadingConnectionsByInstitution: false,
      isLoadingScansByInstitution: false,
      exports: [],
      fairId: window['fair_id']
    };
  },
  mounted() {
    this.fetchHistory();
    Bus.$on('reloadExportHistory', this.fetchHistory);
    Bus.$on('refreshDashboard', this.fetchHistory);
  },
  methods: {
    fetchHistory() {
      Api.fairs(this.fairId)
        .dashboard.exportHistory({ limit: 50 })
        .then((resp) => {
          this.exports = resp.data.data;
        });
    },
    closeModal() {
      $('#modal-view-exports-history').modal('hide');
    },
    formattedDate(time, tz) {
      return this.fairDateTimeDisplay({ starts_at: time, starts_at_timezone: tz, ends_at: null });
    },
    onEmailRegistrationsByAttendeeClicked() {
      const app = this;
      app.isLoadingRegistrationsByAttendee = true;
      app.alertSuccess = false;
      Api.fairs(this.fairId)
        .dashboard.emailRegisteredStudents()
        .then((success) => {
          app.isLoadingRegistrationsByAttendee = false;
          app.alertSuccess = true;
          app.alertMessage = 'Registrations (by attendee) export in progress';
          Bus.$emit('reloadExportHistory');
        })
        .catch((error) => {
          app.isLoadingRegistrationsByAttendee = false;
          app.alertSuccess = false;
          if (error.response.status === 409) {
            app.errorAlert(EMPTY_EXPORT_TITLE, 'There are no registered students to download for this fair.');
          } else {
            app.errorAlert(TOO_LARGE_EXPORT_TITLE, TOO_LARGE_EXPORT_MESSAGE);
          }
        });
    },
    onEmailScansByAttendeeClicked() {
      const app = this;
      app.isLoadingScansByAttendee = true;
      app.alertSuccess = false;
      Api.fairs(this.fairId)
        .dashboard.emailScannedStudentsByAttendee()
        .then((success) => {
          app.isLoadingScansByAttendee = false;
          app.alertSuccess = true;
          app.alertMessage = 'Scans (by attendee) export in progress';
          Bus.$emit('reloadExportHistory');
        })
        .catch((error) => {
          app.isLoadingScansByAttendee = false;
          app.alertSuccess = false;
          if (error.response.status === 409) {
            app.errorAlert(EMPTY_EXPORT_TITLE, EMPTY_EXPORT_SCANS_MESSAGE);
          } else {
            app.errorAlert(TOO_LARGE_EXPORT_TITLE, TOO_LARGE_EXPORT_MESSAGE);
          }
        });
    },
    onEmailConnectionsByAttendeeClicked() {
      const app = this;
      app.isLoadingConnectionsByAttendee = true;
      app.alertSuccess = false;
      Api.fairs(this.fairId)
        .dashboard.emailConnectionsByAttendee()
        .then((success) => {
          app.isLoadingScansByAttendee = false;
          app.alertSuccess = true;
          app.alertMessage = 'Connections (by attendee) export in progress';
          Bus.$emit('reloadExportHistory');
        })
        .catch((error) => {
          app.isLoadingScansByAttendee = false;
          app.alertSuccess = false;
          if (error.response.status === 409) {
            app.errorAlert(EMPTY_EXPORT_TITLE, EMPTY_EXPORT_CONNECTIONS_MESSAGE);
          } else {
            app.errorAlert(TOO_LARGE_EXPORT_TITLE, TOO_LARGE_EXPORT_MESSAGE);
          }
        });
    },
    onEmailConnectionsByInstitutionClicked() {
      const app = this;
      app.isLoadingConnectionsByInstitution = true;
      app.alertSuccess = false;
      Api.fairs(this.fairId)
        .dashboard.emailConnectionsByInstitution()
        .then((success) => {
          app.isLoadingConnectionsByInstitution = false;
          app.alertSuccess = true;
          app.alertMessage = 'Connections (by institution) export in progress';
          Bus.$emit('reloadExportHistory');
        })
        .catch((error) => {
          app.isLoadingConnectionsByInstitution = false;
          app.alertSuccess = false;
          if (error.response.status === 409) {
            app.errorAlert(EMPTY_EXPORT_TITLE, EMPTY_EXPORT_CONNECTIONS_MESSAGE);
          } else {
            app.errorAlert(TOO_LARGE_EXPORT_TITLE, TOO_LARGE_EXPORT_MESSAGE);
          }
        });
    },
    onEmailScansByInstitutionClicked() {
      const app = this;
      app.isLoadingScansByInstitution = true;
      app.alertSuccess = false;
      Api.fairs(this.fairId)
        .dashboard.emailScannedStudentsByInstitution()
        .then((success) => {
          app.isLoadingScansByInstitution = false;
          app.alertSuccess = true;
          app.alertMessage = 'Scans (by institution) export in progress';
          Bus.$emit('reloadExportHistory');
        })
        .catch((error) => {
          app.isLoadingScansByInstitution = false;
          app.alertSuccess = false;
          if (error.response.status === 409) {
            app.errorAlert(EMPTY_EXPORT_TITLE, EMPTY_EXPORT_SCANS_MESSAGE);
          } else {
            app.errorAlert(TOO_LARGE_EXPORT_TITLE, TOO_LARGE_EXPORT_MESSAGE);
          }
        });
    },
    errorAlert(title, message) {
      Bus.$emit('open-custom-dialog', title, message, 'OKAY', null, false, null);
    }
  }
};
</script>

<style scoped>
.custom-list__item {
  font-size: 16px;
  margin-bottom: 24px;
}
.custom-list__item-small {
  font-weight: 600;
  font-size: 12px;
  color: rgba(17, 24, 39, 0.7);
}
.info-text {
  margin-bottom: 20px;
}
</style>
