import BlurValidationMixin from '../../components/validation/blurValidation';
import TwoFactorSelect from '../../components/two-factor-select.vue';
import IntlPhoneInput from '../../components/IntlPhoneInput.vue';

Vue.component('two-factor-auth', {
  components: { TwoFactorSelect, IntlPhoneInput },
  mixins: [BlurValidationMixin],
  props: ['user'],
  data() {
    return {
      qrCode: '',
      qrCodeImage: '',
      qrCodeKey: '',
      qrCodeRecoveryKey: '',
      form: new SparkForm({
        code: '',
        uses_two_factor_auth: false,
        two_factor_channel: 'sms',
        two_factor_email: '',
        two_factor_phone: {
          number: '',
          country_code: 'US',
          country_number: '1'
        }
      }),
      twilioFailure: '',
      loading: false,
      currentlyEnabled: false
    };
  },
  computed: {
    saveButtonDisabled() {
      if (this.form.busy) {
        return true;
      } else {
        if (this.form.uses_two_factor_auth) {
          if (!this.form.two_factor_channel) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    'form.two_factor_channel'() {
      delete this.form.errors.errors['email'];
      delete this.form.errors.errors['phone'];
      delete this.form.errors.errors['totp'];
    },
    'form.uses_two_factor_auth'() {
      delete this.form.errors.errors['email'];
      delete this.form.errors.errors['phone'];
      delete this.form.errors.errors['totp'];
    },
    user() {
      this.getUser();
    }
  },
  mounted() {
    this.getUser();
    this.getTotpQrCode();
  },
  methods: {
    save(verify = false) {
      if (this.form.uses_two_factor_auth) {
        if (this.form.two_factor_channel === 'sms') {
          if (!this.validate_phone(this.form.two_factor_phone.number)) {
            return false;
          }
        } else {
          if (!this.validate_email(this.form.two_factor_email)) {
            return false;
          }
        }
      }

      this.form.busy = true;
      axios
        .post('/api/auth/two-factor', this.form)
        .then((response) => {
          this.form.busy = false;
          $('#modal-2fa-code').modal('hide');
          this.successAlert('Account verified');
          this.currentlyEnabled = response.data.data.uses_two_factor_auth;
          this.form.code = '';
        })
        .catch((error) => {
          this.form.busy = false;
          this.isSubmitDisabled = false;

          if (error.response && error.response.data && error.response.data.errors) {
            if (error.response.data.errors.status === '403') {
              if (!verify) {
                this.openVerificationModal();
              } else {
                this.errorAlert('Incorrect Code', 'Please check the code and try again.');
              }
            } else {
              this.errorAlert('Error Saving Verification Method', error.response.data.errors.detail);
            }
          }
          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.twilioFailure = twilioError[0].detail;
            }
          }
        });
    },
    disableTwoFactor() {
      let app = this;
      if (this.currentlyEnabled) {
        swal(
          {
            title: 'Disable Two Factor Authentication?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'YES',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function(isConfirm) {
            if (isConfirm) {
              app.save();
            }
          }
        );
      }
    },
    openVerificationModal() {
      $('#modal-2fa-code').modal({ backdrop: 'static', keyboard: false });
    },
    closeVerificationModal() {
      $('#modal-2fa-code').modal('hide');
      this.errorAlert('Authentication method not verified.');
    },
    verifyPin() {
      if (this.validate_code(this.form.code)) {
        this.save(true);
      }
    },
    getUser() {
      this.form.two_factor_email = this.user.email;
      this.form.uses_two_factor_auth = this.user.uses_two_factor_auth;
      this.currentlyEnabled = this.user.uses_two_factor_auth;
      this.form.two_factor_channel = this.user.two_factor_channel;
      if (this.form.uses_two_factor_auth) {
        if (this.form.two_factor_channel === 'sms') {
          this.form.two_factor_phone.number = this.user.two_factor_recipient;
        }
      }
    },
    getTotpQrCode() {
      axios.get('/api/totp/generate').then((response) => {
        this.qrCode = response.data.url;
        this.qrCodeImage = response.data.image;
        this.qrCodeKey = response.data.secret;
        this.qrCodeRecoveryKey = response.data.recovery;
      });
    },
    validate_email(value) {
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          email: ['The email is required']
        });
        return false;
      }
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = 'The email must be a valid email address.';
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          email: [error_message]
        });
        return false;
      }
      delete this.form.errors.errors['email'];
      return true;
    },
    validate_code(value) {
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          code: ['The code is required']
        });
        return false;
      }
      let regex = /^\d+$/;
      if (!regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          code: ['The code has to be numeric']
        });
        return false;
      }
      delete this.form.errors.errors['code'];
      return true;
    },
    validate_phone(value) {
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          phone: ['The phone is required']
        });
        return false;
      }
      let regex = /^\d+$/;
      if (!regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          phone: ['The phone has to be numeric']
        });
        return false;
      }
      delete this.form.errors.errors['phone'];
      return true;
    },
    successAlert(title, message = '') {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
      this.form.errors[0] = message;
    }
  }
});
