import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';
import PROVINCES from '../constants/canadian-provinces';

Vue.component('student-signup-ontario-parent', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      redirectTo: urlParams.get('redirectTo'),
      loading: false,
      cookie_name: 'parent_registration_form',
      filter_query: null,
      relationshipTypes: [],
      gradeTypes: [],
      fields_to_validate: {
        parent_first_name: ['required'],
        parent_last_name: ['required'],
        parent_email: ['required', 'custom'],
        parent_email_confirmation: ['required', 'custom'],
        phone: ['required'],
        phone_country_code: ['required'],
        fair: ['required'],
        college_start_semester: ['required'],
        accept_tos: ['required']
      },
      form: new SparkForm({
        parent_first_name: '',
        parent_last_name: '',
        phone: '',
        phone_country_code: 'CA',
        parent_email: '',
        parent_email_confirmation: '',
        parent_relationship: '',
        fair: '',
        text_confirmation: false,
        college_start_semester: '',
        accept_tos: false
      }),
      start_university: [],
      isSubmitDisabled: false,
      phone: '',
      phone_country_code: 'CA',
      upcomingFairs: [],
      isHomeSchooled: false,
      schoolItems: [],
      availableCountries: [],
      ok_to_query_state: false,
      state_to_query: '',
      schoolQuery: '',
      selectedSchool: null,
      student: student,
      semesters: [],
      remember_info: false,
      twilioFailure: '',
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropDown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      provinces: PROVINCES
    };
  },
  computed: {
    fairIsVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    upcomingFairs: function() {
      if (this.upcomingFairs.length === 1) {
        this.form.fair = this.upcomingFairs[0].id;
      }
    },
    'form.fair': function() {
      $(() => {
        let selectedFair;

        this.querySchoolApi('');

        if (!this.student) {
          selectedFair = this.upcomingFairs.filter((f) => {
            return f.id === this.form.fair;
          });
        } else {
          let fairData = {
            id: this.student.fair.id,
            name: this.student.fair.name
          };
          selectedFair = [fairData];
        }

        if (!selectedFair) return;

        let selectedCountry = this.availableCountries.filter((c) => {
          return c.alpha2 === selectedFair[0].country;
        });

        this.setUpLocativeItemsFromCountry(selectedCountry);
      });
    }
  },
  created() {
    this.availableCountries = countries;
    this.relationshipTypes = relationshipTypes;
  },
  mounted() {
    if (typeof query != 'undefined') {
      this.filter_query = query;
    }
    var rawSemesters = [];

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        if (typeof currentLocale !== 'undefined' && currentLocale == 'fr') {
          rawSemesters.push('Printemps ' + offsetYear);
          rawSemesters.push('Automne ' + offsetYear);
        } else {
          rawSemesters.push('Spring ' + offsetYear);
          rawSemesters.push('Fall ' + offsetYear);
        }
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        if (typeof currentLocale !== 'undefined' && currentLocale == 'fr') {
          rawSemesters.push('Printemps ' + offsetYear);
          rawSemesters.push('Automne ' + offsetYear);
        } else {
          rawSemesters.push('Spring ' + offsetYear);
          rawSemesters.push('Fall ' + offsetYear);
        }
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (let i = 0; i <= 6; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();
      let offsetYear = null,
        upsetYear = null,
        strRepresentation = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + 1 + i;
        upsetYear = currentYear + 2 + i;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      } else {
        offsetYear = currentYear + i;
        upsetYear = currentYear + i + 1;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      }

      this.start_university.push(strRepresentation);
    }

    this.isSubmitDisabled = false;

    this.setStudentFromCookie();

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;

      $(() => {
        let canada = this.availableCountries.filter((c) => {
          return c.alpha2 === 'CA';
        });
        this.setUpLocativeItemsFromCountry(canada);
      });
    }

    this.getLocation();
    moment.locale(currentLocale);
  },
  methods: {
    setUpLocativeItemsFromCountry(selectedCountry) {
      if (selectedCountry.length) {
        if (!this.form.phone_country_code || (iti && !iti.getNumber(itiNumberFormats.NATIONAL))) {
          this.form.phone_country_code = selectedCountry[0].alpha2;
          iti.setCountry(selectedCountry[0].alpha2);
        }
      }
    },
    setFormPhone(phone, country_code, formatted_phone) {
      if (phone) {
        this.form.phone = phone;
        this.phone = formatted_phone;
        this.form.phone_country_code = country_code;
        $(() => {
          iti.setCountry(country_code);
          iti.setNumber(phone);
        });
      }
    },
    handleFairRadiusOnChanged(event) {
      this.fairRadius = event.target.value;
      if (this.lat && this.lng) {
        let lat, lng;
        lat = this.lat;
        lng = this.lng;
        this.setUpcomingFairs({ lat, lng });
      }
    },
    handleBarcodeCheckChanged(event) {
      if (event.target.checked) {
        if (student && student.phone_number) {
          this.setFormPhone(student.phone_number.number, student.phone_number.country_code, student.phone_number.formatted_number);
        }
      }
    },
    setUpcomingFairs(query) {
      this.loading = true;
      query.student_type = 'ONTARIO_PARENT';

      if (query.lat && query.lng) {
        query.radius = this.fairRadius;
      }

      query.occurrence = 'upcoming';
      query.limit = '999';

      let url = '/api/v2/fairs/students/index';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          this.upcomingFairs = data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }

          if (this.fairRadius === 'unknown' && data.meta && data.meta.radius) {
            let radius = data.meta.radius;
            if (radius <= 50) {
              this.fairRadius = 50;
            } else if (radius > 50 && radius <= 100) {
              this.fairRadius = 100;
            } else if (radius > 100 && radius <= 200) {
              this.fairRadius = 200;
            } else {
              this.fairRadius = 'any';
            }
          }
        })
        .catch((error) => {
          this.upcomingFairs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropDown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    validate_parent_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = this.$translate('The email must be a valid email address.');
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { parent_email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('parent_email', error_message);
      return true;
    },
    validate_parent_email_confirmation(value) {
      let error_message = this.$translate('The email confirmation does not match.');
      if (value !== this.form.parent_email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { parent_email_confirmation: [error_message] });
        return false;
      }
      this.remove_form_field_errors('parent_email_confirmation', error_message);
      return true;
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    signup() {
      if (this.phone) {
        let intlPhoneInput = $('#intl-phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
        this.form.phone = number;
      }

      if (this.fairIsVirtual && this.remember_info) {
        let rememberForm = {};
        Object.assign(rememberForm, this.form);
        delete rememberForm['fair'];
        delete rememberForm['accept_tos'];
        this.$cookies.set(this.cookie_name, JSON.stringify(rememberForm), '1d');
      }

      this.isSubmitDisabled = true;

      let url = '/ontario/parents';
      let method = 'post';

      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      }

      if (typeof currentLocale !== 'undefined' && currentLocale) {
        url = url + '/' + currentLocale;
      }

      Spark[method](url, this.form)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    setStudentForm(student) {
      //parent
      this.form.parent_first_name = student.first_name;
      this.form.parent_last_name = student.last_name;
      this.form.parent_email = student.email;
      this.form.parent_email_confirmation = student.email;
      this.form.parent_relationship = student.parent_relationship;
      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;
      this.form.college_start_semester = student.college_start_semester;
      this.form.text_confirmation = student.notify_by_text;
      this.form.accept_tos = true;

      if (student.notify_by_text) {
        this.setFormPhone(student.phone_number.number, student.phone_number.country_code, student.phone_number.formatted_number);
      }
    },
    setStudentFromCookie() {
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        for (let key in student) {
          if (key !== 'errors') {
            this.form[key] = student[key];
          }
        }
        //parent
        this.form.parent_first_name = student.first_name;
        this.form.parent_last_name = student.last_name;
        this.form.parent_email = student.email;
        this.form.parent_email_confirmation = student.email;
        this.form.parent_relationship = student.parent_relationship;
        this.form.text_confirmation = student.notify_by_text;
        this.form.accept_tos = true;

        if (student.notify_by_text) {
          this.setFormPhone(student.phone, student.phone_country_code, student.phone);
        }

        // this is a hack, to ensure we set the college_start_semester after other watches are triggered.
        if (student['college_start_semester']) {
          this.$nextTick().then(() => {
            this.form.college_start_semester = student['college_start_semester'];
          });
        }
      }
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=ONTARIO_PARENT&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFairValidation() {
      this.$nextTick(() => {
        this.remove_form_field_errors('fair', 'The fair field is required.');
      });
    },
    positionError(error) {
      console.error(error);
      this.loading = false;
      this.ok_to_query_state = true;
    }
  }
});