import Template from './index.html';
import visitOccurrenceMixin from '../../mixins/visit-occurrence';

export default {
  template: Template,
  props: ['visit', 'authUserId'],
  mixins: [visitOccurrenceMixin],
  computed: {
    visitCreatorName() {
      return this.visit.created_by_user.id == this.authUserId
        ? 'you'
        : this.visit.created_by_user.first_name + ' ' + this.visit.created_by_user.last_name;
    }
  }
};
