<script>
import SelectWithTypeAhead from './SelectWithTypeAhead.vue';

const UNLISTED_CEEB = '000003';
const HOMESCHOOLED_CEEB = '970000';

export default {
  name: 'SchoolSelectWithTypeAhead',
  extends: SelectWithTypeAhead,
  watch: {
    current: function() {
      this.show = false;
    }
  },
  created() {
    if (this.updateMode) {
      // Initially load up data on update mode
      this.$emit('on-query', this.model.school_name);
    }
  },
  methods: {
    chooseItem: function() {
      let format = this.patternFormat;

      if (this.items[this.current].CEEB === UNLISTED_CEEB || this.items[this.current].CEEB === HOMESCHOOLED_CEEB) {
        format = '%school';
      }

      let selectedString = format;
      this.patternValues.forEach((p) => {
        if (this.items[this.current].hasOwnProperty(p)) {
          selectedString = selectedString.replace('%' + p, this.items[this.current][p]);
        } else {
          selectedString = selectedString.replace('%' + p, '');
        }
      });

      this.selected = selectedString;
      this.$emit('on-select', this.items[this.current], this.current);
      this.show = false;
      this.query = null;
    }
  }
};
</script>
