<template>
  <div class="guide-content">
    <div v-if="firstSection" class="content-section highlighted" style="border: none;">
      <div v-html="firstSection.content" />
    </div>
    <div v-for="(section, index) in sections" :key="index" v-if="section.order_column > 1" class="content-section">
      <div class="expandable" :class="{
        'is-open': section.isOpen && section.isExpandable,
        'is-closed': !section.isOpen && section.isExpandable
      }" :ref="el => contentSections[section.order_column] = el">
        <h3>{{ section.title }}</h3>
        <div class="content-section-data" v-html="section.content" />
      </div>
      <div class="blur" v-if="!section.isOpen && section.isExpandable"></div>
      <div v-if="section.isExpandable">
        <div class="expandable-button expandable-open" v-if="!section.isOpen">
          <button @click="toggleSection(section)">
            <svg width="15" height="20" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.4998 14.8333H11.3332V19C11.3332 19.4583 10.9582 19.8333 10.4998 19.8333C10.0415 19.8333 9.6665 19.4583 9.6665 19V14.8333H5.49984C5.0415 14.8333 4.6665 14.4583 4.6665 14C4.6665 13.5416 5.0415 13.1666 5.49984 13.1666H9.6665V8.99996C9.6665 8.54163 10.0415 8.16663 10.4998 8.16663C10.9582 8.16663 11.3332 8.54163 11.3332 8.99996V13.1666H15.4998C15.9582 13.1666 16.3332 13.5416 16.3332 14C16.3332 14.4583 15.9582 14.8333 15.4998 14.8333Z"
                fill="#562782" />
            </svg> {{ $translate('View More') }}</button>
        </div>
        <div class="expandable-button expandable-close" v-if="section.isOpen">
          <button @click="toggleSection(section)">
            <svg width="15" height="20" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.2501 8.75831C14.9251 8.43331 14.4001 8.43331 14.0751 8.75831L10.0001 12.825L5.9251 8.74998C5.6001 8.42498 5.0751 8.42498 4.7501 8.74998C4.4251 9.07498 4.4251 9.59998 4.7501 9.92498L8.8251 14L4.7501 18.075C4.4251 18.4 4.4251 18.925 4.7501 19.25C5.0751 19.575 5.6001 19.575 5.9251 19.25L10.0001 15.175L14.0751 19.25C14.4001 19.575 14.9251 19.575 15.2501 19.25C15.5751 18.925 15.5751 18.4 15.2501 18.075L11.1751 14L15.2501 9.92498C15.5668 9.60831 15.5668 9.07498 15.2501 8.75831Z"
                fill="#562782" />
            </svg> {{ $translate('View Less') }}</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 22 22" fill="none">
            <path
              d="M17.3007 4.71C16.9107 4.32 16.2807 4.32 15.8907 4.71L11.0007 9.59L6.1107 4.7C5.7207 4.31 5.0907 4.31 4.7007 4.7C4.3107 5.09 4.3107 5.72 4.7007 6.11L9.5907 11L4.7007 15.89C4.3107 16.28 4.3107 16.91 4.7007 17.3C5.0907 17.69 5.7207 17.69 6.1107 17.3L11.0007 12.41L15.8907 17.3C16.2807 17.69 16.9107 17.69 17.3007 17.3C17.6907 16.91 17.6907 16.28 17.3007 15.89L12.4107 11L17.3007 6.11C17.6807 5.73 17.6807 5.09 17.3007 4.71Z"
              fill="#181B26" />
          </svg>
        </span>
        <img :src="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideContent',
  props: {
    details: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      sections: [],
      contentSections: {},
      showModal: false,
      image: null,
    };
  },
  mounted() {
    this.sections = this.details.filter((section) => section.title || section.content).map((section) => {
      return {
        order_column: section.order_column,
        title: section.title,
        content: section.content,
        isExpandable: section.content.length > 2000,
        isOpen: false,
      };
    });

    // wait a tick
    this.$nextTick(() => {
      this.setupImageClickEvent();
    });

    // Add event listener for escape key
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    // Clean up event listener
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  computed: {
    firstSection() {
      return this.sections.find((section) => section.order_column === 1);
    },
  },
  methods: {
    setupImageClickEvent() {
      const images = document.querySelectorAll('.content-section img');
      images.forEach(img => {
        img.addEventListener('click', () => {
          this.showImage(img.src);
        });
      });
    },
    showImage(imageSrc) {
      this.showModal = true;
      this.image = imageSrc;
    },
    closeModal() {
      this.showModal = false;
      this.image = null;
    },
    toggleSection(section) {
      const scrollPosition = window.scrollY; // Capture the current scroll position

      section.isOpen = !section.isOpen;

      this.$nextTick(() => {
        if (!section.isOpen) {
          const offset = document.querySelector('.guide-navbar')?.offsetHeight ?? 0;
          const sectionEl = this.contentSections[section.order_column];
          const sectionTop = sectionEl.getBoundingClientRect().top + window.scrollY;

          window.scrollTo({
            top: sectionTop - offset,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo(0, scrollPosition); // Restore the scroll position
        }
      });
    },
    handleKeyDown(event) {
      if (event.key === 'Escape' && this.showModal) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.guide-content {
  color: #4B4B4B;
  font-family: 'Quicksand';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 40px auto 0 auto;
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.highlighted {
  background-color: #F4F2F6;
  padding: 16px;
}

.highlighted :last-child {
  margin-bottom: 0;
}

h3 {
  color: #050505;
}

.content-section {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

::v-deep .content-section p, ::v-deep .content-section p img {
  text-align: left;
  width: 100%;
}

.expandable-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.expandable-button button {
  display: flex;
  justify-content: center;
  background: none;
  color: #562782;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
}

.expandable-button button svg {
  margin-right: 5px;
}

.is-open {
  max-height: 100%;
}

.is-closed {
  max-height: 240px;
  overflow: hidden;
  /* blur the bottom of this div */
}

.blur {
  display: flex;
  margin-top: -20px;
  margin-left: -20px;
  height: 30px;
  z-index: 100;
  background: linear-gradient(to bottom, rgba(245, 248, 250, 0.75), rgba(245, 248, 250, 1));
  filter: blur(5px);
}

@media screen and (max-width: 992px) {
  .guide-content {
    margin: 40px 20px;
    width: auto;
  }
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: #fefefe;
    padding: 15px;
    border: 1px solid #888;
    position: relative;
    display: inline-block;
    border-radius: 24px;

    img {
      max-height: 80vh;
      max-width: 80vw;
    }

    .close {
      background-color: #33FF94;
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
      opacity: 1;
      /* circular shape */
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>