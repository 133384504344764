<template>
  <div :class="{ panel: true, past: isPast }">
    <div class="panel-heading clearfix">
      <h3 class="panel-title">
        <div class="fair-name">
          {{ fair.name }}
        </div>
        <div class="pull-right d-flex align-items-start">
          <div v-if="isPast" class="panel-date-pill panel-date-pill-past">
            <span class="material-icons">history</span> Past
          </div>
          <div class="panel-date-pill">
            {{ fair.starts_at | fairStartDate }}
          </div>
        </div>
      </h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-12">
          <p class="dashboard-text dashboard-text-strong">
            {{ fair.venue.name }}
          </p>
          <p class="dashboard-text">
            {{ fairDate }}
          </p>
          <p class="dashboard-text mt-4">
            <span class="dashboard-text-strong">{{ fair.registered }}</span> Registered &nbsp;&nbsp;&nbsp;
            <span class="dashboard-text-strong">{{ fair.attended }}</span> Scanned
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-end">
        <div class="col-xs-12 col-sm-6">
          <a :href="'/dashboards/' + fair.id">
            <button class="btn pull-right btn-primary">
              View Dashboard&nbsp;&nbsp;&nbsp;
              <i class="fa fa-angle-right" />
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import dateDisplay from './../../../mixins/dateDisplay';

export default {
  name: 'DashboardFairListCard',
  mixins: [dateDisplay],
  props: {
    fair: {
      type: Object,
      default: null
    },
    actingAsAdmin: {
      type: Boolean,
      default: false
    },
    isVirtual: {
      type: Boolean,
      default: false
    },
    isPast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAdmin() {
      if (!this.actingAsAdmin) {
        return false;
      }

      return this.actingAsAdmin == 1;
    },
    fairDate() {
      return this.fairDateTimeDisplay(this.fair);
    }
  }
};
</script>

<style scoped>
.panel {
  border: 1px solid #2b3040;
}
.panel-heading {
  background: #2b3040;
  color: white;
  font-family: 'Inter'; /* TODO: fix font */
}
.past.panel {
  border: 1px solid rgba(0, 0, 0, 0.16);
}
.past .panel-heading {
  background: white;
  color: #181b26;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}
.past .panel-date-pill {
  background: rgba(0, 0, 0, 0.08);
}
.panel-title {
  font-weight: 700;
  font-size: 0.93em;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fair-name {
  padding-right: 5px;
}
.panel-date-pill {
  font-weight: 700;
  font-size: 0.86em;
  line-height: 16px;
  color: #181b26;
  background: white;
  border-radius: 20px;
  padding: 2px 8px;
  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-date-pill-past {
  min-width: none;
  margin-right: 5px;
}

.panel-date-pill-past .material-icons {
  font-size: 1.2em;
  display: block;
  margin-right: 5px;
}

.dashboard-text {
  font-family: 'Inter';
  font-style: normal;
  font-size: 1em;
  line-height: 20px;
  color: #181b26;
  font-weight: 400;
  margin-bottom: 4px;
}

.dashboard-text-strong {
  font-weight: 700;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.mt-4 {
  margin-top: 4px;
}
.btn-primary {
  padding: 8px 14px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1em;
}
</style>
