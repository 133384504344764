import Vue from 'vue';
import Fair from './fair';
import Pagination from './pagination';

export default Vue.extend({
  components: { Fair, Pagination },
  props: {
    listHash: {
      type: String,
      default: null
    },
    occurrence: {
      type: String,
      default: null
    },
    order: {
      type: String,
      default: null
    },
    actingAsAdmin: {
      type: Boolean,
      default: null
    },
    isVirtual: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      fairs: null,
      pagination: {
        current_page: 1,
        page_count: 1
      },
      fetching: false,
      errors: null,
      searching: false,
      searchInProgress: false,
      isActivePage: false,
      searchUnlocked: false
    };
  },
  computed: {
    hasFairs() {
      return this.fairs !== null && this.fairs.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchFairs();
    },
    query() {
      if (this.query == '') {
        this.searching = false;
      }
    }
  },
  mounted() {
    var self = this;
    Bus.$on('sparkHashChanged', function(hash, parameters) {
      self.isActivePage = hash === self.listHash;
      if (self.isActivePage) {
        if (self.fairs === null) {
          if (self.isVirtual && self.occurrence === 'upcoming') {
            return self.fetchFairs(true);
          } else {
            self.fetchFairs();
          }
        }
      }
    });
  },
  created() {
    var self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      if (self.isActivePage) {
        self.pagination.current_page = Number(pageNumber);
      }
    });

    Bus.$on('searchCompleted', function() {
      self.searchInProgress = false;
    });
  },
  methods: {
    fetchFairs(unlocked, hasEventGuide) {
      if (unlocked !== undefined) {
        this.searchUnlocked = unlocked;
      }

      this.fetching = true;
      this.error = null;
      var url = '/api/v2/fairs?limit=25&occurrence=' + this.occurrence + '&page=' + this.pagination.current_page + '&order=' + this.order;

      if (this.searchUnlocked) {
        url += '&locked_status=unlocked';
      }

      if (this.actingAsAdmin) {
        url += '&owned=false';
      }

      if (hasEventGuide !== undefined && hasEventGuide) {
        url += '&event_guide_on=true';
      }

      if (this.location) {
        url += `&state=${this.location}`;
      }

      if (this.tag) {
        url += `&tag=${this.tag}`;
      }

      if (this.isVirtual) {
        url += `&tag=virtual`;
      } else if (!this.actingAsAdmin) {
        url += `&exclude_tags=virtual`;
      }

      if (this.lockedStatus) {
        url += `&locked_status=${this.lockedStatus}`;
      }

      if (this.eventId) {
        url += `&with_event_id=${this.eventId}`;
      }

      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.fairs = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    },
    performSearch() {
      if (this.query == '' || this.fetching || this.searchInProgress) {
        return;
      }

      this.searching = true;
      this.searchInProgress = true;
      Bus.$emit('performSearch', this.query, this.location, this.tag, this.lockedStatus);
    },
    clearSearch() {
      this.query = '';
      this.error = null;
    }
  }
});
