import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';
import { getInterests } from '../constants/interests.js';
import {
  gradeDataWatcher,
  upcomingFairsWatcher,
  currentClassYearWatcher,
  acceptTosWatcher,
  fairWatcher,
  hasParentPermissionWatcher,
  birthdateDayWatcher,
  birthdateMonthWatcher,
  birthdateYearWatcher,
  autocompletedWatcher
} from '../students/watchers.js';
import {
  handleFairRadiusOnChangedMethod,
  validateSATScoreMethod,
  validateACTScoreMethod,
  validateEmailMethod,
  validateConfirmEmailMethod,
  schoolDataClearMethod,
  validateCollegeStartSemesterMethod,
  validateHighSchoolMethod,
  validateHighSchoolCityMethod,
  querySchoolApiMethod,
  clearFairValidationMethod,
  selectSchoolMethod,
  setUpcomingFairsMethod
} from '../students/methods.js';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-global', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      studentType: 'GLOBAL',
      radiusLimits: [50, 100, 200],
      redirectTo: urlParams.get('redirectTo'),
      cookie_name: 'global_registration_form',
      filter_query: null,
      loading: false,
      google: require('google-maps-api')(google_api_key, ['places']),
      countries: [],
      fields_to_validate: {
        fair: ['required'],
        first_name: ['required'],
        last_name: ['required'],
        email: ['required', 'custom'],
        email_confirmation: ['required', 'custom'],
        country_code: ['required'],
        phone: ['required'],
        phone_country_code: ['required'],
        birthdate: ['required'],
        birthdate_month: ['required'],
        birthdate_day: ['required'],
        birthdate_year: ['required'],
        address_line_1: ['required'],
        address_municipality: ['required'],
        address_country: ['required'],
        address_country_code: ['required'],
        high_school: ['required'],
        high_school_city: ['required'],
        current_year_class: ['required'],
        college_start_semester: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        toefl_score: ['custom'],
        ielts_score: ['custom']
      },
      form: new SparkForm({
        first_name: '',
        last_name: '',
        fair: '',
        email: '',
        email_confirmation: '',
        country_code: '',
        phone: '',
        phone_country_code: 'US',
        text_permission: false,
        birthdate: '',
        address_line_1: '',
        address_line_2: '',
        address_locality: '',
        address_municipality: '',
        address_region: '',
        address_postal_code: '',
        address_country: '',
        address_country_code: '',
        gender: '',
        gender_specify: '',
        grade_level: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        college_start_semester: '',
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        country_of_interest_1: '',
        country_of_interest_2: '',
        country_of_interest_3: '',
        email_confirmation_send: true,
        text_confirmation: true,
        authorize_cis: false,
        accept_tos: false,
        high_school_ceeb: '',
        high_school_country_code: '',
        high_school_region: '',
        birthdate_day: '',
        birthdate_month: '',
        birthdate_year: '',
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        toefl_score: '',
        ielts_score: '',
        current_year_class: null,
        graduation_year: '',
        has_parent_permission: false
      }),
      google_autocomplete: null,
      autocompleted: false,
      citizenships: [],
      maxCitizenships: 2,
      grades: [6, 7, 8, 9, 10, 11, 12],
      gradeSelectors: [],
      start_university: [],
      selectedInterests: [],
      interestItems: [],
      interestQuery: '',
      interests: getInterests(),
      maxInterests: 3,
      selectedCountries: [],
      maxCountries: 3,
      countryItems: [],
      isSubmitDisabled: false,
      birthdate: '',
      phone: '',
      upcomingFairs: [],
      isHomeSchooled: false,
      schoolItems: [],
      schoolQuery: '',
      selectedSchool: null,
      showHighschoolFields: false,
      availableCountries: [],
      student: student,
      months: [
        { value: '01', label: this.$translate('January') },
        { value: '02', label: this.$translate('February') },
        { value: '03', label: this.$translate('March') },
        { value: '04', label: this.$translate('April') },
        { value: '05', label: this.$translate('May') },
        { value: '06', label: this.$translate('June') },
        { value: '07', label: this.$translate('July') },
        { value: '08', label: this.$translate('August') },
        { value: '09', label: this.$translate('September') },
        { value: '10', label: this.$translate('October') },
        { value: '11', label: this.$translate('November') },
        { value: '12', label: this.$translate('December') }
      ],
      requiresParent: false,
      requiresParentUnderAge: 16,
      grade_data: null,
      twilioFailure: '',
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropDown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      remember_info: false,
    };
  },
  computed: {
    fairIsVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    grade_data: gradeDataWatcher,
    'form.current_year_class': currentClassYearWatcher,
    'form.accept_tos': acceptTosWatcher,
    'form.has_parent_permission': hasParentPermissionWatcher,
    'form.birthdate_day': birthdateDayWatcher,
    'form.birthdate_month': birthdateMonthWatcher,
    'form.birthdate_year': birthdateYearWatcher,
    upcomingFairs: upcomingFairsWatcher,
    autocompleted: autocompletedWatcher,
    'form.fair': fairWatcher
  },

  created() {
    this.availableCountries = countries;
  },

  mounted() {
    let app = this;
    app.google().then(function(maps) {
      app.google_autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['geocode'],
        fields: ['address_components']
      });
      app.google_autocomplete.setFields(['address_components', 'name']);
      app.google_autocomplete.addListener('place_changed', app.fillInAddress);
    });

    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    $('#gender_specify_input').on('click', function() {
      $('#gender_specify').click();
    });

    $(() => {
      $('#intl-phone').on('countrychange', (e, countryData) => {
        if (countryData && countryData.iso2) {
          this.form.phone_country_code = countryData.iso2.toUpperCase();
        }
        this.validate('phone_country_code', this.form.phone_country_code);
      });
    });

    for (let i = 0; i <= 6; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();
      let offsetYear = null,
        upsetYear = null,
        strRepresentation = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + 1 + i;
        upsetYear = currentYear + 2 + i;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      } else {
        offsetYear = currentYear + i;
        upsetYear = currentYear + i + 1;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      }

      // Create Grade Item
      let classOf = offsetYear + (Math.abs(12 - this.grades[i]) - Math.abs(6 - this.grades[i]));

      let gradeItem = {
        grade: this.grades[i],
        classOf: classOf,
        label: `${classOf}`
      };

      this.gradeSelectors.unshift(gradeItem);

      this.start_university.push(strRepresentation);
    }

    this.gradeSelectors.push({ grade: null, classOf: null, label: this.$translate('Transfer Student') });

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    this.isSubmitDisabled = false;

    this.resetInterests();
    this.resetCountries();
    this.getLocation();

    this.setStudentFromCookie();
    this.querySchoolApi('');
  },

  methods: {
    handleFairRadiusOnChanged: handleFairRadiusOnChangedMethod,
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropDown = true;
            this.setUpcomingFairs({ ...options, lat, lng});
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    setUpcomingFairs: setUpcomingFairsMethod,
    validate_email: validateEmailMethod,
    validate_email_confirmation: validateConfirmEmailMethod,
    validate_guidance_counselor_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = this.$translate('The email must be a valid email address.');
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { guidance_counselor_email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('guidance_counselor_email', error_message);
      return true;
    },
    validate_sat_score: validateSATScoreMethod,
    validate_act_score: validateACTScoreMethod,
    validate_toefl_score(value) {
      let error_message = this.$translate('The :test score must be between :min and :max.', {
        test: this.$translate('TOEFL'),
        min: 0,
        max: 120
      });
      if (value && (value < 0 || value > 120)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { toefl_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('toefl_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_ielts_score(value) {
      let error_message = this.$translate('The :test score must be between :min and :max.', {
        test: this.$translate('IELTS'),
        min: 0,
        max: 9
      });
      if (value && (value < 0 || value > 9)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { ielts_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('ielts_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_college_start_semester: validateCollegeStartSemesterMethod,
    validate_high_school: validateHighSchoolMethod,
    validate_high_school_city: validateHighSchoolCityMethod,
    fillInAddress() {
      let place = this.google_autocomplete.getPlace();

      let fields = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        postal_code: 'long_name',
        postal_code_suffix: 'long_name',
        country: 'long_name'
      };

      for (let i = 0; i < place.address_components.length; i++) {
        let address_type = place.address_components[i].types[0];

        fields[address_type] = place.address_components[i][fields[address_type]];
      }

      let street_number = fields.street_number !== 'short_name' ? fields.street_number : '';
      let route_name = fields.route !== 'long_name' ? fields.route : '';
      let street_number_and_route_name = '';
      street_number_and_route_name =
        street_number && route_name ? street_number + ' ' + route_name : street_number ? street_number : route_name;
      document.getElementById('autocomplete').value = street_number_and_route_name;
      this.form.address_line_1 = street_number_and_route_name;

      let locality = fields.locality !== 'long_name' ? fields.locality : '';
      if (!locality) {
        locality = fields.sublocality_level_1 !== 'long_name' ? fields.sublocality_level_1 : '';
      }
      document.getElementById('locality').value = locality;
      this.form.address_locality = locality;
      this.form.address_municipality = locality;

      let administrative_area_level_1 = fields.administrative_area_level_1 !== 'short_name' ? fields.administrative_area_level_1 : '';
      document.getElementById('administrative_area_level_1').value = administrative_area_level_1;
      this.form.address_region = administrative_area_level_1;

      let postal_code = fields.postal_code !== 'long_name' ? fields.postal_code : '';
      let postal_code_suffix = fields.postal_code_suffix !== 'long_name' ? fields.postal_code_suffix : '';
      let post_code_and_suffix =
        postal_code && postal_code_suffix ? postal_code + '-' + postal_code_suffix : postal_code ? postal_code : postal_code_suffix;
      document.getElementById('address_postal_code').value = post_code_and_suffix;
      this.form.address_postal_code = post_code_and_suffix;

      let country_code = '';
      place.address_components.filter((ac) => {
        if (ac.types[0] === 'country') {
          country_code = ac.short_name;
        }
      });

      this.form.address_country_code = country_code;

      this.autocompleted = true;
    },
    resetCountriesList() {
      let countries = window.countries;

      countries = differenceBy(countries, this.citizenships, 'name');

      this.availableCountries = countries;
    },
    resetCountryOfCitizenshipPositions() {
      this.form.country_of_citizenship_1 = this.citizenships[0] ? this.citizenships[0].alpha2 : '';
      this.form.country_of_citizenship_2 = this.citizenships[1] ? this.citizenships[1].alpha2 : '';
    },
    queryCountry(query) {
      let countryArr = [];

      this.availableCountries.filter((country) => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
          countryArr.push(country);
        } else {
          this.resetCountriesList();
        }
      });

      if (query === null) this.resetCountriesList();

      this.availableCountries = countryArr;
    },
    selectCountryOfCitizenship(selected, index) {
      if (selected === null && index === -1) {
        this.resetCountriesList();
      }

      if (selected && this.citizenships.length < this.maxCitizenships) {
        this.citizenships.push(selected);
        let input = $('#citizenship-country-search input');
        input.val(null);
        input.blur();

        if (this.citizenships.length >= this.maxCitizenships) input.hide();

        this.resetCountriesList();
      }

      this.resetCountryOfCitizenshipPositions();
    },
    removeCitizenship(index) {
      this.citizenships.splice(index, 1);
      this.resetCountriesList();

      let input = $('#citizenship-country-search input');

      if (this.citizenships.length < this.maxCitizenships) {
        input.show();
      }

      this.resetCountryOfCitizenshipPositions();
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);
        let input = $('#interests-search input');
        input.val('');
        input.blur();

        if (this.selectedInterests.length >= 3) {
          input.hide();
        }

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    removeCountries(index) {
      this.selectedCountries.splice(index, 1);
      this.resetCountryPosition();

      let input = $('#countries-search input');

      if (this.selectedCountries.length < 3) {
        input.show();
      }
    },
    resetCountries() {
      let countries = this.studyCountries;

      countries = differenceBy(countries, this.selectedCountries, 'name');

      this.countryItems = countries;
    },
    resetCountryPosition() {
      this.form.country_of_interest_1 = this.selectedCountries[0] ? this.selectedCountries[0].alpha2 : '';
      this.form.country_of_interest_2 = this.selectedCountries[1] ? this.selectedCountries[1].alpha2 : '';
      this.form.country_of_interest_3 = this.selectedCountries[2] ? this.selectedCountries[2].alpha2 : '';
    },
    queryCountries(query) {
      let countries = [];

      this.resetCountries();

      this.countryItems.filter((item) => {
        if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          countries.push({
            name: item.name,
            alpha2: item.alpha2
          });
        }
      });

      this.countryItems = countries;
    },
    selectCountry(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedCountries.length < this.maxCountries) {
        this.selectedCountries.push(selectedObj);
        let input = $('#countries-search input');
        input.val('');
        input.blur();

        if (this.selectedCountries.length >= this.maxCountries) {
          input.hide();
        }

        this.resetCountryPosition();
      }
    },
    querySchoolApi: querySchoolApiMethod,
    selectSchool: selectSchoolMethod,
    signup() {
      if (this.phone) {
        let intlPhoneInput = $('#intl-phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
        this.form.phone = number;
      }

      if (this.fairIsVirtual && this.remember_info) {
        let rememberForm = {};
        Object.assign(rememberForm, this.form);
        delete rememberForm['fair'];
        delete rememberForm['accept_tos'];
        delete rememberForm['authorize_cis'];
        this.$cookies.set(this.cookie_name, JSON.stringify(rememberForm), '1d');
      }

      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      this.isSubmitDisabled = true;

      let url = '/student-global';
      let method = 'post';

      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      } else if (typeof currentLocale !== 'undefined' && currentLocale !== 'en') {
        url += `/${currentLocale}`;
      }

      Spark[method](url, this.form)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    setStudentForm(student) {
      this.autocompleted = true;

      // Popular required personal fields
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.email_confirmation = student.email;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);
      this.form.text_permission = student.text_permission;
      this.form.has_parent_permission = student.has_parent_permission;

      this.form.address_line_1 = student.student_address.line1;
      this.form.address_line_2 = student.student_address.line2;
      this.form.address_locality = student.student_address.locality;
      this.form.address_municipality = student.student_address.municipality;
      this.form.address_postal_code = student.student_address.postal_code;
      this.form.address_country_code = student.student_address.country_code;
      this.form.address_region = student.student_address.region;

      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      this.form.high_school = student.high_school;
      this.form.high_school_ceeb = student.CEEB;
      this.form.high_school_id = student.school_id;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_country_code = student.high_school_country;
      this.form.grade_level = student.grade_level;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      this.form.college_start_semester = student.college_start_semester;

      // pre-populate Academic interests
      let interests_array = [];
      if (student.area_of_interest_1) {
        interests_array.push({ value: this.$translate(student.area_of_interest_1) });
      }

      if (student.area_of_interest_2) {
        interests_array.push({ value: this.$translate(student.area_of_interest_2) });
      }

      if (student.area_of_interest_3) {
        interests_array.push({ value: this.$translate(student.area_of_interest_3) });
      }
      this.selectedInterests = interests_array;

      if (interests_array.length >= 3) {
        let input = $('#interests-search input');
        input.hide();
      }

      this.resetInterestPositions();
      this.resetInterests();

      this.form.text_confirmation = student.notify_by_text;

      // Set Birthdate
      let splitBirthdate = this.birthdate.split('/');
      this.form.birthdate_month = splitBirthdate[0];
      this.form.birthdate_day = splitBirthdate[1];
      this.form.birthdate_year = splitBirthdate[2];

      // GPA / ACT / SAT / IELTS / TOEFL
      if (student.gpa > 0) {
        this.form.gpa = parseFloat(student.gpa).toFixed(2);
      }

      if (student.gpa_max > 0) {
        this.form.gpa_max = parseFloat(student.gpa_max).toFixed(2);
      }

      if (student.sat_score > 0) {
        this.form.sat_score = student.sat_score;
      }

      if (student.act_score > 0) {
        this.form.act_score = student.act_score;
      }

      if (student.toefl_score > 0) {
        this.form.toefl_score = student.toefl_score;
      }

      if (student.ielts_score > 0) {
        this.form.ielts_score = student.ielts_score;
      }

      // Set Current Year / Grade Level
      let currentYearGradeLevel = this.gradeSelectors.filter((item) => {
        return item.label === student.current_year_class;
      });
      this.grade_data = currentYearGradeLevel[0];

      this.validateAge();
      if (this.requiresParent) {
        this.form.has_parent_permission = true;
      }
      this.form.accept_tos = true;

      // Set Phone number
      this.form.phone = student.phone_number.number;
      this.phone = student.phone_number.formatted_number;
      this.form.phone_country_code = student.phone_number.country_code;
      $(() => {
        iti.setCountry(student.phone_number.country_code);
        iti.setNumber(student.phone_number.number);
      });
    },
    setStudentFromCookie() {
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        for (let key in student) {
          if (key !== 'errors') {
            this.form[key] = student[key];
          }
        }

        this.autocompleted = true;

        // Popular required personal fields
        this.form.email_confirmation = student.email;
        this.birthdate = moment(student.birthdate)
          .utc()
          .format('MM/DD/YYYY');
        this.form.birthdate = new Date(this.birthdate);

        if (student.school_ceeb_code === CEEB_UNLISTED) {
          this.showHighschoolFields = true;
        }

        this.form.college_start_semester = student.college_start_semester;

        // pre-populate Academic interests
        let interests_array = [];
        if (student.area_of_interest_1) {
          interests_array.push({ value: this.$translate(student.area_of_interest_1) });
        }

        if (student.area_of_interest_2) {
          interests_array.push({ value: this.$translate(student.area_of_interest_2) });
        }

        if (student.area_of_interest_3) {
          interests_array.push({ value: this.$translate(student.area_of_interest_3) });
        }
        this.selectedInterests = interests_array;

        if (interests_array.length >= 3) {
          let input = $('#interests-search input');
          input.hide();
        }

        this.resetInterestPositions();
        this.resetInterests();

        // Set Current Year / Grade Level
        let currentYearGradeLevel = this.gradeSelectors.filter((item) => {
          return item.label === student.current_year_class;
        });
        this.grade_data = currentYearGradeLevel[0];

        this.validateAge();
        if (this.requiresParent) {
          this.form.has_parent_permission = true;
        }
        this.form.accept_tos = true;

        // Set Phone number
        this.form.phone = student.phone;
        this.phone = student.phone;
        this.form.phone_country_code = student.phone_country_code;
        $(() => {
          iti.setCountry(student.phone_country_code);
          iti.setNumber(student.phone);
        });

        // this is a hack, to ensure we set the college_start_semester after other watches are triggered.
        if (student['college_start_semester']) {
          this.$nextTick().then(() => {
            this.form.college_start_semester = student['college_start_semester'];
          });
        }
      }
    },
    formatBirthdate() {
      return `${this.form.birthdate_month}/${this.form.birthdate_day}/${this.form.birthdate_year}`;
    },
    validateAge() {
      let isValidBirthdate = moment(this.birthdate, ['MM/D/YYYY', 'MM/DD/YYYY'], true).isValid();
      if (this.form.birthdate_day && this.form.birthdate_month && this.form.birthdate_year && isValidBirthdate) {
        const age = moment().diff(new Date(this.birthdate), 'years');
        this.requiresParent = false;
        if (age < this.requiresParentUnderAge) {
          this.requiresParent = true;
          this.isSubmitDisabled = !this.form.has_parent_permission;
        } else {
          this.isSubmitDisabled = false;
        }
      }
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=GLOBAL&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFairValidation: clearFairValidationMethod,
    schoolDataClear: schoolDataClearMethod
  }
});
