Vue.component('fair-credits', {
  props: {
    user: {
      type: Object,
      default: null
    },
    team: {
      type: Object,
      default: null
    },
    billableType: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      form: new SparkForm({
        new_tokens: '',
      }),
      currentTeam: undefined,
      cost_per_credit: 26,
      cpc: 0,
      custom_amount: false,
      creditsGroup: [
        [1, 0],
        [10, 0.0192],
        [20, 0.0385],
        [50, 0.0769],
        [100, 0.1154]
      ]
    };
  },

  computed: {
    cardIcon() {
      if (!this.user.pm_type) {
        return 'fa-cc-stripe';
      }

      switch (this.user.pm_type) {
        case 'American Express':
          return 'fa-cc-amex';
        case 'Diners Club':
          return 'fa-cc-diners-club';
        case 'Discover':
          return 'fa-cc-discover';
        case 'JCB':
          return 'fa-cc-jcb';
        case 'MasterCard':
          return 'fa-cc-mastercard';
        case 'Visa':
          return 'fa-cc-visa';
        default:
          return 'fa-cc-stripe';
      }
    }
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.currentTeam = Spark.state.currentTeam;
    // this.user = Spark.state.user;
    this.form.new_tokens = 0;
  },

  methods: {
    purchase() {
      let app = this;
      swal(
        {
          title: 'Purchase Credits?',
          text: 'You are purchasing ' + app.form.new_tokens + ' credits for ' + (new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(this.getTotal())) + '.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Purchase',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true,
          customClass: 'wide-swal'
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'Credits have not been purchased.');
          } else {
            Spark.put(`/credits`, app.form).then(
              ($result) => {
                Bus.$emit('updateTeam');
                Bus.$emit('updateTeams');
                app.currentTeam.tokens = $result.tokens;
                app.form.new_tokens = 0;
                app.successAlert('Purchased!', 'Your purchase was successful and the Fair Credits have been added to your account!');
              },
              () => {
                app.errorAlert(
                  'Error!',
                  'There was a problem processing your payment. Please try again. If this issue persists, please try a different payment method.'
                );
              }
            );
          }
        }
      );
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
      this.form.errors[0] = message;
    },
    round(number, precision) {
      var factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    },
    getCreditTotalCost(item) {
      const count = item[0];
      const discount = item[1];
      if(count >= 10) {
        return Math.round((count * this.cost_per_credit * (1 - discount)));
      }
      return this.cost_per_credit;
    },
    getDiscountRate(count) {
      let nearestItem = 0;
      for (let i = 0; i < this.creditsGroup.length; i++) {
          if (this.creditsGroup[i][0] <= count) {
              nearestItem = this.creditsGroup[i][1];
          } else {
              break;
          }
      }
      return nearestItem;
    },
    getSavings() {
      let nearestItem = 0;
      for (let i = 0; i < this.creditsGroup.length; i++) {
          if (this.creditsGroup[i][0] <= this.form.new_tokens) {
              nearestItem = this.creditsGroup[i][0];
          } else {
              break;
          }
      }
      let discount = this.getDiscountRate(this.form.new_tokens);
      return (nearestItem * this.cost_per_credit) * discount;
    },
    getSavingAmount(item) {
      let savings_amount = 0;
      if(item[1] > 0) {
        savings_amount = (this.cost_per_credit * item[1]) * item[0];
        return "Save $" + Intl.NumberFormat().format(Math.round(savings_amount));
      }
      
      return null;
    },
    getTotal() {
      let nearestItem = 0;
      for (let i = 0; i < this.creditsGroup.length; i++) {
          if (this.creditsGroup[i][0] <= this.form.new_tokens) {
              nearestItem = this.creditsGroup[i][1];
          } else {
              break;
          }
      }

      let rate = this.cost_per_credit - (this.cost_per_credit * nearestItem);
      let multiply = this.round(rate, 1) * this.form.new_tokens;
      return this.round(multiply, 1);
    },
    getTotalSavings() {
      let total = this.cost_per_credit * this.form.new_tokens;
      return this.round((total - this.getTotal()), 1);
    },
    disableCustom() {
      this.custom_amount = false;
    },
    validateInput() {
      this.form.new_tokens = this.form.new_tokens.replace(/[^0-9]/g, '');;
    }
  }
});
