export default {
  methods: {
    saveFile(success) {
      const contentType = success.headers['content-type'];
      const blob = new Blob([success.data], { type: contentType });
      let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
      if (fileName.charAt(0) === '"') {
        fileName = fileName.substr(1);
      }
      if (fileName.charAt(fileName.length - 1) === '"') {
        fileName = fileName.substr(0, fileName.length - 1);
      }
      FileSaver.saveAs(blob, fileName);
    }
  }
};
