module.exports = {
  props: ['team', 'invitations', 'roles', 'user'],

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    }
  },

  methods: {
    /**
     * Get the displayable role for the given invitation.
     */
    teamMemberRole(invitation) {
      if (this.roles.length == 0) {
        return '';
      }

      const role = _.find(this.roles, (role) => role.value == invitation.role);

      if (typeof role !== 'undefined') {
        return role.text;
      }
    },
    /**
     * Cancel the sent invitation.
     */
    cancel(invitation) {
      axios.delete(`/settings/invitations/${invitation.id}`).then(() => {
        this.$parent.$emit('updateInvitations');
      });
    }
  }
};
