import Template from './index.html';
import moment from 'moment';

export default {
  template: Template,
  props: ['fair', 'actingAsAdmin', 'isVirtual'],
  computed: {
    isAdmin() {
      if (!this.actingAsAdmin) {
        return false;
      }

      return this.actingAsAdmin == 1;
    },
    isLocked() {
      return !this.isUnlocked;
    },
    isUnlocked() {
      return this.fair.unlocked;
    },
    hasOccurred() {
      return moment.utc().diff(moment(this.fair.starts_at), 'hours') > 6;
    },
    isOccurring() {
      // if current time is -6 hours or greater from the start time
      let withinStartWindow = moment.utc().diff(moment(this.fair.starts_at), 'hours') >= -6;

      // if current time is 6 hours or less from the start time
      let withinEndWindow = moment.utc().diff(moment(this.fair.starts_at), 'hours') <= 6;

      return withinStartWindow && withinEndWindow;
    },
    willOccur() {
      return moment.utc().diff(moment(this.fair.starts_at), 'hours') < -6;
    },
    timeToStartPrefix() {
      if (this.hasOccurred) return 'Started';
      if (this.willOccur) return 'Starts in';
    },
    timeToStart() {
      let differenceInHours = Math.abs(moment.utc().diff(moment(this.fair.starts_at), 'hours'));
      if (differenceInHours < 24) {
        let string = differenceInHours + ' hour';
        if (differenceInHours > 1) {
          string = string + 's';
        }

        return string;
      }

      let differenceInDays = Math.abs(moment.utc().diff(moment(this.fair.starts_at), 'days'));
      if (differenceInDays < 30) {
        let string = differenceInDays + ' day';
        if (differenceInDays > 1) {
          string = string + 's';
        }

        return string;
      }

      let differenceInMonths = Math.abs(moment.utc().diff(moment(this.fair.starts_at), 'months'));
      let string = differenceInMonths + ' month';
      if (differenceInMonths > 1) {
        string = string + 's';
      }

      return string;
    },
    timeToStartSuffix() {
      if (this.hasOccurred) {
        return 'ago';
      }

      return '';
    }
  }
};
