<template>
  <div>
    <div v-show="fetchingStudents" class="panel panel-default">
      <div class="panel-body text-center">
        <i class="fa fa-spinner fa-spin fa-3x color-primary" />
      </div>
    </div>
    <div v-show="!fetchingStudents && !hasStudentConnections" class="panel panel-default">
      <div class="panel-body">
        {{ noStudentsMessage }}
      </div>
    </div>

    <div v-show="!fetchingStudents && hasStudentConnections" class="panel panel-default">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Institution</th>
            <th>Year</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="connection in studentConnections" :key="connection.id">
            <td>
              <span v-if="connection.student_submission_form == 'parents' || connection.student_submission_form == 'ontario parents'">Parent</span>
              <span
                v-else-if="
                  connection.student_submission_form == 'professionals' || connection.student_submission_form == 'ontario counsellors'
                "
              >Professional</span>
              <span v-else>Student</span>
            </td>
            <td>
              <a :href="'/connections/details/' + connection.id">{{ connection.student.list_heading.title }}</a>
            </td>
            <td>
              <span v-if="connection.student.list_heading.attributes && connection.student.list_heading.attributes.length > 0">{{
                connection.student.list_heading.attributes[0].value
              }}</span>
            </td>
            <td>
              <span
                v-if="
                  connection.student.list_heading.attributes &&
                    connection.student.list_heading.attributes.length > 1 &&
                    connection.student_submission_form == 'students'
                "
              >
                {{ connection.student.list_heading.attributes[1].value }}
              </span>
            </td>
            <td>
              {{ connection.connection_created_at | moment('MM-DD-YYYY') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="!fetchingStudents && hasStudentConnections" class="text-center">
      <pagination :total-pages="pagination.page_count" :current-page="pagination.current_page" />
    </div>
  </div>
</template>

<script>
import { StudentConnectionApi } from '../../api/student-connection-api';
import Pagination from '../pagination';

export default {
  components: { Pagination },
  props: {
    user: {
      type: Object,
      default: null
    },
    currentTeam: {
      type: Object,
      default: null
    },
    fair: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      studentConnections: null,
      fetchingStudents: false,
      pagination: {
        current_page: 1,
        total_pages: 1,
        page: 1
      },
      noStudentsMessage: 'Your institution does not have any missed connections for this fair yet.'
    };
  },
  computed: {
    hasStudentConnections() {
      if (this.studentConnections == null) {
        return false;
      }

      return this.studentConnections.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchStudentConnections();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.studentConnections === null) {
        this.fetchStudentConnections();
      }
    });
  },
  created() {
    var self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = Number(pageNumber);
    });
  },
  methods: {
    fetchStudentConnections() {
      this.fetchingStudents = true;
      StudentConnectionApi.connections(this.currentTeam.id, this.fair.id)
        .list({
          page: this.pagination.current_page
        })
        .then(
          (success) => {
            this.fetchingStudents = false;
            this.studentConnections = success.data.data;
            this.pagination = success.data.meta.pagination;
          },
          () => {
            this.fetchingStudents = false;
          }
        );
    }
  }
};
</script>
