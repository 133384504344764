<template>
  <div class="footer" :class="{ 'padded-footer': !hasStudent }">
    <div class="footer-top">
      <img src="/img/logo-white.png" class="logo" />
    </div>

    <div class="footer-middle">
      <span class="copyright">&COPY; {{ new Date().getFullYear() }} StriveScan</span>
      <!-- Optionally, add a container for alignment if needed -->
      <div class="middle-content">
        <ul>
          <li><a href="http://strivescan.com/help" target="_blank">{{ $translate('Help & Support') }}</a></li>
          <li><a href="http://strivescan.com/terms" target="_blank">{{ $translate('Terms & Conditions') }}</a></li>
          <li><a href="http://strivescan.com/privacy" target="_blank">{{ $translate('Privacy Policy') }}</a></li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <!-- This will be shown only on small screens; on medium and large screens, it will be hidden -->
      <p>&COPY; {{ new Date().getFullYear() }} StriveScan</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideFooter',
  components: {},
  props: {
    hasStudent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.event-guide .footer {
  width: 100%;
  text-align: center;

  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  .footer-top {
    padding: 24px;
    color: #FFF;
    background-color: #4A2171;
    text-align: center;

    .logo {
      height: 80px;
      object-fit: contain;
      padding: 10px;
    }

    .powered-by {
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      line-height: 24px;
    }

    .clearbit {
      font-family: Quicksand;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .copyright {
    color: #181B26;
    text-align: center;
  }

  .footer-middle {
    padding: 24px;

    ul {
      list-style-type: none;
      margin-bottom: 0px;
    }
  }

  .footer-middle .copyright {
    float: left;
    display: block;
  }

  .footer-middle ul {
    float: right;
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0px;
    gap: 8px;
  }

  .footer-middle ul {
    display: flex;
  }

  .footer-bottom {
    display: none;
    padding: 10px;
    color: #FFF;
    background-color: #37006B;
  }

  @media only screen and (max-width: 576px) {
    .footer-bottom {
      display: block;
    }

    .footer-middle ul {
      flex-direction: column;
      align-items: center;
      float: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .footer-bottom {
      display: block;
    }

    .footer-middle .copyright {
      display: none;
    }

    .footer-middle ul {
      flex-direction: column;
      align-items: center;
      float: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .padded-footer {
      padding-bottom: 80px;
  }
}
</style>