const _ = require('lodash');

Vue.component('users-search-advanced', {
  props: ['user'],

  /**
   * The component's data.
   */
  data() {
    return {
      plans: [],

      searchForm: new SparkForm({
        query: ''
      }),

      searching: false,
      noSearchResults: false,
      searchResults: [],

      showingUserProfile: false,

      isGrouped: false,
      teamData: {},
      groups: {}
    };
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    var self = this;

    this.getPlans();

    this.$on('showSearch', function () {
      self.navigateToSearch();
    });

    Bus.$on('sparkHashChanged', function (hash, parameters) {
      if (hash != 'users') {
        return true;
      }

      if (parameters && parameters.length > 0) {
        self.loadProfile({ id: parameters[0] });
      } else {
        self.showSearch();
      }

      return true;
    });
  },

  methods: {
    /**
     * Get all of the available subscription plans.
     */
    getPlans() {
      axios.get('/spark/plans').then((response) => {
        this.plans = response.data;
      });
    },

    /**
     * Perform a search for the given query.
     */
    search() {
      this.searching = true;
      this.noSearchResults = false;
      this.isGrouped = false;

      axios.post('/api/v2/users-search', this.searchForm).then((response) => {
        this.searchResults = response.data;
        this.noSearchResults = this.searchResults.length === 0;

        this.searching = false;
        this.isGrouped = response.data.length > 0 && response.data[0].group;

        if (this.isGrouped) {
          const users = _.get(response, 'data.0.users', []);

          const sortedUsers = _.sortBy(users, [
            (user) => {
              switch (user.team_role) {
                case 'Admin':
                  return 1;
                case 'Member':
                case 'Standard':
                  return 2;
                case 'Limited':
                  return 3;
                default:
                  return 4;
              }
            }
          ]);

          this.teamData = _.groupBy(sortedUsers, 'team_id', 0);
        }
      });
    },

    getTeamName(userGroup) {
      return _.get(userGroup, '0.team.name', {});
    },

    getTeamId(userGroup) {
      return _.get(userGroup, '0.team.id', {});
    },

    getTeamTokens(userGroup) {
      return _.get(userGroup, '0.team.tokens', {});
    },

    getTeamGroupsByRoles(userGroup) {
      return _.groupBy(userGroup, 'team_role');
    },

    /**
     * Show the search results and update the browser history.
     */
    navigateToSearch() {
      history.pushState(null, null, '#/users');

      this.showSearch();
    },

    /**
     * Show the search results.
     */
    showSearch() {
      this.showingUserProfile = false;

      Vue.nextTick(function () {
        $('#kiosk-users-search').focus();
      });
    },

    /**
     * Show the user profile for the given user.
     */
    showUserProfile(user) {
      history.pushState(null, null, '#/users/' + user.id);

      this.loadProfile(user);
    },

    /**
     * Load the user profile for the given user.
     */
    loadProfile(user) {
      this.$emit('showUserProfile', user.id);

      this.showingUserProfile = true;
    },

    imageURL(team) {
      return '/logos/' + this.clearBitUrl(team) + '.png';
    },

    clearBitUrl(team) {
      let adjustedWebsite = _.get(team, '0.team.website', '');
      if (adjustedWebsite) {
        if (!adjustedWebsite.startsWith('http')) {
          adjustedWebsite = 'https://' + adjustedWebsite;
        }

        try {
          const parsedUrl = new URL(adjustedWebsite);
          adjustedWebsite = parsedUrl.hostname;
        } catch (error) {
          // bad url, let's drop it.
          adjustedWebsite = '';
        }
      } else {
        adjustedWebsite = '';
      }
      return adjustedWebsite;
    },

    getTeamLocation(team) {
      const city = _.get(team, '0.team.contact_city', '');
      const state = _.get(team, '0.team.contact_state', '');
      const country = _.get(team, '0.team.contact_country_code', '');

      return `${city}, ${state} ${country}`;
    }
  }
});
