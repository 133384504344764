module.exports = {
  props: ['user', 'team'],

  /**
   * The component's data.
   */
  data() {
    return {
      form: new SparkForm({
        name: ''
      })
    };
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.form.name = this.team.name;
  },

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    }
  },

  methods: {
    /**
     * Update the team name.
     */
    update() {
      Spark.put(`/settings/${Spark.pluralTeamString}/${this.team.id}/name`, this.form).then(() => {
        Bus.$emit('updateTeam');
        Bus.$emit('updateTeams');
      });
    }
  }
};
