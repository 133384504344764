import Pagination from './pagination';
import TeamVisitScanList from './visit-scans/team';
import PersonalVisitScanList from './visit-scans/personal';
import FreeTextSchoolSearchWithTypeAhead from '../components/FreeTextSchoolSearchWithTypeAhead.vue';
import visitOccurrenceMixin from '../mixins/visit-occurrence';
import moment from 'moment';
import MaterialStyleCbx from '../components/MaterialStyleCbx.vue';
import DateTimePicker from './shared/DateTimePicker.vue';

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

Vue.component('individual-visit', {
  components: { Pagination, TeamVisitScanList, PersonalVisitScanList, FreeTextSchoolSearchWithTypeAhead, MaterialStyleCbx, DateTimePicker },
  mixins: [visitOccurrenceMixin],
  props: {
    user: {
      type: Object,
      default: null
    },
    currentTeam: {
      type: Object,
      default: null
    },
    timezones: {
      type: Array,
      default: null
    },
    backLink: {
      type: String,
      default: null
    }
  },
  data() {
    const parsedData = moment(visit.starts_at).tz(visit.starts_at_timezone);
    const now = new Date();
    const localDate = moment(now).tz(visit.starts_at_timezone);
    return {
      visit: visit,
      originalNotes: visit.notes,
      originalName: visit.school ? null : visit.name,
      originalSchoolId: visit.school ? visit.school.id : null,
      originalStartsAt: visit.starts_at,
      originalStartsAtFormatted: moment(visit.starts_at).format(DATE_FORMAT),
      originalStartsAtTimezone: visit.school ? null : visit.starts_at_timezone,
      exporting: false,
      downloading: false,
      viewingPersonalScans: false,
      editingNotes: false,
      savingNotes: false,
      editingVisit: false,
      savingVisit: false,
      exportHistory: [],
      summaryScanning: [],
      visitHasPast: moment(parsedData).isBefore(localDate),
      form: new SparkForm({
        name: visit.school ? null : visit.name,
        school_id: visit.school ? visit.school.id : null,
        starts_at: visit.starts_at,
        starts_at_formatted: moment.parseZone(visit.starts_at).format(DATE_FORMAT),
        starts_at_timezone: visit.school ? null : visit.starts_at_timezone,
        notes: visit.notes,
        guid_id: visit.guid_id,
        send_student_summary: visit.send_student_summary ? true : !!visit.send_student_summary
      })
    };
  },
  computed: {
    visitCreatorName() {
      return this.visit.created_by_user.id == this.user.id
        ? 'you'
        : this.visit.created_by_user.first_name + ' ' + this.visit.created_by_user.last_name;
    },
    isVisitValid() {
      return (this.form.name && this.form.starts_at && this.form.starts_at_timezone) || (this.form.school_id && this.form.starts_at);
    }
  },
  watch: {
    'form.name': function() {
      if (this.form.errors.has('name')) {
        this.form.errors.forget('name');
      }
    },
    'form.starts_at': function() {
      if (this.form.errors.has('starts_at')) {
        this.form.errors.forget('starts_at');
      }
      this.form.starts_at_formatted = moment.parseZone(visit.starts_at).format(DATE_FORMAT);
    }
  },
  mounted() {
    $(() => {
      this.initializeDatePicker();
      if (this.visitHasPast) {
        this.getExportHistory();
        this.getSummaryScanning();
      }
    });
  },
  methods: {
    getSummaryScanning() {
      let url = `/api/visits/${visit.id}/scanning-summary`;
      axios.get(url).then(
        (success) => {
          if (!success.data.data) {
            return (this.summaryScanning = []);
          }
          this.summaryScanning = success.data.data.map((item) => {
            const prefix = item.user_scans_count > 1 ? 'Scans' : 'Scan';
            return `${item.name} (${item.user_scans_count} ${prefix})`;
          });
        },
        (error) => {
          this.errors = error;
        }
      );
    },
    getExportHistory() {
      let url = `/api/visits/${visit.id}/export-history`;

      axios.get(url).then(
        (success) => {
          if (!success.data.data) {
            return (this.exportHistory = []);
          }
          this.exportHistory = success.data.data.map((item) => {
            const exportDate = moment(item.exported_at)
              .tz(item.exported_at_timezone)
              .format('MM-DD-YY hh:mm A z');
            item.exportDate = exportDate;
            if (item.type === 'DOWNLOAD') {
              item.message = `${item.name} exported ${item.exported_records} records via download`;
            } else if (item.type === 'MANUAL') {
              item.message = `${item.name} exported ${item.exported_records} records to ${item.export_email}`;
            } else if (item.type === 'SFTP') {
              item.message = `Automatic upload via SFTP to [user] at [host]`;
            }
            return item;
          });
        },
        (error) => {
          this.errors = error;
        }
      );
    },
    onVisitLocationChange(event) {
      if (event.text) {
        this.form.name = event.text;
        this.form.school_id = null;
      } else if (event.school) {
        this.form.name = null;
        this.form.school_id = event.school.id;
        this.form.starts_at_timezone = null;
      } else {
        this.form.name = null;
        this.form.school_id = null;
      }
    },
    saveNotes() {
      this.savingNotes = true;

      Spark.put('/api/visits/' + this.visit.id, this.form).then(
        (success) => {
          this.visit = success.data;

          this.setFormAndOriginalValues(this.visit);

          this.savingNotes = false;
          this.editingNotes = false;
        },
        (error) => {
          this.savingNotes = false;
          window.scrollTo(0, 0);
        }
      );
    },
    resetNotes() {
      this.form.notes = this.originalNotes;
      this.editingNotes = false;
    },
    saveVisit() {
      if (!this.isVisitValid) {
        return;
      }

      this.savingVisit = true;

      Spark.put('/api/visits/' + this.visit.id, this.form).then(
        (success) => {
          this.visit = success.data;

          this.setFormAndOriginalValues(this.visit);

          this.savingVisit = false;
          this.editingVisit = false;

          const app = this;

          this.$nextTick(() => {
            app.successAlert('Success', 'This Visit has been successfully updated.');
          });
        },
        (error) => {
          this.savingVisit = false;
          window.scrollTo(0, 0);
        }
      );
    },
    deleteVisit() {
      if (this.visit.team_scans_count > 0) {
        this.errorAlert('Cannot Delete Visit', 'There are existing scans for this Visit, so it cannot be deleted.');
      } else {
        const app = this;

        const backParts = this.backLink.split('#');

        let backLink = backParts[0] + '?visit_deleted=true';

        if (backParts.length > 1) {
          backLink += '#' + backParts[1];
        }

        swal(
          {
            title: 'Delete Visit?',
            text: 'Are you sure you would like to delete this Visit?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Delete',
            closeOnConfirm: false,
            closeOnCancel: false,
            showLoaderOnConfirm: true
          },
          function(isConfirm) {
            if (!isConfirm) {
              swal.close();
            } else {
              axios.delete('/api/visits/' + app.visit.id).then(
                (success) => {
                  window.location.href = backLink;
                },
                (error) => {
                  app.errorAlert('Cannot Delete Visit', 'There are existing scans for this Visit, so it cannot be deleted.');
                }
              );
            }
          }
        );
      }
    },
    setFormAndOriginalValues(visit) {
      this.form.name = visit.school ? null : visit.name;
      this.form.school_id = visit.school ? visit.school.id : null;
      this.form.starts_at = visit.starts_at;
      this.form.starts_at_timezone = visit.school ? null : visit.starts_at_timezone;
      this.form.notes = visit.notes;

      this.originalName = visit.school ? null : visit.name;
      this.originalSchoolId = visit.school ? visit.school.id : null;
      this.originalStartsAt = visit.starts_at;
      this.originalStartsAtTimezone = visit.school ? null : visit.starts_at_timezone;
      this.originalNotes = visit.notes;
    },
    resetVisit() {
      this.form.name = this.originalName;
      this.form.school_id = this.originalSchoolId;
      this.form.starts_at = this.originalStartsAt;
      this.form.starts_at_timezone = this.originalStartsAtTimezone;
      this.editingVisit = false;
    },
    askToExport() {
      const app = this;
      swal(
        {
          title: 'Export?',
          text: "Are you sure you want to export your institution's list and send it to your email address?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Export!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'Your list has not been exported!');
          } else {
            app.exporting = true;

            axios
              .post('/api/exports/visits/' + app.visit.id + '/scans', {
                timezone: moment.tz.guess(true)
              })
              .then(
                (success) => {
                  app.exporting = false;

                  if (success.data.data.scans_exported) {
                    app.successAlert(success.data.data.title, success.data.data.message);
                    app.getExportHistory();
                  } else {
                    app.errorAlert(success.data.data.title, success.data.data.message);
                  }
                },
                (error) => {
                  app.exporting = false;
                  app.errorAlert('Canceled', 'Your list has not been exported!');
                }
              );
          }
        }
      );
    },
    askToDownload() {
      const app = this;
      swal(
        {
          title: 'Download?',
          text: "Are you sure you want to download your institution's list?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Download!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'Your list has not been exported!');
          } else {
            app.downloading = true;

            axios
              .post(
                '/api/exports/visits/' + app.visit.id + '/scans/download',
                {},
                {
                  responseType: 'blob'
                }
              )
              .then(
                (success) => {
                  app.downloading = false;
                  const contentType = success.headers['content-type'];
                  const blob = new Blob([success.data], { type: contentType });
                  let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');

                  if (fileName.charAt(0) === '"') {
                    fileName = fileName.substr(1);
                  }

                  if (fileName.charAt(fileName.length - 1) === '"') {
                    fileName = fileName.substr(0, fileName.length - 1);
                  }

                  FileSaver.saveAs(blob, fileName);
                  swal.close();
                  app.getExportHistory();
                },
                (error) => {
                  app.downloading = false;

                  if (error.response.status === 409) {
                    app.errorAlert('Nothing to Export', 'There are no synced scans to export for your institution.');
                  } else {
                    app.errorAlert('Export Too Large', 'This export is too large to download directly. Please export to email instead.');
                  }
                }
              );
          }
        }
      );
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    setSendStudentSummary(ev) {
      this.form.send_student_summary = ev.target.checked;
    }
  }
});
