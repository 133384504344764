import Template from './customAccordion.html';
export const AccordionExpandedState = {
  expanded: 'expanded',
  collapsed: 'collapsed'
};
Vue.component('custom-accordion', {
  props: {
    headerTitle: {
      type: String,
      default: null
    },
    headerSubtitle: {
      type: String,
      default: null
    },
    initialState: {
      //no type here because I'm seeing both bools and numbers
      //depending on how the ui gets on the page
      default: false
    }
  },
  data: () => {
    return {
      expanded: false
    };
  },
  computed: {
    icon() {
      return ['fa', 'fa-btn', this.expanded ? 'fa-chevron-up' : 'fa-chevron-down'].join(' ');
    }
  },
  mounted() {
    this.expanded = this.initialState ? true : false;
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    }
  },
  template: Template
});
