import Cropper from '../../../components/Cropper.vue';

var base = require('settings/teams/update-team-photo');

Vue.component('spark-update-team-photo', {
  data() {
    return {
      form: new SparkForm({
        photo: null,
      }),
    };
  },
  mixins: [base],
  components: { Cropper },
  created() {
    var self = this;

    Bus.$on('cropped', function(data) {
      self.form.photo = data;
    });
  },
  computed: {
    urlForUpdate() {
      return `/api/settings/teams/${this.team.id}/photo`;
    }
  },
  watch: {
    'photo': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== '') {
        $('#modal-photo').modal('show');
      }
    }
  },
  methods: {
    closeModal() {
      this.photo = '';
      this.form.photo = null;

      $('#modal-photo').modal('hide');
    },
    saveAndClose() {
      var self = this;
      this.form.startProcessing();

      axios.post(this.urlForUpdate, this.form).then(
        () => {
          self.form.finishProcessing();
          self.closeModal();

          Bus.$emit('updateCurrentTeam');
          Bus.$emit('updateTeams');
        }).catch(
          (error) => {
            self.form.setErrors(error.response.data);
          }
        );
    },
    photodelete(e) {
      e.preventDefault();

      // We need to gather a fresh FormData instance with the profile photo appended to
      // the data so we can POST it up to the server. This will allow us to do async
      // uploads of the profile photos. We will update the user after this action.
      axios
        .delete(this.urlForUpdate)
        .then((response) => {
          // This is here to fix a weird bug where the update handler
          // doesn't fire if you select a photo and the field already
          // has a value
          $(function() {
            $("input[name='photo']").val('');
          });

          Bus.$emit('updateCurrentTeam');
          Bus.$emit('updateTeams');
        })
        .catch(function(response) {
          this.form.setErrors(response.data);
        });
    }
  }
});
