const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

export const stateToQueryWatcher = function() {
  if (this.state_to_query != 'show_all') {
    this.setUpcomingFairs({ state: this.state_to_query });
    this.form.fair = '';
  } else {
    this.upcomingFairs = fairs;
  }
};

export const gradeDataWatcher = function() {
  this.form.current_year_class = this.grade_data.label;
  this.form.graduation_year = this.grade_data.classOf;

  if (!this.grade_data || (this.grade_data && this.grade_data.grade === null)) return;

  let selected = Math.abs(this.grade_data.grade - 12);
  this.form.college_start_semester = this.start_university[selected];
};

export const graduationObjectWatcher = function() {
  this.form.graduation_year = this.graduation_object.year;
  this.form.grad_type = this.graduation_object.type;

  // clear form errors for graduation_year and grad_type
  this.remove_form_field_errors('grad_type', 'required');
};

export const graduationYearWatcher = function() {
  if (this.form && this.form.graduation_year) {
    let currentDate = new Date(),
      currentYear = currentDate.getFullYear(),
      offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    if (borderDate < currentDate && this.form.graduation_year === currentYear) {
      offsetYear = currentYear + 1;
      var spring = window.$translate('Spring');
      this.form.college_start_semester = `${spring} ${offsetYear}`;
    } else {
      var fall = window.$translate('Fall');
      this.form.college_start_semester = `${fall} ${this.form.graduation_year}`;
    }
    this.remove_form_field_errors('college_start_semester', 'required');
  }
};

export const upcomingFairsWatcher = function() {
  if (this.upcomingFairs.length === 1) {
    this.form.fair = this.upcomingFairs[0].id;
  }
};

export const currentClassYearWatcher = function() {
  let app = this;
  let gradeItem = this.gradeSelectors.filter(function(item) {
    return item.label === app.form.current_year_class;
  });

  this.form.graduation_year = gradeItem.firstObject().classOf;

  let selected = Math.abs(gradeItem.firstObject().grade - 12);
  this.form.college_start_semester = this.start_university[selected];
};

export const acceptTosWatcher = function() {
  let error_message = window.$translate('You must agree before continuing.');
  if (!this.form.accept_tos) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { accept_tos: [error_message] });
  } else {
    this.remove_form_field_errors('accept_tos', error_message);
  }
};

export const autocompletedWatcher = function() {
  if (!this.hasOwnProperty('show_address')) {
    this.show_address = true;
  }
  if (this.autocompleted) {
    this.validate('address_line_1', this.form.address_line_1);
    this.validate('address_line_2', this.form.address_line_2);
    this.validate('address_city', this.form.address_city);
    this.validate('address_region', this.form.address_region);
    this.validate('address_postal_code', this.form.address_postal_code);
    if (!this.form.hasOwnProperty('address_country_code')) {
      this.validate('address_country_code', this.form.address_country_code);
    }
  }
};

export const hispanicLatinoSpanishOriginWatcher = function() {
  if (this.hispanic_latino_spanish_origin === 'true') {
    this.form.has_hispanic_latino_or_spanish_origin = true;

    if (this.form.ethnicities.length < 1) {
      var errorString = window.$translate('The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.');
      if (this.form.errors.errors['ethnicities']) {
        this.form.errors.errors['ethnicities'] = [errorString];
      } else {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { ['ethnicities']: [errorString] });
      }
    }
  } else {
    this.form.has_hispanic_latino_or_spanish_origin = this.hispanic_latino_spanish_origin === 'false' ? false : null;
    this.ethnicities = [];
    // clear out form errors
    Vue.delete(this.form.errors.errors, 'ethnicities');
  }
};

export const ethnicitiesWatcher = function() {
  let results = [];
  for (let i = 0; i < this.ethnicities.length; i++) {
    results.push({ ethnicity: this.ethnicities[i] });
  }
  this.form.ethnicities = results;

  if (this.form.ethnicities.length > 0) {
    Vue.delete(this.form.errors.errors, 'ethnicities');
  } else if (this.form.ethnicities.length <= 0 && this.form.has_hispanic_latino_or_spanish_origin) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
      ['ethnicities']: [window.$translate('The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.')]
    });
  }
};

export const racesWatcher = function() {
  let results = [];
  for (let i = 0; i < this.races.length; i++) {
    results.push({ race: this.races[i] });
  }
  this.form.races = results;
};

export const addressCityWatcher = function() {
  if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
    this.form.high_school_city = this.form.address_city;
  }
};

export const addressRegionWatcher = function() {
  if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
    this.form.high_school_region = this.form.address_region;
  }
};

export const highSchoolWatcher = function() {
  if (this.form.high_school_ceeb) {
    this.remove_form_field_errors('high_school', 'required');
  }
};

export const fairWatcher = function() {
  $(() => {
    let selectedFair;

    this.querySchoolApi('');

    if (!this.student) {
      selectedFair = this.upcomingFairs.filter((f) => {
        return f.id === this.form.fair;
      });
    } else {
      let fairData = {
        id: this.student.fair.id,
        name: this.student.fair.name
      };

      // Check if the venue property exists before trying to access it
      if (this.student.fair.venue) {
        fairData.country = this.student.fair.venue.address.country_code;
      }

      selectedFair = [fairData];
    }

    if (!selectedFair) return;

    let selectedCountry = this.availableCountries.filter((c) => {
      return c.alpha2 === selectedFair[0].country;
    });

    if (selectedCountry.length) {
      this.form.address_country_code = selectedCountry[0].alpha2;
      this.form.address_country = selectedCountry[0].name;

      if (!this.form.phone_country_code || (iti && !iti.getNumber(itiNumberFormats.NATIONAL))) {
        this.form.phone_country_code = selectedCountry[0].alpha2;
        iti.setCountry(selectedCountry[0].alpha2);
      }
    }
  });
};

export const hasParentPermissionWatcher = function() {
  let error_message = window.$translate('You must agree that you have permission before continuing.');
  if (this.requiresParent && !this.form.has_parent_permission) {
    this.isSubmitDisabled = true;
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { has_parent_permission: [error_message] });
  } else {
    this.isSubmitDisabled = false;
    this.remove_form_field_errors('has_parent_permission', error_message);
  }
};

export const birthdateDayWatcher = function() {
  this.birthdate = this.formatBirthdate();
  this.validateAge();
};

export const birthdateMonthWatcher = function() {
  this.birthdate = this.formatBirthdate();
  this.validateAge();
};

export const birthdateYearWatcher = function() {
  this.birthdate = this.formatBirthdate();
  this.validateAge();
};
