import FreeTextSchoolSearchWithTypeAhead from '../components/FreeTextSchoolSearchWithTypeAhead.vue';
import VisitsNotEnabled from '../components/VisitsNotEnabled.vue';
import moment from 'moment';
import MaterialStyleCbx from '../components/MaterialStyleCbx.vue';
import DateTimePicker from './shared/DateTimePicker.vue';

const DATE_FORMAT = 'MM/DD/YYYY h:mm A';

export default {
  components: {
    FreeTextSchoolSearchWithTypeAhead,
    VisitsNotEnabled,
    MaterialStyleCbx,
    DateTimePicker
  },
  data() {
    return {
      lat: null,
      lng: null,
      // These are the options for the geolocation request in the browser
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      form: new SparkForm({
        name: null,
        school_id: null,
        starts_at: moment().format(DATE_FORMAT),
        starts_at_formatted: moment().format(DATE_FORMAT),
        starts_at_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        send_student_summary: true
      }),
      minDate: moment().startOf('day')
    };
  },

  props: {
    timezones: {
      type: Array,
      required: true
    },
    isVisitsEnabled: {
      required: true
    }
  },

  mounted() {
    this.requestLocationPermissions();
    this.listenPushState();
  },

  computed: {
    isValid() {
      return (this.form.name && this.form.starts_at && this.form.starts_at_timezone) || (this.form.school_id && this.form.starts_at);
    }
  },

  methods: {
    listenPushState() {
      const pushState = history.pushState;
      const self = this;
      history.pushState = function(state) {
        pushState.apply(history, arguments);
        self.requestLocationPermissions();
      };
    },

    isLocationRequired() {
      let path = window.location.href;
      if (path[path.length - 1] === '/') {
        path = path.slice(0, -1);
      }

      const lastPathValue = path.split('/').pop();
      return lastPathValue === 'create-visit';
    },

    requestLocationPermissions() {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state == 'prompt' && this.isLocationRequired()) {
          navigator.geolocation.getCurrentPosition(this.locationAccessGranted, console.error, this.geolocationOptions);
        }
      });
    },
    locationAccessGranted(position) {
      let coordinates = position.coords;
      this.lat = coordinates.latitude;
      this.lng = coordinates.longitude;
    },
    createVisit() {
      Spark.post('/api/visits', this.form).then(
        (success) => {
          this.successAlert('Success', 'This visit was successfully created.');
          this.resetForm();
          Bus.$emit('visitCreated');
        },
        (error) => {
          window.scrollTo(0, 0);
        }
      );
    },
    resetForm() {
      this.$refs.schoolTypeAhead.clearSelection();

      this.form = new SparkForm({
        name: null,
        school_id: null,
        starts_at: null,
        starts_at_timezone: null
      });

      window.scrollTo(0, 0);
    },
    onVisitLocationChange(event) {
      if (event.text) {
        this.form.name = event.text;
        this.form.school_id = null;
      } else if (event.school) {
        this.form.name = null;
        this.form.school_id = event.school.id;
        this.form.starts_at_timezone = null;
      } else {
        this.form.name = null;
        this.form.school_id = null;
      }
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    setSendStudentSummary(ev) {
      this.form.send_student_summary = ev.target.checked;
    }
  },

  watch: {
    'form.name': function() {
      if (this.form.errors.has('name')) {
        this.form.errors.forget('name');
      }
    },
    'form.starts_at': function() {
      if (this.form.errors.has('starts_at')) {
        this.form.errors.forget('starts_at');
      }
      this.form.starts_at_formatted = moment.parseZone(this.form.starts_at).format(DATE_FORMAT);
    },
    'form.starts_at_timezone': function() {
      let minDate;

      if (this.form.starts_at_timezone) {
        minDate = moment
          .tz(this.form.starts_at_timezone)
          .startOf('day')
          .format('YYYY-MM-DD');
      } else {
        minDate = this.form.school_id
          ? moment()
              .subtract(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DD')
          : moment()
              .startOf('day')
              .format('YYYY-MM-DD');
      }

      this.minDate = moment(minDate);

      if (!this.form.starts_at && !this.form.starts_at_timezone && !this.form.name && !this.form.school_id) {
        const now = moment();
        this.form.starts_at = now.format(DATE_FORMAT);
      } else if (!this.form.starts_at) {
        this.form.starts_at = this.minDate.format(DATE_FORMAT);
      }
    }
  }
};
