import { ApiPaths } from '../constants/dashboard-paths';

/**
 * The result of a download request
 * @typedef {Object} DownloadResults
 * @property {binary} data - Binary content containing the file data.
 */
/**
 * The information for an individual export
 * @typedef {Object} ExportItem
 * @property {string} name
 * @property {string} type
 * @property {string} exported_at
 * @property {string} exported_at_timezone
 * @property {number} exported_records
 * @property {string} export_email
 */
/**
 * The arguments filtered table requests
 * @typedef {Object} SchoolsTableQueryArgs
 * @property {string|null} query - The filter parameter to be applied by the back-end
 * @property {number|null} limit - the maximum number of rows to return
 * @property {number|null} page - the 1-based page index of the paged data
 * @property {name|registered|attended|null} orderBy - the column used to order the data
 * @property {asc|desc} order - the sort direction
 */

/**
 * The paginated result meta data
 * @typedef {Object} ResultPaginationInfo
 * @property {number} current_page - the 1-based current page number
 * @property {number} per_page - the number of items per page passed to the request
 * @property {number} page_count - the total number of pages available
 * @property {number} total_count - the total number of items available
 */
/**
 * The information for an individual user
 * @typedef {Object} UserStatus
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} title
 * @property {Object} team
 */
/**
 * The result of a export history request
 * @typedef {Object} ExportHistoryResultMeta
 * @property {ResultPaginationInfo} pagination - the pagination information
 * @property {ExportItem[]} data - the list of exports records
 */
/**
 * The result of a user table request
 * @typedef {Object} ListExportsResultMeta
 * @property {ResultPaginationInfo} pagination - the pagination information
 * @property {UserStatus[]} data - the list of user records
 */
/**
 * The result of a schools table request
 * @typedef {Object} SchoolsTableQueryResultMeta
 * @property {ResultPaginationInfo} pagination - the pagination information
 * @property {number} graph_range - the maximum value for the attended/registered graph
 */
/**
 * school table entry
 * @typedef {Object} SchoolWithAddressAndEngagement
 * @property {string} fair_id - the fair_id
 * @property {string} id - the id for the school
 * @property {string} registered - the # of students registered for this fair from the school
 * @property {string} attended - the # of students who actually attended for this fair from the school
 * @property {string} CEEB - the CEEB for the school
 * @property {string} name - the name for the school
 * @property {string} address_line1 - the address_line1 for the school
 * @property {string} address_line2 - the address_line2 for the school
 * @property {string} municipality - the municipality for the school
 * @property {string} locality - the locality for the school
 * @property {string} region - the region for the school
 * @property {string} postal_code - the postal_code for the school
 * @property {string} country_code - the country_code for the school
 * @property {string} longitude - the longitude for the school
 * @property {string} latitude - the latitude for the school*
 */
/**
 * The result of a schools table request
 * @typedef {Object} SchoolsTableQueryResult
 * @property {boolean} success - was the query successful
 * @property {SchoolWithAddressAndEngagement[]} data - the list of school records
 * @property {SchoolsTableQueryResultMeta} meta - the meta data about the result
 */
/**
 * The arguments filtered table requests
 * @typedef {Object} InstitutionsTableQueryArgs
 * @property {string|null} query - The filter parameter to be applied by the back-end
 * @property {number|null} limit - the maximum number of rows to return
 * @property {number|null} page - the 1-based page index of the paged data
 * @property {students_scanned|name|null} orderBy - the column used to order the data
 * @property {asc|desc} order - the sort direction
 */
/**
 * The parameters for the fair dashboard list api
 * @typedef {Object} FairDashboardIndexQueryArgs
 * @property {upcoming|past|null} occurrence - limit the page to either upcoming or past fairs
 * @property {string|null} query - The filter parameter to be applied by the back-end
 * @property {number|null} limit - the maximum number of rows to return
 * @property {number|null} page - the 1-based page index of the paged data
 * @property {asc|desc} order - the sort direction
 * @property {string|null} state - limit the page to only fairs within a state
 * @property {string|null} tag - limit the page to only fairs with a specific tag
 * @property {Date|null} from_date - limit the page to only fairs within a date range
 * @property {Date|null} to_date - limit the page to only fairs within a date range
 */
/**
 * the parameters for the fair dashboard user invitations listing api
 * @typedef {Object} ListUsersQueryArgs
 * @property {number} page - the 1-based page number,
 * @property {number} limit - the number of items per page,
 * @property {asc|desc} order - the sort direction
 */
/**
 * @typedef {Object} DashboardInfoBreakdown
 * @property {number} parent
 * @property {number} professional
 * @property {number} seniors
 * @property {number} juniors
 * @property {number} sophomores
 * @property {number} freshman
 * @property {number} seniors_graduation_year
 * @property {number} juniors_graduation_year
 * @property {number} sophomores_graduation_year
 * @property {number} freshman_graduation_year
 * @property {number} middle_school
 * @property {number} transfer
 * @property {number} other
 * @property {number} total
 */

/**
 * @typedef {Object} DashboardInfo
 * @property {DashboardInfoBreakdown} registered
 * @property {DashboardInfoBreakdown} attended
 */
/**
 * @typedef {Object} DashboardInfoTotals
 * @property {number} registered
 * @property {number} attended
 */

/**
 * @typedef {Object} DashboardGraphSlice
 * @property {Date} starts_at
 * @property {Date} ends_at,
 * @property {number} count
 */

export class ApiSurface {
  /**
   *
   * @param {AxiosRequestConfig} request
   * @returns {Promise<undefined>} async
   */
  _request(request) {
    return this._axios.request(this.appendTokenHeaders(request));
  }
  appendTokenHeaders(request) {
    if (request.headers === undefined) {
      request.headers = {};
    }
    if (this._csrfToken) {
      request.headers['X-CSRF-TOKEN'] = this._csrfToken;
    }
    if (this._xsrfToken) {
      request.headers['X-XSRF-TOKEN'] = this._xsrfToken;
    }
    return request;
  }
  constructor(axiosHttp) {
    this._axios = axiosHttp;
    this._xsrfToken = window.Cookies.get('XSRF-TOKEN');
    this._csrfToken = window.Spark.csrfToken;

    /** @param {number} fair_id - the fair_id for the current dashboard */
    this.fairs = Object.assign(
      (fair_id) => {
        return {
          dashboard: {
            /**
             * @param {SchoolsTableQueryArgs|null} args
             * @returns {Promise<SchoolsTableQueryResult>} the school details rows for the specified args
             * @property {string} featureService - the url of the engagement feature service
             */
            schools: Object.assign(
              (args) => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.schools, params: args }),
              {
                engagement: () => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.schools.featureService }),
                featureService: ApiPaths.fairs(fair_id).dashboards.schools.featureService
              }
            ),

            // exportHistory:`${dashboard_base}/export-history`,
            /**
             * @param {InstitutionsTableQueryArgs|null} args
             * @returns the school details rows for the specified args
             */

            institutions: Object.assign(
              (args) => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.institutions, params: args }),
              {
                engagement: () => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.institutions.featureService }),
                featureService: ApiPaths.fairs(fair_id).dashboards.institutions.featureService
              }
            ),
            /**
             * @param {object|null} school - the school clicked on to retrieve info for or null to get the overall summary
             * @returns {Promise<DashboardInfo>}
             */
            info: (school) => {
              const args = {};
              const url = ApiPaths.fairs(fair_id).dashboards.info;
              if (school) {
                // Pass both the name and id to the endpoint to build it as expected -- data[name + id] - for the unique identifier
                if (school.uniqueSchoolId) {
                  args['uniqueSchoolId'] = school.uniqueSchoolId;
                }
              }
              return this._request({ method: 'get', url: url, params: args });
            },
            /**
             *
             */
            permissions: () => {
              const args = {};
              const url = ApiPaths.fairs(fair_id).dashboards.permissions;
              return this._request({ method: 'get', url: url, params: args });
            },
            /**
             * @param {array|null} emails - the list of emails for users to invite
             * @param {string|null} permissionType - the permissionType for users to invite
             * @param {array|null} schools - this list of schools for the users
             * @returns {Promise} async
             */
            inviteUsers: (emails, permissionType, schools) => {
              if (null === emails) {
                return Promise.resolve(undefined);
              }
              if (null == permissionType) {
                return Promise.resolve(undefined);
              }
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.users.index,
                data: {
                  emails: emails,
                  permissionType: permissionType,
                  schools: schools
                },
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            },
            resendInvite: (userId) => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.users.resendInvite(userId)
              });
            },
            /**
             * @param {ListUsersQueryArgs} args - the query parameters for listing users
             * @returns {Promise<ListUsersResultMeta>} the list of users that have access to the fair dashboard
             */
            listUsers: (args) => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.users.index, params: args }),

            /**
             * @returns {Promise} async
             */
            listFairUsers: (args) =>
              this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.users.indexFairUsers, params: args }),

            /**
             * @param {number} userId
             * @returns {Promise} async
             */
            listUserSchools: (userId) => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.users.indexSchools(userId) }),

            /**
             *
             * @param {number} userId
             * @param {number} schoolId
             * @returns
             */
            deleteUserSchool: (userId, schoolId) =>
              this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.users.indexSchools(userId, schoolId) }),

            /**
             *
             * @param {number} userId
             * @returns {Promise} async
             */
            deleteUser: (userId) => this._request({ method: 'delete', url: ApiPaths.fairs(fair_id).dashboards.users.delete(userId) }),
            /**
             * Get the scanned students data for the dashboard graphs
             * @param {number} slice_minutes -- defaults to 10
             * @param {number} slice_alignment -- defaults to 'input'
             * @returns {Promise<DashboardGraphSlice[]>}
             */
            scanned: (slice_minutes = 10, slice_alignment = 'input') =>
              this._request({
                method: 'get',
                url: ApiPaths.fairs(fair_id).dashboards.scanned,
                params: { slice_minutes, slice_alignment }
              }).then((d) => {
                return d.data.data.map((g) => {
                  return {
                    starts_at: new Date(g.starts_at),
                    ends_at: new Date(g.ends_at),
                    count: g.count
                  };
                });
              }),
            /**
             * gets registered students graph data
             * @param {number} slice_minutes
             * @param {number} slice_alignment -- defaults to 'days'
             * @returns {Promise<DashboardGraphSlice[]>}
             */
            registered: (slice_minutes = 10, slice_alignment = 'day') =>
              this._request({
                method: 'get',
                url: ApiPaths.fairs(fair_id).dashboards.registered,
                params: { slice_minutes, slice_alignment }
              }).then((d) => {
                return d.data.data.map((g) => {
                  return {
                    starts_at: new Date(g.starts_at),
                    ends_at: new Date(g.ends_at),
                    count: g.count
                  };
                });
              }),
            // registered:`${dashboard_base}/registered`,
            // scanned:`${dashboard_base}/scanned`,
            // users:`${dashboard_base}/users`,
            /**
             * @returns {Promise<DownloadResults>} Binary blob containing the download.
             */
            emailRegisteredStudents: () => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.emailRegistered
              });
            },
            /**
             * @returns {Promise<DownloadResults>} Binary blob containing the download.
             */
            emailScannedStudentsByAttendee: () => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.emailScannedByAttendee
              });
            },
            /**
             * @returns {Promise<DownloadResults>} Binary blob containing the download.
             */
            emailScannedStudentsByInstitution: () => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.emailScannedByInstitution
              });
            },
            /**
             * @returns {Promise<DownloadResults>} Binary blob containing the download.
             */
            emailConnectionsByAttendee: () => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.emailConnectionsByAttendee
              });
            },
            /**
             * @returns {Promise<DownloadResults>} Binary blob containing the download.
             */
            emailConnectionsByInstitution: () => {
              return this._request({
                method: 'post',
                url: ApiPaths.fairs(fair_id).dashboards.emailConnectionsByInstitution
              });
            },
            /**
             * @returns {Promise<ExportHistoryResultMeta>} the list of exports history
             */
            exportHistory: (args) => this._request({ method: 'get', url: ApiPaths.fairs(fair_id).dashboards.exportHistory, params: args })
          }
        };
      },
      {
        /**
         * @param {FairDashboardIndexQueryArgs|null} args
         * @returns fairs for the specified args
         */
        index: (args) => this._request({ method: 'get', url: ApiPaths.fairs.dashboards, params: args })
      }
    );
  }
}

export const Api = new ApiSurface(window.axios);
