<template>
  <div class="permissions-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selectedValueName }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i.id" @click="didSelectValue(option)">
        {{ option.display_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddUsersPermissionSelect',

  props: {
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    value: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      open: false,
      selected: null
    };
  },

  computed: {
    selectedValueName() {
      if (this.selected) {
        return this.selected.display_name;
      } else {
        return 'Please Select';
      }
    }
  },

  watch: {
    value(newVal) {
      this.selected = newVal;
    }
  },

  mounted() {
    Bus.$on('resetAddUsersForm', this.clearForm);
    if (this.selected) {
      this.$emit('input', this.selected.id);
    }
  },

  methods: {
    clearForm: function() {
      this.tabindex = 0;
      this.selected = null;
    },

    didSelectValue(option) {
      this.selected = option;
      this.open = false;
      this.$emit('input', option);
    }
  }
};
</script>

<style scoped>
.permissions-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;

  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.permissions-select .selected {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #181b26;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.permissions-select .selected.open {
  border: 1px solid #56267c;
  border-radius: 6px 6px 0px 0px;
}

.permissions-select .selected:after {
  position: absolute;
  content: '';
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #2b3040 transparent transparent transparent;
}

.permissions-select .items {
  color: #181b26;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #56267c;
  border-left: 1px solid #56267c;
  border-bottom: 1px solid #56267c;
  position: absolute;
  background-color: #642c8f;
  left: 0;
  right: 0;
  z-index: 1;
}

.permissions-select .items div {
  color: #181b26;
  background-color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.permissions-select .items div:hover {
  background-color: #642c8f;
  color: #fff;
}

.selectHide {
  display: none;
}
</style>
