import MissedConnectionsUsersTextarea from './MissedConnectionsUsersTextarea.vue';
import Loader from '../../components/dashboard/Loader.vue';
import { StudentConnectionApi } from '../../api/student-connection-api';
import UpdateTeam from '../../mixins/updateTeam';

Vue.component('missed-connections', {
  components: { MissedConnectionsUsersTextarea, Loader },
  mixins: [UpdateTeam],
  props: {
    isAllowedToModify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingList: false,
      isDeletingRecipient: false,
      deletingRecipient: null,
      recipients: [],
      connectionNotifyAll: this.team.connection_notify_all ? true : false
    };
  },
  computed: {},
  watch: {
    connectionNotifyAll: function(newVal, oldVal) {
      this.team.connection_notify_all = newVal ? 1 : 0;
      this.form.connection_notify_all = this.team.connection_notify_all;
      this.update();
    }
  },
  mounted() {
    const app = this;
    this.$nextTick().then(() => {
      app.refreshRecipients();
    });
  },
  methods: {
    inviteUsers(emails) {
      StudentConnectionApi.connection_recipients(this.team.id)
        .create({ emails: emails })
        .then((resp) => {
          Bus.$emit('resetAddUsersForm');
          if (resp.data.errors && resp.data.errors.length > 0) {
            this.alertSuccess = true;
            this.alertMessage = resp.data.errors[0].detail;
          }
          this.refreshRecipients();
        })
        .catch((error) => {
          Bus.$emit('resetAddUsersForm');
          this.handleError(error);
        });
    },
    handleError(error) {
      if (error && error.response && error.response.data && error.response.data.errors) {
        const detail = error.response.data.errors.detail;
        if (typeof detail === 'string') {
          this.errorAlert(error.response.data.errors.title, detail);
        } else {
          this.errorAlert(error.response.data.errors.title, detail.emails[0]);
        }
      } else {
        this.errorAlert('Error', 'Unknown error occurred');
      }
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    removeRecipient(recipient) {
      this.deletingRecipient = recipient;
      $('#modal-delete-recipient').modal('show');
    },
    deleteRecipient() {
      const app = this;
      app.isDeletingRecipient = true;
      StudentConnectionApi.connection_recipients(this.team.id)
        .remove(this.deletingRecipient.id)
        .then(() => {
          app.isDeletingRecipient = false;
          app.deletingRecipient = null;
          $('#modal-delete-recipient').modal('hide');
          this.refreshRecipients();
        })
        .catch((error) => {
          app.isDeletingRecipient = false;
          app.deletingRecipient = null;
          $('#modal-delete-recipient').modal('hide');
          this.handleError(error);
        });
    },
    refreshRecipients() {
      const app = this;
      app.loadingList = true;
      StudentConnectionApi.connection_recipients(this.team.id)
        .list()
        .then((resp) => {
          app.loadingList = false;
          this.recipients = resp.data.data;
        })
        .catch((error) => {
          app.loadingList = false;
          this.handleError(error);
        });
    }
  }
});
