import differenceBy from 'lodash/differenceBy';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import DateTimePicker from '../components/shared/DateTimePicker.vue';

export default {
  components: {
    VueBootstrapTypeahead,
    DateTimePicker
  },

  data() {
    return {
      allTags: [],
      tagOptions: [],
      query: '',
      fair: null,
      form: new SparkForm({
        name: '',
        starts_at: '',
        ends_at: '',
        starts_at_timezone: '',
        is_locked: true,
        pin: null,
        summary: '',
        additional_info: null,
        dashboard_custom_text: null,
        tags: [],
        forms: [],
        send_student_summary: true,
        connections_on: true,
        event_guide_on: false,
        has_institution_limit: false,
        venue: {
          name: '',
          address: {
            line_1: '',
            line_2: '',
            city: '',
            region: '',
            postal_code: '',
            country_code: 'US'
          }
        },
        latitude: null,
        longitude: null,
      }),
      timezones: moment.tz.names(),
      states: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ],
      type: 'standard',
      formsData: [],
      ontarioFormsData: [],
      show_pin: false,
      countries: [],
      geocodingConfidence: null,
      busyGeocoding: false
    };
  },
  beforeMount() {
    this.getForms();
  },
  created() {
    this.getTags();
  },

  mounted() {
    this.countries = countries;
    $(() => {
      $('#tags-search div').removeClass('input-group');
    });
  },

  computed: {
    googleMapsUrl: function() {
      if (this.hasValidCoordinates) {
        return `https://www.google.com/maps/search/?api=1&query=${this.form.latitude.trim()},${this.form.longitude.trim()}`;
      }

      return null;
    },
    hasValidCoordinates: function() {
      return (
        this.form.latitude !== null &&
        this.form.longitude !== null &&
        this.form.latitude.trim().length &&
        this.form.longitude.trim().length &&
        !isNaN(this.form.latitude.trim()) &&
        !isNaN(this.form.longitude.trim())
      );
    },
    hasValidVenueAddress: function() {
      return this.form.venue.address.line_1 && this.form.venue.address.city && this.form.venue.address.country_code;
    },
    geocodingConfidenceMessage: function() {
      if (this.geocodingConfidence === null) {
        return 'Geocode venue address for confidence rating';
      }

      if (this.geocodingConfidence === 'custom') {
        return 'Geocoding confidence not available for custom coordinates';
      }

      return 'Geocoding confidence: ' + this.geocodingConfidence * 100 + '%';
    }
  },

  watch: {
    query: _.debounce(function(q) {
      this.queryTags(q);
    }, 500),
    type: function(newType) {
      switch (newType) {
        case 'standard':
          this.form.is_locked = true;
          this.form.pin = null;
          break;
        case 'open':
          this.form.is_locked = false;
          this.form.pin = null;
          break;
        case 'pin':
          this.form.is_locked = true;
          this.show_pin = true;
          break;
        default:
          break;
      }
    },
    'form.latitude': function() {
      this.handleCustomCoordinatesInput();
    },
    'form.longitude': function() {
      this.handleCustomCoordinatesInput();
    }
  },

  methods: {
    getFormDataWithValidInstitutionLimit(form) {
      let formData = Object.assign({}, form);
      if (!formData.has_institution_limit) {
        formData.institution_limit = 0;
      }
      return formData;
    },
    getForms() {
      axios.get('/api/forms').then(
        ({ data }) => {
          let nonOntarioForms = data.data.filter(
            (x) =>
              !x.form
                .toString()
                .toLowerCase()
                .includes('ontario')
          );
          let arrays = [];
          while (nonOntarioForms.length > 0) {
            arrays.push(nonOntarioForms.splice(0, 3));
          }
          this.formsData = arrays;

          this.ontarioFormsData = data.data.filter((x) =>
            x.form
              .toString()
              .toLowerCase()
              .includes('ontario')
          );

          // Filter out any that are not 'selected_by_default'
          this.form.forms = data.data.filter((x) => x.selected_by_default);
        },
        (err) => {
          this.formsData = [];
        }
      );
    },
    getTags() {
      axios.get('/api/fair-tags?scope=all').then(
        ({ data }) => {
          this.allTags = data.data; // data expected to be an array
          this.tagOptions = this.allTags.slice(0);
        },
        (err) => {
          this.allTags = [];
          this.tagOptions = [];
        }
      );
    },
    queryTags(query) {
      let tags = [];

      this.resetTags();

      this.tagOptions.filter((tag) => {
        if (tag.title.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          tags.push(tag);
        }
      });

      this.tagOptions = tags;
    },
    resetTags() {
      this.tagOptions = this.allTags.slice(0);
      this.tagOptions = differenceBy(this.tagOptions, this.form.tags, 'id');
    },
    selectTag(selectedObj) {
      if (selectedObj && selectedObj.id !== null && this.form.tags.length < 3) {
        this.form.tags.push(selectedObj);
        this.query = '';

        this.$refs.tagInput.inputValue = '';

        if (this.form.tags.length >= 3) {
          $('#tags-search input').hide();
        }
      }

      this.resetTags();
    },
    removeTag(index) {
      this.form.tags.splice(index, 1);

      this.resetTags();

      if (this.form.tags.length < 3) {
        $('#tags-search input').show();
      }
    },
    successAlert(title, message, callback) {
      if (!callback) {
        return swal(title, message, 'success', callback);
      }
      swal(
        {
          title,
          text: message,
          type: 'success'
        },
        callback
      );
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    checkFairTypeCode() {
      if (this.type == 'pin' && this.form.pin == '') {
        swal('Missing PIN', 'Please enter a pin for or change fair type.', 'error');
        return false;
      } else {
        return true;
      }
    },
    onLaunchGoogleMapsButtonClick() {
      if (this.hasValidCoordinates) {
        window.open(this.googleMapsUrl, '_blank');
      }
    },
    onGecodeVenueAddressButtonClick() {
      this.busyGeocoding = true;
      axios
        .post('/api/geocode', {
          address: this.form.venue.address.line_1,
          municipality: this.form.venue.address.city,
          region: this.form.venue.address.region,
          postal_code: this.form.venue.address.postal_code,
          country_code: this.form.venue.address.country_code
        })
        .then(({ data }) => {
          if (data.data.lng !== null && data.data.lat !== null && data.data.confidence !== null && data.data.timezone !== null) {
            this.form.longitude = data.data.lng ? data.data.lng.toString() : '';
            this.form.latitude = data.data.lat ? data.data.lat.toString() : '';
            const app = this;
            this.$nextTick(() => {
              app.geocodingConfidence = data.data.confidence;
            });
            this.form.starts_at_timezone = data.data.timezone ? data.data.timezone : '';
            this.form.errors.forget('latitude');
            this.form.errors.forget('longitude');
            this.form.errors.forget('timezone');
          } else {
            this.errorAlert('Geocoding Failed', 'No results found for given address.');
          }
        })
        .catch((error) => {
          this.form.longitude = '';
          this.form.latitude = '';
          this.geocodingConfidence = null;
          this.form.starts_at_timezone = '';
          this.errorAlert('Geocoding Failed', 'Failed to geocode the given address.');
        })
        .finally(() => {
          this.busyGeocoding = false;
        });
    },
    handleCustomCoordinatesInput() {
      if (this.hasValidCoordinates) {
        this.geocodingConfidence = 'custom';
      } else {
        this.geocodingConfidence = null;
      }
    }
  }
};