module.exports = {
  props: ['user', 'team', 'roles'],

  /**
   * The component's data.
   */
  data() {
    return {
      updatingTeamMember: null,
      deletingTeamMember: null,
      addExistingUserToTeamRequestPayload: null,

      updateTeamMemberForm: $.extend(
        true,
        new SparkForm({
          role: ''
        }),
        Spark.forms.updateTeamMember
      ),

      updating: false,

      deleteTeamMemberForm: new SparkForm({})
    };
  },

  created() {
    var self = this;

    Bus.$on('addExistingUserToTeam', function(payload) {
      self.displayAddExistingUserToTeamModal(payload);
    });
  },

  methods: {
    /**
     * Edit the given team member.
     */
    editTeamMember(member) {
      this.updatingTeamMember = member;
      this.updateTeamMemberForm.role = member.pivot.role;

      $('#modal-update-team-member').modal('show');
    },

    /**
     * Display the approval modal for the deletion of a team member.
     */
    approveTeamMemberDelete(member) {
      this.deletingTeamMember = member;

      $('#modal-delete-member').modal('show');
    },

    /**
     * Delete the given team member.
     */
    deleteMember() {
      Spark.delete(this.urlForDeleting, this.deleteTeamMemberForm).then(() => {
        Bus.$emit('updateCurrentTeam');

        $('#modal-delete-member').modal('hide');
      });
    },

    /**
     * Determine if the current user can edit a team member.
     */
    canEditTeamMember(member) {
      return this.user.id !== member.id && this.isAdmin;
    },

    /**
     * Determine if the current user can delete a team member.
     */
    canDeleteTeamMember(member) {
      return this.user.id !== member.id && this.isAdmin;
    },

    updateMemberRole() {
      this.updating = true;
      Spark.put(this.urlForUpdating, this.updateTeamMemberForm).then(() => {
        Bus.$emit('updateCurrentTeam');
        this.updating = false;
        $('#modal-update-team-member').modal('hide');
      });
    },

    /**
     * Display Add User Back to Team Modal.
     */
    displayAddExistingUserToTeamModal(payload) {
      this.addExistingUserToTeamRequestPayload = payload;
      $('#modal-add-existing-user-to-team').modal('show');
    },

    /**
     * Add Existing User to Team.
     */
    addExistingUserToTeam() {
      const user = this.addExistingUserToTeamRequestPayload['user'];
      const data = this.addExistingUserToTeamRequestPayload['data'];

      axios.put(`/settings/teams/${this.team.id}/members/${user.id}`, data).then(() => {
        this.addExistingUserToTeamRequestPayload = null;
        Bus.$emit('updateCurrentTeam');
        Bus.$emit('resetInvitationForm');
      });
    },

    getUserByRole(role = 'owner') {
      const members = this.team.users.filter((user) => user.pivot.role === role);
      members.sort((a, b) => {
        // Check if either user's first name is "You"
        if (a.id === this.user.id) {
          return -1; // "You" comes before other names
        } else if (b.id === this.user.id) {
          return 1; // "You" comes before other names
        }

        // If neither user has "You" as the first name, proceed with sorting by last name
        const lastNameA = (a.last_name || '').toUpperCase(); // Using empty string as default value if last name is missing
        const lastNameB = (b.last_name || '').toUpperCase(); // Using empty string as default value if last name is missing

        // Both users don't have "You" as the first name
        if (lastNameA < lastNameB) {
          return -1;
        }
        if (lastNameA > lastNameB) {
          return 1;
        }
        return 0;
      });

      return members;
    }
  },

  computed: {
    isAdmin() {
      return this.user.teams[0] && this.user.teams[0].pivot.role === 'owner';
    },

    /**
     * Get the URL for updating a team member.
     */
    urlForUpdating() {
      return `/settings/${Spark.pluralTeamString}/${this.team.id}/members/${this.updatingTeamMember.id}`;
    },
    /**
     * Get the URL for deleting a team member.
     */
    urlForDeleting() {
      return `/settings/${Spark.pluralTeamString}/${this.team.id}/members/${this.deletingTeamMember.id}`;
    }
  }
};
