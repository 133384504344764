/*
 |--------------------------------------------------------------------------
 | Laravel Spark Bootstrap
 |--------------------------------------------------------------------------
 |
 | First, we will load all of the "core" dependencies for Spark which are
 | libraries such as Vue and jQuery. This also loads the Spark helpers
 | for things such as HTTP calls, forms, and form validation errors.
 |
 | Next, we'll create the root Vue application for Spark. This will start
 | the entire application and attach it to the DOM. Of course, you may
 | customize this script as you desire and load your own components.
 |
 */

require('spark-bootstrap');
require('jquery');

window.FileSaver = require('file-saver');

require('./components/bootstrap');

require('./fairs/fairs');
require('./users/users-search-advanced');
require('./dashboard/dashboard');
require('./sftp/sftp');
require('./visits/visits');
require('./students/students');
require('./connections/connections');
require('./components/individualFair');
require('./components/individualFairDashboard');
require('./components/institution');
require('./components/eventGuide');
require('./components/eventGuideLanding');
require('./components/fairDashboardManageEventGuide');
require('./components/individualVisit');
require('./components/userFairStudent');
require('./components/visitScan');
require('./components/post-login');
require('./components/registration/customAccordion');
require('./components/registration/selectTeamOwnersModal');

require('./ext-js/core');

import 'material-icons/iconfont/material-icons.css';

import './config/chart';

import VTooltip from 'v-tooltip';
import forEach from 'lodash/forEach';
import * as Sentry from "@sentry/vue";

Vue.use(require('vue-cookies'));
Vue.use(VTooltip);

Vue.filter('moment', function(value, format) {
  return moment.parseZone(value).format(format);
});

Vue.filter('birthDateFilter', {
  read: function(value) {
    return value;
  },
  write: function(value) {
    return value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
      .substr(0, 9);
  }
});

let langCache = {};

window.$checkForTranslation = Vue.prototype.$checkForTranslation = function(string) {
  const locale = typeof currentLocale !== 'undefined' ? currentLocale : 'en';
  return getLang(locale)[string] ? true : false;
};

window.$translate = Vue.prototype.$translate = function(string, replace = {}) {
  const locale = currentLocale || 'en';
  let translation = getLang(locale)[string] 
    || getLang('en')[string] 
    || findCaseInsensitiveTranslation(getLang(locale), string) 
    || findCaseInsensitiveTranslation(getLang('en'), string) 
    || string;

  Object.entries(replace).forEach(([key, value]) => {
    if (value === null) {
      console.error(`Translation '${translation}' for key '${key}' contains a null replacement.`);
      return;
    }
    const search = new RegExp(':' + key, 'gi');
    translation = translation.replace(search, value.toString());
  });

  return translation;
};

function findCaseInsensitiveTranslation(lang, string) {
  const lowerCaseString = string.toLowerCase();
  for (const key of Object.keys(lang)) {
    if (key.toLowerCase() === lowerCaseString) {
      return lang[key];
    }
  }
  return null;
}

function getLang(language) {
  if (!langCache[language]) {
    langCache[language] = require(`../../../lang/${language}.json`);
  }
  return langCache[language];
}

Sentry.init({
  Vue,
  dsn: "https://275212eda554b29dc49bd81cfc986eb1@o4507035067023360.ingest.us.sentry.io/4508956120383488",
});

var app = new Vue({
  mixins: [require('spark')]
});