import BaseFairList from './fairList';

export default BaseFairList.extend({
  props: ['query', 'order', 'actingAsAdmin', 'isVirtual'],
  data() {
    return {
      location: null,
      tag: null,
      lockedStatus: '',
      eventId: null
    };
  },
  methods: {
    fetchFairs() {
      if (this.query == '' || this.fetching) {
        return;
      }

      var url =
        '/api/v2/fairs?limit=25&occurrence=' +
        this.occurrence +
        '&query=' +
        this.query +
        '&page=' +
        this.pagination.current_page +
        '&order=' +
        this.order;

      if (this.actingAsAdmin) {
        url += '&owned=false';
      }

      if (this.location) {
        url += `&state=${this.location}`;
      }

      if (this.tag) {
        url += `&tag=${this.tag}`;
      }

      if (this.isVirtual) {
        url += `&tag=virtual`;
      }

      if (this.lockedStatus) {
        url += `&locked_status=${this.lockedStatus}`;
      }

      if (this.eventId) {
        url += `&with_event_id=${this.eventId}`;
      }

      console.log(url);

      this.fetching = true;
      this.error = null;
      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.fairs = success.data.data;
          this.pagination = success.data.meta.pagination;
          Bus.$emit('searchCompleted');
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    }
  },
  created() {
    var self = this;

    Bus.$on('performSearch', function(query, location, tag, lockedStatus, eventId) {
      self.location = location;
      self.tag = tag;
      self.lockedStatus = lockedStatus;
      self.pagination.current_page = 1;
      // self.eventId = eventId;
      self.fetchFairs();
    });
  }
});
