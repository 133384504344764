<template>
  <div class="quill-editor" :class="{
    'ql-contrained': !disabled
  }">
    <slot name="toolbar"></slot>
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import ResizeModule from "@ssumo/quill-resize-module";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// Create a custom image upload handler
const ImageUploader = {
  upload: async (file) => {
    try {
      // Create a temporary image to get dimensions
      const img = new Image();
      const imgPromise = new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Failed to load image'));
      });
      img.src = URL.createObjectURL(file);
      
      // Wait for image to load
      const loadedImg = await imgPromise;
      
      // Create canvas with original dimensions
      const canvas = document.createElement('canvas');
      canvas.width = loadedImg.width;
      canvas.height = loadedImg.height;
      const ctx = canvas.getContext('2d');
      
      // Draw image at original size
      ctx.drawImage(loadedImg, 0, 0);
      
      // Convert to JPEG with high quality compression
      const dataUrl = canvas.toDataURL('image/jpeg', 0.9);
      
      // Convert base64 to blob for upload
      const base64Data = dataUrl.split(',')[1];
      const blobData = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(blobData.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < blobData.length; i++) {
        view[i] = blobData.charCodeAt(i);
      }
      const compressedFile = new Blob([arrayBuffer], { type: 'image/jpeg' });
      
      // Clean up
      URL.revokeObjectURL(img.src);
      
      // Get presigned URL from our API
      const response = await axios.post('/api/images/presigned-url', {
        file_name: file.name.replace(/\.[^/.]+$/, '.jpg'), // Change extension to .jpg
        content_type: 'image/jpeg',
      });
      
      const { presigned_url, key } = response.data;
      
      // Upload the compressed file to S3
      await axios.put(presigned_url, compressedFile, {
        headers: {
          'Content-Type': 'image/jpeg'
        }
      });
      
      // Return the permanent URL without query parameters
      const baseUrl = presigned_url.split('?')[0];
      return baseUrl;
    } catch (error) {
      console.error('Image upload error:', error);
      throw error;
    }
  }
};

Quill.register("modules/resize", ResizeModule);

const defaultOptions = {
  theme: 'snow',
  boundary: document.body,
  modules: {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image', 'video']
      ],
      handlers: {
        'image': function() {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();

          input.onchange = async () => {
            const file = input.files[0];
            try {
              // Insert a loading placeholder first
              const range = this.quill.getSelection(true);
              // Create a simpler spinning SVG
              const loadingSvg = 'data:image/svg+xml;base64,' + btoa(`
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <circle cx="12" cy="12" r="10" fill="none" stroke="#662C8F" stroke-width="2"/>
                    <path d="M12 2C6.48 2 2 6.48 2 12h2c0-4.42 3.58-8 8-8V2z" fill="#662C8F">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 12 12"
                        to="360 12 12"
                        dur="1s"
                        repeatCount="indefinite"/>
                    </path>
                  </g>
                </svg>
              `);
              this.quill.insertEmbed(range.index, 'image', loadingSvg, 'user');
              const uploadingImageIndex = range.index;
              
              // Perform the upload
              const dataUrl = await ImageUploader.upload(file);
              
              // Replace the loading image with the actual image
              this.quill.deleteText(uploadingImageIndex, 1);
              this.quill.insertEmbed(uploadingImageIndex, 'image', dataUrl, 'user');
              this.quill.setSelection(uploadingImageIndex + 1);
            } catch (error) {
              // If error, remove the loading image
              if (typeof uploadingImageIndex !== 'undefined') {
                this.quill.deleteText(uploadingImageIndex, 1);
              }
              console.error('Failed to process image:', error);
            }
          };
        }
      }
    },
    resize: true
  },
  placeholder: 'Insert text here ...',
  readOnly: false
}

// export
export default {
  name: 'quill-editor',
  data() {
    return {
      _options: {},
      _content: '',
      defaultOptions,
    }
  },
  props: {
    content: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    this.quill = null
    delete this.quill
  },
  methods: {
    // Init Quill instance
    initialize() {
      if (this.$el) {
        // Options
        this._options = Object.assign({}, this.defaultOptions, this.globalOptions, this.options)

        // Instance
        this.quill = new Quill(this.$refs.editor, this._options)

        this.quill.enable(false)

        // Set editor content
        if (this.value || this.content) {
          this.quill.clipboard.dangerouslyPasteHTML(this.value || this.content)
        }

        // Mark model as touched if editor lost focus
        this.quill.on('selection-change', range => {
          if (!range) {
            this.$emit('blur', this.quill)
          } else {
            this.$emit('focus', this.quill)
          }
        })

        // Update model if text changes
        this.quill.on('text-change', (delta, oldDelta, source) => {
          let html = this.$refs.editor.children[0].innerHTML
          const quill = this.quill
          const text = this.quill.getText()
          if (html === '<p><br></p>') html = ''
          this._content = html
          this.$emit('input', this._content)
          this.$emit('change', { html, text, quill })
        });

        // Disabled editor
        if (!this.disabled) {
          this.quill.enable(true)
        }

        // Emit ready event
        this.$emit('ready', this.quill)
      }
    },
  },
  watch: {
    // Watch content change
    content(newVal, oldVal) {
      if (this.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal
          this.quill.clipboard.dangerouslyPasteHTML(newVal)
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch content change
    value(newVal, oldVal) {
      if (this.quill) {
        if (newVal && newVal !== this._content) {
          this._content = newVal
          this.quill.clipboard.dangerouslyPasteHTML(newVal)
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch disabled change
    disabled(newVal, oldVal) {
      if (this.quill) {
        this.quill.enable(!newVal)
      }
    }
  }
}
</script>

<style scoped>
.ql-contrained {
  .ql-container {
    height: 200px !important;
  }
}

.ql-disabled {
  border: none;
}
</style>

<style>
.ql-disabled {
  .ql-editor {
    padding: 0 !important;
  }
}
</style>