export default Vue.extend({
  components: {},
  props: {
    team: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      countries: [],
      isSaving: false,
      form: new SparkForm({
        contact_address: '',
        contact_address_line_2: '',
        contact_city: '',
        contact_state: '',
        contact_zip: '',
        contact_country_code: '',
        email: '',
        phone: '',
        phone_country_code: '',
        website: '',
        blog: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        snapchat: '',
        connection_notify_all: false
      })
    };
  },
  mounted() {
    this.form.contact_address = this.team.contact_address;
    this.form.contact_address_line_2 = this.team.contact_address_line_2;
    this.form.contact_city = this.team.contact_city;
    this.form.contact_state = this.team.contact_state;
    this.form.contact_zip = this.team.contact_zip;
    this.form.contact_country_code = this.team.contact_country_code;
    this.form.email = this.team.email;
    this.form.phone = this.team.phone;
    this.form.phone_country_code = this.team.phone_country_code;
    this.form.website = this.team.website;
    this.form.blog = this.team.blog;
    this.form.facebook = this.team.facebook;
    this.form.twitter = this.team.twitter;
    this.form.youtube = this.team.youtube;
    this.form.instagram = this.team.instagram;
    this.form.snapchat = this.team.snapchat;
    this.form.connection_notify_all = this.team.connection_notify_all;

    this.countries = countries;
  },
  methods: {
    update() {
      if (this.form.phone) {
        let countryData = iti2.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
      }

      this.isSaving = true;
      const app = this;
      Spark.put(`/settings/teams/${this.team.id}/profile`, this.form)
        .then(() => {
          app.isSaving = false;
          Bus.$emit('updateTeam');
          Bus.$emit('updateTeams');
        })
        .catch((error) => {
          app.isSaving = false;
          let twilioError = error.errors.filter((e) => {
            return e.title === 'twilio';
          });

          if (twilioError) {
            this.form.errors.errors = Object.assign({}, this.form.errors.errors, { twilio: [twilioError[0].detail] });
          }
        });
    }
  }
});
