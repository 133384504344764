<template>
  <div class="row">
    <div class="col-md-6">
      <div class="mdc-card">
        <h2 class="dashboard-card-title">
          Total Registered by Class
        </h2>
        <loader v-if="fetching" />
        <div class="graph-container">
          <canvas v-show="registered.length > 0 && !fetching" ref="registered-pie-chart" width="400" height="250" />
          <img v-if="registered.length === 0 && !fetching" src="/img/placeholder_pie_chart.png">
        </div>
        <dashboard-pie-chart-table
          v-if="!fetching"
          :items="registered"
          :colors="colorsRegistered"
          :total="registeredTotal"
          empty-title="No one has registered yet."
          empty-subtitle="Data will display after the first person registers."
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mdc-card">
        <h2 class="dashboard-card-title">
          Total Attended by Class
        </h2>
        <loader v-if="fetching" />
        <div class="graph-container">
          <canvas v-show="attended.length > 0 && !fetching" ref="attended-pie-chart" width="400" height="250" />
          <img v-if="attended.length === 0 && !fetching" src="/img/placeholder_pie_chart.png">
        </div>
        <dashboard-pie-chart-table
          v-if="!fetching"
          :items="attended"
          :colors="colorsAttended"
          :total="attendedTotal"
          empty-title="No one has attended yet."
          empty-subtitle="Check back once scanning has started."
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import Loader from '../Loader.vue';
import DashboardPieChartTable from './DashboardPieChartTable.vue';

export default {
  name: 'DashboardPieCharts',
  components: { Loader, DashboardPieChartTable },
  data() {
    return {
      fetching: true,
      registered: [],
      registeredTotal: 0,
      attendedTotal: 0,
      attended: [],
      chartRegistered: null,
      chartScanned: null,
      registeredColorsStart: 'C4F2FD',
      registeredColorsEnd: '02414F',
      attendedColorsStart: 'C9A4E3',
      attendedColorsEnd: '230F31',
      colorsRegistered: [],
      colorsAttended: []
    };
  },
  mounted() {
    this.fetchGraphData();
    Bus.$on('refreshDashboard', this.fetchGraphData);
  },
  methods: {
    createRegisteredGraph() {
      this.createGraph('registered-pie-chart', this.colorsRegistered, 'registered', 'chartRegistered');
    },
    createScannedGraph() {
      this.createGraph('attended-pie-chart', this.colorsAttended, 'attended', 'chartScanned');
    },
    createGraph(ref, colors, chartData, chartObj) {
      const ctx = this.$refs[ref];
      var data = {
        labels: this[chartData].map((item) => item.label),
        datasets: [
          {
            fill: true,
            backgroundColor: colors,
            data: this[chartData].map((item) => item.value)
          }
        ]
      };

      const vm = this;

      const options = {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem) {
              const idx = tooltipItem['0'].index;
              return vm[chartData][idx].label;
            },
            label: function(tooltipItem) {
              const idx = tooltipItem.index;
              return 'Percent: ' + vm[chartData][idx].percentage;
            },
            afterLabel: function(tooltipItem) {
              const idx = tooltipItem.index;
              return 'Number: ' + vm[chartData][idx].value;
            }
          }
        }
      };

      this[chartObj] = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: options
      });
    },
    parseDashboardInfo(dashboardInfo) {
      let infoMap = {
        ['Class of ' + dashboardInfo.seniors_graduation_year]: 'seniors',
        ['Class of ' + dashboardInfo.juniors_graduation_year]: 'juniors',
        ['Class of ' + dashboardInfo.sophomores_graduation_year]: 'sophomores',
        ['Class of ' + dashboardInfo.freshman_graduation_year]: 'freshman'
      };

      if (dashboardInfo.unnamed_total > 0) {
        dashboardInfo = Object.assign(dashboardInfo, dashboardInfo.unnamed_graduation_years);

        Object.keys(dashboardInfo.unnamed_graduation_years).forEach((key) => {
          infoMap = Object.assign(infoMap, { ['Class of ' + key]: key });
        });
      }

      infoMap = Object.assign(infoMap, {
        'Middle School': 'middle_school',
        Transfer: 'transfer_student',
        Parents: 'parent',
        Professionals: 'professional',
        Other: 'other'
      });

      return Object.entries(infoMap)
        .map(([key, value], idx) => {
          return {
            label: key,
            value: dashboardInfo[value],
            percentage: Math.round((dashboardInfo[value] / dashboardInfo.total) * 100) + '%'
          };
        })
        .filter((item) => item.value > 0);
    },
    toHex(number) {
      number = number.toString(16);
      return number.length == 1 ? '0' + number : number;
    },
    generateColors(startingHex, endingHex, numberOfValues) {
      return numberOfValues < 2
        ? ['#' + startingHex]
        : Array(numberOfValues)
            .fill(0)
            .map((_, i) => {
              let ratio = 1 - i / (numberOfValues - 1);
              const red = Math.ceil(
                parseInt(startingHex.substring(0, 2), 16) * ratio + parseInt(endingHex.substring(0, 2), 16) * (1 - ratio)
              );
              const green = Math.ceil(
                parseInt(startingHex.substring(2, 4), 16) * ratio + parseInt(endingHex.substring(2, 4), 16) * (1 - ratio)
              );
              const blue = Math.ceil(
                parseInt(startingHex.substring(4, 6), 16) * ratio + parseInt(endingHex.substring(4, 6), 16) * (1 - ratio)
              );

              return '#' + this.toHex(red) + this.toHex(green) + this.toHex(blue);
            });
    },
    fetchGraphData() {
      this.fetching = true;
      const fair_id = window['fair_id'];
      Bus.$emit('dashboard-info-fetch-' + fair_id + '-loading');
      Api.fairs(fair_id)
        .dashboard.info()
        .then(
          (response) => {
            Bus.$emit('dashboard-info-fetch-' + fair_id + '-completed', response.data);
            this.fetching = false;
            const registered = response.data.registered;
            const attended = response.data.attended;

            if (registered.total > 0) {
              this.registered = this.parseDashboardInfo(registered);
              this.registeredTotal = registered.total;
              this.colorsRegistered = this.generateColors(
                this.registeredColorsStart,
                this.registeredColorsEnd,
                Object.keys(this.registered).length
              );
              this.createRegisteredGraph();
            }

            if (attended.total > 0) {
              this.attended = this.parseDashboardInfo(attended);
              this.attendedTotal = attended.total;
              this.colorsAttended = this.generateColors(
                this.attendedColorsStart,
                this.attendedColorsEnd,
                Object.keys(this.attended).length
              );
              this.createScannedGraph();
            }
          },
          () => {
            this.fetching = false;
            Bus.$emit('dashboard-info-fetch-' + fair_id + '-error');
          }
        );
    }
  }
};
</script>

<style scoped>
.graph-container {
  padding: 32px 0;
  text-align: center;
}
</style>
