import BlurValidationMixin from './validation/blurValidation';
import connectFairsHistory from './connectFairsHistory.vue';

Vue.component('connect-fairs', {
  components: { connectFairsHistory },
  mixins: [BlurValidationMixin],
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isDownloading: false,
      isImporting: false,
      form: new SparkForm({
        file: null,
        logo: null,
        slug: '',
        title: '',
        description: ''
      }),
      fields_to_validate: {
        file: ['required'],
        title: ['required'],
        slug: ['required']
      },
      formErrors: []
    };
  },
  methods: {
    save() {
      if (this.formIsValid()) {
        const app = this;
        app.isImporting = true;

        const formData = new FormData();
        formData.append('csv_file', app.form.file);
        formData.append('logo', app.form.logo);
        formData.append('description', app.form.description);
        formData.append('title', app.form.title);
        formData.append('slug', app.form.slug);
        axios
          .post('/api/connect-fair', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((success) => {
            app.isImporting = false;
            Bus.$emit('reload-connect-fair-history');
            this.formErrors = [];
            this.resetForm();
            app.successAlert(success.data.data.title, success.data.data.message);
          })
          .catch((error) => {
            app.isImporting = false;
            app.file = null;
            app.$refs.fileRef.value = null;

            if (error && error.response && error.response.status === 413) {
              app.errorAlert(
                'Import Too Large',
                'This list of fairs is too large to import. Please break up the list into multiple files and import them one at a time.'
              );
            } else if (error && error.response && error.response.status === 422) {
              this.formErrors = error.response.data.errors[0].detail;
            } else {
              app.handleError(error);
            }
          });
      }
    },
    onDownloadClick() {
      this.isDownloading = true;
      axios
        .get('/api/connect-fair/instructions', {
          responseType: 'blob'
        })
        .then((success) => {
          this.isDownloading = false;
          const contentType = success.headers['content-type'];
          const blob = new Blob([success.data], { type: contentType });
          let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
          if (fileName.charAt(0) === '"') {
            fileName = fileName.substr(1);
          }
          if (fileName.charAt(fileName.length - 1) === '"') {
            fileName = fileName.substr(0, fileName.length - 1);
          }
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          this.isDownloading = false;
          this.handleError(error, 'Failed to download templates and instructions, please try again later.');
        });
    },
    onFileUploadChange() {
      if (this.$refs.fileRef.files && this.$refs.fileRef.files.length) {
        this.form.file = this.$refs.fileRef.files[0];
      } else {
        this.form.file = null;
      }
    },
    onLogoUploadChange() {
      if (this.$refs.logoRef.files && this.$refs.logoRef.files.length) {
        this.form.logo = this.$refs.logoRef.files[0];
      } else {
        this.form.logo = null;
      }
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    handleError(error, defaultMsg = null) {
      if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
        this.errorAlert(error.response.data.errors[0].title, error.response.data.errors[0].detail);
      } else {
        this.errorAlert('Error', defaultMsg ? defaultMsg : 'An unknown error occurred, please try again later.');
      }
    },
    resetForm() {
      this.form.file = null;
      this.form.logo = null;
      this.form.slug = '';
      this.form.title = '';
      this.form.description = '';
      this.$refs.fileRef.value = '';
      this.$refs.logoRef.value = '';
    }
  }
});
