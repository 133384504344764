var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fairUsers.length > 0 && !_vm.loading),expression:"fairUsers.length > 0 && !loading"}],staticClass:"custom-list-table"},[_vm._l((_vm.fairUsers),function(fairUser){return [_c('div',{key:fairUser.email,staticClass:"custom-list-table__item-wrapper"},[_c('div',{staticClass:"custom-list-table__item",class:{
            'no-expanders': _vm.fairUsers.every(fairUser => fairUser.schools.length === 0)
          }},[_c('div',{staticClass:"user-info-container"},[(!_vm.fairUsers.every(fairUser => fairUser.schools.length === 0))?_c('div',{staticClass:"expander-buttons"},[(fairUser.schools.length > 0)?_c('button',{on:{"click":function($event){return _vm.toggleShowMore(fairUser)}}},[(!fairUser.showMore)?_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.76644 7.74167L9.99977 10.975L13.2331 7.74167C13.5581 7.41667 14.0831 7.41667 14.4081 7.74167C14.7331 8.06667 14.7331 8.59167 14.4081 8.91667L10.5831 12.7417C10.2581 13.0667 9.73311 13.0667 9.40811 12.7417L5.58311 8.91667C5.25811 8.59167 5.25811 8.06667 5.58311 7.74167C5.90811 7.42501 6.44144 7.41667 6.76644 7.74167V7.74167Z","fill":"black","fill-opacity":"0.54"}})]):_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.76644 12.2583L9.99977 9.02494L13.2331 12.2583C13.5581 12.5833 14.0831 12.5833 14.4081 12.2583C14.7331 11.9333 14.7331 11.4083 14.4081 11.0833L10.5831 7.25828C10.2581 6.93328 9.73311 6.93328 9.40811 7.25828L5.58311 11.0833C5.25811 11.4083 5.25811 11.9333 5.58311 12.2583C5.90811 12.5749 6.44144 12.5833 6.76644 12.2583V12.2583Z","fill":"black","fill-opacity":"0.54"}})])]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',[_c('p',{staticClass:"custom-list-table__item-title"},[_vm._v("\n                "+_vm._s(_vm.getUserName(fairUser))+"\n              ")]),_vm._v(" "),(fairUser.user.registered_profile)?_c('p',{staticClass:"custom-list-table__item-subtitle"},[_vm._v("\n                "+_vm._s(fairUser.user.email)+"\n              ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"custom-list-table__item-subtitle"},[_vm._v("\n                "+_vm._s(_vm.getUserTitle(fairUser.user))+"\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"custom-list-table__btn-containers",class:{
              'margin-right': !_vm.isAdmin
            }},[(_vm.isAdmin)?_c('button',{staticClass:"pill edit-access-btn",on:{"click":function($event){return _vm.editUser(fairUser)}}},[_vm._v("\n              "+_vm._s(fairUser.permission.display_name)+" "),_c('span',{staticClass:"material-icons"},[_vm._v("edit")])]):_c('span',{staticClass:"pill edit-access-btn"},[_vm._v(_vm._s(fairUser.permission.display_name))]),_vm._v(" "),(!fairUser.user.registered_profile && _vm.isAdmin)?_c('button',{staticClass:"resend-btn",on:{"click":function($event){return _vm.resendInvite(fairUser.user)}}},[_vm._v("\n              RESEND\n            ")]):_vm._e(),_vm._v(" "),(_vm.isAdmin)?_c('button',{staticClass:"btn btn-outline-danger",on:{"click":() => {
                  _vm.removeUserAlert(fairUser.user.id);
                }}},[_c('span',{staticClass:"material-icons"},[_vm._v("close")])]):_vm._e()])]),_vm._v(" "),(fairUser.schools.length > 0 && fairUser.showMore)?_c('div',{staticClass:"expander"},[_c('div',{staticClass:"bold"},[_vm._v("\n            Selected Schools ("+_vm._s(fairUser.schools.length)+")\n          ")]),_vm._v(" "),_c('ul',_vm._l((fairUser.schools),function(school,index){return _c('li',{key:index},[_vm._v("\n              "+_vm._s(school.school)+" "),_c('span',[_vm._v(_vm._s(school.city)+", "+_vm._s(school.state))])])}),0)]):_vm._e()])]})],2),_vm._v(" "),(_vm.fairUsers.length === 0 && !_vm.loading)?_c('list-empty-state',{attrs:{"title":_vm.isPendingUsers ? 'No pending invitations.' : 'No users have been granted access.'}},[_c('template',{slot:"icon"},[_c('img',{attrs:{"src":"/img/empty.svg"}})])],2):_vm._e(),_vm._v(" "),(_vm.loading)?_c('loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }