<template>
  <div id="textarea" :class="{ 'textarea-container': true, disabled, 'has-error': hasError }">
    <label>Recipient email(s)</label>
    <add-users-email v-model="emails" @on-update-emails="updateEmails" />
    <div v-show="hasError" class="error-block">
      <span class="material-icons">error_outline</span>
      <span>{{ error }}</span>
    </div>
    <br>

    <table class="invite-access-table">
      <tr>
        <td class="permissionType">
          <div>
            <label>Access</label>
            <br>
            <add-users-permission-select v-model="permissionType" class="permission-selector" :options="permissionTypes" />
            <p class="invite-help-text">
              Learn about access <a href="https://www.strivescan.com/dashboard-permissions" target="_blank">here.</a>
            </p>
            <div v-show="hasPermissionsError" class="error-block">
              <span class="material-icons">error_outline</span>
              <span>{{ permissionsError }}</span>
            </div>
          </div>
        </td>
        <td class="spacer" />
        <td class="schoolSearch">
          <div v-if="showSchoolPicker">
            <label>Select School(s)</label>
            <add-users-school @on-update-schools="updateSchools" />
            <div v-show="hasSchoolsError" class="error-block">
              <span class="material-icons">error_outline</span>
              <span>{{ schoolsError }}</span>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div class="btn-container">
      <button class="btn btn-primary" @click="inviteUsers">
        {{ buttonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import AddUsersPermissionSelect from './AddUsersPermissionSelect.vue';
import AddUsersEmail from './AddUsersEmail.vue';
import AddUsersSchool from './AddUsersSchool.vue';

import { EMAIL_VALIDATION } from '../../../constants/validations';
import { DASHBOARD_PERMISSIONS } from '../../../constants/permissions';

export default {
  name: 'AddUsersTextarea',
  components: { AddUsersPermissionSelect, AddUsersEmail, AddUsersSchool },
  props: {
    buttonTitle: {
      type: String,
      default: ''
    },
    isWithinModal: {
      type: Boolean,
      default: false
    },
    permissionTypes: {
      type: Array,
      default: null,
      required: false
    }
  },
  data() {
    return {
      emails: [],
      schools: [],
      disabled: false,
      permissionType: null,
      submitted: false,
      initialSchoolValue: null
    };
  },

  computed: {
    showSchoolPicker() {
      return this.permissionType && this.permissionType.name === DASHBOARD_PERMISSIONS['school'];
    },
    error() {
      if (this.emails.length === 0) {
        return 'This field is required.';
      } else {
        for (let i = 0; i < this.emails.length; i++) {
          if (!EMAIL_VALIDATION.test(this.emails[i])) {
            return 'One or more email address is invalid.';
          }
        }
      }
      return null;
    },
    hasError() {
      return this.error && this.submitted;
    },
    schoolsError() {
      let hasSchoolField = (this.permissionType && this.permissionType.name === DASHBOARD_PERMISSIONS['school']) || !this.permissionType;
      if (hasSchoolField && this.schools && this.schools.length < 1) {
        return 'This field is required.';
      }
      return null;
    },
    hasSchoolsError() {
      return this.schoolsError && this.submitted;
    },
    permissionsError() {
      if (!this.permissionType) {
        return 'This field is required.';
      }
      return null;
    },
    hasPermissionsError() {
      return this.permissionsError && this.submitted;
    }
  },
  mounted() {
    Bus.$on('resetAddUsersForm', this.clearForm);
    if (this.isWithinModal) {
      Bus.$on('closeUsersModal', this.closeModalAlert);
    }
  },

  methods: {
    updateSchools(schools) {
      this.schools = schools;
    },

    updateEmails(emails) {
      this.emails = emails;
    },

    inviteUsers() {
      let schoolIds = this.schools.map(function(school) {
        return school.id;
      });

      this.submitted = true;
      if (!this.error && !this.schoolsError) {
        this.disabled = true;
        this.$emit('invite-users', this.emails, this.permissionType.id, schoolIds);
      }
    },
    clearForm() {
      this.emails = [];
      this.schools = [];
      this.permissionType = null;
      this.disabled = false;
      this.submitted = false;
    },
    closeModalAlert() {
      if (this.emails.length === 0 && this.schools.length === 0 && !this.permissionType) {
        this.closeModal();
      } else {
        Bus.$emit('open-custom-dialog', '', 'Discard unsent invitation(s)?', 'DISCARD', 'CANCEL', true, () => {
          this.closeModal();
        });
      }
    },
    closeModal() {
      this.clearForm();
      $('#modal-add-users-to-dashboard').modal('hide');
    }
  }
};
</script>

<style>
.textarea-container.focused .textarea {
  border: 2px solid #662c8f;
  padding: 12px 16px;
}

.textarea-container.has-error .textarea {
  border: 1px solid #c8472d;
}

.textarea-container.focused label {
  color: #662c8f;
}

.textarea-container {
  padding: 16px;
  background: #f3f4f6;
  border-radius: 4px;
  margin-top: 10px;
}

.btn-container {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}

label {
  font-weight: 500;
}

.textarea-container.disabled {
  pointer-events: none;
}

.textarea-container.disabled .pill {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.26);
}

.textarea-container.disabled .pill .close {
  opacity: 0.3;
}

.textarea-container.disabled .btn-primary {
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.26);
  outline: none !important;
}

.invite-help-text {
  padding-top: 4px;
  gap: 4px;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.invite-help-text a {
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}

.invite-access-table .spacer {
  min-width: 15px;
}

.invite-access-table .permissionType {
  min-width: 170px;
}

.invite-access-table .schoolSearch {
  width: 100%;
}
.invite-access-table td {
  vertical-align: top;
}

.permission-selector {
  margin-bottom: 6px;
}

.error-block {
  color: #c8472d;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 0;
}

.error-block .material-icons {
  font-size: 18px !important;
  vertical-align: middle;
}
</style>
