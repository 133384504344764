import Pagination from './pagination';
import _ from 'lodash';
import moment from 'moment-timezone';

export default Vue.extend({
  components: { Pagination },
  props: [],
  data() {
    return {
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      noUploadsMessage: 'There are no uploads to display',
      fetching: false,
      uploads: null
    };
  },
  computed: {
    hasUploads() {
      if (this.uploads == null) {
        return false;
      }

      return this.uploads.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  created() {
    var self = this;
    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = Number(pageNumber);
    });
  },
  methods: {
    loadData() {
      this.fetching = true;
      axios.get('/api/sftp/updates?order=desc&page=' + this.pagination.current_page).then(
        (success) => {
          this.fetching = false;
          this.uploads = _.chain(success.data.data)
            .groupBy(function(item) {
              const centralTime = moment(item.created_at)
                .tz('America/Chicago') // Use the correct timezone
                .utcOffset(-5, true); // UTC-6 for CST, UTC-5 for CDT

              // Format the Central Time as needed (e.g., 'YYYY-MM-DD HH:00:00')
              return centralTime.format('YYYY-MM-DD hh:MM A');
            })
            .map(function(items, date) {
              return {
                date: date,
                items: items
              };
            })
            .value();
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    },

    isUploadFailed(upload) {
      const items = upload.items;
      return _.some(items, (item) => item.status === 'failure');
    },

    getUploadScansData(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.scans;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );

      return groupedData;
    },

    getUploadVisitsData(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.visits;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );

      return groupedData;
    },

    getUploadConnectionsData(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.cx;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );

      return groupedData;
    },

    prefixType(type, name) {
      if (type.startsWith('scans')) {
        return 'Scans - ' + name;
      } else if (type.startsWith('cxs')) {
        return 'Connections - ' + name;
      } else if (type.startsWith('visits')) {
        return 'Visits - ' + name;
      } else {
        return 'Unknown - ' + name;
      }
    },

    getNomenclatureOfScan(scan, type = 'scans') {
      return Object.keys(scan);
    },

    getCountOfScan(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.scans;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );
      return groupedData;
    },

    getCountOfVisit(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.visits;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );
      return groupedData;
    },

    getCountOfConnection(upload) {
      const items = upload.items;
      const groupedData = _.reduce(
        items,
        (result, item) => {
          const fairRecords = item.cx;
          _.forEach(fairRecords, (value, key) => {
            result[key] = (result[key] || 0) + value;
          });
          return result;
        },
        {}
      );
      return groupedData;
    },

    getEvents(upload) {
      const items = upload.items;
      const events = [];
      _.forEach(items, (item) => {
        events.push(...item.events);
      });

      return _.uniq(events);
    },

    getStatus(upload) {
      const items = upload.items;
      const status = [];
      _.forEach(items, (item) => {
        status.push(item.status);
      });

      return status;
    },

    getError(upload) {
      return _.get(upload, 'items.0.error_description', '');
    }
  }
});
