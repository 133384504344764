<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '../Maps';

export default {
  name: 'EventGuideMapbox',
  components: {},
  props: [
    'participants',
  ],
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.initializeMap();
  },
  unmounted() {
    this.map.remove();
    this.map = null;
  },
  methods: {
    initializeMap() {
      const bounds = this.participants.reduce((acc, participant) => {
        return acc.extend([participant.lng, participant.lat]);
      }, new mapboxgl.LngLatBounds());

      // Check if the bounds are a single point
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      if (northEast.lat === southWest.lat && northEast.lng === southWest.lng) {
        const offset = 0.01; // Adjust this value as needed for the bounding box size
        bounds.extend([northEast.lng - offset, northEast.lat - offset]);
        bounds.extend([northEast.lng + offset, northEast.lat + offset]);
      }

      const map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: 'mapbox://styles/mapbox/light-v11',
        center: bounds.getCenter(),
        zoom: 9,
      });

      map.addControl(new mapboxgl.NavigationControl());

      map.on('load', () => {
        if (bounds) {
          map.fitBounds(bounds, {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
          });
        }

        this.addParticipants();
      });

      this.map = map;
    },
    addParticipants() {
      this.participants.forEach((participant) => {
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${participant.image_url})`;
        el.style.backgroundSize = 'cover';
        el.style.width = '50px';
        el.style.height = '50px';
        el.style.borderRadius = '4px';

        new mapboxgl.Marker(el)
          .setLngLat([participant.lng, participant.lat])
          .addTo(this.map);
      });
    },
  },
};
</script>

<style>
.map-container {
  height: 400px;
  padding: 0;
  flex: 1;
  margin-bottom: 40px;
}
</style>