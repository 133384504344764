import Template from './attendanceChart.html';

export default Vue.extend({
  props: {
    range: {
      type: Number,
      default: 100
    },
    registered: {
      type: Number,
      default: 100
    },
    attended: {
      type: Number,
      default: 100
    }
  },
  computed: {
    attendedBarWidth() {
      if (this.registered > 0) {
        return (this.attended / this.registered) * 100.0;
      }
      return 0.0;
    }
  },
  template: Template,
  beforeUnmount() {}
});
