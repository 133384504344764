import Template from './engagmentPerInstitutions.html';
import { TYPES, LAYERS } from './mapManager.js';
import EngagementBase from '../../../mixins/engagmentBase';

export default Vue.extend({
  mixins: [EngagementBase],
  props: {
    zoom: {
      type: Number,
      default: 7
    }
  },
  computed: {
    containerRef() {
      return this.$refs.institutions_engagement_map_container;
    },
    mapType() {
      return TYPES.INSTITUTIONS;
    }
  },
  methods: {
    onMapInitComplete() {
      this.map.setVisibleLayer(LAYERS.INSTITUTIONS);
    }
  },
  template: Template
});
