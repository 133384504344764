import Vue from 'vue';
import Fair from './DashboardFairListCard.vue';
import Pagination from '../../PaginationVariant.vue';
import EmptyState from '../../ListEmptyState.vue';
import { FAIR_DASHBOARD_LIST, FAIR_TAGS } from '../../../constants/dashboard-paths';
import DateTimePicker from '../../shared/DateTimePicker.vue';

const DATE_FORMAT = 'YYYY-MM-DD';

export default Vue.extend({
  components: { Fair, Pagination, EmptyState, DateTimePicker },
  props: {
    listHash: {
      type: String,
      default: null
    },
    occurrence: {
      type: String,
      default: null
    },
    order: {
      type: String,
      default: null
    },
    isVirtual: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      fairs: null,
      pagination: {
        current_page: 1,
        page_count: 1,
        per_page: 20,
        total_count: 1
      },
      fetching: false,
      errors: null,
      isActivePage: false,
      query: '',
      fair_locations: [],
      fair_types: [],
      location: null,
      from_date: null,
      to_date: null,
      fetchingTypes: false,
      fetchingStates: false,
      tag: null
    };
  },
  computed: {
    hasFairs() {
      return this.fairs !== null && this.fairs.length > 0;
    },
    isSearching() {
      return (
        this.query.length > 0 ||
        (this.location && this.location.length > 0) ||
        (this.tag && this.tag.length > 0) ||
        (this.from_date && this.from_date.length) > 0 ||
        (this.date && this.to_date.length) > 0
      );
    },
    isPast() {
      return this.occurrence == 'past';
    },
    emptyStateText() {
      if (this.isSearching) {
        return `You don’t have any dashboards for ${this.occurrence} fairs that match your search criteria.`;
      } else {
        return `You don’t have any dashboards for ${this.occurrence} fairs.`;
      }
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchFairs();
    }
  },
  mounted() {
    var self = this;
    Bus.$on('sparkHashChanged', function(hash) {
      self.isActivePage = hash === self.listHash;
      if (self.isActivePage) {
        self.clearFilters();
        if (self.fair_types.length === 0 && self.fair_locations.length === 0) {
          self.fetchData();
        }
      }
    });
  },
  created() {
    var self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      if (self.isActivePage) {
        self.pagination.current_page = Number(pageNumber);
      }
    });
  },
  methods: {
    fetchFairs() {
      this.fetching = true;
      var url =
        FAIR_DASHBOARD_LIST +
        '?occurrence=' +
        this.occurrence +
        '&page=' +
        this.pagination.current_page +
        '&limit=' +
        this.pagination.per_page +
        '&order=' +
        this.order +
        '&query=' +
        this.query;

      if (this.location) {
        url += `&state=${this.location}`;
      }

      if (this.tag) {
        url += `&tag=${this.tag}`;
      }

      if (this.from_date) {
        url += `&from_date=${moment(this.from_date).format(DATE_FORMAT)}`;
      }

      if (this.to_date) {
        url += `&to_date=${moment(this.to_date).format(DATE_FORMAT)}`;
      }

      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.fairs = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    },
    clearFilters() {
      this.query = '';
      this.location = null;
      this.from_date = null;
      this.to_date = null;
      this.tag = null;
      this.pagination.current_page = 1;
      document.querySelectorAll('.filter').forEach((el) => {
        el.value = '';
      });
      document.querySelectorAll('.date').forEach((el) => {
        el.value = 'View All';
      });
      this.fetchFairs();
    },
    fetchData() {
      if (this.fetchingTypes !== true) {
        this.fetchingTypes = true;

        axios.get(FAIR_TAGS).then(
          (response) => {
            this.fair_types = response.data.data;
            this.fetchingTypes = false;
          },
          () => {
            this.fetchingTypes = false;
          }
        );
      }

      if (this.fetchingStates !== true) {
        this.fetchingStates = true;
        axios.get('/api/fair-states').then(
          (response) => {
            this.fetchingStates = false;
            this.fair_locations = response.data.data;
          },
          (error) => {
            this.fetchingStates = false;
          }
        );
      }
    },
    changeQuery() {
      this.pagination.current_page = 1;
      this.fetchFairs();
    },
    changeLocation(ev) {
      this.location = ev.target.value;
      this.changeQuery();
    },
    changeFromDate(val) {
      this.from_date = val;
      this.changeQuery();
    },
    changeToDate(val) {
      this.to_date = val;
      this.changeQuery();
    },
    changeTag(ev) {
      this.tag = ev.target.value;
      this.changeQuery();
    },
    clearDatePicker(id) {
      $(id).val('View All');
    },
    triggerSearch() {
      if (this.query === '') {
        this.changeQuery();
      }
    }
  }
});
