import VisitScanList from '../visitScanList';

export default VisitScanList.extend({
  data() {
    return {
      noStudentsMessage: 'You have not scanned any students for this visit.'
    };
  },
  computed: {
    url() {
      return '/api/visits/' + this.visit.id + '/scans?limit=25&page=' + this.pagination.current_page;
    }
  }
});
