Vue.component('student-import', {
  data() {
    return {
      isDownloading: false,
      isImporting: false,
      studentType: 'USA',
      sendStudentsEmails: false,
      sendStudentsSms: false,
      file: null,
      validationErrors: null
    };
  },
  mounted() {},
  methods: {
    onStudentImportClick() {
      if (this.file) {
        const app = this;
        swal(
          {
            title: 'Import?',
            text: 'Are you sure you want to import this list of students?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Import!',
            closeOnConfirm: false,
            closeOnCancel: false,
            showLoaderOnConfirm: true
          },
          function(isConfirm) {
            if (!isConfirm) {
              app.errorAlert('Canceled', 'The import has been canceled!');
            } else {
              app.isImporting = true;

              const formData = new FormData();
              formData.append('csv_file', app.file);
              formData.append('student_type', app.studentType);
              formData.append('send_emails', app.sendStudentsEmails.toString());
              formData.append('send_sms', app.sendStudentsSms.toString());

              axios
                .post('/api/student-import', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((success) => {
                  app.resetForm();
                  app.successAlert(success.data.data.title, success.data.data.message);
                })
                .catch((error) => {
                  app.isImporting = false;
                  app.validationErrors = null;
                  app.file = null;
                  app.$refs.fileRef.value = null;

                  if (error && error.response && error.response.status === 413) {
                    app.errorAlert(
                      'Import Too Large',
                      'This list of students is too large to import. Please break up the list into multiple files and import them one at a time.'
                    );
                  } else if (
                    error &&
                    error.response &&
                    error.response.status === 422 &&
                    error.response.data &&
                    error.response.data.errors &&
                    error.response.data.errors.length
                  ) {
                    swal(
                      {
                        title: 'Validation Failed',
                        text: 'The uploaded list of students contains invalid data.',
                        type: 'error'
                      },
                      function() {
                        app.validationErrors = error.response.data.errors[0].errors;
                      }
                    );
                  } else if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    error.response.data.errors.length
                  ) {
                    app.errorAlert(error.response.data.errors[0].title, error.response.data.errors[0].detail);
                  } else {
                    app.errorAlert('Error', 'An unknown error occurred, please try again later.');
                  }
                });
            }
          }
        );
      }
    },
    onDownloadClick() {
      this.isDownloading = true;

      axios
        .get('/api/student-import/instructions', {
          responseType: 'blob'
        })
        .then((success) => {
          this.isDownloading = false;
          const contentType = success.headers['content-type'];
          const blob = new Blob([success.data], { type: contentType });
          let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
          if (fileName.charAt(0) === '"') {
            fileName = fileName.substr(1);
          }
          if (fileName.charAt(fileName.length - 1) === '"') {
            fileName = fileName.substr(0, fileName.length - 1);
          }
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          this.isDownloading = false;
          this.errorAlert('Download Failed', 'Failed to download templates and instructions, please try again later.');
        });
    },
    onFileUploadChange() {
      if (this.$refs.fileRef.files && this.$refs.fileRef.files.length) {
        this.file = this.$refs.fileRef.files[0];
      } else {
        this.file = null;
      }

      this.validationErrors = null;
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    resetForm() {
      this.file = null;
      this.studentType = 'USA';
      this.sendStudentsEmails = false;
      this.sendStudentsSms = false;
      this.isImporting = false;
      this.isDownloading = false;
      this.$refs.fileRef.value = null;
      this.validationErrors = null;
    }
  }
});
