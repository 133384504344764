import { ApiSurface } from './api';

const BASE_URL = '/api/';

const STUDENT_CONNECTIONS_LIST = (teamId, fairId) => {
  return `${BASE_URL}v2/teams/${teamId}/connections/${fairId}`;
};

const STUDENT_CONNECTIONS_INSTITUTION_SEARCH = (hashCreatedAt, hashStudentId, fairId) => {
  return `${BASE_URL}connections/${hashCreatedAt}/${hashStudentId}/${fairId}`;
};

const CONNECTION_RECIPIENTS_REQUESTS_BASE = (teamId) => {
  return `${BASE_URL}teams/${teamId}/connection_recipients`;
};

const CONNECTION_RECIPIENTS_REQUESTS_DELETE = (teamId, recipientId) => {
  return `${CONNECTION_RECIPIENTS_REQUESTS_BASE(teamId)}/${recipientId}`;
};

/**
 * request table entry
 * @typedef {Object} ConnectionRecipientsCreationRequest
 * @property {string[]} emails - Array of emails to be added as connection repipeints
 */

/**
 * The result of an adding of connection recipients
 * @typedef {Object} ConnectionRecipientsCreationResponse
 * @property {boolean} success - was the query successful
 * @property {ConnectionRecipients[]} data - the list of connection recipients
 */

/**
 * The result of an adding of connection recipients
 * @typedef {Object} ConnectionRecipients
 * @property {number} id - id of the object
 * @property {string} email - added email
 * @property {string} created_at - iso date format
 * @property {string} updated_at - iso date format
 */

/**
 * The paginated result meta data
 * @typedef {Object} ResultPaginationInfo
 * @property {number} current_page - the 1-based current page number
 * @property {number} per_page - the number of items per page passed to the request
 * @property {number} page_count - the total number of pages available
 * @property {number} total_count - the total number of items available
 */
/**
 * request table entry
 * @typedef {Object} Student
 * @property {string} id - the id for the student
 * @property {string} first_name - the first_name for the student
 * @property {string} last_name - the last_name for the student
 * @property {string} phone - the phone for the student
 * @property {string} birthday - the birthday for the student
 * @property {string} email - the email for the student
 * @property {string} high_school - the high_school for the student
 * @property {string} graduation_year - the graduation_year for the student
 * @property {string} connection_created_at - the date the connection was created
 */
/**
 * The result of a student connections table request
 * @typedef {Object} StudentConnectionsListResult
 * @property {boolean} success - was the query successful
 * @property {Student[]} data - the list of students
 * @property {ResultPaginationInfo} meta - the meta data about the result
 */

/**
 * @typedef {Object} IndexInstitutionsRequest
 * @property {string|null} query - The filter parameter to be applied by the back-end
 * @property {number|null} page - the 1-based page index of the paged data
 * @property {number|null} limit - the maximum number of rows to return
 * @property {number|null} offset - the maximum number of rows to return
 */

/**
 * @typedef {Object} IndexInstitutionsResult
 * @property {boolean} success - Status of the request
 * @property {InstitutionSearchResult[]} data - List of institutions
 * @property {ResultPaginationInfo} meta - Meta data containing pagination information
 */

/**
 * @typedef {Object} InstitutionSearchResult
 * @property {string} id - ID of the Fair
 * @property {string} team - ID of the Team
 * @property {string} name - Name of the Institution
 * @property {string} address_line1 - The institution's address (line 1)
 * @property {string} address_line2 - The institution's address (line 2)
 * @property {string} municipality - The institution's municipality
 * @property {string} locality - The institution's locality
 * @property {string} region - The institution's region
 * @property {string} postal_code - The institution's postal_code
 * @property {string} country_code - The institution's country_code
 * @property {number} longitude - The institution's country_code
 * @property {number} latitude - The institution's country_code
 * @property {string} website - The institution's website
 * @property {string} scanned_status - The scanned status of the requested student for this institution
 */

export class StudentConnectionApiSurface extends ApiSurface {
  constructor(axiosHttp) {
    super(axiosHttp);

    /** @param {number} team_id - the team_id for the current request */
    this.connection_recipients = (team_id) => {
      return {
        /**
         * @param {ConnectionRecipientsCreationRequest}
         * @returns {Promise<ConnectionRecipientsCreationResponse>}
         */
        create: (data) => {
          return this._request({
            method: 'post',
            url: CONNECTION_RECIPIENTS_REQUESTS_BASE(encodeURI(team_id)),
            data
          });
        },
        list: () => {
          return this._request({
            method: 'get',
            url: CONNECTION_RECIPIENTS_REQUESTS_BASE(encodeURI(team_id))
          });
        },
        remove: (recipient_id) => {
          return this._request({
            method: 'delete',
            url: CONNECTION_RECIPIENTS_REQUESTS_DELETE(encodeURI(team_id), encodeURI(recipient_id))
          });
        }
      };
    };

    /** @param {number} team_id - the team_id for the current request */
    /** @param {number} fair_id - the fair_id for the current request */
    this.connections = (team_id, fair_id) => {
      return {
        /**
         * @returns { Promise<StudentConnectionsListResult> } the students rows for the specified args
         */
        list: (params) => {
          return this._request({
            method: 'get',
            url: STUDENT_CONNECTIONS_LIST(encodeURI(team_id), encodeURI(fair_id)),
            params
          });
        }
      };
    };
    /** @param {number} fair_id - the fair_id for the current request */
    /** @param {number} hash_created_at - the hash_created_at for the current request */
    /** @param {number} hash_student_id - the hash_student_id for the current request */
    this.institution_search_requests = (hash_created_at, hash_student_id, fair_id) => {
      return {
        list: (params) => {
          /**
           * @param { IndexInstitutionsRequest | null } args
           * @returns { Promise<IndexInstitutionsResult> } - Result set containing requested data
           */
          return this._request({
            method: 'get',
            url: STUDENT_CONNECTIONS_INSTITUTION_SEARCH(encodeURI(hash_created_at), encodeURI(hash_student_id), encodeURI(fair_id)),
            params
          });
        }
      };
    };
  }
}

export const StudentConnectionApi = new StudentConnectionApiSurface(window.axios);
