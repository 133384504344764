Vue.component('home', {
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  mounted() {
    //
  }
});
