import BaseFairList from './fairList';
import SearchedFairs from './searchedFairs';

export default BaseFairList.extend({
  components: { SearchedFairs },
  data() {
    return {
      query: '',
      fair_locations: [],
      fair_types: [],
      location: null,
      unlocked: false,
      hasEventGuide: false,
      fetchingTypes: false,
      fetchingStates: false,
      tag: null,
      eventId: null
    };
  },

  mounted() {
    var self = this;
    Bus.$on('sparkHashChanged', function(hash, parameters) {
      if (hash === self.listHash && self.fair_types.length === 0 && self.fair_locations.length === 0) {
        self.fetchData();
      }
    });
  },

  methods: {
    fetchData() {
      if (this.fetchingTypes !== true) {
        this.fetchingTypes = true;

        axios.get(this.url).then(
          (response) => {
            this.fetchingTypes = false;
            if (this.actingAsAdmin) {
              this.fair_types = response.data.data;
            } else {
              this.fair_types = response.data.data.filter((item) => item.slug !== 'virtual');
            }
          },
          (error) => {
            this.fetchingTypes = false;
          }
        );
      }

      if (this.fetchingStates !== true) {
        this.fetchingStates = true;
        axios.get('/api/fair-states').then(
          (response) => {
            this.fetchingStates = false;
            this.fair_locations = response.data.data;
          },
          (error) => {
            this.fetchingStates = false;
          }
        );
      }
    },
    changeLocation(ev) {
      this.location = ev.target.value;
      if (this.query) {
        this.performSearch();
      } else {
        this.fetchFairs();
      }
    },

    changeTag(ev) {
      this.tag = ev.target.value;
      if (this.query) {
        this.performSearch();
      } else {
        this.fetchFairs();
      }
    },

    changeEventId(ev) {
      this.eventId = ev.target.value;
      if (this.query) {
        this.performSearch();
      } else {
        this.fetchFairs();
      }
    }
  },
  watch: {
    unlocked(val) {
      this.fetchFairs(val, this.hasEventGuide);
    },
    hasEventGuide(val) {
      this.fetchFairs(this.unlocked, val);
    }
  },
  computed: {
    url() {
      if (this.actingAsAdmin) {
        return '/api/fair-tags';
      }
      return '/api/fair-tags?exclude=virtual';
    }
  }
});
