<template>
  <div>
    <div v-show="fairUsers.length > 0 && !loading" class="custom-list-table">
      <template v-for="fairUser in fairUsers">
        <div :key="fairUser.email" class="custom-list-table__item-wrapper">
          <div
            class="custom-list-table__item"
            :class="{
              'no-expanders': fairUsers.every(fairUser => fairUser.schools.length === 0)
            }"
          >
            <div class="user-info-container">
              <div v-if="!fairUsers.every(fairUser => fairUser.schools.length === 0)" class="expander-buttons">
                <button v-if="fairUser.schools.length > 0" @click="toggleShowMore(fairUser)">
                  <svg v-if="!fairUser.showMore" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.76644 7.74167L9.99977 10.975L13.2331 7.74167C13.5581 7.41667 14.0831 7.41667 14.4081 7.74167C14.7331 8.06667 14.7331 8.59167 14.4081 8.91667L10.5831 12.7417C10.2581 13.0667 9.73311 13.0667 9.40811 12.7417L5.58311 8.91667C5.25811 8.59167 5.25811 8.06667 5.58311 7.74167C5.90811 7.42501 6.44144 7.41667 6.76644 7.74167V7.74167Z"
                      fill="black"
                      fill-opacity="0.54"
                    />
                  </svg>
                  <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.76644 12.2583L9.99977 9.02494L13.2331 12.2583C13.5581 12.5833 14.0831 12.5833 14.4081 12.2583C14.7331 11.9333 14.7331 11.4083 14.4081 11.0833L10.5831 7.25828C10.2581 6.93328 9.73311 6.93328 9.40811 7.25828L5.58311 11.0833C5.25811 11.4083 5.25811 11.9333 5.58311 12.2583C5.90811 12.5749 6.44144 12.5833 6.76644 12.2583V12.2583Z"
                      fill="black"
                      fill-opacity="0.54"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <p class="custom-list-table__item-title">
                  {{ getUserName(fairUser) }}
                </p>
                <p v-if="fairUser.user.registered_profile" class="custom-list-table__item-subtitle">
                  {{ fairUser.user.email }}
                </p>
                <p class="custom-list-table__item-subtitle">
                  {{ getUserTitle(fairUser.user) }}
                </p>
              </div>
            </div>
            <div
              class="custom-list-table__btn-containers"
              :class="{
                'margin-right': !isAdmin
              }"
            >
              <button v-if="isAdmin" class="pill edit-access-btn" @click="editUser(fairUser)">
                {{ fairUser.permission.display_name }} <span class="material-icons">edit</span>
              </button>
              <span v-else class="pill edit-access-btn">{{ fairUser.permission.display_name }}</span>

              <button v-if="!fairUser.user.registered_profile && isAdmin" class="resend-btn" @click="resendInvite(fairUser.user)">
                RESEND
              </button>
              <button
                v-if="isAdmin"
                class="btn btn-outline-danger"
                @click="
                  () => {
                    removeUserAlert(fairUser.user.id);
                  }
                "
              >
                <span class="material-icons">close</span>
              </button>
            </div>
          </div>
          <div v-if="fairUser.schools.length > 0 && fairUser.showMore" class="expander">
            <div class="bold">
              Selected Schools ({{ fairUser.schools.length }})
            </div>
            <ul>
              <li v-for="(school, index) in fairUser.schools" :key="index">
                {{ school.school }} <span>{{ school.city }}, {{ school.state }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
    <list-empty-state
      v-if="fairUsers.length === 0 && !loading"
      :title="isPendingUsers ? 'No pending invitations.' : 'No users have been granted access.'"
    >
      <template slot="icon">
        <img src="/img/empty.svg">
      </template>
    </list-empty-state>
    <loader v-if="loading" />
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import ListEmptyState from '../../ListEmptyState.vue';
import Loader from '../Loader.vue';

export default {
  name: 'AddUsersList',
  components: { Loader, ListEmptyState },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isPendingUsers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fairUsers: [],
      loading: true
    };
  },
  computed: {},
  mounted() {
    this.fetchUsers();
    Bus.$on('resetAddUsersForm', this.fetchUsers);
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      Api.fairs(window['fair_id'])
        .dashboard.listFairUsers({ is_pending_registration: this.isPendingUsers })
        .then((resp) => {
          this.fairUsers = resp.data.data;
          this.loading = false;
        });
    },
    getUserName(fairUser) {
      let user = fairUser.user;
      if (user && user.registered_profile === 1) {
        return user.first_name + ' ' + user.last_name;
      } else {
        return 'Pending Access';
      }
    },
    getUserTitle(user) {
      let result = '';
      if (user.registered_profile) {
        if (user.team && user.team.team_type_id == 1) {
          result += user.team.name;
        } else {
          if (user.school.school) {
            result += user.school.school + ', ';
          } else if (user.organization) {
            result += user.organization + ', ';
          }
        }
        if (user.title) {
          result += user.title;
        }
        return result;
      } else {
        return user.email;
      }
    },
    removeUserAlert(id) {
      Bus.$emit(
        'open-custom-dialog',
        this.isPendingUsers ? 'Remove invitation?' : 'Remove User?',
        this.isPendingUsers
          ? 'This user will not be able to accept this invitation unless re-invited.'
          : 'This user will not be able to access this dashboard unless re-invited.',
        'REMOVE',
        'CANCEL',
        true,
        () => {
          this.removeUser(id);
        }
      );
    },
    removeUser(id) {
      Api.fairs(window['fair_id'])
        .dashboard.deleteUser(id)
        .then(() => {
          if (id === window['user_id']) {
            window.location.href = '/dashboards';
          }
          this.fetchUsers();
          this.$emit(
            'showAlertSuccess',
            this.isPendingUsers ? 'User invitation was removed from the dashboard' : 'User was removed from the dashboard.'
          );
        });
    },
    resendInvite(user) {
      this.$emit('resendInvite', user.id);
    },
    editUser(fairUser) {
      this.$emit('editUser', fairUser);
    },
    toggleShowMore(fairUser) {
      if (fairUser.hasOwnProperty('showMore')) {
        fairUser.showMore = !fairUser.showMore;
      } else {
        this.$set(fairUser, 'showMore', true);
      }
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: 600;
}

.custom-list-table {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.custom-list-table__item-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.custom-list-table__item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 4px;
}

.custom-list-table__item.no-expanders {
  padding: 10px 0 10px 16px;
}

.custom-list-table__item-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-list-table__item-subtitle {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-list-table__btn-containers {
  align-items: center;
  display: flex;
}

.margin-right {
  margin-right: 10px;
}

.user-info-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.user-info-container .expander-buttons {
  min-width: 32px;
}

.expander-buttons button {
  background: none;
  border: none;
}

.btn {
  display: flex;
}

.edit-access-btn {
  color: #181b26;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 0 0;
}

.edit-access-btn .material-icons {
  font-size: 18px !important;
  margin-left: 8px;
  vertical-align: bottom;
}

.resend-btn {
  align-items: center;
  background: #ffffff;
  border: none;
  color: #662c8f;
  display: flex;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.56px;
  line-height: 20px;
  padding: 4px 2px;
  padding: 6px 12px;
  text-transform: uppercase;
}

.expander {
  width: 100%;
  padding-left: 46px;
  padding-bottom: 10px;
}

.expander ul {
  list-style-type: none;
  padding-left: 0;
}

.expander ul li {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 600;
}

.expander ul li span {
  font-size: 12px;
  padding-left: 10px;
  color: #181b26;
  font-weight: 400;
}
</style>
