<template>
  <div>
    <div id="modal-school-details-dashboard" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button " class="close" data-dismiss="modal" aria-hidden="true">
              &times;
            </button>
            <div>
              <p class="custom-subtitle">
                Details
              </p>
              <h3 class="modal-title">
                {{ school.name }}
              </h3>
            </div>
          </div>

          <div id="scrollable-modal-body" class="modal-body">
            <loader v-if="fetching" />
            <div v-if="items.length > 0 && !fetching">
              <div class="mdc-data-table">
                <div class="mdc-data-table__table-container">
                  <table class="mdc-data-table__table" aria-label="School details">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
                          Category
                        </th>
                        <th
                          class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__cell--registered"
                          role="columnheader"
                          scope="col"
                        >
                          Registered
                        </th>
                        <th
                          class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__cell--scanned"
                          role="columnheader"
                          scope="col"
                        >
                          Attended
                        </th>
                        <th
                          v-if="!hideConnections"
                          class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                          role="columnheader"
                          scope="col"
                        >
                          Connections
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      <tr v-for="item in items" :key="item.label" class="mdc-data-table__row">
                        <th class="mdc-data-table__cell" scope="row">
                          {{ item.label }}
                        </th>
                        <td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--registered">
                          {{ item.registered }}
                        </td>
                        <td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--scanned">
                          {{ item.attended }}
                        </td>
                        <td v-if="!hideConnections" class="mdc-data-table__cell mdc-data-table__cell--numeric">
                          {{ item.connections }}
                        </td>
                      </tr>
                      <tr class="mdc-data-table__row">
                        <th class="mdc-data-table__cell" scope="row">
                          <b>Total</b>
                        </th>
                        <td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--registered">
                          {{ registeredTotal }}
                        </td>
                        <td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--scanned">
                          {{ attendedTotal }}
                        </td>
                        <td v-if="!hideConnections" class="mdc-data-table__cell mdc-data-table__cell--numeric">
                          {{ connectionsTotal }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="school-modal-info">
                <p><strong>Registered:</strong> Count of people who registered.</p>
                <p><strong>Attended:</strong> Count of people who scanned with at least one institution at the fair.</p>
                <p>
                  <strong>Connections:</strong> Number of connections sent after the fair. All registered people have the opportunity to
                  share their information and connect with institutions that they might have missed at the fair.
                </p>
              </div>
            </div>
            <list-empty-state v-if="items.length === 0 && !fetching" title="No details are available yet." :no-border="true">
              <template slot="icon">
                <span class="material-icons">alarm</span>
              </template>
              <span>This data will be available after the first registration.</span>
            </list-empty-state>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import ListEmptyState from '../../ListEmptyState.vue';
import Loader from '../Loader.vue';

function findPosition(obj) {
  var currenttop = 0;
  if (obj.offsetParent) {
    do {
      currenttop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [currenttop];
  }
}
export default {
  name: 'SchoolDetailsModal',
  components: { ListEmptyState, Loader },
  props: ['school'],
  data() {
    return {
      fetching: false,
      registeredTotal: 0,
      attendedTotal: 0,
      connectionsTotal: 0,
      items: [],
      hideConnections: false
    };
  },
  watch: {
    school() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetching = true;
      Api.fairs(window['fair_id'])
        .dashboard.info(this.school)
        .then(
          (response) => {
            this.fetching = false;
            let registered = response.data.registered;
            let attended = response.data.attended;
            let connections = response.data.connections;
            this.registeredTotal = registered.total;
            this.attendedTotal = attended.total;

            if (connections) {
              this.connectionsTotal = connections.total;
            } else {
              this.hideConnections = true;
            }

            var senior_year =
              registered.seniors_graduation_year == 0 && attended.seniors_graduation_year == 0
                ? connections.seniors_graduation_year
                : registered.seniors_graduation_year == 0 && attended.seniors_graduation_year != 0
                ? attended.seniors_graduation_year
                : registered.seniors_graduation_year;
            var junior_year =
              registered.juniors_graduation_year == 0 && attended.juniors_graduation_year == 0
                ? connections.juniors_graduation_year
                : registered.juniors_graduation_year == 0 && attended.juniors_graduation_year != 0
                ? attended.juniors_graduation_year
                : registered.juniors_graduation_year;
            var sophmore_year =
              registered.sophomores_graduation_year == 0 && attended.sophomores_graduation_year == 0
                ? connections.sophomores_graduation_year
                : registered.sophomores_graduation_year == 0 && attended.sophomores_graduation_year != 0
                ? attended.sophomores_graduation_year
                : registered.sophomores_graduation_year;
            var freshman_year =
              registered.freshman_graduation_year == 0 && attended.freshman_graduation_year == 0
                ? connections.freshman_graduation_year
                : registered.freshman_graduation_year == 0 && attended.freshman_graduation_year != 0
                ? attended.freshman_graduation_year
                : registered.freshman_graduation_year;

            let itemsMap = {
              ['Class of ' + senior_year]: 'seniors',
              ['Class of ' + junior_year]: 'juniors',
              ['Class of ' + sophmore_year]: 'sophomores',
              ['Class of ' + freshman_year]: 'freshman'
            };

            if (registered.unnamed_total > 0 || attended.unnamed_total > 0) {
              registered = Object.assign(registered, registered.unnamed_graduation_years);
              attended = Object.assign(attended, attended.unnamed_graduation_years);
              if (connections) {
                connections = Object.assign(connections, connections.unnamed_graduation_years);
              }

              Object.keys(registered.unnamed_graduation_years).forEach((key) => {
                itemsMap = Object.assign(itemsMap, { ['Class of ' + key]: key });
              });

              Object.keys(attended.unnamed_graduation_years).forEach((key) => {
                itemsMap = Object.assign(itemsMap, { ['Class of ' + key]: key });
              });

              if (connections) {
                Object.keys(connections.unnamed_graduation_years).forEach((key) => {
                  itemsMap = Object.assign(itemsMap, { ['Class of ' + key]: key });
                });
              }

              // add the keys in sorted order, so the classes appear correctly ordered. https://stackoverflow.com/a/31102605
              itemsMap = Object.keys(itemsMap)
                .sort()
                .reduce((obj, key) => {
                  obj[key] = itemsMap[key];
                  return obj;
                }, {});
            }

            itemsMap = Object.assign(itemsMap, {
              'Middle School': 'middle_school',
              Transfer: 'transfer_student',
              Parents: 'parent',
              Professionals: 'professional'
            });

            if (this.registeredTotal > 0 || this.attendedTotal > 0 || this.connectionsTotal > 0) {
              this.items = Object.entries(itemsMap).map(([key, value], idx) => {
                let info = {
                  label: key,
                  registered: registered[value] ? registered[value] : 0,
                  attended: attended[value] ? attended[value] : 0
                };
                if (connections) {
                  info['connections'] = connections[value] ? connections[value] : 0;
                }
                return info;
              });
            }
          },
          () => {
            this.fetching = false;
          }
        );
    },
    scrollToInfo() {
      document
        .getElementById('scrollable-modal-body')
        .scrollTo({ top: findPosition(document.getElementById('school-modal-info')), behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
.custom-subtitle {
  font-weight: 500;
  line-height: 20px;
  color: rgba(17, 24, 39, 0.7);
  margin-bottom: 0;
}
.modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mdc-data-table {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  width: 100%;
}

.mdc-data-table .mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 16px;
}

.mdc-data-table .mdc-data-table__header-cell.mdc-data-table__header-cell--numeric {
  color: #181b26;
  font-weight: 600;
}
.mdc-data-table__cell--registered {
  background: rgba(102, 44, 143, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mdc-data-table__cell--scanned {
  background: rgba(102, 44, 143, 0.05);
}

#school-modal-info {
  padding: 16px;
  background: #f3f4f6;
  border-radius: 8px;
  margin-top: 24px;
}

.custom-link {
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 16px;
}

.custom-link .material-icons {
  font-size: 16px;
}
.modal-body {
  overflow-y: scroll;
  height: 80vh;
}
</style>
