const CEEB_UNLISTED = '000003';
import SchoolSearchWithTypeAhead from '../../SchoolSearchWithTypeAhead.vue';

export default {
  components: {
    SchoolSearchWithTypeAhead
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    invitation: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: new SparkForm({
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        password: '',
        password_confirmation: '',
        professional_type: '',
        professional_type_text: '',
        high_school_ceeb: 0,
        high_school_id: '',
        high_school: '',
        high_school_city: '',
        high_school_region: '',
        organization: '',
        terms: false,
        invitation: ''
      }),
      professionalTypes: [
        'Secondary/High School - Public',
        'Secondary/High School - Private',
        'College/University - 4 year',
        'College/University - 2 year',
        'Independent Educational Consultant',
        'Organization',
        'Business',
        'Retired',
        'Student',
        'N/A',
        'Other'
      ],
      schoolItems: [],
      selected: {
        school: '',
        school_ceeb_code: '',
        school_name: '',
        school_municipality: '',
        school_region: ''
      },
      selectedSchool: null,
      showHighschoolFields: false,
      passwordVisible: false,
      passwordConfirmationVisible: false,
      loading: false,
      alertSuccess: false
    };
  },
  mounted() {
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.form.title = this.user.title;
    this.form.email = this.user.email;
    if (this.user.professional_type) {
      if (this.professionalTypes.includes(this.user.professional_type)) {
        this.form.professional_type = this.user.professional_type;
      } else {
        this.form.professional_type = 'Other';
        this.form.professional_type_text = this.user.professional_type;
      }
    }
  },
  methods: {
    register() {
      this.loading = true;
      this.form.invitation = this.invitation;
      Spark.post('/register/dashboard', this.form)
        .then(
          (response) => {
            this.alertSuccess = true;
            setTimeout(function() {
              window.location = response.redirect;
            }, 1000);
          },
          (error) => {
            if (error.errors.status == 400) {
              window.location.reload();
            }
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    querySchoolApi(query) {
      axios.get(`/api/schools?query=${encodeURIComponent(query)}`).then(
        ({ data }) => {
          this.schoolItems = data;
        },
        () => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school = school.school;
        this.form.high_school_id = school.id;
        this.form.high_school_city = school.city;
        this.form.high_school_region = school.state;
        this.form.high_school_country_code = '';

        if (school.CEEB === CEEB_UNLISTED) {
          this.showHighschoolFields = true;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';
      this.querySchoolApi('');
    }
  },
  computed: {
    isProfessionSchoolType() {
      const schoolTypes = ['Secondary/High School - Public', 'Secondary/High School - Private', 'College/University - 2 year'];
      return schoolTypes.indexOf(this.form.professional_type) !== -1;
    }
  }
};
