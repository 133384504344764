<template>
  <div>
    <div v-if="items.length > 0" class="mdc-data-table">
      <div class="mdc-data-table__table-container">
        <table class="mdc-data-table__table" aria-label="Registered">
          <thead>
            <tr class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
                Class
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">
                Percent
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">
                Number
              </th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content">
            <tr v-for="(item, idx) in items" :key="item.label" class="mdc-data-table__row">
              <th class="mdc-data-table__cell" scope="row">
                <span class="color-rect" :style="'background:' + colors[idx]" />
                {{ item.label }}
              </th>
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                {{ item.percentage }}
              </td>
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                {{ item.value }}
              </td>
            </tr>
            <tr class="mdc-data-table__row">
              <th class="mdc-data-table__cell" scope="row">
                <b>Total</b>
              </th>
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                &nbsp;
              </td>
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                {{ total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <list-empty-state v-else :title="emptyTitle">
      <template slot="icon">
        <span class="material-icons">alarm</span>
      </template>
      <span>{{ emptySubtitle }}</span>
    </list-empty-state>
  </div>
</template>

<script>
import ListEmptyState from '../../ListEmptyState.vue';
export default {
  name: 'DashboardPieChartTable',
  components: { ListEmptyState },
  props: ['items', 'total', 'colors', 'emptyTitle', 'emptySubtitle']
};
</script>

<style scoped>
.mdc-data-table {
  width: 100%;
}
.color-rect {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}
</style>
