import STATES from '../../constants/states';
import debounce from 'lodash/debounce';

Vue.component('register-institution', {
  props: {
    currentTeam: {
      type: Object,
      default: null
    },
    currentUser: {
      type: Object,
      default: null
    },
    regions: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      form: new SparkForm({
        name: '',
        contact_address: '',
        contact_address_line_2: '',
        contact_city: '',
        contact_state: '',
        contact_zip: '',
        contact_country_code: null,
        email: '',
        phone: '',
        phone_country_code: 'US',
        website: '',
        blog: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        snapchat: '',
        college_scorecard_dataset_id: null,
        organization_type: '',
      }),
      iti: null,
      countries: [],
      usStates: STATES,
      twilioFailure: '',
      query: '',
      datasets: [],
      showInstitutionForm: false,
      redirect: null,
      college_scorecard_dataset: this.currentTeam.college_scorecard_dataset,
      searching: false,
      debouncedSearch: debounce(function() {
        this.search();
      }, 300),
    };
  },
  computed: {
    isEnabled() {
      const team = this.currentUser.teams[0];
      return team && team.pivot.role === 'owner';
    },
    isUSCountrySelected() {
      return this.form.contact_country_code && this.form.contact_country_code === 'US';
    },
    isNonUSCountrySelected() {
      return this.form.contact_country_code && this.form.contact_country_code !== 'US';
    }
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.form.name = this.currentTeam.name;
    this.form.contact_address = this.currentTeam.contact_address;
    this.form.contact_address_line_2 = this.currentTeam.contact_address_line_2;
    this.form.contact_city = this.currentTeam.contact_city;
    this.form.contact_state = this.currentTeam.contact_state;
    this.form.contact_zip = this.currentTeam.contact_zip;
    this.form.contact_country_code =
      this.currentTeam.contact_country_code === null || typeof this.currentTeam.contact_country_code === 'undefined'
        ? 'US'
        : this.currentTeam.contact_country_code;
    this.form.email = this.currentTeam.email;
    this.form.phone = this.currentTeam.phone;
    this.form.website = this.currentTeam.website;
    this.form.blog = this.currentTeam.blog;
    this.form.facebook = this.currentTeam.facebook;
    this.form.twitter = this.currentTeam.twitter;
    this.form.youtube = this.currentTeam.youtube;
    this.form.instagram = this.currentTeam.instagram;
    this.form.snapchat = this.currentTeam.snapchat;
    this.form.college_scorecard_dataset_id = this.currentTeam.college_scorecard_dataset_id;
    this.form.organization_type = this.currentTeam.organization_type ?? '';
    this.countries = countries;

    if (this.currentTeam) {
      this.form.phone_country_code = this.currentTeam.phone_country_code ? this.currentTeam.phone_country_code : 'US';
    }
  },

  methods: {
    back() {
      this.showInstitutionForm = false;
      document.getElementById('progress').style.width = '35%';
    },
    update() {
      this.showInstitutionForm = true;
      document.getElementById('progress').style.width = '50%';

      this.$nextTick(() => {
        var phoneInput = document.querySelector("input[name='phone']");
        if (phoneInput != null) {
            this.iti = window.intlTelInput(phoneInput, {
                nationalMode: true,
                utilsScript: "/intlTelInput/utils.js"
            });

            this.iti.setCountry(this.form.phone_country_code);
            if (this.currentTeam.phone) {
              this.iti.setNumber(this.currentTeam.phone);
            }
        }
      });
    },
    save() {
      if (this.form.phone) {
        let countryData = this.iti.getSelectedCountryData();
        this.form.phone_country_code = countryData ? countryData.iso2.toUpperCase() : 'US';
      }

      Spark.post('/register/step/2', this.form)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          if (error.hasOwnProperty('twilio')) {
            this.form.errors.errors = Object.assign({}, this.form.errors.errors, { twilio: [error['twilio'][0].detail] });
          }
        });
    },
    search() {
      this.searching = true;
      axios.get(`/college-scorecard-datasets/search?query=${this.query}`)
        .then((response) => {
          this.searching = false;
          this.datasets = response.data;
        })
        .catch((error) => {
          this.searching = false;
          console.error(error);
        });
    },
    selectCollege(college) {
      this.form.college_scorecard_dataset_id = college.id;
      this.form.name = college.name;
      this.form.contact_address = college.address;
      this.form.contact_city = college.city;
      this.form.contact_state = college.state;
      this.form.contact_zip = college.zip;
      this.form.contact_country_code = college.country;
      this.form.website = college.website;
      this.college_scorecard_dataset = college;
      this.query = '';
      this.datasets = [];
    },
    change() {
      this.form.college_scorecard_dataset_id = null;
      this.showInstitutionForm = false;
    },
  },

  watch: {
    query() {
      if (this.query.length > 2) {
        this.debouncedSearch();
      } else {
        this.datasets = [];
      }
    },
    "form.organization_type": function (val) {
      if (val !== 'College or University in the United States') {
        this.form.name = '';
        this.form.contact_address = '';
        this.form.contact_city = '';
        this.form.contact_state = '';
        this.form.contact_zip = '';
        this.form.contact_country_code = '';
        this.form.website = '';
        this.form.college_scorecard_dataset_id = null;
      }
    }
  }
});
