/*
 * Load Vue & Vue-Resource.
 *
 * Vue is the JavaScript framework used by Spark.
 */
if (window.Vue === undefined) {
  window.Vue = require('vue');

  window.Bus = new Vue();
}

/**
 * Load Vue Global Mixin.
 */
Vue.mixin(require('./mixin'));

Vue.use(require('v-mask').VueMaskPlugin);

/**
 * Define the Vue filters.
 */
require('./filters');

/**
 * Load the Spark form utilities.
 */
require('./forms/bootstrap');
