import Template from './selectTeamOwnersModal.html';
import { TeamRequestApi } from '../../api/team-request-api';

const API_ERROR_TITLE = 'Something Went Wrong';
const API_ERROR_MESSAGE = 'Please try again later.';

Vue.component('select-team-owners-modal', {
  props: {
    teamName: {
      type: String,
      default: null
    },
    teamIndex: {
      type: Number,
      default: 0
    },
    teamId: {
      type: Number,
      default: null
    },
    teamContactAddress: {
      type: String,
      default: null
    },
    teamContactAddressLine2: {
      type: String,
      default: null
    },
    teamContactCity: {
      type: String,
      default: null
    },
    teamContactState: {
      type: String,
      default: null
    },
    teamContactZip: {
      type: String,
      default: null
    },
    teamEmail: {
      type: String,
      default: null
    },
    teamPhone: {
      type: String,
      default: null
    },
    teamPhoneCountryCode: {
      type: String,
      default: null
    },
    teamCountryCode: {
      type: String,
      default: null
    },
    teamWebsite: {
      type: String,
      default: null
    },
    teamOrganizationType: {
      type: String,
      default: null
    },
    teamCollegeScorecardDatasetId: {
      type: Number,
      default: null
    }
  },
  data: () => {
    return {
      form: new SparkForm({
        name: '',
        contact_address: '',
        contact_address_line_2: '',
        contact_city: '',
        contact_state: '',
        contact_zip: '',
        contact_country_code: null,
        email: '',
        phone: '',
        phone_country_code: '',
        website: '',
        blog: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        snapchat: ''
      }),
      didSucceed: false,
      loading: false,
      redirectLocation: null
    };
  },
  methods: {
    submit() {
      let users = $('#select-admins-form-' + this.teamIndex)
        .serializeArray()
        .map((admin) => Number(admin.value));

      this.loading = true;
      const app = this;
      TeamRequestApi.teams_requests(this.teamId)
        .create({ users: users })
        .then(() => {
          app.createTeam();
        })
        .catch((error) => {
          app.loading = false;
          swal(API_ERROR_TITLE, API_ERROR_MESSAGE, 'error');
        });
    },
    clearSelection() {
      $('#select-admins-form-' + this.teamIndex + ' input:checkbox').prop('checked', false);
    },
    createTeam() {
      const app = this;
      this.form.name = this.teamName;
      this.form.contact_address = this.teamContactAddress;
      this.form.contact_address_line_2 = this.teamContactAddressLine2;
      this.form.contact_city = this.teamContactCity;
      this.form.contact_state = this.teamContactState;
      this.form.contact_zip = this.teamContactZip;
      this.form.contact_country_code = this.teamCountryCode;
      this.form.email = this.teamEmail;
      this.form.phone = this.teamPhone;
      this.form.phone_country_code = this.teamPhoneCountryCode;
      this.form.website = this.teamWebsite;
      this.form.organization_type = this.teamOrganizationType;
      this.form.college_scorecard_dataset_id = this.teamCollegeScorecardDatasetId;

      Spark.post('/register/step/2', this.form)
        .then((response) => {
          app.didSucceed = true;
          app.loading = false;
          app.redirectLocation = response.redirect;
        })
        .catch((error) => {
          app.loading = false;
          swal(API_ERROR_TITLE, API_ERROR_MESSAGE, 'error');
        });
    },
    onContinueClicked() {
      if (this.redirectLocation) {
        window.location = this.redirectLocation;
      } else {
        // something's screwy, reload
        window.location.reload();
      }
    }
  },
  template: Template
});
