import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import STATES from '../constants/states';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-visit', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    return {
      schoolQuery: '',
      loading: false,
      size: '10px',
      color: '#642c8f',
      radius: '50%',
      show_address: true,
      google: require('google-maps-api')(google_api_key, ['places']),
      fields_to_validate: {
        last_name: ['required'],
        first_name: ['required'],
        phone: ['required', 'custom'],
        email: ['required', 'custom'],
        high_school: ['required'],
        address_line_1: ['required'],
        address_city: ['required'],
        address_region: ['required'],
        address_postal_code: ['required'],
        birthdate: ['required', 'custom'],
        grad_type: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        college_start_semester: ['required'],
        accept_tos: ['required', 'custom']
      },
      races: [],
      hispanic_latino_spanish_origin: null,
      previously_selected_hispanic_latino_spanish_origin: null,
      ethnicities: [],
      ok_to_query_state: false,
      state_to_query: '',
      form: new SparkForm({
        fair: 900,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_region: '',
        address_postal_code: '',
        races: [],
        ethnicities: [],
        has_hispanic_latino_or_spanish_origin: null,
        text_permission: true,
        birthdate: '',
        grad_type: '',
        graduation_year: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        high_school_region: '',
        college_start_semester: null,
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        email_confirmation: true,
        text_confirmation: true,
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        accept_tos: '',
        high_school_ceeb: 0
      }),
      previously_selected_form_field: '',
      currently_selected_form_field: '',
      birthdate: null,
      months: [
        { value: 0, text: 'January' },
        { value: 1, text: 'February' },
        { value: 2, text: 'March' },
        { value: 3, text: 'April' },
        { value: 4, text: 'May' },
        { value: 5, text: 'June' },
        { value: 6, text: 'July' },
        { value: 7, text: 'August' },
        { value: 8, text: 'September' },
        { value: 9, text: 'October' },
        { value: 10, text: 'November' },
        { value: 11, text: 'December' }
      ],
      semesters: [],
      grad_years: [],
      gradeTypes: [],
      interests: [
        {
          main: 'Undecided',
          children: ['Undecided']
        },
        {
          main: 'Agriculture, Agriculture Operations, and Related Sciences',
          children: ['Agricultural Business and Management', 'Animal Sciences']
        },
        {
          main: 'Architecture and Related Services',
          children: ['Architecture', 'City/Urban, Community, and Regional Planning', 'Landscape Architecture']
        },
        {
          main: 'Area, Ethnic, Cultural, and Gender Studies',
          children: ['Area Studies', 'Ethnic, Cultural Minority, and Gender Studies']
        },
        {
          main: 'Biological and Biomedical Sciences',
          children: [
            'Biology/Biological Sciences, General',
            'Biochemistry',
            'Biophysics',
            'Biotechnology',
            'Cell/Cellular Biology and Anatomical Sciences',
            'Ecology',
            'Genetics',
            'Marine Biology and Biological Oceanography',
            'Microbiological Sciences and Immunology',
            'Molecular Biology',
            'Zoology/Animal Biology'
          ]
        },
        {
          main: 'Business Management, Marketing, and Related Support Services',
          children: [
            'Accounting and Related Services',
            'Actuarial Science',
            'Business Administration, Management and Operations',
            'Finance and Financial Management Services',
            'Hospitality Administration/Management',
            'Human Resources Management and Services',
            'International Business',
            'Management Information Systems and Services',
            'Marketing/Marketing Management, General'
          ]
        },
        {
          main: 'Communication, Journalism, and Related Programs',
          children: [
            'Advertising',
            'Communication and Media Studies',
            'Digital Communication and Media/Multimedia',
            'Journalism',
            'Public Relations/Image Management',
            'Radio and Television'
          ]
        },
        {
          main: 'Computer and Information Sciences and Support Services',
          children: ['Computer Science', 'Information Science/Studies']
        },
        {
          main: 'Education',
          children: [
            'Early Childhood Education and Teaching',
            'Elementary Education and Teaching',
            'Secondary Education and Teaching',
            'Special Education and Teaching'
          ]
        },
        {
          main: 'Engineering',
          children: [
            'Aerospace, Aeronautical and Astronautical Engineering',
            'Agricultural/Biological Engineering and Bioengineering',
            'Architectural Engineering',
            'Biomedical/Medical Engineering',
            'Chemical Engineering',
            'Civil Engineering',
            'Computer Engineering, General',
            'Electrical, Electronics and Communications Engineering',
            'Engineering Physics',
            'Engineering Science',
            'Environmental/Environmental Health Engineering',
            'Geological/Geophysical Engineering',
            'Industrial Engineering',
            'Materials Engineering',
            'Mechanical Engineering',
            'Mining and Mineral Engineering',
            'Nuclear Engineering',
            'Petroleum Engineering',
            'Materials Science',
            'Polymer/Plastics Engineering'
          ]
        },
        {
          main: 'Engineering Technologies/Technicians',
          children: [
            'Computer Engineering Technology/Technician',
            'Drafting/Design Engineering Technologies/Technician',
            'Telecommunications Technology/Technician'
          ]
        },
        {
          main: 'English Language and Literature/Letters',
          children: ['Creative Writing']
        },
        {
          main: 'Family and Consumer Sciences/Human Sciences',
          children: ['Foods, Nutrition and Wellness Studies, General']
        },
        {
          main: 'Foreign Languages, Literatures, and Linguistics',
          children: [
            'Classics and Classical Languages, Literatures, and Linguistics',
            'Comparative Literature',
            'East Asian Languages, Literatures, and Linguistics',
            'French Language and Literature',
            'German Language and Literature',
            'Linguistics',
            'Russian Language and Literature',
            'Spanish Language and Literature'
          ]
        },
        {
          main: 'Health Professions and Related Clinical Services',
          children: [
            'Allied Health Diagnostic, Intervention, and Treatment Professions',
            'Athletic Training/Trainer',
            'Clinical/Medical Laboratory Science and Allied Professions',
            'Communication Disorders Sciences and Services',
            'Dental Hygiene/Hygienist',
            'Dietetics and Clinical Nutrition Services',
            'Nursing',
            'Occupational Therapy/Therapist',
            'Physical Therapy/Therapist',
            'Pre-Dentistry Studies',
            'Pre-Medicine/Pre-Medical Studies',
            'Pre-Pharmacy Studies',
            'Pre-Veterinary Studies'
          ]
        },
        {
          main: 'History',
          children: ['History']
        },
        {
          main: 'Legal Professions and Studies',
          children: ['Pre-Law Studies']
        },
        {
          main: 'Liberal Arts and Sciences, General Studies, and Humanities',
          children: ['Humanities/Humanistic Studies', 'Liberal Arts and Sciences/Liberal Studies']
        },
        {
          main: 'Mathematics and Statistics',
          children: ['Applied Mathematics', 'Mathematics', 'Statistics']
        },
        {
          main: 'Multi/Interdisciplinary Studies',
          children: ['Biological and Physical Sciences', 'International/Global Studies', 'Mathematics and Computer Science', 'Neuroscience']
        },
        {
          main: 'Natural Resources and Conservation',
          children: [
            'Environmental Science',
            'Environmental Studies',
            'Fishing and Fisheries Sciences and Management',
            'Forestry',
            'Wildlife and Wildlands Science and Management'
          ]
        },
        {
          main: 'Parks, Recreation, Leisure, and Fitness Studies',
          children: ['Parks, Recreation, and Leisure Facilities Management', 'Sport and Fitness Administration/Management']
        },
        {
          main: 'Personal and Culinary Services',
          children: ['Personal and Culinary Services']
        },
        {
          main: 'Philosophy and Religious Studies',
          children: ['Philosophy', 'Religion/Religious Studies']
        },
        {
          main: 'Physical Sciences',
          children: [
            'Astronomy',
            'Astrophysics',
            'Atmospheric Sciences and Meteorology',
            'Chemistry',
            'Geological and Earth Sciences/Geosciences',
            'Physics'
          ]
        },
        {
          main: 'Psychology',
          children: ['Psychology']
        },
        {
          main: 'Public Administration and Social Service Professions',
          children: ['Human Services, General', 'Public Administration', 'Public Policy Analysis', 'Social Work']
        },
        {
          main: 'Security and Protective Services',
          children: ['Criminal Justice/Law Enforcement Administration', 'Forensic Science and Technology']
        },
        {
          main: 'Social Sciences',
          children: [
            'Anthropology',
            'Archaeology',
            'Economics',
            'Geography',
            'International Relations and Affairs',
            'Political Science and Government',
            'Sociology'
          ]
        },
        {
          main: 'Theology and Religious Vocations',
          children: ['Theology and Religious Vocations']
        },
        {
          main: 'Visual and Performing Arts',
          children: [
            'Art History, Criticism, and Conservation',
            'Dance',
            'Drama and Dramatics, Theatre Arts, General',
            'Fashion/Apparel Design',
            'Film/Video and Photographic Arts',
            'Fine and Studio Art',
            'Graphic Design',
            'Interior Design',
            'Music',
            'Technical Theatre/Theatre Design and Technology'
          ]
        },
        {
          main: 'Other',
          children: ['Other']
        }
      ],
      states: STATES,
      student: student,
      filter_query: null,
      suggested_addresses: [],
      autocompleted: false,
      google_autocomplete: null,
      schoolItems: [],
      interestItems: [],
      selectedSchool: null,
      interestQuery: '',
      selectedInterests: [],
      maxInterests: 3,
      showHighschoolFields: false,
      isSubmitDisabled: false,
      hasQRSendError: false,
      twilioFailure: null,
      graduation_object: null,
      isHomeSchooled: false,
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropdown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      }
    };
  },
  watch: {
    autocompleted: function() {
      this.show_address = true;
      if (this.autocompleted) {
        this.validate('address_line_1', this.form.address_line_1);
        this.validate('address_line_2', this.form.address_line_2);
        this.validate('address_city', this.form.address_city);
        this.validate('address_region', this.form.address_region);
        this.validate('address_postal_code', this.form.address_postal_code);
      }
    },
    hispanic_latino_spanish_origin: function() {
      if (this.hispanic_latino_spanish_origin === 'true') {
        this.form.has_hispanic_latino_or_spanish_origin = true;

        if (this.form.ethnicities.length < 1) {
          if (this.form.errors.errors['ethnicities']) {
            this.form.errors.errors['ethnicities'] = [
              'The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.'
            ];
          } else {
            this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
              ['ethnicities']: ['The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.']
            });
          }
        }
      } else {
        this.form.has_hispanic_latino_or_spanish_origin = this.hispanic_latino_spanish_origin === 'false' ? false : null;
        this.ethnicities = [];
        // clear out form errors
        Vue.delete(this.form.errors.errors, 'ethnicities');
      }
    },
    ethnicities: function() {
      let results = [];
      for (let i = 0; i < this.ethnicities.length; i++) {
        results.push({ ethnicity: this.ethnicities[i] });
      }
      this.form.ethnicities = results;

      if (this.form.ethnicities.length > 0) {
        Vue.delete(this.form.errors.errors, 'ethnicities');
      } else if (this.form.ethnicities.length <= 0 && this.form.has_hispanic_latino_or_spanish_origin) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          ['ethnicities']: ['The ethnicities field is required if you are of Hispanic, Latino, or Spanish origin.']
        });
      }
    },
    races: function() {
      let results = [];
      for (let i = 0; i < this.races.length; i++) {
        results.push({ race: this.races[i] });
      }
      this.form.races = results;
    },
    'form.accept_tos': function() {
      let error_message = 'You must agree before continuing.';
      if (!this.form.accept_tos) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { accept_tos: [error_message] });
      } else {
        this.remove_form_field_errors('accept_tos', error_message);
      }
    },
    'form.graduation_year': function() {
      if (this.form && this.form.graduation_year) {
        let currentDate = new Date(),
          currentYear = currentDate.getFullYear(),
          offsetYear = null;

        let borderDate = new Date(`${currentYear}-06-01`);

        if (borderDate < currentDate && this.form.graduation_year === currentYear) {
          offsetYear = currentYear + 1;
          this.form.college_start_semester = `Spring ${offsetYear}`;
        } else {
          this.form.college_start_semester = `Fall ${this.form.graduation_year}`;
        }
        this.remove_form_field_errors('college_start_semester', 'required');
      }
    },
    graduation_object: function() {
      this.form.graduation_year = this.graduation_object.year;
      this.form.grad_type = this.graduation_object.type;

      // clear form errors for graduation_year and grad_type
      this.remove_form_field_errors('grad_type', 'required');
    },
    'form.address_city': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_city = this.form.address_city;
      }
    },
    'form.address_region': function() {
      if (this.form.high_school_ceeb === CEEB_HOMESCHOOLED) {
        this.form.high_school_region = this.form.address_region;
      }
    },
    'form.high_school': function() {
      if (this.form.high_school_ceeb) {
        this.remove_form_field_errors('high_school', 'required');
      }
    }
  },

  created() {},

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.isSubmitDisabled = false;
    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    var rawSemesters = [];
    var now = new Date();

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        rawSemesters.push('Spring ' + offsetYear);
        rawSemesters.push('Fall ' + offsetYear);
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        rawSemesters.push('Spring ' + offsetYear);
        rawSemesters.push('Fall ' + offsetYear);
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (var i = now.getFullYear() - 3; i <= now.getFullYear() + 5; i++) {
      this.grad_years.push(i);
    }

    let gradeYears = [
      {
        year: this.getGraduationYear(0),
        type: 'year',
        label: `Senior - Class of ${this.getGraduationYear(0)}`
      },
      {
        year: this.getGraduationYear(1),
        type: 'year',
        label: `Junior - Class of ${this.getGraduationYear(1)}`
      },
      {
        year: this.getGraduationYear(2),
        type: 'year',
        label: `Sophomore - Class of ${this.getGraduationYear(2)}`
      },
      {
        year: this.getGraduationYear(3),
        type: 'year',
        label: `Freshman - Class of ${this.getGraduationYear(3)}`
      },
      {
        year: 0,
        type: 'middle_school',
        label: `Middle School`
      },
      {
        year: 0,
        type: 'transfer_student',
        label: `Transfer Student`
      }
    ];

    this.gradeTypes = gradeYears;

    this.resetInterests();

    if (this.student) {
      this.setStudentForm(this.student);
    }

    var app = this;
    app.google().then(function(maps) {
      app.google_autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['geocode'],
        fields: ['address_components']
      });
      app.google_autocomplete.setFields(['address_components', 'name']);
      app.google_autocomplete.addListener('place_changed', app.fillInAddress);
    });
  },
  methods: {
    validate_phone(value) {
      let regex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
      let error_message = 'The phone must be 10 characters.';
      if (value && !regex.test(value)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { phone: [error_message] });
        return false;
      }
      this.remove_form_field_errors('phone', error_message);
      return true;
    },
    validate_sat_score(value) {
      let error_message = 'The SAT score must be between 200 and 1600';
      if (value && (value < 200 || value > 1600)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { sat_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('sat_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_act_score(value) {
      let error_message = 'The ACT score must be between 1 and 36.';
      if (value && (value < 1 || value > 36)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { act_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('act_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = 'The email must be a valid email address.';
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email', error_message);
      return true;
    },
    validate_birthdate(value) {
      let regex = /(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}/;
      let error_message = 'Birthdate must be in the format `MM/DD/YYYY`';
      if (value && !regex.test(value)) {
        if (this.form.errors.errors['birthdate']) {
          this.form.errors.errors['birthdate'].push(error_message);
        } else {
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, { birthdate: [error_message] });
        }
        return false;
      } else if (value) {
        this.form.birthdate = new Date(value);
      }
      this.remove_form_field_errors('birthdate', error_message);
      return true;
    },
    validate_grad_type(value) {
      const errorMessage = 'The grade field is required.';

      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { grad_type: [errorMessage] });
        return false;
      }

      return true;
    },
    uncheck_ethnicity_radio_buttons(value) {
      this.hispanic_latino_spanish_origin = value;
      if (value == this.previously_selected_hispanic_latino_spanish_origin) {
        this.hispanic_latino_spanish_origin = null;
      }
      this.previously_selected_hispanic_latino_spanish_origin = this.hispanic_latino_spanish_origin;
    },
    update_show_address() {
      this.show_address = true;
    },
    setStudentForm(student) {
      // Show fields that usually only show after google auto completion.
      this.autocompleted = true;
      this.form.fair = 900;

      // populate personal information
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.phone = student.phone;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);

      this.form.address_line_1 = student.address.line_1;
      this.form.address_line_2 = student.address.line_2;
      this.form.address_city = student.address.city;
      this.form.address_region = student.address.region;
      this.form.address_postal_code = student.address.postal_code;

      // populate student information
      this.form.high_school = student.high_school;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_id = student.school_id;
      this.form.high_school_ceeb = student.CEEB;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      // populate Academics
      if (student.gpa > 0) {
        this.form.gpa = parseFloat(student.gpa).toFixed(2);
      }
      if (student.gpa_max > 0) {
        this.form.gpa_max = parseFloat(student.gpa_max).toFixed(2);
      }
      if (student.sat_score > 0) {
        this.form.sat_score = student.sat_score;
      }
      if (student.act_score > 0) {
        this.form.act_score = student.act_score;
      }

      this.form.accept_tos = true;

      // text permission
      this.form.text_permission = student.text_permission;

      // populate ethnicities
      if (student.has_hispanic_latino_or_spanish_origin) {
        this.hispanic_latino_spanish_origin = 'true';
        this.previously_selected_hispanic_latino_spanish_origin = 'true';
        for (let i = 0; i < student.ethnicities.length; i++) {
          this.ethnicities.push(student.ethnicities[i].ethnicity);
        }
      } else if (student.has_hispanic_latino_or_spanish_origin == 0) {
        this.previously_selected_hispanic_latino_spanish_origin = 'false';
        this.hispanic_latino_spanish_origin = 'false';
      }

      // populate races
      for (let k = 0; k < student.races.length; k++) {
        this.races.push(student.races[k].race);
      }

      // pre-populate Academic interests
      let interests_array = [];
      if (student.area_of_interest_1) {
        interests_array.push({ value: student.area_of_interest_1 });
      }

      if (student.area_of_interest_2) {
        interests_array.push({ value: student.area_of_interest_2 });
      }

      if (student.area_of_interest_3) {
        interests_array.push({ value: student.area_of_interest_3 });
      }
      this.selectedInterests = interests_array;

      if (interests_array.length >= 3) {
        let input = $('#interests-search input');
        input.hide();
      }

      this.resetInterestPositions();
      this.resetInterests();

      if (student.phone && student.phone.indexOf('+1') > -1) {
        this.form.phone = student.phone.replace('+1', '');
      }

      // Set Grade and expected college start date.
      for (let i = 0; i < this.gradeTypes.length; i++) {
        if (student.graduation_year == this.gradeTypes[i].year) {
          this.graduation_object = this.gradeTypes[i];
        } else if (this.gradeTypes[i].year === 0 && student.grad_type === this.gradeTypes[i].type) {
          this.graduation_object = this.gradeTypes[i];
        }
      }
      this.form.college_start_semester = student.college_start_semester;

      // text me my barcode
      this.form.text_confirmation = student.notify_by_text;
    },
    fillInAddress() {
      var place = this.google_autocomplete.getPlace();

      let fields = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'long_name',
        postal_code_suffix: 'long_name'
      };

      for (let i = 0; i < place.address_components.length; i++) {
        let address_type = place.address_components[i].types[0];

        fields[address_type] = place.address_components[i][fields[address_type]];
      }

      let street_number = fields.street_number !== 'short_name' ? fields.street_number : '';
      let route_name = fields.route !== 'long_name' ? fields.route : '';
      let street_number_and_route_name = '';
      street_number_and_route_name =
        street_number && route_name ? street_number + ' ' + route_name : street_number ? street_number : route_name;
      document.getElementById('autocomplete').value = street_number_and_route_name;
      this.form.address_line_1 = street_number_and_route_name;

      /*
                Brooklyn and other parts of New York City do not include the city as part of the address.
                They use sublocality_level_1 instead.
                @see https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
             */
      let locality = fields.locality !== 'long_name' ? fields.locality : '';
      if (!locality) {
        locality = fields.sublocality_level_1 !== 'long_name' ? fields.sublocality_level_1 : '';
      }
      document.getElementById('locality').value = locality;
      this.form.address_city = locality;

      let administrative_area_level_1 = fields.administrative_area_level_1 !== 'short_name' ? fields.administrative_area_level_1 : '';
      document.getElementById('administrative_area_level_1').value = administrative_area_level_1;
      this.form.address_region = administrative_area_level_1;

      // Not every address has a postal code suffix
      let postal_code = fields.postal_code !== 'long_name' ? fields.postal_code : '';
      let postal_code_suffix = fields.postal_code_suffix !== 'long_name' ? fields.postal_code_suffix : '';
      let post_code_and_suffix =
        postal_code && postal_code_suffix ? postal_code + '-' + postal_code_suffix : postal_code ? postal_code : postal_code_suffix;
      document.getElementById('address_postal_code').value = post_code_and_suffix;
      this.form.address_postal_code = post_code_and_suffix;
      this.autocompleted = true;
    },
    getRegionFromGoogleAPIResponse(address_components) {
      for (let i = 0; i < address_components.length; i++) {
        let types = address_components[i].types;
        for (let k = 0; k < types.length; k++) {
          if (types[k] === 'administrative_area_level_1') {
            let inner_component = address_components[i].address_components;
            for (let q = 0; q < inner_component.length; q++) {
              if (this.isValidStateShort(inner_component[q].short_name)) {
                return inner_component[q].short_name;
              }
            }
          }
        }
      }
      return '';
    },
    isValidStateShort(state_short) {
      let is_valid = false;
      for (let i = 0; i < this.states.length; i++) {
        if (this.states[i].abbreviation === state_short || this.states[i].name === state_short) {
          is_valid = true;
        }
      }
      return is_valid;
    },
    signup() {
      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      const data = Object.assign({}, this.form, { phone: this.form.phone.replace(/[^0-9]+/g, '') });

      this.isSubmitDisabled = true;

      let url = '/students';
      let method = 'post';
      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      }
      Spark[method](url, data)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      this.schoolQuery = search;

      queryURL = '/api/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = '';
          this.form.high_school_region = '';

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.address_city;
          this.form.high_school_region = this.form.address_region;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.showHighschoolFields = false;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);

        this.$nextTick(() => {
          let input = $('#interests-search input');
          input.val('');
          input.blur();

          if (this.selectedInterests.length >= 3) {
            input.hide();
          }
        });

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    getGraduationYear(addYears = 0) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + addYears + 1;
      } else {
        offsetYear = currentYear + addYears;
      }

      return offsetYear;
    }
  }
});
