<template>
  <div>
    <loader v-if="fetching" />
    <canvas v-show="data.length > 0 && !fetching" id="ActivityGraph" width="400" height="250" />
    <bar-graph-empty-state
      v-if="data.length === 0 && !fetching"
      title="No one has been scanned yet."
      subtitle="Check back once scanning has started."
    >
      <span class="material-icons">alarm</span>
    </bar-graph-empty-state>
  </div>
</template>

<script>
import Loader from '../Loader.vue';
import BarGraphEmptyState from './BarGraphEmptyState.vue';
import dateDisplay from '../../../mixins/dateDisplay';
import { Api } from '../../../dashboard/dashboard-api';

export default {
  name: 'ActivityGraph',
  components: { BarGraphEmptyState, Loader },
  mixins: [dateDisplay],
  data() {
    let fairInfo = typeof window.getFairInfo !== 'undefined' ? window.getFairInfo() : null;
    if (fairInfo == null) {
      throw 'getFairInfo is undefined!';
    }
    const fair = fairInfo ? { ...fairInfo } : {};

    return {
      fair: fair,
      fetching: true,
      data: [],
      chart: null
    };
  },
  computed: {
    fairStartTime() {
      let startTime = moment(this.fair.starts_at)
        .tz(this.fair.starts_at_timezone)
        .subtract(1, 'hours');
      if (startTime.minutes() < 30) {
        startTime.set('minute', 30);
      } else {
        startTime.set('minute', 0);
        startTime.add(1, 'hours');
      }
      return startTime;
    },
    fairEndTime() {
      let endTime;
      if (this.fairHasValidEndDate(this.fair)) {
        endTime = moment(this.fair.ends_at)
          .tz(this.fair.starts_at_timezone)
          .add(1, 'hours');
      } else {
        endTime = moment(this.fair.starts_at)
          .tz(this.fair.starts_at_timezone)
          .add(4, 'hours');
      }
      if (endTime.minutes() < 30) {
        endTime.set('minute', 30);
      } else {
        endTime.set('minute', 0);
        endTime.add(1, 'hours');
      }
      return endTime;
    }
  },
  mounted() {
    this.fetchGraphData();
    Bus.$on('refreshDashboard', this.fetchGraphData);
  },
  methods: {
    options(maximumYValue) {
      // round the maximum y to the next 100;\
      const app = this;
      maximumYValue = Math.ceil(maximumYValue / 100) * 100;
      const options = {
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              isoWeekday: false,
              type: 'time',
              ticks: {
                callback: (label, index, values) => {
                  return moment(label)
                    .tz(app.fair.starts_at_timezone)
                    .format('h:mm a');
                },
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45,
                min: this.fairStartTime.valueOf(),
                max: this.fairEndTime.valueOf()
              },
              time: {
                stepSize: 30,
                displayFormats: {
                  minute: 'YYYY-MM-DDTHH:mm:ss'
                },
                tooltipFormat: 'YYYY-MM-DDTHH:mm:ss',
                unit: 'minute'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                max: maximumYValue
              },
              gridLines: {
                drawBorder: false,
                borderDash: [8, 4],
                color: '#E5E7EB'
              }
            }
          ]
        },
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 14,
            fontColor: '#181B26'
          },
          onClick: (e) => e.stopPropagation()
        },
        title: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem) {
              let result = tooltipItem[0].xLabel;
              const current = moment(result).tz(app.fair.starts_at_timezone);
              const next = moment(result)
                .tz(app.fair.starts_at_timezone)
                .add(10, 'minutes');
              result = current.format('h:mm - ') + next.format('h:mm');
              return result;
            }
          }
        }
      };
      return options;
    },
    fetchGraphData() {
      this.fetching = true;
      const app = this;
      Api.fairs(this.fair.id)
        .dashboard.scanned(10)
        .then(
          (response) => {
            app.fetching = false;
            app.data = response;
            const ctx = document.getElementById('ActivityGraph');
            let minutes10 = {};
            let maximumY = 0;
            const fairEndTime = app.fairEndTime;

            app.data.forEach((slice) => {
              const currentMoment = moment(slice.starts_at);
              if (currentMoment.isBefore(fairEndTime)) {
                const minute10 = moment(slice.starts_at).toDate();
                minutes10[minute10] = slice.count;
              }
            });

            minutes10 = Object.entries(minutes10).map(([key, value]) => {
              maximumY = Math.max(value, maximumY);
              return { x: new Date(key), y: value };
            });

            const options = app.options(maximumY);
            app.chart = new Chart(ctx, {
              type: 'line',
              data: {
                datasets: [
                  {
                    label: 'Incremental Scans (per 10 mins)',
                    data: minutes10,
                    backgroundColor: '#7B00C20F',
                    borderColor: '#7B00C2FF',
                    pointRadius: 0,
                    order: 2,
                    fill: 'origin',
                    lineTension: 0
                  }
                ]
              },
              options,
              plugins: [
                {
                  beforeDraw: function(canvas) {
                    var legends = canvas.legend.legendItems;
                    legends.forEach(function(legend) {
                      legend.fillStyle = legend.strokeStyle;
                    });
                  }
                }
              ]
            });
          },
          () => {
            app.fetching = false;
          }
        );
    }
  }
};
</script>
