import BaseStudentList from './studentList';
import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';

export default BaseStudentList.extend({
  components: {
    VueBootstrapTypeahead
  },

  data() {
    return {
      fairOccurrence: 'upcoming',
      allFairs: [],
      fairOptions: [],
      selectedFair: null,
      fairQuery: '',
      fetching: false,
      students: null,
      studentQuery: '',
      error: null
    };
  },

  created() {
    var self = this;
    Bus.$on('sparkHashChanged', function(hash, parameters) {
      if (hash == 'student-search' && self.allFairs.length === 0) {
        self.getFairs();
      }
    });
  },

  methods: {
    getFairs() {
      axios.get('/api/fairs/admin/index').then(
        ({ data }) => {
          this.allFairs = data.data; // data expected to be an array
          this.resetFairs();
        },
        (err) => {
          this.allFairs = [];
        }
      );
    },
    queryFairs(query) {
      let fairs = [];

      this.resetFairs();

      if (query !== '') {
        this.fairOptions.filter((fair) => {
          if (fair.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            fairs.push(fair);
          }
        });

        this.fairOptions = fairs;
      }
    },
    resetFairs() {
      let self = this;
      let fairs = [];

      this.allFairs.filter((fair) => {
        let matchesOccurrence = (self.fairOccurrence == 'upcoming' && fair.upcoming) || (self.fairOccurrence == 'past' && !fair.upcoming);

        if (matchesOccurrence) {
          fairs.push(fair);
        }
      });

      this.fairOptions = fairs;
    },
    selectFair(selectedObj) {
      this.selectedFair = selectedObj;
      this.resetFairs();
    },
    fetchStudents() {
      if (this.selectedFair === null || this.fetching) {
        return;
      }

      this.fetching = true;
      this.error = null;

      let url =
        '/api/fairs/' + this.selectedFair.id + '/students?limit=25&query=' + this.studentQuery + '&page=' + this.pagination.current_page;

      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.students = success.data.data;
          this.pagination = success.data.meta.pagination;
          Bus.$emit('searchCompleted');
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    }
  },

  watch: {
    fairQuery: _.debounce(function(q) {
      this.queryFairs(q);
    }, 500),
    fairOccurrence: _.debounce(function(q) {
      this.resetFairs();
    }, 500),
    selectedFair: _.debounce(function(q) {
      this.fetchStudents();
    }, 500)
  }
});
