<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 22 22" fill="none">
          <path
            d="M17.3007 4.71C16.9107 4.32 16.2807 4.32 15.8907 4.71L11.0007 9.59L6.1107 4.7C5.7207 4.31 5.0907 4.31 4.7007 4.7C4.3107 5.09 4.3107 5.72 4.7007 6.11L9.5907 11L4.7007 15.89C4.3107 16.28 4.3107 16.91 4.7007 17.3C5.0907 17.69 5.7207 17.69 6.1107 17.3L11.0007 12.41L15.8907 17.3C16.2807 17.69 16.9107 17.69 17.3007 17.3C17.6907 16.91 17.6907 16.28 17.3007 15.89L12.4107 11L17.3007 6.11C17.6807 5.73 17.6807 5.09 17.3007 4.71Z"
            fill="#181B26" />
        </svg>
      </span>
      <event-guide-participant-details :participant="participant" :student="student" />
    </div>
  </div>
</template>

<script>
import EventGuideParticipantDetails from './EventGuideParticipantDetails.vue';

export default {
  props: {
    participant: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  components: {
    EventGuideParticipantDetails,
  },
  methods: {
    closeModal() {
      this.$emit('close');
      document.body.style.overflow = ''; // Reset overflow when modal closes
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal opens
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.body.style.overflow = ''; // Reset overflow when component is destroyed
    document.removeEventListener('keydown', this.handleKeydown);
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 40px;
  border: 1px solid #888;
  position: relative;
  display: inline-block;
  border-radius: 24px;
  max-height: 90vh;
  width: 640px;
  height: 100%;
  max-width: 90vw;
}

.close {
  background-color: #33FF94;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  /* circular shape */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>