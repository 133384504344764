var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fetchingStudents),expression:"fetchingStudents"}],staticClass:"panel panel-default"},[_vm._m(0)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fetchingStudents && !_vm.hasStudentConnections),expression:"!fetchingStudents && !hasStudentConnections"}],staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_vm._v("\n      "+_vm._s(_vm.noStudentsMessage)+"\n    ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fetchingStudents && _vm.hasStudentConnections),expression:"!fetchingStudents && hasStudentConnections"}],staticClass:"panel panel-default"},[_c('table',{staticClass:"table table-hover"},[_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.studentConnections),function(connection){return _c('tr',{key:connection.id},[_c('td',[(connection.student_submission_form == 'parents' || connection.student_submission_form == 'ontario parents')?_c('span',[_vm._v("Parent")]):(
                connection.student_submission_form == 'professionals' || connection.student_submission_form == 'ontario counsellors'
              )?_c('span',[_vm._v("Professional")]):_c('span',[_vm._v("Student")])]),_vm._v(" "),_c('td',[_c('a',{attrs:{"href":'/connections/details/' + connection.id}},[_vm._v(_vm._s(connection.student.list_heading.title))])]),_vm._v(" "),_c('td',[(connection.student.list_heading.attributes && connection.student.list_heading.attributes.length > 0)?_c('span',[_vm._v(_vm._s(connection.student.list_heading.attributes[0].value))]):_vm._e()]),_vm._v(" "),_c('td',[(
                connection.student.list_heading.attributes &&
                  connection.student.list_heading.attributes.length > 1 &&
                  connection.student_submission_form == 'students'
              )?_c('span',[_vm._v("\n              "+_vm._s(connection.student.list_heading.attributes[1].value)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('td',[_vm._v("\n            "+_vm._s(_vm._f("moment")(connection.connection_created_at,'MM-DD-YYYY'))+"\n          ")])])}),0)])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fetchingStudents && _vm.hasStudentConnections),expression:"!fetchingStudents && hasStudentConnections"}],staticClass:"text-center"},[_c('pagination',{attrs:{"total-pages":_vm.pagination.page_count,"current-page":_vm.pagination.current_page}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-body text-center"},[_c('i',{staticClass:"fa fa-spinner fa-spin fa-3x color-primary"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Type")]),_vm._v(" "),_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Institution")]),_vm._v(" "),_c('th',[_vm._v("Year")]),_vm._v(" "),_c('th',[_vm._v("Date")])])])
}]

export { render, staticRenderFns }