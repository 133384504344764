export default Vue.extend({
  components: {},
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fetching: false,
      updating: false,
      editingSettings: false,
      editingCredentials: false,
      credential: null,
      file: null,
      form: this.initializedForm(),
      availableFields: ['host', 'port', 'username', 'password', 'ssh_key', 'passphrase', 'upload_directory', 'notification_email'],
      requiredFields: ['host', 'port', 'username', 'notification_email'],
      isSubmitDisabled: false
    };
  },
  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    }
  },
  mounted() {
    this.loadCredentials();
  },
  methods: {
    editSettings() {
      this.editingSettings = !this.editingSettings;

      if (this.editingSettings) {
        this.loadCredentials();
      }
    },
    editCredentials() {
      this.editingCredentials = !this.editingCredentials;

      if (this.editingCredentials) {
        this.setCredentialsFormData();
      }
    },
    validateFile(file) {
      const fsize = file.size;
      const sizeMb = Math.round(fsize / 1024);
      // The size of the file.
      if (sizeMb >= 1024) {
        return false;
      }
      return true;
    },
    onFileUploadChange(e) {
      if (e.target.files && e.target.files.length) {
        const file = e.target.files[0];
        if (this.validateFile(file)) {
          this.form.ssh_key = file;
        } else {
          this.form.ssh_key = null;
          this.$refs.fileRef.value = null;
          this.errorAlert('File size is too large', 'Please upload a SSH key that is under 1mb.');
        }
      } else {
        this.form.ssh_key = null;
      }
    },
    loadCredentials() {
      const app = this;
      this.fetching = true;
      axios.get('/api/sftp/credentials').then(
        (success) => {
          app.fetching = false;
          if (!success.data.data) {
            app.setCredential(null);
          } else {
            app.setCredential(success.data.data);
            app.setSettingsFormData(success.data.data);
          }
        },
        (error) => {
          app.fetching = false;
        }
      );
    },
    setCredential(data) {
      this.credential = data;
    },
    updateCredentials() {
      const app = this;
      var isValid = this.validateCredentials();
      if (isValid) {
        const formData = new FormData();

        // We need to clarify if we are saving credentials so that we only upsert when necessary
        formData.append('editingCredentials', this.editingCredentials || !this.credential);

        for (let field of this.availableFields) {
          formData.append(field, this.form[field]);
        }

        app.updating = true;
        axios
          .post('/api/sftp/credentials', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((success) => {
            app.updating = false;
            app.editingCredentials = false;
            app.editingSettings = false;
            app.setCredential(success.data.data);
            app.successAlert('SFTP Settings Saved');
          })
          .catch((error) => {
            app.updating = false;
            if (
              error &&
              error.response &&
              error.response.status === 422 &&
              error.response.data &&
              Object.keys(error.response.data).length
            ) {
              swal(
                {
                  title: 'Validation Failed',
                  text: 'The SFTP settings contain invalid data.',
                  type: 'error'
                },
                function() {
                  app.form.errors.errors = error.response.data;
                }
              );
            } else if (error && error.response && error.response.data) {
              app.errorAlert(error.response.data.errors.title, error.response.data.errors.detail);
            } else {
              app.errorAlert('Error', 'An unknown error occurred, please try again later.');
            }
          });
      }
    },
    setSettingsFormData(data) {
      this.form.host = data.host;
      this.form.port = data.port;
      this.form.username = data.username;
      this.form.upload_directory = data.upload_directory;
      this.form.notification_email = data.notification_email;
    },
    setCredentialsFormData() {
      this.form.passphrase = '';
      this.form.password = '';
      this.form.ssh_key = null;
    },
    initializedForm() {
      return new SparkForm({
        host: '',
        port: '22',
        username: '',
        password: '',
        ssh_key: null,
        passphrase: '',
        upload_directory: '',
        notification_email: ''
      });
    },
    validateEmail(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      if (value && !regex.test(value.toLowerCase())) {
        return false;
      }
      return true;
    },
    validateCredentials() {
      let isValid = true;
      this.form.errors.errors = {};
      for (let fieldName of this.requiredFields) {
        if (!this.form[fieldName]) {
          isValid = false;
          // errors are built a little different in this file: so instead we are just doing the ternary here to make the error name nicer!
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
            [fieldName]: [`The ${fieldName === 'notification_email' ? 'email' : fieldName} field is required.`]
          });
        }
      }

      if (!this.validateEmail(this.form.notification_email)) {
        isValid = false;
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
          notification_email: ['The email must be a valid email address.']
        });
      }

      if (this.editingCredentials || !this.credential) {
        if (!this.form.ssh_key && !this.form.passphrase && !this.form.password) {
          isValid = false;
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
            password: ['Must include a Password or an SSH Private Key.']
          });
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
            ssh_key: ['Must include a Password or an SSH Private Key.']
          });
        } else if (this.form.passphrase && !this.form.ssh_key) {
          isValid = false;
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
            ssh_key: ['The SSH Private Key field is required when the Passphrase is present.']
          });
        } else if (this.form.ssh_key && this.form.password) {
          isValid = false;
          this.form.errors.errors = Object.assign({}, this.form.errors.errors, {
            password: ['Must be empty if providing a SSH Private Key.']
          });
        }
      }

      return isValid;
    },
    clearCredentials() {
      const app = this;
      swal(
        {
          title: 'Clear SFTP Settings?',
          text: 'Are you sure you want to clear your active settings?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          closeOnConfirm: false,
          closeOnCancel: true,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (isConfirm) {
            app.updating = true;
            axios
              .delete(
                '/api/sftp/credentials',
                {},
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
              )
              .then((success) => {
                app.editing = false;
                app.updating = false;
                app.setCredential(null);
                app.successAlert('SFTP Settings Removed');
              })
              .catch((error) => {
                app.updating = false;
                if (error && error.response && error.response.data) {
                  app.errorAlert(error.response.data.errors[0].title, error.response.data.errors[0].detail);
                } else {
                  app.errorAlert('Error', 'An unknown error occurred, please try again later.');
                }
              })
              .finally(() => {
                app.resetForm();
              });
          }
        }
      );
    },
    testConnection() {
      const app = this;
      app.updating = true;
      axios
        .get('/api/sftp/credentials/test', {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((success) => {
          app.updating = false;
          app.successAlert('Test Successful');
        })
        .catch((error) => {
          app.updating = false;
          if (error && error.response && error.response.data && error.response.data.errors) {
            app.errorAlert('Test Failed', error.response.data.errors.detail);
          } else {
            app.errorAlert('Test Failed', 'Please verify your SFTP settings are entered correctly.');
          }
        });
    },
    sendTestFiles() {
      const app = this;
      app.updating = true;
      axios
        .get('/api/sftp/credentials/send-test-files', {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((success) => {
          app.updating = false;
          app.successAlert('Test Successful');
        })
        .catch((error) => {
          app.updating = false;
          if (error && error.response && error.response.data && error.response.data.errors) {
            app.errorAlert('Test Failed', error.response.data.errors.detail);
          } else {
            app.errorAlert('Test Failed', 'Please verify your SFTP settings are entered correctly.');
          }
        });
    },
    resetForm() {
      this.form = this.initializedForm();
      this.$refs.fileRef.value = null;
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    successAlert(title, message = '') {
      swal(title, message, 'success');
    }
  }
});
