import file from '../mixins/file';
import Loader from './dashboard/Loader.vue';

import EventGuideHeader from './event-guide/EventGuideHeader.vue';
import EventGuideFooter from './event-guide/EventGuideFooter.vue';
import EventGuideContent from './event-guide/EventGuideContent.vue';
import EventGuideParticipantsList from './event-guide/EventGuideParticipantsList.vue';
import EventGuideParticipantsSearch from './event-guide/EventGuideParticipantsSearch.vue';
import Snackbar from './Snackbar.vue';

Vue.component('event-guide', {
  components: {
    Loader,
    EventGuideHeader,
    EventGuideFooter,
    EventGuideContent,
    EventGuideParticipantsList,
    EventGuideParticipantsSearch,
    Snackbar,
  },
  mixins: [file],
  created() {
    var self = this;

    Bus.$on('notesSaved', function () {
      self.showSnackbar(self.$translate('My Notes saved.'));
    });
  },
  data() {
    return {
      alertSuccess: false,
      alertMessage: false,
      alertTimeout: null,
    };
  },
  methods: {
    showSnackbar(message, type = 'success') {
      this.alertSuccess = type;
      this.alertMessage = message;

      this.alertTimeout = setTimeout(() => {
        this.hideSnackbar();
      }, 3000);
    },
    hideSnackbar() {
      this.alertSuccess = false;
      this.alertMessage = false;
    },
  },
});
