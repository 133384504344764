import BaseFairList from './fairList';
import SearchedFairs from './searchedFairs';

export default BaseFairList.extend({
  components: { SearchedFairs },
  data() {
    return {
      query: '',
      lockedStatus: ''
    };
  },
  mounted() {},
  methods: {}
});
