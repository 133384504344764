import moment from 'moment';

export default {
  computed: {
    hasOccurred() {
      return (
        moment
          .tz(this.visit.starts_at, this.visit.starts_at_timezone)
          .startOf('day')
          .diff(
            moment()
              .tz(this.visit.starts_at_timezone)
              .startOf('day')
          ) < 0
      );
    },
    isOccurring() {
      return (
        moment.tz(this.visit.starts_at, this.visit.starts_at_timezone).isSame(moment().tz(this.visit.starts_at_timezone), 'day') &&
        moment.tz(this.visit.starts_at, this.visit.starts_at_timezone).isBefore(
          moment()
            .tz(this.visit.starts_at_timezone)
            .endOf('day')
        )
      );
    },
    timeToStartPrefix() {
      return this.hasOccurred ? 'Started' : 'Starts in';
    },
    timeToStart() {
      let differenceInHours = Math.abs(moment.utc().diff(moment(this.visit.starts_at), 'hours'));
      if (differenceInHours < 24) {
        let string = differenceInHours + ' hour';
        if (differenceInHours > 1) {
          string = string + 's';
        }

        return string;
      }

      let differenceInDays = Math.abs(moment.utc().diff(moment(this.visit.starts_at), 'days'));
      if (differenceInDays < 31) {
        let string = differenceInDays + ' day';
        if (differenceInDays > 1) {
          string = string + 's';
        }

        return string;
      }

      let differenceInMonths = Math.abs(moment.utc().diff(moment(this.visit.starts_at), 'months'));
      let string = differenceInMonths + ' month';
      if (differenceInMonths > 1) {
        string = string + 's';
      }

      return string;
    },
    timeToStartSuffix() {
      return this.hasOccurred ? 'ago' : '';
    }
  }
};
