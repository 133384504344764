export default {
  props: ['user'],
  data() {
    return {
      student: student,
      resending: false
    };
  },
  computed: {
    textPermission() {
      if (this.student && this.student.text_permission) {
        return 'Yes';
      }

      return 'No';
    },
    authorizeCis() {
      if (this.student && this.student.authorize_cis) {
        return 'Yes';
      }

      return 'No';
    },
    notifiedByText() {
      if (this.student && this.student.notify_by_text) {
        return 'Yes';
      }

      return 'No';
    },
    notifiedByEmail() {
      if (this.student && this.student.notify_by_email) {
        return 'Yes';
      }

      return 'No';
    }
  },
  methods: {
    resendBarcode() {
      if (this.resending) {
        return;
      }

      this.resending = true;

      axios.post('/api/students/' + this.student.id + '/barcode/resend').then(
        (success) => {
          this.resending = false;
          this.successAlert('Resent!', 'The barcode has been successfully resent to this student.');
        },
        (error) => {
          this.resending = false;
          this.errorAlert('Failed!', 'Unable to this students barcode.');
        }
      );
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    }
  }
};
