<template>
  <div class="component_school-search-with-type-ahead">
    <div class="input-with-icon">
      <input
        ref="schoolSearchInput"
        type="text"
        class="form-control"
        :readonly="isSchoolSearchDisabled"
        :placeholder="placeholder"
        @click.prevent="showCheck"
        @input="queryDebounced"
      >
      <i v-show="isSchoolSearchDisabled" class="fas fa-times" @click.prevent="clearData" />
    </div>
    <ul v-show="show" class="items-list">
      <li v-for="(item, index) in items" :key="item.id" class="items" @click.prevent="selectSchool" @mousemove="setItemCurrent(index)">
        <strong>{{ $translate(item.school) }}</strong>
        <br>
        <small> {{ item.city }} - {{ item.state }} </small>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';

const UNLISTED_CEEB = '000003';
const HOMESCHOOLED_CEEB = '970000';

export default {
  name: 'SchoolSearchWithTypeAhead',

  props: {
    placeholder: {
      type: String,
      required: true
    },
    selectedSchool: {
      type: Object,
      required: false
    },
    items: {
      type: Array,
      require: true
    },
    student: {
      require: true
    }
  },

  data: function() {
    return {
      current: -1,
      selected: null,
      query: '',
      show: false
    };
  },

  mounted: function() {
    if (this.student) {
      let schoolSearchInput = this.$refs.schoolSearchInput;

      this.selected = this.student.school;

      if (this.student.school_ceeb_code === UNLISTED_CEEB || this.student.school_ceeb_code === HOMESCHOOLED_CEEB) {
        schoolSearchInput.value = this.$translate(this.student.school_name);
      } else {
        schoolSearchInput.value = `${this.student.school_name} - ${this.student.school_municipality}, ${this.student.school_region}`;
      }
    }
  },

  methods: {
    clearData: function() {
      this.current = -1;
      this.selected = null;
      this.query = '';

      let schoolSearchInput = this.$refs.schoolSearchInput;
      schoolSearchInput.value = null;

      this.$emit('on-clear');
    },

    showCheck: function() {
      if (this.isSchoolSearchDisabled) {
        this.show = false;
      } else {
        this.show = !this.show;
      }
    },

    outsideClickEvent: function(event) {
      this.show = this.$el.contains(event.target);
    },

    queryDebounced: _.debounce(function(event) {
      if (this.isSchoolSearchDisabled) {
        return;
      }

      this.$emit('on-query', event.target.value);
    }, 400),

    setItemCurrent: function(index) {
      this.current = index;
    },

    selectSchool: function() {
      this.selected = this.items[this.current];
      this.$emit('on-select', this.items[this.current], this.current);
      this.show = false;
      this.query = '';

      this.setSchool();
    },
    setSchool: function() {
      let schoolSearchInput = this.$refs.schoolSearchInput;
      if (this.selected.CEEB === HOMESCHOOLED_CEEB || this.selected.CEEB === UNLISTED_CEEB) {
        schoolSearchInput.value = `${this.$translate(this.selected.school)}`;
      } else {
        schoolSearchInput.value = `${this.selected.school} - ${this.selected.city}, ${this.selected.state}`;
      }
    }
  },

  computed: {
    isSchoolSearchDisabled: function() {
      return this.selected;
    }
  },

  watch: {
    show: function() {
      if (this.show) {
        window.document.addEventListener('click', this.outsideClickEvent);
      } else {
        window.document.removeEventListener('click', this.outsideClickEvent);
      }
    },
    selected: function() {
      this.show = false;
    },
    selectedSchool: function(newValue, oldValue) {
      if (newValue) {
        this.selected = newValue;
        this.setSchool();
      } else {
        this.clearData();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.component_school-search-with-type-ahead {
  position: relative;
}

.input-with-icon {
  position: relative;

  input[type='text'] {
    padding-right: 20px;

    &:disabled,
    &:read-only {
      background: #ffffff;
    }
  }

  i {
    color: #43beef;
    position: absolute;
    right: 0;
    top: 7px;
    cursor: pointer;
    font-size: 20px;
  }
}

.items-list {
  max-height: 300px;
  width: 100%;
  list-style: none;
  position: absolute;
  left: 0;
  top: 36px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  border: 1px solid #d3e0e9;
  border-radius: 4px;
}

.items-list > li {
  cursor: pointer;
  user-select: none;
  font-weight: bold;

  &:hover {
    background: #642c8f;
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
}

.items-list > li > a {
  font-weight: normal;
  line-height: 1.6;
  color: #636b6f;
  text-decoration: none;

  &:hover {
    color: white !important;
  }
}

.items {
  padding: 3px 20px;
}
</style>
