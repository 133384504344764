import fairMixin from './../mixins/fair';
import QuillEditor from './QuillEditor.vue'

export default {
  mixins: [fairMixin],

  components: { QuillEditor },

  props: ['user'],

  data() {
    // We need to keep track of Start && End date, but we keep the SAME timezone because they should never be different
    const parsedStartData = moment(fair.starts_at).tz(fair.starts_at_timezone);
    const parsedEndData = fair.ends_at ? moment(fair.ends_at).tz(fair.starts_at_timezone) : null;

    return {
      fair: fair,
      exporting: false,
      downloading: false,
      fairFullDisplayedDate: this.getFullDisplayedFairDate(parsedStartData, parsedEndData)
    };
  },

  created() {
    this.setFormDetails(this.fair);
    this.setType(this.fair);
  },

  mounted() {
    var self = this;
    this.setStudentSummary();
    this.setInstitutionLimit();
  },

  methods: {
    getFullDisplayedFairDate(parsedStartData, parsedEndData) {
      // First let's make sure they have an end date -- We don't want old records to fail
      if (!parsedEndData) {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} ${parsedStartData.format('z')}`;
      }

      // Next! To clean up the date display we will compare the dates and then display as needed, example:
      // If same date: If same date:  01/01/2022 1:00 PM - 4:00 PM CST
      // If not same date: 01/01/2022 1:00 PM - 01/02/2022 4:00 PM CST
      if (parsedStartData.format('MM-DD-YYYY') === parsedEndData.format('MM-DD-YYYY')) {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} - ${parsedEndData.format('h:mm A')} ${parsedStartData.format('z')}`;
      } else {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} - ${parsedEndData.format('MM-DD-YYYY h:mm A')} ${parsedStartData.format(
          'z'
        )}`;
      }
    },
    resetInstitutionLimit(e) {
      if (!this.form.has_institution_limit) {
        this.form.institution_limit = '';
      }
    },
    setStudentSummary() {
      this.form.send_student_summary = !!this.fair.send_student_summary;
    },
    setInstitutionLimit() {
      this.form.has_institution_limit = this.fair.institution_limit > 0;
      this.form.institution_limit = this.fair.institution_limit > 0 ? this.fair.institution_limit : '';
    },
    askToDownloadAllRegisteredStudents() {
      if (this.downloading) {
        return;
      }
      const app = this;
      swal(
        {
          title: 'Download?',
          text: 'Do you want to download all students registered for this fair?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Download!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'Your list has not been exported!');
          } else {
            this.downloading = true;
            axios
              .post(
                '/api/exports/fairs/' + app.fair.id + '/students/download',
                {},
                {
                  responseType: 'blob'
                }
              )
              .then((success) => {
                this.downloading = false;
                const contentType = success.headers['content-type'];
                const blob = new Blob([success.data], { type: contentType });
                let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
                if (fileName.charAt(0) === '"') {
                  fileName = fileName.substr(1);
                }
                if (fileName.charAt(fileName.length - 1) === '"') {
                  fileName = fileName.substr(0, fileName.length - 1);
                }
                FileSaver.saveAs(blob, fileName);
                swal.close();
              })
              .catch((error) => {
                this.downloading = false;
                if (error.response.status === 409) {
                  app.errorAlert('Nothing to Download', 'There are no registered students to download for this fair.');
                } else {
                  app.errorAlert('Export Too Large', 'This export is too large to download directly. Please export to email instead.');
                }
              });
          }
        }
      );
    },
    askToExportAllRegisteredStudents() {
      if (this.exporting) {
        return;
      }
      let app = this;
      swal(
        {
          title: 'Export?',
          text: 'Do you want to export all students registered for this fair?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Export!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'The export has been canceled!');
          } else {
            this.exporting = true;
            axios
              .post('/api/exports/fairs/' + app.fair.id + '/students', {
                timezone: moment.tz.guess(true)
              })
              .then(
                (success) => {
                  this.exporting = false;
                  if (success.data.data.title === 'Nothing to Export') {
                    app.errorAlert(success.data.data.title, success.data.data.message);
                  } else {
                    app.successAlert(success.data.data.title, success.data.data.message);
                  }
                },
                (error) => {
                  this.exporting = false;
                  app.errorAlert('Canceled', 'There was en error during export!');
                }
              );
          }
        }
      );
    },
    askToExportStudentBarcodes() {
      if (this.exporting) {
        return;
      }
      let app = this;
      swal(
        {
          title: 'Export?',
          text: 'Do you want to export barcodes for all registered students for this fair?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Export!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'The export has been canceled!');
          } else {
            this.exporting = true;
            axios
              .post('/api/exports/fairs/' + app.fair.id + '/barcodes', {
                timezone: moment.tz.guess(true)
              })
              .then(
                (success) => {
                  this.exporting = false;
                  if (success.data.data.title === 'Nothing to Export') {
                    app.errorAlert(success.data.data.title, success.data.data.message);
                  } else {
                    app.successAlert(success.data.data.title, success.data.data.message);
                  }
                },
                (error) => {
                  this.exporting = false;
                  app.errorAlert('Canceled', 'There was en error during export!');
                }
              );
          }
        }
      );
    },
    askToDownloadStudentBarcodes() {
      if (this.downloading) {
        return;
      }
      const app = this;
      swal(
        {
          title: 'Download?',
          text: 'Do you want to download barcodes for all registered students for this fair?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Download!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            app.errorAlert('Canceled', 'Your barcode list download was canceled!');
          } else {
            this.downloading = true;
            axios
              .post(
                '/api/exports/fairs/' + app.fair.id + '/barcodes/download',
                {},
                {
                  responseType: 'blob'
                }
              )
              .then((success) => {
                this.downloading = false;
                const contentType = success.headers['content-type'];
                const blob = new Blob([success.data], { type: contentType });
                let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
                if (fileName.charAt(0) === '"') {
                  fileName = fileName.substr(1);
                }
                if (fileName.charAt(fileName.length - 1) === '"') {
                  fileName = fileName.substr(0, fileName.length - 1);
                }
                FileSaver.saveAs(blob, fileName);
                swal.close();
              })
              .catch((error) => {
                this.downloading = false;
                if (error.response.status === 409) {
                  app.errorAlert('Nothing to Download', 'There are no registered students to download for this fair.');
                } else {
                  app.errorAlert('Export Too Large', 'This export is too large to download directly. Please export to email instead.');
                }
              });
          }
        }
      );
    },
    setType(fair) {
      let pin_is_empty = fair.pin === null || fair.pin === '';
      if (fair.is_locked && !pin_is_empty) {
        this.type = 'pin';
      } else if (fair.is_locked && pin_is_empty) {
        this.type = 'standard';
      } else {
        this.type = 'open';
      }
    },
    setFairDetails(fair) {
      this.fair = fair;
    },
    setFormDetails(fair) {
      this.form.name = fair.name;
      this.form.starts_at = fair.starts_at;
      this.form.starts_at_formatted = moment.parseZone(fair.starts_at).format('MM/DD/YYYY hh:mm A');
      this.form.starts_at_timezone = fair.starts_at_timezone;
      this.form.ends_at = fair.ends_at;
      this.form.ends_at_formatted = moment.parseZone(fair.ends_at).format('MM/DD/YYYY hh:mm A');
      this.form.tags = fair.tags;
      this.form.is_locked = fair.is_locked;
      this.form.pin = fair.pin;
      this.form.summary = fair.summary;
      this.setStudentSummary(fair.send_student_summary);
      this.form.connections_on = fair.connections_on;
      this.form.event_guide_on = fair.event_guide_on;
      this.form.additional_info = fair.additional_info;
      this.form.dashboard_custom_text = fair.dashboard_custom_text;
      this.form.venue = {
        name: fair.venue.name,
        address: {
          line_1: fair.venue.address.line_1,
          line_2: fair.venue.address.line_2,
          city: fair.venue.address.city,
          region: fair.venue.address.region,
          postal_code: fair.venue.address.postal_code,
          country_code: fair.venue.address.country_code
        }
      };
      this.form.latitude = fair.latitude ? fair.latitude.toString() : null;
      this.form.longitude = fair.longitude ? fair.longitude.toString() : null;
    },
    updateFair() {
      var fairTypeValidates = this.checkFairTypeCode();
      if (fairTypeValidates) {
        Spark.put('/api/fairs/' + this.fair.id, this.getFormDataWithValidInstitutionLimit(this.form)).then(
          (success) => {
            this.successAlert('Success', 'The fair was successfully updated.');
            this.setFairDetails(success.data);
            this.setFormDetails(success.data);
          },
          (error) => {
            window.scrollTo(0, 0);
          }
        );
      }
    },
    updateSelectedForms() {
      let allForms = this.formsData.concat(this.ontarioFormsData);
      this.form.forms = allForms.flat().filter((x) => this.fair.forms_array.includes(x.form));
    }
  },
  watch: {
    formsData(newVal, oldVal) {
      this.updateSelectedForms();
    },
    ontarioFormsData(newVal, oldVal) {
      this.updateSelectedForms();
    },
    'form.starts_at': function(newVal, oldVal) {
      this.form.starts_at_formatted = moment.parseZone(newVal).format('MM/DD/YYYY hh:mm A');
    },
    'form.ends_at': function(newVal, oldVal) {
      this.form.ends_at_formatted = moment.parseZone(newVal).format('MM/DD/YYYY hh:mm A');
    }
  }
};