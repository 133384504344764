<template>
  <div>
    <input :id="id" type="checkbox" :name="name" class="cbx hidden" :checked="checked" @change="updateCbx($event)">
    <label :for="id" class="lbl" />
  </div>
</template>

<script>
export default {
  name: 'MaterialStyleCbx',
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateCbx(ev) {
      this.$emit('updatecheck', ev);
    }
  }
};
</script>

<style scoped lang="scss">
.lbl {
  position: relative;
  display: block;
  height: 14px;
  width: 34px;
  background: rgba(157, 157, 157, 0.5);
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    position: absolute;
    left: -2px;
    top: -3px;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: #9d9d9d;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
    content: '';
    transition: all 0.3s ease;
  }

  &:active:after {
    transform: scale(1.15, 0.85);
  }
}

.cbx {
  &:checked ~ label {
    background: rgba(72, 190, 239, 0.5);

    &:after {
      left: 20px;
      background: #48beef;
    }
  }

  &:disabled ~ label {
    background: rgba(157, 157, 157, 0.5);
    pointer-events: none;

    &:after {
      background: rgba(157, 157, 157, 0.5);
    }
  }
}

.hidden {
  display: none;
}
</style>
