/*
 |--------------------------------------------------------------------------
 | Laravel Spark Components
 |--------------------------------------------------------------------------
 |
 | Here we will load the Spark components which makes up the core client
 | application. This is also a convenient spot for you to load all of
 | your components that you write while building your applications.
 */

require('./../spark-components/bootstrap');

require('./partials/dropdown');

require('./home');

require('./student-signup');
require('./student-signup-ontario-parent');
require('./student-signup-ontario-student');
require('./student-signup-professional');
require('./student-signup-ontario-professional');
require('./student-visit');
require('./student-signup-cis');
require('./student-signup-linden');
require('./student-signup-global');
require('./student-signup-parent');
require('./student-signup-linden-boarding');
require('./phone-filter');
require('./update-student-contact-information');
require('./update-team-profile');
require('./update-team-org');
require('./fair-credits');
require('./fair-tags');
require('./teams');
require('./onboarding-screens');
require('./student-import');
require('./importFairs');
require('./connectFairs');
require('./invite-panel');
require('./credits-transaction-log');
require('./invoices');

// Vue Components
import QRSaveAsButton from './QRSaveAsButton.js';
Vue.component('qr-save-as-button', QRSaveAsButton);
