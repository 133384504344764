<template>
    <div>
      <nav class="guide-navbar">
        <div class="container">
            <div class="landing-page-logos">
                <h1 class="text-center" style="margin: 0;">
                <img src="/img/logo-white.png">
                </h1>
            </div>
            <div class="header-content">
                <h1 class="event-guide-title">{{ participant.name }}</h1>
                <div>
                    <p>
                        {{ participant.city }}, {{ participant.state }}
                    </p>
                </div>
            </div>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InsitutionHeader',
    components: {},
    props: {
      participant: {
        type: Object,
        required: false,
        default: () => null,
      },
    },
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  .guide-navbar {
    width: 100%;
    min-height: 128px;
    background: url('/img/student-microsite-header-texture.png') no-repeat top right, linear-gradient(77.23deg, #1F003C 37.57%, #B070E7 106.63%);
    background-size: cover;
    border-radius: 0px 0px 40px 40px;
    margin: 0 auto;
    color: #FFF;
    padding: 20px 10px;
    overflow-anchor: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;

    .container {
        display: flex;
        flex-direction: column;
    
        .landing-page-logos {
            display: flex;
            margin-bottom: 20px;
            margin-left: 0;

            img {
                height: 60px;
            }
        }
        
        .header-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;

            h1 {
                font-family: Quicksand;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
                margin: 0px;
            }

            p {
                font-family: Quicksand;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
            }
        }
    }
  }
  
  
  .container>div:not(:last-child) {
    margin-bottom: 15px;
  }
  
  .header-info-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 8px;
  }
  
  .time-container {
    margin-top: 10px;
  }
  
  .time-container span {
    background: var(--Primary-700, #4A2171);
    border-radius: 4px;
    font-family: var(--font-family, Quicksand);
    font-size: var(--Body-2-size, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Body-2-line-height, 24px);
    padding: 2px 6px;
    text-align: center;
  }
  
  .featured-institution-logo {
    width: 50px;
    height: 50px;
    background-color: #FFF;
  }
  
  
  
  .welcome-banner {
    display: flex;
    margin: 5px 0 15px 0;
    width: 100%;
  }
  
  .welcome-banner span {
    display: flex;
    gap: 4px;
    padding: 2px 6px;
    align-items: center;
    border-radius: 4px;
    width: fit-content;
    background: rgba(255, 255, 255, 0.20);
  }
  
  .header-placeholder {
    height: 0px;
  }
  
  .event-guide-title {
    align-items: center;
    width: 100%;
  }
  
  .event-guide-header {
    display: flex;
    align-items: top;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .column {
    display: flex;
    align-items: left;
    justify-content: left;
  }
  
  .icon-column {
    flex: 0 0 auto;
    /* Takes only as much space as the content */
  }
  
  .center-content-small {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .content-column {
    flex-direction: column;
    flex: 1;
    /* Takes the remaining space */
  }
  
  .content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 1rem 0;
    flex: 1;
    /* Take the full width */
  }
  
  .content-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right;
    padding: 1rem;
  }
  
  .button-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  .register-button {
    background-color: #33FF94;
    border-radius: 24px;
    color: #050505;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    height: 48px;
    letter-spacing: 0.64px;
    line-height: 24px;
    padding: 12px 16px;
    text-transform: uppercase;
    width: 100%;
    min-width: 300px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
  
  .register-button-outline {
    background-color: transparent;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    margin-bottom: 10px;
    padding: 10px 16px;
  }
  
  .fixed-register-button {
    display: none;
    background: #1F003C;
    padding: 10px 20px 20px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    /* border radius on top left and right only */
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .action-button {
    /* floating action button like the android */
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    display: none;
  }
  
  .fixed-action-button {
    display: block;
  }
  
  .align-stretch {
    display: flex;
    align-items: stretch;
    /* Ensure all columns stretch to the same height */
  }
  
  .small-screen-button {
    display: none;
  }
  
  .display-none {
    display: none !important;
  }
  
  .barcode-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .barcode-modal-content {
    background-color: #fefefe;
    padding: 15px;
    border: 1px solid #888;
    position: relative;
    display: inline-block;
    border-radius: 24px;
  }
  
  .close {
    background-color: #33FF94;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    /* circular shape */
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 768px) {
    .guide-navbar h1 {
      font-size: 2.4rem;
    }
  
    .guide-navbar h2 {
      font-size: 1.6rem;
    }
  
    .register-button,
    .guide-navbar .time-container span {
      font-size: 1.4rem;
    }
  
    .welcome-banner {
      font-size: 1.2rem;
    }
  
    .landing-page-logos {
      margin-left: 0px;
    }
  
    .landing-page-logos img {
      height: 35px;
    }
  
    .content-right {
      display: none;
    }
  
    .content-left {
      flex-direction: row;
    }
  
    .center-content-small {
      display: block;
    }
  
    .small-screen-button {
      margin-top: 10px;
      display: block;
    }
  
    .center-content-small {
      margin: 0;
    }
  
    .fixed-button {
      display: block;
    }
  }
  </style>