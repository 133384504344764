import upcomingFairs from '../components/upcomingFairs';
import pastFairs from '../components/pastFairs';
import upcomingVirtualFairs from '../components/upcomingVirtualFairs';
import pastVirtualFairs from '../components/pastVirtualFairs';
import fairCreation from '../components/fairCreation';
import fairDetails from '../components/fairDetails';
import fairStartDateFilter from '../filters/date.MM.DD.YY';

Vue.filter('fairStartDate', fairStartDateFilter);
Vue.component('upcoming-fairs', upcomingFairs);
Vue.component('past-fairs', pastFairs);
Vue.component('upcoming-virtual-fairs', upcomingVirtualFairs);
Vue.component('past-virtual-fairs', pastVirtualFairs);
Vue.component('create-fair', fairCreation);
Vue.component('fair-details', fairDetails);
