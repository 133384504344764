module.exports = {
  props: ['user'],

  /**
   * The component's data.
   */
  data() {
    return {
      form: $.extend(
        true,
        new SparkForm({
          first_name: '',
          last_name: '',
          email: ''
        }),
        Spark.forms.updateContactInformation
      )
    };
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.form.email = this.user.email;
  },

  methods: {
    /**
     * Update the user's contact information.
     */
    update() {
      this.form.busy = true;
      Spark.put('/settings/contact', this.form)
        .then(() => {
          this.form.busy = false;
          Bus.$emit('updateUser');
        })
        .catch((error) => {
          this.form.busy = false;
          let twilioError = error.errors.filter((e) => {
            return e.title === 'twilio';
          });

          if (twilioError) {
            this.form.errors.errors = Object.assign({}, this.form.errors.errors, { twilio: [twilioError[0].detail] });
          }
        });
    },
    checkTwoFactorEmail() {
      this.form.busy = true;
      if (this.user.uses_two_factor_auth) {
        Bus.$emit('check-two-factor', this.user);
      } else {
        this.update();
      }
    },
    twoFactorFailed() {
      this.form.busy = false;
    }
  }
};
