var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cropper',{ref:"cropper",staticClass:"twitter-cropper",attrs:{"background-class":"twitter-cropper__background","foreground-class":"twitter-cropper__foreground","image-restriction":"stencil","stencil-size":_vm.stencilSize,"stencil-props":{
      lines: {},
      handlers: {},
      movable: false,
      scalable: false,
      aspectRatio: 1,
      previewClass: 'twitter-cropper__stencil',
    },"background-wrapper-component":_vm.$options.components.CustomBackgroundWrapper,"default-size":_vm.defaultSize,"transitions":false,"canvas":true,"debounce":false,"min-width":360,"min-height":360,"src":_vm.img},on:{"change":_vm.onChange}}),_vm._v(" "),_c('navigation',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }