import BaseVisitList from './visitList';
import SearchedVisits from './searchedVisits';

export default BaseVisitList.extend({
  props: ['query'],
  components: { SearchedVisits },
  methods: {
    performSearch() {
      this.fetchVisits();
    }
  },
});
