Vue.component('onboarding-screens', {
  data() {
    return {
      isLoading: true,
      form: new SparkForm({
        src1: null,
        src2: null,
        src3: null,
        src4: null,
        src5: null,
        src6: null,
        src7: null,
        src8: null
      })
    };
  },
  mounted() {
    axios
      .get('/api/onboarding-screens')
      .then(({ data }) => {
        if (data.data) {
          this.setForm(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        this.errorAlert('Error', 'Failed to load Onboarding Screen URLs!');
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    setForm(data) {
      this.form.src1 = null;
      this.form.src2 = null;
      this.form.src3 = null;
      this.form.src4 = null;
      this.form.src5 = null;
      this.form.src6 = null;
      this.form.src7 = null;
      this.form.src8 = null;

      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data.hasOwnProperty(i)) {
            this.form['src' + (i + 1).toString()] = data[i].src;
          }
        }
      }
    },
    updateOnboardingScreens() {
      Spark.put('/api/onboarding-screens', this.form)
        .then((success) => {
          this.setForm(success.data);
          this.successAlert('Success', 'Onboarding Screens updated!');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    }
  }
});
