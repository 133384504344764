module.exports = {
  /**
   * Load mixins for the component.
   */
  mixins: [require('./../mixins/register'), require('./../mixins/plans'), require('./../mixins/vat')],

  /**
   * The component's data.
   */
  data() {
    return {
      query: null,
      verifyParam: null,
      coupon: null,
      invalidCoupon: false,

      country: null,
      taxRate: 0,

      clickedButton: null,

      registerForm: $.extend(
        true,
        new SparkForm({
          stripe_token: '',
          plan: '',
          team: '',
          team_slug: '',
          name: '',
          email: window?.tokenEmail ?? '',
          password: '',
          password_confirmation: '',
          address: '',
          address_line_2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
          vat_id: '',
          terms: false,
          coupon: null,
          invitation: null,
          mfa_status: 'starting',
          mfa_code: null,
          mfa_bypass: false
        }),
        Spark.forms.register
      ),

      cardForm: new SparkForm({
        name: '',
        number: '',
        cvc: '',
        month: '',
        year: ''
      })
    };
  },

  watch: {
    /**
     * Watch for changes on the entire billing address.
     */
    currentBillingAddress: function(value) {
      if (!Spark.collectsEuropeanVat) {
        return;
      }

      this.refreshTaxRate(this.registerForm);
    },

    /**
     * Watch the team name for changes.
     */
    'registerForm.team': function(val, oldVal) {
      if (this.registerForm.team_slug == '' || this.registerForm.team_slug == oldVal.toLowerCase().replace(/[\s\W-]+/g, '-')) {
        this.registerForm.team_slug = val.toLowerCase().replace(/[\s\W-]+/g, '-');
      }
    }
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    Stripe.setPublishableKey(Spark.stripeKey);

    this.getPlans();

    this.guessCountry();

    this.query = URI(document.URL).query(true);

    if (this.query.coupon) {
      this.getCoupon();

      this.registerForm.coupon = this.query.coupon;
    }

    if (this.query.invitation) {
      this.getInvitation();

      this.registerForm.invitation = this.query.invitation;
    }
  },

  /**
   * Prepare the component.
   */
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.verifyParam = urlParams.get('verify');
    if (this.verifyParam === 'off' || window?.tokenEmail) {
      this.registerForm.mfa_status = 'approved';
      this.registerForm.mfa_bypass = true;
    }
  },

  watch: {
    'registerForm.email': function (val) {
      if (this.verifyParam === 'off' || val === window?.tokenEmail) {
        this.registerForm.mfa_status = 'approved';
        this.registerForm.mfa_bypass = true;
      } else {
        this.registerForm.mfa_status = 'starting';
        this.registerForm.mfa_bypass = false;
      }
    }
  },

  methods: {
    /**
     * Get the invitation specified in the query string.
     */
    getInvitation() {
      axios
        .get(`/invitations/${this.query.invitation}`)
        .then((response) => {
          this.invitation = response.data;
          this.registerForm.email = response.data.email;
          this.registerForm.mfa_status = 'approved';
          this.registerForm.mfa_bypass = true;
        })
        .catch((response) => {
          this.invalidInvitation = true;
        });
    },

    /**
     * Attempt to guess the user's country.
     */
    guessCountry() {
      axios
        .get('/geocode/country')
        .then((response) => {
          if (response.data != 'ZZ') {
            this.registerForm.country = response.data;
          }
        })
        .catch((response) => {
          //
        });
    },

    /**
     * Get the coupon specified in the query string.
     */
    getCoupon() {
      axios
        .get('/coupon/' + this.query.coupon)
        .then((response) => {
          this.coupon = response.data;
        })
        .catch((response) => {
          this.invalidCoupon = true;
        });
    },

    /**
     * Attempt to register with the application.
     */
    register(event) {
      this.cardForm.errors.forget();

      this.registerForm.busy = true;
      this.registerForm.errors.forget();

      // Get button that is clicked to improve the UI experience when busy or spin
      this.clickedButton = event.target.parentElement.id;
      if (this.clickedButton === 'resendcode') {
        this.registerForm.mfa_status = 'starting';
      }

      if (!Spark.cardUpFront || this.selectedPlan.price == 0) {
        return this.sendRegistration();
      }

      Stripe.card.createToken(this.stripePayload(), (status, response) => {
        if (response.error) {
          this.cardForm.errors.set({ number: [response.error.message] });
          this.registerForm.busy = false;
        } else {
          this.registerForm.stripe_token = response.id;
          this.sendRegistration();
        }
      });
    },

    /**
     * Build the Stripe payload based on the form input.
     */
    stripePayload() {
      // Here we will build out the payload to send to Stripe to obtain a card token so
      // we can create the actual subscription. We will build out this data that has
      // this credit card number, CVC, etc. and exchange it for a secure token ID.
      return {
        name: this.cardForm.name,
        number: this.cardForm.number,
        cvc: this.cardForm.cvc,
        exp_month: this.cardForm.month,
        exp_year: this.cardForm.year,
        address_line1: this.registerForm.address,
        address_line2: this.registerForm.address_line_2,
        address_city: this.registerForm.city,
        address_state: this.registerForm.state,
        address_zip: this.registerForm.zip,
        address_country: this.registerForm.country
      };
    },

    /*
     * After obtaining the Stripe token, send the registration to Spark.
     */
    sendRegistration() {
      Spark.post('/register', this.registerForm)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          this.registerForm.mfa_status = error.mfa_status;
          if ('approved' === error.mfa_status) {
            // Clean the mfa code up
            this.registerForm.mfa_code = '';
          }

          let twilioError = false;
          if ('errors' in error && 'filter' in error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });
          }

          if (twilioError) {
            this.registerForm.errors.errors = Object.assign({}, this.registerForm.errors.errors, { twilio: [twilioError[0].detail] });
          }
        });
    }
  },

  computed: {
    mfaApprovedWithoutBypass() {
      return this.registerForm.mfa_status === 'approved' && this.registerForm.mfa_bypass !== true;
    },

    userFormFieldInputDisabled() {
      return this.registerForm.mfa_bypass !== true && this.registerForm.mfa_status !== 'approved';
    },

    /**
     * Determine if the selected country collects European VAT.
     */
    countryCollectsVat() {
      return this.collectsVat(this.registerForm.country);
    },

    /**
     * Get the displayable discount for the coupon.
     */
    discount() {
      if (this.coupon) {
        if (this.coupon.percent_off) {
          return this.coupon.percent_off + '%';
        } else {
          return Vue.filter('currency')(this.coupon.amount_off / 100);
        }
      }
    },

    /**
     * Get the current billing address from the register form.
     *
     * This used primarily for wathcing.
     */
    currentBillingAddress() {
      return (
        this.registerForm.address +
        this.registerForm.address_line_2 +
        this.registerForm.city +
        this.registerForm.state +
        this.registerForm.zip +
        this.registerForm.country +
        this.registerForm.vat_id
      );
    }
  }
};
