import template from './index.html';

export default {
  template: template,
  props: {
    totalPages: {
      type: Number,
      default: null
    },
    currentPage: {
      type: Number,
      default: null
    },
    context: {
      type: String,
      default: 'global'
    },
    title: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      selectorsPerPage: 15 // Should be more or equal than 2
    };
  },
  computed: {
    navAriaLabel() {
      if (null !== this.title && '' !== this.title) {
        `Page navigation for ${this.title}`;
      }
      return 'Page navigation';
    },
    onFirstPage() {
      return this.currentPage == 1;
    },
    onLastPage() {
      return this.currentPage == this.totalPages;
    },
    selectors() {
      if (!this.totalPages) {
        return 1;
      } else if (this.totalPages < this.selectorsPerPage) {
        // not enough pages to fill all selectors in first page
        return this.totalPages;
      } else if (this.totalPages < this.offset + this.selectorsPerPage) {
        // not enough pages to fill all selectors in last page
        return this.totalPages - this.offset;
      } else {
        return this.selectorsPerPage;
      }
    },
    offset() {
      return Math.floor((this.currentPage - 1) / this.selectorsPerPage) * this.selectorsPerPage;
    }
  },
  methods: {
    jumpToPage(pageNumber) {
      if (pageNumber < 1 || pageNumber > this.totalPages) {
        return;
      }

      Bus.$emit('currentPageUpdated', pageNumber, this.context);
    },
    goToPreviousPage() {
      if (this.onFirstPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', this.currentPage - 1, this.context);
    },
    goToNextPage() {
      if (this.onLastPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', this.currentPage + 1, this.context);
    },
    isCurrentPage(pageNumber) {
      return pageNumber == this.currentPage;
    }
  }
};
