import Vue from 'vue';
import TeamRequestApproveModal from '../../../components/team-requests/TeamRequestApproveModal.vue';
import Snackbar from '../../../components/Snackbar.vue';
import CustomDialog from '../../../components/dashboard/CustomDialog.vue';
import { TeamRequestApi } from '../../../api/team-request-api';

export default Vue.extend({
  components: { TeamRequestApproveModal, Snackbar, CustomDialog },
  props: ['team', 'user'],
  data() {
    return {
      items: [],
      selected: {
        user_creator: {
          name: ''
        }
      },
      alertSuccess: false,
      alertMessage: false,
      loading: false
    };
  },
  computed: {
    role() {
      return this.user.teams[0].pivot.role;
    }
  },
  created() {
    this.fetchTeamRequests();
  },
  methods: {
    fetchTeamRequests() {
      TeamRequestApi.teams_requests(this.team.id)
        .list()
        .then((success) => {
          this.items = success.data.data;
        });
    },
    onApproveClicked(teamRequest) {
      this.selected = teamRequest;
      $('#modal-team-request-approve').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onRejectClicked(teamRequest) {
      Bus.$emit(
        'open-custom-dialog',
        'Reject from Team?',
        teamRequest.user_creator.name +
          ' will receive an email notifying them that their request to join has been rejected. Your team will not be changed.',
        'REJECT',
        'CANCEL',
        true,
        () => {
          this.loading = true;
          this.selected = teamRequest;
          this.submitRejection();
        }
      );
    },
    submitApproval(role) {
      TeamRequestApi.teams_requests(this.selected.id)
        .approve({ role })
        .then(() => {
          this.alertMessage = this.selected.user_creator.name + ' was added to the team.';
          this.alertSuccess = true;
          $('#modal-team-request-approve').modal('hide');
          this.fetchTeamRequests();
          Bus.$emit('updateCurrentTeam');
        });
    },
    submitRejection() {
      TeamRequestApi.teams_requests(this.selected.id)
        .reject()
        .then(() => {
          this.alertMessage = this.selected.user_creator.name + ' request to join your team has been rejected.';
          this.alertSuccess = true;
          this.loading = false;
          this.fetchTeamRequests();
        });
    }
  }
});
