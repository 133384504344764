<template>
  <div>
    <div class="dashboard-card-title-container">
      <h2 v-if="fairInstitutionLimit == 0" class="dashboard-card-title">
        <span v-show="!suView">Institutions</span>
        <span v-show="suView">Teams Unlocked</span>
      </h2>
      <h2 v-if="fairInstitutionLimit > 0" class="dashboard-card-title">
        <span v-show="!suView">Top {{ fairInstitutionLimit }} Institutions</span>
        <span v-show="suView">Teams Unlocked</span>
      </h2>
      <form
        autocomplete="off"
        :class="{ disabled: !fetching && !hasInstitutions && !isSearching }"
        @keypress.enter="$event.preventDefault()"
      >
        <div class="input-group">
          <input v-model="input" type="text" class="form-control" placeholder="Search institutions"
                 @keyup.enter="triggerSearch"
          >
          <span class="input-group-btn">
            <button class="btn btn-primary search-button" type="button" @click="triggerSearch">
              <i class="fa fa-search fa-lg" />
            </button>
          </span>
        </div>
      </form>
    </div>
    <table v-show="!fetching && hasInstitutions" class="table institutions-details">
      <thead>
      <tr>
        <th scope="col" class="col-left sortable" @click="toggleSort('name')">
          Institution
          <span v-show="currentSortArrow === 'name-desc'" class="material-icons"
                aria-label="Toggle sort by institutions"
          >arrow_downward</span>
          <span v-show="currentSortArrow === 'name-asc'" class="material-icons"
                aria-label="Toggle sort by institutions"
          >arrow_upward</span>
        </th>
        <th v-show="suView" scope="col" class="col-left sortable" @click="toggleSort('team_id')">
          Team ID
          <span v-show="currentSortArrow === 'team_id-desc'" class="material-icons" aria-label="Toggle sort by team id">arrow_downward</span>
          <span v-show="currentSortArrow === 'team_id-asc'" class="material-icons" aria-label="Toggle sort by team id">arrow_upward</span>
        </th>
        <th v-show="suView" scope="col" class="col-lef sortable" @click="toggleSort('unlocked_at')">
          Unlock
          <span v-show="currentSortArrow === 'unlocked_at-desc'" class="material-icons"
                aria-label="Toggle sort by unlock time"
          >arrow_downward</span>
          <span v-show="currentSortArrow === 'unlocked_at-asc'" class="material-icons"
                aria-label="Toggle sort by unlock time"
          >arrow_upward</span>
        </th>
        <th v-show="suView" scope="col" class="col-left">
          Scanning
        </th>
        <th scope="col" class="col-right sortable" @click="toggleSort('students_scanned')">
          <span v-if="!suView">Total Scans</span>
          <span v-if="suView">Team Scans</span>
          <span v-show="currentSortArrow === 'students_scanned-desc'" class="material-icons"
                aria-label="Toggle sort by scans"
          >arrow_downward</span>
          <span v-show="currentSortArrow === 'students_scanned-asc'" class="material-icons"
                aria-label="Toggle sort by scans"
          >arrow_upward</span>
        </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="institution in institutions" :key="institution.id" class="info">
          <td class="location">
            <div class="name">
              <a :href="institution.website" target="_blank">
                {{ institution.name }} &nbsp;
                <span class="material-icons">open_in_new</span>
              </a>
            </div>
            <div class="municipality-region">
              {{ getInstitutionLocation(institution) }}
            </div>
          </td>
          <td v-show="suView" class="team">
            {{ institution.team_id }}
          </td>
          <td v-if="suView" class="unlock">
            <div>
              {{ institution.unlock_mode | capitalize }}
              <div>{{ institution.unlocked_by }}  ID: {{ institution.user_id }}</div>
              <div>{{ institution.user_title }}</div>
              <div>{{ institution.user_email }}</div>
              <div>{{ getTimeAtFairTimezone(institution.unlocked_at, institution.fair_tz) }}</div>
            </div>
          </td>
          <td v-if="suView" class="scanning">
            <div v-for="rep in institution.scanning_users" :key="rep.id" class="rep">
              <div>{{ rep.name }}  ID: {{ rep.id }}</div>
              <div>{{ rep.title }}</div>
              <div>{{ rep.email }}</div>
            </div>
          </td>
          <td class="count-detail">
            {{ institution.students_scanned }}
          </td>
        </tr>
      </tbody>
    </table>
    <Loader v-if="fetching" />
    <list-empty-state
      v-if="!fetching && !hasInstitutions"
      :no-border="true"
      :title="isSearching ? 'There are no institutions that match your search criteria.' : suView ? 'No teams have unlocked this fair.' : 'Check back once scanning has started.'"
    >
      <template slot="icon">
        <span v-if="isSearching" class="material-icons">search</span>
        <span v-else class="material-icons">alarm</span>
      </template>
      <span v-if="!isSearching & !suView">Check back once scanning has started.</span>
    </list-empty-state>
    <pagination-variant
      v-show="!fetching && hasInstitutions"
      :total-pages="pagination.page_count"
      :current-page="pagination.current_page"
      :total-items="pagination.total_count"
      :per-page="pagination.per_page"
      :items-on-page="institutions ? institutions.length : 0"
      :context="contextKey"
      :title="'Institutions'"
    />
  </div>
</template>

<script>
import { Api } from '../../../dashboard/dashboard-api';
import ListEmptyState from '../../ListEmptyState.vue';
import PaginationVariant from '../../PaginationVariant.vue';
import Loader from '../Loader.vue';
import moment from 'moment-timezone';

const DEFAULT_PAGE_SIZE = 20;

export default {
  name: 'DashboardFairInstitutionsTable',
  components: { Loader, ListEmptyState, PaginationVariant },
  props: {
    fairId: {
      type: Number,
      default: null
    },
    fairStartTime: {
      type: Date,
      default: null
    },
    fairInstitutionLimit: {
      type: Number,
      default: null
    },
    suView: {
      type: Boolean | String,
      default: false
    }
  },
  data: () => {
    return {
      institutions: null,
      order: 'desc',
      pagination: {
        current_page: 1,
        page_count: 1,
        per_page: DEFAULT_PAGE_SIZE,
        total_count: 1
      },
      fetching: false,
      errors: null,
      input: '',
      query: ''
    };
  },
  computed: {
    hasInstitutions() {
      return this.institutions !== null && this.institutions.length > 0;
    },
    isSearching() {
      return this.query && this.query.length > 0;
    },
    hasStarted() {
      return this.fairStartTime <= new Date();
    },
    contextKey() {
      return 'institutions_table';
    },
    currentSortArrow() {
      if (!this.fetching && this.hasInstitutions) {
        return `${this.orderBy}-${this.order}`;
      }
      return 'none';
    }
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      return value
        .toLowerCase()
        .replace(/(^\w|\s\w)/g, match => match.toUpperCase());
    }
  },
  watch: {
    input: function() {
      if (this.input === '' && this.query !== '') {
        this.triggerSearch();
      }
    },
    'pagination.current_page': function() {
      this.fetchInstitutions();
    }
  },
  mounted() {
    this.orderBy = 'students_scanned';
    this.fetchInstitutions();
    Bus.$on('refreshDashboard', this.fetchInstitutions);
  },
  created() {
    Bus.$on('currentPageUpdated', (pageNumber, context) => {
      if (this.contextKey === context) {
        this.pagination.current_page = Number(pageNumber);
      }
    });
  },
  methods: {
    fetchInstitutions() {
      this.fetching = true;
      Api.fairs(this.fairId)
        .dashboard.institutions({
        page: this.pagination.current_page,
        limit: this.suView ? 999 : this.pagination.per_page,
        order: this.order,
        order_by: this.orderBy,
        query: this.query,
        fair_institution_limit: this.fairInstitutionLimit,
        su_view: this.suView
      })
        .then(
          (success) => {
            this.fetching = false;
            this.institutions = success.data.data.map((institution) => {
              let scanning_users = institution.associated_users ? JSON.parse(institution.associated_users) : [];
              let scanning_users_array = [].concat(scanning_users || []);

              institution.scanning_users = scanning_users_array;

              if (institution.website) {
                try {
                  const url = new URL(institution.website);
                  if (!('http:' === url.protocol || 'https:' === url.protocol)) {
                    institution.website = 'https://' + institution.website;
                  }
                } catch (_) {
                  institution.website = 'https://' + institution.website;
                }
              } else {
                institution.website = 'about:blank';
              }
              return institution;
            });
            this.pagination = success.data.meta.pagination;
          },
          (error) => {
            this.fetching = false;
            this.errors = error.data.errors;
          }
        );
    },
    toggleSort(orderBy) {
      if (this.orderBy === orderBy) {
        this.toggleSortOrder();
      }
      this.orderBy = orderBy;
      this.fetchInstitutions();
    },
    toggleSortOrder() {
      if (this.order === 'desc') {
        this.order = 'asc';
      } else {
        this.order = 'desc';
      }
    },
    triggerSearch() {
      this.query = this.input;
      if (this.pagination.current_page !== 1) {
        // there is a watch on `pagination.current_page` that calls this.fetchInstitutions();
        this.pagination.current_page = 1;
      } else {
        this.fetchInstitutions();
      }
    },
    getInstitutionLocation(institution) {
      let location = '';
      if (institution.municipality) {
        location += institution.municipality;
      }
      if (institution.region) {
        location += ', ' + institution.region;
      }
      if (institution.country_code && institution.country_code !== 'US') {
        location += ', ' + institution.country_code;
      }
      return location;
    },
    getTimeAtFairTimezone(time, tz) {
      return moment.utc(time).tz(tz).format('MMM D, YYYY [@] h:mm A z');
    }
  }
};
</script>

<style scoped>
th {
  display: flex;
  align-items: center;
  border-bottom: none !important;
}

.col-right {
  text-align: right !important;
}

.sortable {
  cursor: pointer;
}

.dashboard-card-title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 0;
}

.name a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
}

.name a:hover {
  text-decoration: none;
}

th .material-icons,
td .material-icons {
  font-size: 18px;
  color: #642c8f;
}

form.disabled {
  pointer-events: none;
}

form.disabled input {
  background: rgba(0, 0, 0, 0.03);
}

form.disabled button {
  background: rgba(0, 0, 0, 0.26);
}
</style>

<style>
.pagination-variant-container {
  margin: 0 !important;
}

.rep {
  margin-bottom: 10px;
}

.team, .unlock, .scanning, .count-detail {
  font-size: 14px;
}
</style>
