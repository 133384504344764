<template>
  <div ref="dropdown" class="tw relative inline-block text-left w-full md:w-auto m-0">
    <!-- Dropdown button -->
    <div>
      <button
        type="button"
        style="padding: 8px 14px;"
        class="inline-flex justify-center border btn btn-export focus:outline-none w-full md:w-auto uppercase items-center gap-x-2"
        aria-haspopup="true"
        aria-expanded="true"
        @click="toggleDropdown"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8 overflow-visible">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
        </svg>
        Barcodes
      </button>
    </div>

    <!-- Dropdown menu -->
    <div
      v-if="isOpen"
      class="origin-top-right absolute xs:left-6 md:right-0 mt-2 dropdown rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div class="py-4 divide-y divide-gray-200">
        <div class="px-4 pb-4">
          <!--          <span class="font-semibold">Select Schools</span>-->
          <div>
            <div class="w-full">
              <!-- Select All Button -->
              <div class="flex justify-between items-center mb-4">
                <button
                  class="px-4 py-2 btn btn-export border uppercase"
                  @click="toggleSelectAll"
                >
                  {{ selectAll ? 'Deselect All' : 'Select All' }}
                </button>
              </div>

              <!-- Scrollable Checkbox List -->
              <div class="h-64 overflow-y-auto border rounded-md divide-y divide-grey-200">
                <div>
                  <input class="w-full p-4 outline outline-0 focus:outline focus:outline-0" type="text" placeholder="Search for School" @input="filterText">
                </div>
                <div
                  v-for="(school, index) in availableSchools"
                  :key="index"
                  class="flex items-center p-4 justify-left items-start gap-x-4"
                >
                  <input
                    :id="`school-${index}`"
                    v-model="selectedSchools"
                    type="checkbox"
                    :value="school"
                    style="margin: 0;"
                    class="m-0"
                  >
                  <label :for="`school-${index}`" class="text-gray-700 m-0">{{ school.name }} ({{ school.registered }})</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 pt-4 flex justify-end">
          <button class="btn btn-export border uppercase" :disabled="!selectedSchools.length" @click="requestBarcodes">
            Submit
          </button>
        </div>
      </div>
    </div>
    <snackbar :show="alertSuccess" :message="alertMessage" />
  </div>
</template>

<script>
import { Api } from '../../dashboard/dashboard-api';
import {filter, includes} from 'lodash';

export default {
  props: {
    canViewPrintBarcode: {
      type: Number,
      default: 0,
    },
    schoolPermissions: {
      type: Array,
      default: null
    },
    isAdmin: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      alertSuccess: false,
      alertMessage: '',
      isOpen: false,
      schools: [],
      selectedSchools: [], // Stores the selected schools
      selectAll: false, // Tracks the state of the select all button
      searchTerm: '',
    };
  },
  computed: {
    availableSchools: function() {
      if(this.isAdmin) {
        if(this.searchTerm) {
          return this.searchTerm ? this.schools.filter((object) =>
            object.name && object.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) || this.schools : this.schools;
        }
        return this.schools
      }

      const schools = [];
      const filteredData = this.schoolPermissions.filter((i) => i.user.email === window.Spark.state.user.email);
      const schoolIds = filteredData.flatMap((data) =>
        data.schools.map((school) => school.id)
      );
      return filter(this.schools, (school) => includes(schoolIds, school.id));
    }
  },
  watch: {
    selectedSchools(newSelected) {
      // If the number of selected schools matches the total list, set selectAll to true
      this.selectAll = newSelected.length === this.schools.length;
    }
  },
  mounted() {
    // Add event listener to handle click outside
    window.addEventListener('click', this.handleClickOutside);

    this.fetchSchools();
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    window.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    filterText(event) {
      this.searchTerm = event.target.value;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    handleClickOutside(event) {
      // Check if the click was outside of the dropdown element
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
    toggleSelectAll() {
      // If not all are selected, select all, otherwise deselect all
      if (!this.selectAll) {
        this.selectedSchools = [...this.schools];
      } else {
        this.selectedSchools = [];
      }
      this.selectAll = !this.selectAll;
    },
    fetchSchools() {
      Api.fairs(window['fair_id']).dashboard.schools()
        .then(
          (success) => {
            this.schools = success.data.data.map((school) => {
              if(!school.name) school.name = 'Organization Unknown';
              return school;
            }).filter((school, index, self) =>
                index === self.findIndex((s) =>
                  s.name === school.name && s.id === school.id
                )
            ).sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.schools = this.schools.filter((school, index, self) =>
                index === self.findIndex((s) =>
                  s.CEEB === school.CEEB && s.name === school.name
                )
            );
          },
          (error) => console.log(error)
        );
    },
    requestBarcodes() {
      const schoolIds = this.selectedSchools
        .filter((s) => s.id > 0)
        .map((s) => s.id);
      const uniqueSchoolIds = this.selectedSchools
        .filter((s) => s.id < 0)
        .map((s) => s.uniqueSchoolId);

      this.alertSuccess = false;

      axios.post('/api/v2/barcode/dashboard', {
        fairId: window['fair_id'],
        schoolIds,
        uniqueSchoolIds
      }).then(
        (success) => {
          this.alertSuccess = true;
          this.alertMessage = 'Barcode export in progress and will be emailed to ' + window.Spark.state.user.email + '.';
        },
        (error) => {
          this.alertSuccess = false;
          this.alertMessage = 'Barcode export failed to export'
        }
      )
    },
    findSchoolById(fairUserData, schoolId) {
      for (const item of fairUserData) {
        const school = _.find(item.schools, { id: schoolId });
        if (school) {
          return school;
        }
      }
      return null; // Return null if no matching school is found
    },
    canPrintBarcode(school_id) {
      const filteredData = this.schoolPermissions.filter((i) => i.user.email === window.Spark.state.user.email);
      return this.findSchoolById(filteredData, school_id);
    }
  }
};
</script>

<style scoped>
@import "/css/tailwindcss.min.css";

.dropdown {
  min-width: 400px;
}

@media (max-width: 639px) {
  .dropdown {
    min-width: 320px;
  }
}
</style>