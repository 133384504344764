<template>
  <div>
    <div id="modal-event-guide-details" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" aria-hidden="true" @click="closeModal">
                &times;
              </button>
              <h3 class="modal-title">
                Edit Content
              </h3>
            </div>

            <div class="modal-body">
              <div class="panel-body">
                <div v-if="selectedItem.order_column !== 1" style="margin-bottom: 20px;">
                  <p class="custom-margin">
                    <strong>Section Header</strong>
                  </p>
                  <input type="text" class="form-control" v-model="form.title" placeholder="Enter section header" />
                </div>
                <div v-else style="margin-bottom: 20px;">
                  <p class="custom-margin">
                    <strong>Section 1 (Highlighted Info)</strong>
                  </p>
                  This text will display at the top of the page and without a section header.
                </div>
                <div>
                  <p class="custom-margin">
                    <strong>Custom Text</strong>
                  </p>
                  <quill-editor v-model="form.content" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" style="background: none;" @click="closeModal">
                CANCEL
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" @click="saveAndClose">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuillEditor from '../../QuillEditor.vue';

export default {
  name: 'EventGuideSectionDetailsModal',

  components: { QuillEditor },

  props: {
    fair: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      default: () => ({
        id: null,
        title: '',
        order_column: 0,
        description: ''
      })
    }
  },

  data() {
    return {
      form: new SparkForm({
        title: '',
        content: ''
      }),
      loading: true,
    };
  },

  computed: {
  },

  mounted() {
    $('#modal-event-guide-details').on('hidden.bs.modal', this.resetForm);
    $('#modal-event-guide-details').on('shown.bs.modal', this.setFormData);
  },

  methods: {
    closeModal() {
      $('#modal-event-guide-details').modal('hide');
    },
    saveAndClose() {
      this.form.startProcessing();

      axios.post(`/api/fairs/${this.fair.id}/dashboards/fair-details/${this.selectedItem.id}`, this.form).then(
        () => {
          this.form.finishProcessing();

          Bus.$emit('updatedEventGuideSection');

          $('#modal-event-guide-details').modal('hide');
        }).catch(
          (error) => {
            this.form.setErrors(error.response.data);
          }
        );
    },
    setFormData() {
      this.form.title = this.selectedItem.title;
      this.form.content = this.selectedItem.content;
    },
    resetForm() {
      this.form.title = '';
      this.form.content = '';
    }
  }
};
</script>