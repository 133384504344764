export default Vue.extend({
  data() {
    return {
      fields_to_validate: []
    };
  },
  methods: {
    formIsValid() {
      let valid = true;
      for (let field in this.fields_to_validate) {
        if (!this.isValid(field, this.fields_to_validate[field])) {
          valid = false;
        }
      }
      return valid;
    },
    isValid(field, value) {
      let is_valid = this.validate_field(field, value);
      if (is_valid) {
        this.remove_form_field_errors(field);
      }
      return is_valid;
    },
    validate(field, value) {
      let is_valid = this.validate_field(field, value);
      if (is_valid) {
        this.remove_form_field_errors(field);
      }
    },

    validate_field(field, value) {
      let is_valid = false;
      if (this.fields_to_validate[field]) {
        if (this.fields_to_validate[field].indexOf('required') > -1) {
          is_valid = this.validate_required(field, value);
        }

        if (this.fields_to_validate[field].indexOf('custom') > -1) {
          /** TODO: Need a better way to access the correct value to pass into the validation method
           * (birthdate needs the data birthdate to be validated and not form['birthdate']...
           **/
          if (this[field]) {
            is_valid = this['validate_' + field](this[field]);
          } else {
            is_valid = this['validate_' + field](this.form[field]);
          }
        }
      }
      return is_valid;
    },

    remove_form_field_errors(field, indexOf) {
      let form_errors = this.form.errors.errors;
      if (form_errors[field]) {
        for (let i = 0; i < form_errors[field].length; i++) {
          let index = form_errors[field][i].indexOf(indexOf);
          if (index > -1) {
            form_errors[field].splice(i, 1);
          }
        }
      }

      if (this.form.errors.errors[field] && this.form.errors.errors[field].length < 1) {
        Vue.delete(this.form.errors.errors, field);
      }
    },

    validate_required(field, value) {
      let form_errors = this.form.errors.errors;

      let is_valid = false;
      if (!this[field] && !this.form[field]) {
        // raise error for form field
        if (form_errors[field]) {
          form_errors[field] = [this.get_string_for_locale(field)];
        } else {
          this.form.errors.errors = Object.assign({}, form_errors, {
            [field]: [this.get_string_for_locale(field)]
          });
        }
      } else {
        is_valid = true;
        this.remove_form_field_errors(field, 'required');
      }
      return is_valid;
    },
    get_string_for_locale(field) {
      // Thus far this is the only JS -level localization we're needing. Hardcode for now, but if we end up doing more true localization, we should import an appropriate localization library.
      console.log('field', field.replace(/_/g, ' '));
      
      // check if the exact key exists
      var fullTranslation = 'the-' + field.replace(/_/g, '-') + '-field-is-required';
      if (this.$checkForTranslation(fullTranslation)) {
        return this.$translate(fullTranslation);
      }

      return this.$translate('The :field field is required.', { field: this.$translate(field.replace(/_/g, ' ')) });
    }
  }
});