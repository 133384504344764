<template>
  <div>
    <div id="modal-event-guide-add-manually" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" aria-hidden="true" @click="closeModal">
                &times;
              </button>
              <h3 v-if="! addAnother" class="modal-title">
                Add Manually
              </h3>
              <h3 v-else class="modal-title">
                Success!
              </h3>
            </div>

            <div class="modal-body">
              <div v-if="! addAnother" class="panel-body">
                <div style="margin-bottom: 20px;" :class="{
                  'has-error': form.errors?.has('participants.0.name')
                }">
                  <p class="custom-margin">
                    <strong>Institution Name</strong>
                  </p>
                  <input required="required" type="text" class="form-control" v-model="form.participants[0].name"
                    placeholder="Enter institution name" />
                  <p v-if="form.errors?.has('participants.0.name')" class="text-danger" style="margin-top: 5px;">
                    <i class="fa fa-exclamation-circle"></i>
                    {{ form.errors.get('participants.0.name') }}
                  </p>
                </div>
                <div style="margin-bottom: 20px;" :class="{
                  'has-error': form.errors?.has('participants.0.email')
                }">
                  <p class="custom-margin">
                    <strong>Representative Email</strong>
                  </p>
                  <input required="required" type="text" class="form-control" v-model="form.participants[0].email"
                    placeholder="Enter institution email" />
                  <p v-if="form.errors?.has('participants.0.email')" class="text-danger" style="margin-top: 5px;">
                    <i class="fa fa-exclamation-circle"></i>
                    {{ form.errors.get('participants.0.email') }}
                  </p>
                </div>
                <div>
                  <p class="custom-margin">
                    <strong>Location at Event (optional)</strong>
                  </p>
                  <input type="text" class="form-control" v-model="form.participants[0].location"
                    placeholder="Enter institution location" />
                </div>
              </div>
              <div v-else class="panel-body">
                <p>The institution was successfully added.</p>
              </div>
            </div>
            <div class="modal-footer">
              <template v-if="! addAnother">
                <button class="btn btn-secondary" style="background: none;" @click="closeModal">
                  CANCEL
                </button>
                <button class="btn btn-primary" style="margin-left: 10px;" @click="saveAndClose" :disabled="form.participants[0].name === '' || form.participants[0].email === ''">
                  ADD
                </button>
              </template>
              <template v-else>
                <button class="btn btn-secondary" style="color: #662C8F; background: none;" @click="resetForm">
                  ADD ANOTHER
                </button>
                <button class="btn btn-secondary" style="background: none;" @click="closeModal">
                  DONE
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideAddManuallyModal',

  props: {
    fair: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      addAnother: false,
      form: new SparkForm({
        manual_entry: true,
        participants: [
          {
            name: '',
            email: '',
            location: '',
          }
        ]
      }),
      loading: true,
    };
  },

  computed: {
  },

  mounted() {
    $('#modal-event-guide-add-manually').on('hidden.bs.modal', this.resetForm);
  },

  methods: {
    closeModal() {
      $('#modal-event-guide-add-manually').modal('hide');
    },
    saveAndClose() {
      this.form.startProcessing();

      axios.post(`/api/dashboards/fairs/${this.fair.id}/fair-participants`, this.form).then(
        () => {
          this.addAnother = true;
          this.form.finishProcessing();

          Bus.$emit('addEventGuideParticipant');
        }).catch(
          (error) => {
            this.form.setErrors(error.response.data);
          }
        );
    },
    resetForm() {
      this.form = new SparkForm({
        manual_entry: true,
        participants: [
          {
            name: '',
            email: '',
            location: '',
          }
        ]
      });
      this.addAnother = false;
    }
  }
};
</script>

<style scoped>
.full-width-btn {
  width: 100%;
  border-radius: 54px;
}
</style>