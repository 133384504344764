import Pagination from './pagination';
import TeamUserFairStudentList from './user-fair-students/team';
import PersonalUserFairStudentList from './user-fair-students/personal';
import MissedConnectionsList from './missed-connections/missedConnectionsList.vue';

Vue.component('individual-fair', {
  components: { Pagination, TeamUserFairStudentList, PersonalUserFairStudentList, MissedConnectionsList },
  props: {
    user: {
      type: Object,
      default: null
    },
    currentTeam: {
      type: Object,
      default: null
    }
  },
  data() {
    let fairInfo = typeof window.getFairInfo !== 'undefined' ? window.getFairInfo() : null;
    if (fairInfo == null) {
      throw 'getFairInfo is undefined!';
    }

    const fair = fairInfo ? { ...fairInfo } : {};

    // We need to keep track of Start && End date, but we keep the SAME timezone because they should never be different
    const parsedStartData = moment(fair.starts_at).tz(fair.starts_at_timezone);
    const parsedEndData = fair.ends_at ? moment(fair.ends_at).tz(fair.starts_at_timezone) : null;
    const now = new Date();

    var defaultOpenTab = 1;
    if (window.location.hash.substr(1) === 'missed-connections') {
      defaultOpenTab = 2;
    }

    return {
      fair: fair,
      fairinformation: this.generateFairDescription(fair).join(', '),
      fairSummary: fair.summary,
      fairStartDate: parsedStartData.format('MM-DD-YYYY'),
      fairStartHour: parsedStartData.format('h:mm A'),
      fairTimezone: parsedStartData.format('z'),
      fairFullDisplayedDate: this.getFullDisplayedFairDate(parsedStartData, parsedEndData),
      fairHasPast: moment(parsedEndData).isBefore(moment(now).tz(fair.starts_at_timezone)),
      fetchingCredits: false,
      unlocking: false,
      exporting: false,
      downloading: false,
      isEditingGuid: false,
      updatingGuid: false,
      relocking: false,
      availableCredits: null,
      activeTab: defaultOpenTab,
      isAdmin: false,
      exportHistory: [],
      summaryScanning: [],
      fairUnlockedDetail: {},
      form: new SparkForm({
        pin: ''
      }),
      guidForm: new SparkForm({
        guid_id: fair.guid_id
      })
    };
  },
  computed: {
    hasAvailableCredits() {
      return this.availableCredits !== null && this.availableCredits > 0;
    },
    isVirtual() {
      if (!this.fair) {
        return false;
      }
      return this.fair.tags.findIndex((tag) => tag.slug === 'virtual') > -1;
    },
    relockable() {
      return this.fair.unlocked && this.fair.can_relock;
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      if (this.fairHasPast) {
        this.getSummaryScanning(this);
      }
      this.getUnlockedDetail();
      this.getExportHistory();
      this.fetchCreditCount();
      if (typeof isAdmin !== 'undefined') {
        this.isAdmin = true;
      }
    });
  },
  methods: {
    getFullDisplayedFairDate(parsedStartData, parsedEndData) {
      // First let's make sure they have an end date -- We don't want old records to fail
      if (!parsedEndData) {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} ${parsedStartData.format('z')}`;
      }

      // Next! To clean up the date display we will compare the dates and then display as needed, example:
      // If same date: If same date:  01/01/2022 1:00 PM - 4:00 PM CST
      // If not same date: 01/01/2022 1:00 PM - 01/02/2022 4:00 PM CST
      if (parsedStartData.format('MM-DD-YYYY') === parsedEndData.format('MM-DD-YYYY')) {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} - ${parsedEndData.format('h:mm A')} ${parsedStartData.format('z')}`;
      } else {
        return `${parsedStartData.format('MM-DD-YYYY h:mm A')} - ${parsedEndData.format('MM-DD-YYYY h:mm A')} ${parsedStartData.format(
          'z'
        )}`;
      }
    },
    generateFairDescription(fair) {
      const fairLocationInfo = [];

      if (fair && fair.venue) {
        fairLocationInfo.push(fair.venue.name);
        if (fair.venue.address) {
          fairLocationInfo.push(fair.venue.address.line_1);
          fairLocationInfo.push(fair.venue.address.line_2);
          fairLocationInfo.push(fair.venue.address.city);
          fairLocationInfo.push(fair.venue.address.region);
          fairLocationInfo.push(fair.venue.address.postal_code);
        }
      }
      return fairLocationInfo.filter((item) => item && item.length > 0);
    },
    getUnlockedDetail() {
      let url = `/api/fairs/${this.fair.id}/unlock-details`;

      axios.get(url).then(
        (success) => {
          if (!success.data.data) {
            return (this.fairUnlockedDetail = []);
          }
          this.fairUnlockedDetail = success.data.data.map((item) => {
            const unlockDate = moment(item.done_at)
              .tz(item.done_at_timezone)
              .format('MM-DD-YYYY [at] h:mm A');
            if (item.type === 'unlock') {
              return `Unlocked: ${unlockDate} by ${item.name}`;
            } else if (item.type === 'relock') {
              return `Relocked: ${unlockDate} by ${item.name}`;
            }
          });
        },
        (error) => {
          this.errors = error;
        }
      );
    },
    getSummaryScanning() {
      let url = `/api/fairs/${this.fair.id}/scanning-summary`;
      axios.get(url).then(
        (success) => {
          if (!success.data.data) {
            return (this.summaryScanning = []);
          }
          this.summaryScanning = success.data.data.map((item) => {
            const prefix = item.user_scans_count > 1 ? 'Scans' : 'Scan';
            return `${item.name} (${item.user_scans_count} ${prefix})`;
          });
        },
        (error) => {
          this.errors = error;
        }
      );
    },
    getExportHistory() {
      let url = `/api/fairs/${this.fair.id}/export-history`;

      axios.get(url).then(
        (success) => {
          if (!success.data.data) {
            return (this.exportHistory = []);
          }
          this.exportHistory = success.data.data.map((item) => {
            const exportDate = moment(item.exported_at)
              .tz(item.exported_at_timezone)
              .format('MM-DD-YY hh:mm A z');
            item.exportDate = exportDate;
            if (item.type === 'DOWNLOAD') {
              item.message = `${item.name} exported ${item.exported_records} records via download`;
            } else if (item.type === 'MANUAL') {
              item.message = `${item.name} exported ${item.exported_records} records to ${item.export_email}`;
            } else if (item.type === 'SFTP') {
              item.message = `Automatic upload via SFTP to [user] at [host]`;
            }
            return item;
          });
        },
        (error) => {
          this.errors = error;
        }
      );
    },
    fetchCreditCount() {
      this.fetchingCredits = true;
      axios.get('/api/tokens/count').then(
        (success) => {
          this.availableCredits = parseInt(success.data.data.count);
          this.fetchingCredits = false;
        },
        (error) => {
          this.errors = error.data.errors;
          this.fetchingCredits = false;
        }
      );
    },
    verifyPin() {
      Spark.post('/api/teams/' + this.currentTeam.id + '/fairs/' + this.fair.id + '/pin', this.form).then(
        (success) => {
          this.successAlert('Success', 'Fair unlocked!');
          this.handleSuccessfulUnlock(success);
          $('#modal-confirm-pin').modal('hide');
        },
        (error) => {
          this.errorAlert('Error', 'Could not verify Pin. Please check the code and try again.');
        }
      );
    },
    askToUnlockOpenFair() {
      swal(
        {
          title: 'Unlock Fair?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Unlock!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        (isConfirm) => {
          if (!isConfirm) {
            this.errorAlert('Canceled', 'The fair has not been unlocked!');
          } else {
            this.unlocking = true;
            axios
              .post('/api/teams/' + this.currentTeam.id + '/fairs', {
                fair_id: this.fair.id
              })
              .then(
                (success) => {
                  this.unlocking = false;
                  this.successAlert('Unlocked!', 'This fair is now unlocked!');
                  this.handleSuccessfulUnlock(success.data);
                },
                (error) => {
                  this.unlocking = false;
                  this.errorAlert('Canceled', 'The fair has not been unlocked!');
                }
              );
          }
        }
      );
    },
    toggleEditGuid() {
      this.isEditingGuid = !this.isEditingGuid;
    },
    cancelEditGuid() {
      this.guidForm.guid_id = this.fair.guid_id;
    },
    addGuidIdToFair() {
      const url = `/api/teams/${this.currentTeam.id}/fairs/${this.fair.id}/add-guid`;
      this.updatingGuid = true;
      axios.post(url, this.guidForm).then(
        (success) => {
          this.updatingGuid = false;
          this.handleSuccessfulUnlock(success.data);
          this.toggleEditGuid();
        },
        (error) => {
          this.updatingGuid = false;
          this.guidForm.guid_id = this.fair.guid_id;
        }
      );
    },
    askToUnlockPinFair() {
      $('#modal-confirm-pin').modal('show');
    },
    askToUnlockStandardFair() {
      swal(
        {
          title: 'Unlock Fair?',
          text: 'You currently have ' + this.availableCredits + ' credit(s) available',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Unlock!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        (isConfirm) => {
          if (!isConfirm) {
            this.errorAlert('Canceled', 'The fair has not been unlocked!');
          } else {
            this.unlocking = true;
            axios
              .post('/api/teams/' + this.currentTeam.id + '/fairs', {
                fair_id: this.fair.id
              })
              .then(
                (success) => {
                  this.unlocking = false;
                  this.successAlert('Unlocked!', 'This fair is now unlocked!');
                  this.handleSuccessfulUnlock(success.data);
                  this.fetchCreditCount();
                },
                (error) => {
                  this.unlocking = false;
                  this.errorAlert('Canceled', 'The fair has not been unlocked!');
                }
              );
          }
        }
      );
    },
    handleSuccessfulUnlock(response) {
      this.fair = { ...this.fair, ...response.data };
      this.getUnlockedDetail(this);
    },
    askToExport() {
      swal(
        {
          title: 'Export?',
          text: "Are you sure you want to export your institution's list and send it to your email address?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Export!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        (isConfirm) => {
          if (!isConfirm) {
            this.errorAlert('Canceled', 'Your list has not been exported!');
          } else {
            this.exporting = true;
            axios
              .post('/api/exports/fairs/' + this.fair.id + '/user-fair-students', {
                fair_id: this.fair.id,
                timezone: moment.tz.guess(true)
              })
              .then(
                (success) => {
                  this.exporting = false;

                  if (success.data.data.scans_exported) {
                    this.successAlert(success.data.data.title, success.data.data.message);
                    this.getExportHistory(this);
                  } else {
                    this.errorAlert(success.data.data.title, success.data.data.message);
                  }
                },
                (error) => {
                  this.exporting = false;
                  this.errorAlert('Canceled', 'Your list has not been exported!');
                }
              );
          }
        }
      );
    },
    askToDownload() {
      swal(
        {
          title: 'Download?',
          text: "Are you sure you want to download your institution's list?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Download!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        (isConfirm) => {
          if (!isConfirm) {
            this.errorAlert('Canceled', 'Your list has not been exported!');
          } else {
            this.downloading = true;
            axios
              .post(
                '/api/exports/fairs/' + this.fair.id + '/user-fair-students/download',
                {},
                {
                  responseType: 'blob'
                }
              )
              .then((success) => {
                this.downloading = false;
                const contentType = success.headers['content-type'];
                const blob = new Blob([success.data], { type: contentType });
                let fileName = success.headers['content-disposition'].replace('attachment; filename=', '');
                if (fileName.charAt(0) === '"') {
                  fileName = fileName.substr(1);
                }
                if (fileName.charAt(fileName.length - 1) === '"') {
                  fileName = fileName.substr(0, fileName.length - 1);
                }
                FileSaver.saveAs(blob, fileName);
                swal.close();
                this.getExportHistory();
              })
              .catch((error) => {
                this.downloading = false;
                if (error.response.status === 409) {
                  this.errorAlert('Nothing to Export', 'There are no synced scans to export for your institution.');
                } else {
                  this.errorAlert('Export Too Large', 'This export is too large to download directly. Please export to email instead.');
                }
              });
          }
        }
      );
    },
    successAlert(title, message) {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
    },
    askToLock() {
      swal(
        {
          title: 'Relock?',
          text: 'Do you want to relock this fair and return the credit spent to unlock it?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Relock!',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        (isConfirm) => {
          if (!isConfirm) {
            this.errorAlert('Canceled', 'Your fair remains unlocked.');
          } else {
            this.relocking = true;
            axios.delete('/api/teams/' + this.currentTeam.id + '/fairs/' + this.fair.id).then(
              (success) => {
                this.relocking = false;
                this.fair.unlocked = false;
                this.successAlert(
                  'Relocked!',
                  this.fair.name +
                    " has been locked. 1 credit has been returned to your institution's account. You now have " +
                    (this.availableCredits + 1) +
                    ' credits.'
                );
                this.fetchCreditCount();
              },
              (error) => {
                this.relocking = false;
                this.errorAlert('Error', this.fair.name + ' could not be locked because someone at your institution has scanned students.');
              }
            );
          }
        }
      );
    }
  }
});
