<template>
  <div>
    <div class="mdc-dialog">
      <div class="mdc-dialog__container">
        <div
          class="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="my-dialog-title"
          aria-describedby="my-dialog-content"
        >
          <div v-if="title.length > 0" class="mdc-dialog__title-container">
            <h2 id="my-dialog-title" class="mdc-dialog__title">
              {{ title }}
            </h2>
            <button class="btn btn-outline-secondary" @click="dialog.close()">
              <span class="material-icons">close</span>
            </button>
          </div>
          <div id="my-dialog-content" class="mdc-dialog__content">
            {{ text }}
          </div>
          <div class="mdc-dialog__actions">
            <button v-if="buttonSecondaryTitle" class="btn btn-outline-secondary" @click="dialog.close()">
              {{ buttonSecondaryTitle }}
            </button>
            <button
              class="btn"
              :class="{
                'btn-outline-danger': isActionDestructive,
                'btn-primary': !isActionDestructive && buttonSecondaryTitle,
                'btn-primary-only': !isActionDestructive && !buttonSecondaryTitle
              }"
              @click="action"
            >
              {{ buttonPrimaryTitle }}
            </button>
          </div>
        </div>
      </div>
      <div class="mdc-dialog__scrim" />
    </div>
  </div>
</template>

<script>
import { MDCDialog } from '@material/dialog';

export default {
  name: 'CustomDialog',
  components: {},
  data() {
    return {
      dialog: null,
      title: '',
      text: '',
      buttonPrimaryTitle: null,
      buttonSecondaryTitle: null,
      isActionDestructive: false,
      action: () => {}
    };
  },
  computed: {},
  mounted() {
    Bus.$on('open-custom-dialog', this.openDialog);
    Bus.$on('close-custom-dialog', () => {
      this.dialog.close();
    });
    this.dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
  },
  methods: {
    openDialog(title, text, buttonPrimaryTitle, buttonSecondaryTitle, isActionDestructive, action) {
      this.title = title;
      this.text = text;
      this.isActionDestructive = isActionDestructive;
      this.buttonPrimaryTitle = buttonPrimaryTitle;
      this.buttonSecondaryTitle = buttonSecondaryTitle;
      this.action = () => {
        if (action) {
          action();
        }
        this.dialog.close();
      };
      this.dialog.open();
    }
  }
};
</script>

<style scoped>
.mdc-dialog {
  z-index: 9999;
  font-family: 'Inter', sans-serif;
}
.mdc-dialog__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #181b26;
}
.mdc-dialog__content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(17, 24, 39, 0.7);
}
.mdc-dialog__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mdc-dialog__surface {
  max-width: 250px;
}
</style>
