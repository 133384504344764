<template>
  <div class="bar-graph-empty-state">
    <div class="icon-container">
      <slot />
    </div>
    <p>{{ title }}</p>
    <p class="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BarGraphEmptyState',
  props: ['title', 'subtitle']
};
</script>

<style scoped>
.bar-graph-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 250px;
  height: 100%;
  width: 100%;
  background: url('/img/placeholder_chart.png');
}
.icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 50px;
  background: rgba(75, 85, 99, 0.1);
}
.material-icons {
  font-size: 35px;
  color: rgba(0, 0, 0, 0.54);
}

p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(17, 24, 39, 0.7);
}

.subtitle {
  font-size: 12px;
}
</style>
