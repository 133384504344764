module.exports = {
  props: ['user', 'teams', 'team', 'currentTeam', 'fair_participant_claimed', 'fair_participant_failed'],

  /**
   * Load mixins for the component.
   */
  mixins: [require('./../mixins/tab-state')],

  /**
   * The component's data.
   */
  data() {
    return {
      billableType: 'user',
      source_team_users: [],
      destination_team_users: [],
      invitation: invitation
    };
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    var self = this;

    Bus.$on('sparkHashChanged', function(hash, parameters) {
      $(`${this.pushStateSelector} a[href!="#${hash}"][data-toggle="tab"]`)
        .parent('li')
        .removeClass('active');
      var tab = $(`${this.pushStateSelector} a[href="#${hash}"][data-toggle="tab"]`).parent('li');

      tab.addClass('active');

      return true;
    });

    if (this.fair_participant_claimed) {
      this.successAlert('Thank you!', 'Your StriveScan account email address has been confirmed. You can now scan at fairs and share information with attendees about your institution.');
    }

    if (this.fair_participant_failed) {
      this.errorAlert('Oops!', 'It looks like this email address is already registered as a fair participant. Please use a different email address to claim your account.');
    }
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.usePushStateForTabs('.spark-settings-tabs');
    if (this.invitation && this.invitation.id) {
      // Populate source users
      if (this.invitation.current_user) {
        this.source_team_users.push(this.invitation.current_user);
      }
      for (let i = 0; i < this.invitation.source_team_users.length; i++) {
        this.source_team_users.push(this.invitation.source_team_users[i]);
      }

      // populate destination users
      if (this.invitation.destination_invitor) {
        this.destination_team_users.push(this.invitation.destination_invitor);
      }
      for (let i = 0; i < this.invitation.destination_team_users.length; i++) {
        this.destination_team_users.push(this.invitation.destination_team_users[i]);
      }

      // pop up modal
      $('#modal-merge-invitation').modal({ backdrop: 'static', keyboard: false });
    }

    if (window.location.search.indexOf('visit_deleted=true') > -1) {
      this.successAlert('Visit Deleted', 'This visit has been successfully deleted.');
    }
  },

  computed: {
    isVisible() {
      const team = this.user.teams[0];
      return team && team.pivot.role !== 'limited';
    }
  },

  methods: {
    merge_confirmation() {
      $('#modal-merge-confirmation').modal({ backdrop: 'static', keyboard: false });
    },

    delete_confirmation() {
      $('#modal-delete-confirmation').modal({ backdrop: 'static', keyboard: false });
    },

    back_to_merge_invitation() {
      $('#modal-merge-invitation').modal({ backdrop: 'static', keyboard: false });
    },

    merge_teams() {
      axios.delete('/api/teams/' + this.currentTeam.id + '?destination=' + this.invitation.team_id).then(
        (success) => {
          window.location.reload(true);
        },
        (error) => {
          this.errorAlert('Error', 'Oops! Looks like something went wrong. Team not merged.');
        }
      );
    },

    delete_invitation() {
      axios.delete('/api/invitations/' + this.invitation.id).then(
        (success) => {},
        (error) => {
          this.errorAlert('Error', 'Oops! Looks like the invitation could not be deleted!');
        }
      );
    },

    successAlert(title, message) {
      swal(title, message, 'success');
    },

    errorAlert(title, message) {
      swal(title, message, 'error');
    }
  }
};
