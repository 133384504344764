require('chart.js');

Chart.defaults.global.defaultFontColor = '#8C90A1';
Chart.defaults.global.defaultFontFamily = 'Inter';
Chart.defaults.global.tooltips.backgroundColor = 'white';
Chart.defaults.global.tooltips.titleFontColor = '#181B26';
Chart.defaults.global.tooltips.bodyFontColor = '#181B26';
Chart.defaults.global.tooltips.xPadding = 8;
Chart.defaults.global.tooltips.yPadding = 8;
Chart.defaults.global.tooltips.borderColor = 'rgba(0,0,0,.1)';
Chart.defaults.global.tooltips.borderWidth = 1;
