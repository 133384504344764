module.exports = {
  props: ['user', 'team'],

  /**
   * The component's data.
   */
  data() {
    return {
      photo: '',
      form: new SparkForm({})
    };
  },

  methods: {
    /**
     * Update the team's photo.
     */
    async update(e) {
      e.preventDefault();

      if (!this.$refs.photo.files.length) {
        return;
      }

      const file = this.$refs.photo.files[0];
      
      try {
        // Create a temporary image to check dimensions
        const img = new Image();
        const imgPromise = new Promise((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error('Failed to load image'));
        });
        img.src = URL.createObjectURL(file);
        
        // Wait for image to load and check dimensions
        const loadedImg = await imgPromise;
        if (loadedImg.width < 360 || loadedImg.height < 360) {
          URL.revokeObjectURL(img.src);
          throw new Error('Image dimensions must be 360x360 pixels or larger');
        }
        URL.revokeObjectURL(img.src);

        // Import the utility function
        const { ensureMinImageSize } = await import('../../../utils/imageUtils');
        
        // Process the image to ensure minimum size
        const processedImage = await ensureMinImageSize(file);
        
        // Update the photo data which will be used by the cropper
        this.photo = processedImage;
        
        // The rest of the upload process will be handled by the cropper component
        // when it emits its 'cropped' event
      } catch (error) {
        console.error('Image processing error:', error);
        this.form.setErrors({ photo: [error] });
      }
    },
  },

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    },

    /**
     * Get the URL for updating the team photo.
     */
    urlForUpdate() {
      return `/settings/${Spark.pluralTeamString}/${this.team.id}/photo`;
    },

    /**
     * Calculate the style attribute for the photo preview.
     */
    previewStyle() {
      return `background-image: url(${this.team.photo_url})`;
    }
  }
};
