import upcomingVisits from '../components/upcomingVisits';
import pastVisits from '../components/pastVisits';
import visitStartDateFilter from '../filters/date.MM.DD.YY';
import visitStartTimeFilter from '../filters/time.h.mm.A';
import visitCreation from '../components/visitCreation';

Vue.filter('visitStartDate', visitStartDateFilter);
Vue.filter('visitStartTime', visitStartTimeFilter);

Vue.component('upcoming-visits', upcomingVisits);
Vue.component('past-visits', pastVisits);
Vue.component('create-visit', visitCreation);
