import UserFairStudentList from '../userFairStudentList';

export default UserFairStudentList.extend({
  data() {
    return {
      noStudentsMessage: 'Your institution has not scanned any students for this fair yet'
    };
  },
  computed: {
    url() {
      return (
        '/api/v2/teams/' +
        this.currentTeam.id +
        '/fairs/' +
        this.fair.id +
        '/user-fair-students?limit=25&page=' +
        this.pagination.current_page
      );
    }
  }
});
