<template>
  <div :id="id" class="form-group">
    <date-picker @change="onChange" :type="type" :format="format" v-model="innerValue" input-class="form-control">
      <template v-if="type === 'datetime'" v-slot:content="slotProps">
        <div :style="{ display: 'flex' }">
          <calendar-panel :value="slotProps.value" @select="slotProps.emit"></calendar-panel>
          <time-panel show-time-header :value="slotProps.value" format="hh:mm A" @select="slotProps.emit"></time-panel>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const { CalendarPanel, TimePanel } = DatePicker;

export default {
  name: 'DateTimePicker',
  components: {
    DatePicker,
    CalendarPanel,
    TimePanel
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [Date, String, null],
      default: null
    },
    type: {
      type: String,
      default: 'datetime'
    },
    format: {
      type: String,
      default: 'MM/DD/YYYY hh:mm A'
    }
  },
  data() {
    return {
      innerValue: this.parseDate(this.value)
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal !== this.innerValue) {
          this.innerValue = this.parseDate(newVal)
        }
      },
      immediate: true
    },
  },
  methods: {
    parseDate(val) {
      if (!val) return null
      
      // For ISO8601 strings with timezone
      if (typeof val === 'string' && val.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:?\d{2}$/)) {
        // Parse the components directly from the string to preserve exact time
        const [datePart, timePart] = val.split('T')
        const [year, month, day] = datePart.split('-').map(Number)
        const [hours, minutes, seconds] = timePart.substring(0, 8).split(':').map(Number)
        
        // Create date with exact components
        return new Date(year, month - 1, day, hours, minutes, seconds)
      }
      
      return new Date(val)
    },
    
    onChange(date) {
      this.$emit('input', date)
      this.$emit('change', date)
    },
  }
}
</script>

<style>
.mx-datepicker {
  width: 100%;
}
.mx-datepicker .mx-input {
  height: 36px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style> 