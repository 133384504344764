import fairMixin from './../mixins/fair';
import QuillEditor from './QuillEditor.vue'


export default {
  mixins: [fairMixin],

  components: { QuillEditor },

  methods: {
    createFair() {
      var fairTypeValidates = this.checkFairTypeCode();
      if (fairTypeValidates) {
        Spark.post('/api/fairs', this.getFormDataWithValidInstitutionLimit(this.form)).then(
          (success) => {
            this.successAlert('Success', 'The fair was successfully created and is now available for purchase.', () => {
              this.resetForm();
              window.location.reload();
            });
          },
          (error) => {
            window.scrollTo(0, 0);
          }
        );
      }
    },
    resetForm() {
      this.form = new SparkForm({
        name: '',
        starts_at: '',
        starts_at_formatted: '',
        ends_at: '',
        ends_at_formatted: '',
        starts_at_timezone: '',
        is_locked: true,
        pin: null,
        summary: '',
        additional_info: null,
        dashboard_custom_text: null,
        tags: [],
        forms: this.formsData,
        send_student_summary: true,
        connections_on: true,
        event_guide_on: false,
        has_institution_limit: false,
        institution_limit: '',
        venue: {
          name: '',
          address: {
            line_1: '',
            line_2: '',
            city: '',
            region: '',
            postal_code: '',
            country_code: 'US'
          }
        },
        latitude: null,
        longitude: null,
      });

      window.scrollTo(0, 0);
    },
    resetInstitutionLimit(e) {
      if (!this.form.has_institution_limit) {
        this.form.institution_limit = '';
      }
    },
  },

  watch: {
    'form.starts_at': function(newVal, oldVal) {
      this.form.starts_at_formatted = moment.parseZone(newVal).format('MM/DD/YYYY hh:mm A');
    },
    'form.ends_at': function(newVal, oldVal) {
      this.form.ends_at_formatted = moment.parseZone(newVal).format('MM/DD/YYYY hh:mm A');
    }
  }
};