import file from '../mixins/file';
import Loader from './dashboard/Loader.vue';

import InstitutionHeader from './institution/InstitutionHeader.vue';
import EventGuideParticipantDetails from './event-guide/EventGuideParticipantDetails.vue';
import EventGuideFooter from './event-guide/EventGuideFooter.vue';

Vue.component('institution', {
  components: {
    Loader,
    InstitutionHeader,
    EventGuideParticipantDetails,
    EventGuideFooter,
  },
  mixins: [file],
  props: {},
  computed: {},
  mounted() {},
  methods: {},
});
