module.exports = {
  props: ['user', 'team', 'billableType'],

  /**
   * The component's data.
   */
  data() {
    return {
      roles: [],
      invitations: []
    };
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    var self = this;
    this.getInvitations();

    this.getRoles();

    this.$on('updateInvitations', function() {
      self.getInvitations();
    });
  },

  computed: {
    isVisible() {
      const team = this.user.teams[0];
      return team && team.pivot.role !== 'limited';
    }
  },

  methods: {
    /**
     * Get the available team member roles.
     */
    getRoles() {
      axios.get(`/settings/${Spark.pluralTeamString}/roles`).then((response) => {
        this.roles = response.data;
      });
    },
    /**
     * Get all of the invitations for the team.
     */
    getInvitations() {
      axios.get(`/settings/${Spark.pluralTeamString}/${this.team.id}/invitations`).then((response) => {
        this.invitations = response.data;
      });
    }
  }
};
