Vue.component('teams', {
  data() {
    return {
      searchEmail: null,
      searchUsername: null,
      searchName: null,
      teams: null,
      fetching: null,
      fetched: false,
      error: null,
      checkedTeams: [],
      promptingMerge: false
    };
  },
  mounted() {
    var self = this;
    Bus.$on('sparkHashChanged', function(hash, parameters) {
      if (hash == 'teams' && self.teams === null) {
        self.fetchTeams();
      }
    });
  },
  methods: {
    fetchTeams(searchTerm = undefined, searchField = undefined) {
      if (this.fetching === true) {
        return;
      }

      var url = '/api/teams';

      if (searchField && searchTerm) {
        url += '?' + searchField + '=' + searchTerm;
      }

      this.fetching = true;
      this.error = null;
      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.fetched = true;
          this.teams = success.data;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    },
    canCheckTeam(team) {
      return this.checkedTeams.length < 2 || this.checkedTeams.includes(team);
    },
    mergeTeamsPrompt() {
      this.promptingMerge = true;
    },
    mergeTeamsCancel() {
      this.promptingMerge = false;
    },
    mergeTeamInto(teamToDelete, teamToKeep) {
      var url = '/api/teams/' + teamToDelete.id + '?destination=' + teamToKeep.id;
      let _this = this;
      swal(
        {
          title: 'Merge Team #' + teamToDelete.id + ' into Team #' + teamToKeep.id + '?',
          text: 'Team #' + teamToDelete.id + ' will be permanently deleted and its users will be moved to Team #' + teamToKeep.id + '.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Merge',
          closeOnConfirm: false,
          closeOnCancel: false,
          showLoaderOnConfirm: true
        },
        function(isConfirm) {
          if (!isConfirm) {
            swal('Canceled', 'Merge canceled.', 'error');
          } else {
            axios.delete(url).then(
              ($result) => {
                _this.promptingMerge = false;
                _this.fetchTeams();
                swal(
                  'Merged!',
                  'Team #' + teamToDelete.id + ' has been deleted and its users moved to Team #' + teamToKeep.id + '.',
                  'success'
                );
                _this.checkedTeams = [];
              },
              () => {
                swal(
                  'Error!',
                  'There was a problem merging teams. Please try again. If this issue persists, please contact support.',
                  'error'
                );
                _this.checkedTeams = [];
              }
            );
          }
        }
      );
    }
  }
});
