import { MapManager } from '../components/dashboard/maps/mapManager.js';
import Loader from '../components/dashboard/Loader.vue';

export default Vue.extend({
  components: {
    Loader
  },
  props: {
    zoom: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      isLoading: true,
      map: null
    };
  },
  computed: {
    containerRef() {
      // purposefully left empty, needs to be overridden by subclass.
      return '';
    },
    mapType() {
      // purposefully left empty, needs to be overridden by subclass.
      return '';
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.handleScroll();
    Bus.$on('refreshDashboard', this.initMap);
  },
  created() {},
  beforeUnmount() {
    window.removeEventListener('scroll', this.onRefresh);
  },
  methods: {
    initMap() {
      const app = this;
      app.map = new MapManager(app.containerRef, window['currentFair'], app.zoom, app.mapType);
      app.map.init().then(() => {
        app.isLoading = false;
        app.onMapInitComplete();
      });
    },
    handleScroll() {
      const viewportOffset = this.containerRef.getBoundingClientRect();
      // wait to initialize the map until it's close to being visible.
      if (this.map == null && viewportOffset.y < viewportOffset.top + 100) {
        this.initMap();
      }
    },
    onMapInitComplete() {
      // purposefully left empty, needs to be overridden by subclass.
    },
    onRefresh() {
      this.map = null;
      this.isLoading = true;
      this.handleScroll();
    }
  }
});
