<template>
  <div :class="{ 'list-empty-state-container': true, 'list-empty-state-container--no-border': noBorder }">
    <div class="icon-container">
      <slot name="icon" />
    </div>
    <p class="list-empty-state-title">
      {{ title }}
    </p>
    <p class="list-empty-state-subtitle">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'ListEmptyState',
  props: ['title', 'noBorder']
};
</script>

<style scoped>
.list-empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background: white;
  padding: 40px;
}

.list-empty-state-container--no-border {
  border: none !important;
}
.icon-container {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(75, 85, 99, 0.1);
  margin-bottom: 25px;
}

.material-icons {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.6);
}

p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
  color: rgba(17, 24, 39, 0.7);
}

.list-empty-state-subtitle {
  font-size: 0.86em;
  line-height: 16px;
}
</style>
