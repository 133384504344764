<template>
  <div class="component_free-text-school-search-with-type-ahead">
    <div class="input-with-icon">
      <input
        ref="schoolSearchInput"
        type="text"
        class="form-control"
        :placeholder="placeholder"
        :readonly="isSchoolSelected"
        @click.prevent="onTextInputClick"
        @input="searchSchoolsDebounced"
      >
      <i v-show="isSchoolSelected" class="fas fa-times" @click.prevent="clearSelection" />
    </div>
    <ul v-show="showSelectionList" class="items-list">
      <li
        v-for="(item, index) in schoolsList"
        :key="item.id"
        class="items"
        @click.prevent="selectSchool"
        @mousemove="setItemCurrent(index)"
      >
        <strong>{{ item.school }}</strong>
        <br>
        <small> {{ item.city }} - {{ item.state }} </small>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FreeTextSchoolSearchWithTypeAhead',

  props: {
    placeholder: {
      type: String,
      required: true
    },
    initialSelectedSchool: {
      type: Object,
      default: null,
      required: false
    },
    initialSearchText: {
      type: String,
      default: '',
      required: false
    }
  },

  data() {
    return {
      currentIndex: -1,
      selectedSchool: null,
      searchText: '',
      showSelectionList: false,
      schoolsList: [],
      lat: null,
      lng: null,
      // These are the options for the geolocation request in the browser
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      }
    };
  },

  computed: {
    isSchoolSelected() {
      return !!this.selectedSchool;
    }
  },

  watch: {
    showSelectionList: function() {
      if (this.showSelectionList) {
        window.document.addEventListener('click', this.outsideClickEvent);
      } else {
        window.document.removeEventListener('click', this.outsideClickEvent);
      }
    },
    selectedSchool: function() {
      this.showSelectionList = false;
    }
  },

  mounted() {
    if (this.initialSelectedSchool) {
      this.selectedSchool = this.initialSelectedSchool;
      this.$refs.schoolSearchInput.value = `${this.selectedSchool.school} - ${this.selectedSchool.city}, ${this.selectedSchool.state}`;
    } else if (this.initialSearchText) {
      this.searchText = this.initialSearchText;
      this.$refs.schoolSearchInput.value = this.searchText;
    }
    this.validateLocationPermissions();
  },

  methods: {
    validateLocationPermissions() {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state == 'granted') {
          // We call the navigator to get the position as the component is loaded
          navigator.geolocation.getCurrentPosition(this.setCoordinates, console.error, this.geolocationOptions);
        }
      });
    },
    // This function is called when the browser gets the user's location (it can be called multiple times)
    setCoordinates(position) {
      let coordinates = position.coords;
      this.lat = coordinates.latitude;
      this.lng = coordinates.longitude;
      this.searchSchools(this.searchText);
    },
    // This function is called when there is an error in locating the user's position
    outsideClickEvent(event) {
      this.showSelectionList = this.$el.contains(event.target);
    },
    onTextInputClick(event) {
      if (this.isSchoolSelected) {
        this.showSelectionList = false;
      } else {
        this.showSelectionList = !this.showSelectionList;
      }
    },
    clearSelection() {
      this.currentIndex = -1;
      this.selectedSchool = null;
      this.searchText = '';

      this.$refs.schoolSearchInput.value = null;

      this.emitOnChange();
    },
    emitOnChange() {
      if (this.isSchoolSelected) {
        this.$emit('on-change', {
          school: this.selectedSchool,
          text: ''
        });
      } else if (this.searchText !== '') {
        this.$emit('on-change', {
          school: null,
          text: this.searchText
        });
      } else {
        this.$emit('on-change', {
          school: null,
          text: ''
        });
      }
    },
    setItemCurrent(index) {
      this.currentIndex = index;
    },
    selectSchool() {
      this.selectedSchool = this.schoolsList[this.currentIndex];

      this.showSelectionList = false;
      this.searchText = '';

      this.$refs.schoolSearchInput.value = `${this.selectedSchool.school} - ${this.selectedSchool.city}, ${this.selectedSchool.state}`;

      this.emitOnChange();
    },
    searchSchools(searchText) {
      if (this.isSchoolSelected) {
        return;
      }

      this.searchText = searchText;

      this.emitOnChange();

      let query = {
        query: this.searchText,
        exclude_placeholder_schools: 1
      };

      if (this.lat && this.lng) {
        query.lat = this.lat;
        query.lng = this.lng;
      }

      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');

      const url = '/api/schools?' + queryString;

      axios.get(url).then(
        (success) => {
          this.schoolsList = success.data;
        },
        (error) => {
          console.error(error);
          this.schoolsList = [];
        }
      );
    },
    searchSchoolsDebounced: _.debounce(function(event) {
      this.searchSchools(event.target.value);
    }, 400)
  }
};
</script>

<style scoped lang="scss">
.component_free-text-school-search-with-type-ahead {
  position: relative;
}

.input-with-icon {
  position: relative;

  input[type='text'] {
    padding-right: 38px;

    &:disabled,
    &:read-only {
      background: #ffffff;
    }
  }

  i {
    color: #43beef;
    position: absolute;
    right: 13px;
    top: 7px;
    cursor: pointer;
    font-size: 20px;
  }
}

.items-list {
  max-height: 300px;
  width: 100%;
  list-style: none;
  position: absolute;
  left: 0;
  top: 36px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  border: 1px solid #d3e0e9;
  border-radius: 4px;
}

.items-list > li {
  cursor: pointer;
  user-select: none;
  font-weight: bold;

  &:hover {
    background: #642c8f;
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
}

.items-list > li > a {
  font-weight: normal;
  line-height: 1.6;
  color: #636b6f;
  text-decoration: none;

  &:hover {
    color: white !important;
  }
}

.items {
  padding: 3px 20px;
}
</style>
