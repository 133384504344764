<template>
  <div ref="dropdown" class="select-with-type-ahead" @click.prevent="showCheck">
    <span v-if="!selected">{{ optionPlaceholder }}</span>
    <span v-else>{{ selected }}</span>
    <ul v-show="show" class="items-list">
      <div>
        <input
          ref="schoolInput"
          v-model="query"
          type="text"
          class="search-input"
          :placeholder="placeholder"
          @input="inputUpdatedDebounce"
        >
      </div>
      <li v-for="(item, index) in items" :key="item" class="items" @mousedown="chooseItem" @mousemove="setItemActive(index)">
        <SchoolSelectSlot v-if="componentType === 'studentSelect'" :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';
import SchoolSelectSlot from './slots/SchoolSelectSlot.vue';

export default {
  name: 'SelectWithTypeAhead',
  components: {
    SchoolSelectSlot
  },
  partials: {
    default: '<a><span v-html="item | query"></span></a>'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    inputQuery: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search...'
    },
    optionPlaceholder: {
      type: String,
      default: 'Search...'
    },
    debounce: {
      type: Number,
      default: 0
    },
    patternFormat: {
      type: String,
      default: '%string'
    },
    patternValues: {
      type: Array,
      required: true
    },
    updateMode: {
      type: Boolean,
      default: false
    },
    comparisonKeys: {
      type: Array,
      required: true
    },
    model: {
      required: true
    },
    componentType: {
      type: String,
      default: 'studentSelect'
    }
  },
  data: function() {
    return {
      current: -1,
      selected: null,
      query: '',
      show: false,
      templateName: 'default',
      inited: false
    };
  },
  methods: {
    showCheck: function(e) {
      if (!this.show) {
        this.show = true;
      }
    },
    inputUpdated: function(e) {
      this.$dispatch('on-query', this.query);
    },
    chooseItem: function() {
      let selectedString = this.patternFormat;
      this.patternValues.forEach((p) => {
        selectedString = selectedString.replace('%' + p, this.items[this.current][p]);
      });
      this.selected = selectedString;
      this.$emit('on-select', this.items[this.current], this.current);
      this.show = false;
      this.query = null;
    },
    setItemActive: function(index) {
      this.current = index;
    },
    outSideClickEvent: function(event) {
      this.show = this.$el.contains(event.target);
    },
    inputUpdatedDebounce: _.debounce(function(e) {
      this.$emit('on-query', e.target.value);
    }, 500)
  },
  watch: {
    show: function(v, o) {
      if (this.show) {
        window.document.addEventListener('click', this.outSideClickEvent);
        this.inited = true;
      } else {
        window.document.removeEventListener('click', this.outSideClickEvent);
      }
    },
    items: function() {
      if (this.updateMode && this.items.length && !this.inited) {
        Vue.nextTick(() => {
          this.items.forEach((i, index) => {
            if (i[this.comparisonKeys[0]] === this.model[this.comparisonKeys[1]]) {
              this.setItemActive(index);
              this.chooseItem();
            }
          });
        });
      }
    }
  }
};
</script>

<style scoped></style>
