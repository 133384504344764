<template>
  <div>
    <div id="modal-team-request-approve" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              Approve Request to Join Team
            </h3>
          </div>
          <div class="modal-body">
            <p class="subtitle">
              Select
              <a target="_blank" href="https://strivescan.helpscoutdocs.com/article/17-user-permissions-admin-standard-and-limited-users">permission level</a>
              for {{ request.user_creator.name }}:
            </p>
            <div class="radio-container">
              <div v-for="item in rolesList" :key="item.value" class="radio" @click="onRadioContainerClicked">
                <label> <input v-model="role" type="radio" :value="item.value">&nbsp;&nbsp;{{ item.label }} </label>
              </div>
            </div>
            <div class="d-flex custom-btn-containers">
              <button class="btn btn-outline-secondary" :disabled="submitting" data-dismiss="modal" @click="cancel">
                CANCEL
              </button>
              <button class="btn btn-primary" :disabled="disabled || submitting" @click="approve">
                <span v-if="submitting">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                APPROVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamRequestApproveModal',
  props: ['request'],
  data() {
    return {
      submitting: false,
      role: '',
      rolesList: [
        {
          label: 'Standard',
          value: 'member'
        },
        {
          label: 'Admin',
          value: 'owner'
        },
        {
          label: 'Limited',
          value: 'limited'
        }
      ]
    };
  },
  computed: {
    disabled() {
      return this.role === '';
    }
  },
  methods: {
    cancel() {
      this.role = '';
      $('#modal-team-request-approve').modal('hide');
    },
    async approve() {
      this.submitting = true;
      this.$emit('submit', this.role);
    },
    onRadioContainerClicked(e) {
      const target = e.target;
      const radios = document.querySelectorAll('.radio');
      radios.forEach((radio) => {
        radio.classList.remove('selected');
      });
      const el = target.querySelector('input');
      if (el) {
        el.click();
      } else {
        target.parentNode.closest('.radio').classList.add('selected');
      }
    }
  }
};
</script>
