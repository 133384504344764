<template>
  <transition name="fade">
    <div v-if="show && showInternal" class="snackbar" :class="show">
      {{ message }}
      <template v-if="enableUndoButton">
        &nbsp;<button class="undo-button" @click="undoButtonPressed">
          {{ undoText }}
        </button>
      </template>
      <template v-if="enableDismissButton">
        &nbsp;<button class="dismiss-button" @click="dismissButtonPressed">
          Dismiss
        </button>
      </template>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    message: [String, Boolean],
    show: [String, Boolean],
    enableUndoButton: {
      type: Boolean,
      default: false
    },
    undoToken: {
      type: String,
      default: null
    },
    enableDismissButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showInternal: false,
      timer: null
    };
  },
  computed: {
    undoText() {
      return this.$translate('Undo');
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.showInternal = true;
        let vm = this;
        this.timer = setTimeout(function() {
          vm.showInternal = false;
          Bus.$emit('snackbar-closed-nocancel', this.undoToken ? this.undoToken : 'no-token');
        }, 5000);
      }
    }
  },
  methods: {
    undoButtonPressed() {
      clearTimeout(this.timer);
      this.showInternal = false;
      Bus.$emit('snackbar-undo-pressed', this.undoToken ? this.undoToken : 'no-token');
    },
    dismissButtonPressed() {
      clearTimeout(this.timer);
      this.showInternal = false;
      Bus.$emit('snackbar-closed-nocancel', this.undoToken ? this.undoToken : 'no-token');
    },
  }
};
</script>

<style scoped>
.snackbar {
  background: black;
  color: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 14px 16px;
  position: fixed;
  z-index: 99999;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 350px;
}

.snackbar.error {
  background: #f44336;

  .dismiss-button {
    color: #ffffff;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

 {
  opacity: 0;
}

.undo-button {
  color: #ba70eb;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.dismiss-button {
  color: #ba70eb;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
</style>
