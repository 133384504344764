Vue.component('invite-panel', {
  props: {
    user: {
      type: Object,
      default: null
    },
    invitation: {
      type: Object,
      default: null
    }
  },
  methods: {
    acceptInvitation() {
      axios.post(`/settings/invitations/${this.invitation.id}/accept`).then(() => {
        Bus.$emit('updateTeams');
        window.location = '/home';
      });
    },

    declineInvitation() {
      axios.post(`/settings/invitations/${this.invitation.id}/reject`).then(() => {
        window.location.reload(true);
      });
    }
  }
});
