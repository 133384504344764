import Cropper from '../../components/Cropper.vue';

Vue.component('register-photo', {
  props: {
    currentTeam: {
      type: Object,
      default: null
    },
    currentUser: {
      type: Object,
      default: null
    },
  },

  components: { Cropper },

  data() {
    return {
      form: new SparkForm({
        photo: null,
      }),
      photo_url: this.currentTeam.photo_url,
      photo: '',
    };
  },

  created() {
    var self = this;

    Bus.$on('cropped', function(data) {
      self.form.photo = data;
    });
  },

  computed: {
    isEnabled() {
      const team = this.currentUser.teams[0];
      return team && team.pivot.role === 'owner';
    },

    urlForUpdate() {
      return `/api/settings/teams/${this.currentTeam.id}/photo`;
    }
  },
  
  watch: {
    'photo': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== '') {
        $('#modal-photo').modal('show');
      }
    }
  },

  methods: {
    photoupdate(e) {
      e.preventDefault();

      if (!this.$refs.photo.files.length) {
        return;
      }

      const file = this.$refs.photo.files[0];
      
      (async () => {
        try {
          // Create a temporary image to check dimensions
          const img = new Image();
          const imgPromise = new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error('Failed to load image'));
          });
          img.src = URL.createObjectURL(file);
          
          // Wait for image to load and check dimensions
          const loadedImg = await imgPromise;
          if (loadedImg.width < 360 || loadedImg.height < 360) {
            URL.revokeObjectURL(img.src);
            throw new Error('Image dimensions must be 360x360 pixels or larger');
          }
          URL.revokeObjectURL(img.src);

          // Import the utility function
          const { ensureMinImageSize } = await import('../../utils/imageUtils');
          
          // Process the image to ensure minimum size
          const processedImage = await ensureMinImageSize(file);
          
          // Update the photo data which will be used by the cropper
          this.photo = processedImage;
          
        } catch (error) {
          console.error('Image processing error:', error);
          this.form.setErrors({ photo: [error.message] });
        }
      })();
    },
    complete() {
      Spark.post('/register/step/3', this.form)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeModal() {
      $('#modal-photo').modal('hide');
    },
    saveAndClose() {
      var self = this;
      this.form.startProcessing();

      axios.post(this.urlForUpdate, this.form).then(
        () => {
          axios.get(`/teams/${self.currentTeam.id}`).then((response) => {
            self.photo_url = response.data['photo_url'];
          });

          self.photo = '';
          self.form.photo = null;
          self.form.finishProcessing();
          self.closeModal();
        }).catch(
          (error) => {
            self.form.setErrors(error.response.data);
          }
        );
    },
    photodelete() {
      var self = this;

      axios
        .delete(this.urlForUpdate)
        .then((response) => {
          // This is here to fix a weird bug where the photoupdate handler
          // doesn't fire if you select a photo and the field already
          // has a value
          $(function() {
            $("input[name='logo']").val('');
          });

          self.photo = '';
          self.photo_url = undefined;
        })
        .catch(function(response) {
          self.form.setErrors(response.data);
        });
    },
  },
});
