import Template from './index.html';

export default {
  template: Template,
  props: ['student', 'actingAsAdmin'],
  computed: {
    isAdmin() {
      if (!this.actingAsAdmin) {
        return false;
      }

      return this.actingAsAdmin == 1;
    }
  }
};
