import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';
import STATES from '../constants/states';
import {
  stateToQueryWatcher,
  gradeDataWatcher,
  graduationObjectWatcher,
  graduationYearWatcher,
  upcomingFairsWatcher,
  currentClassYearWatcher,
  acceptTosWatcher,
  fairWatcher
} from '../students/watchers.js';
import {
  handleFairRadiusOnChangedMethod,
  validateEmailMethod,
  validateConfirmEmailMethod,
  validateBirthdateMethod,
  validateGradTypeMethod,
  clearFairValidationMethod,
  schoolDataClearMethod,
  getFairsInRegionMethod,
  validateCollegeStartSemesterMethod,
  validateHighSchoolMethod,
  validateHighSchoolCityMethod,
  querySchoolApiMethod,
  selectSchoolMethod,
  setUpcomingFairsMethod
} from '../students/methods.js';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-parent', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      studentType: 'PARENT',
      radiusLimits: [5, 25, 50],
      redirectTo: urlParams.get('redirectTo'),
      loading: false,
      cookie_name: 'parent_registration_form',
      filter_query: null,
      relationshipTypes: [],
      gradeTypes: [],
      fields_to_validate: {
        parent_first_name: ['required'],
        parent_last_name: ['required'],
        parent_email: ['required', 'custom'],
        parent_email_confirmation: ['required', 'custom'],
        phone: ['required'],
        parent_phone_country_code: ['required'],
        parent_relationship: ['required'],
        fair: ['required'],
        first_name: ['required'],
        last_name: ['required'],
        email: ['required', 'custom'],
        email_confirmation: ['required', 'custom'],
        birthdate: ['required', 'custom'],
        high_school: ['required'],
        high_school_city: ['required'],
        current_year_class: ['required'],
        grad_type: ['required', 'custom'],
        college_start_semester: ['custom']
      },
      form: new SparkForm({
        parent_first_name: '',
        parent_last_name: '',
        parent_phone: '',
        parent_phone_country_code: 'US',
        parent_email: '',
        parent_email_confirmation: '',
        parent_relationship: '',
        first_name: '',
        last_name: '',
        fair: '',
        email: '',
        email_confirmation: '',
        text_permission: false,
        text_confirmation: true,
        birthdate: '',
        current_year_class: '',
        graduation_year: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        college_start_semester: '',
        authorize_cis: false,
        accept_tos: false,
        high_school_ceeb: '',
        high_school_country_code: '',
        high_school_region: ''
      }),
      grades: [6, 7, 8, 9, 10, 11, 12],
      gradeSelectors: [],
      start_university: [],
      isSubmitDisabled: false,
      birthdate: '',
      phone: '',
      upcomingFairs: [],
      isHomeSchooled: false,
      schoolItems: [],
      availableCountries: [],
      ok_to_query_state: false,
      state_to_query: '',
      schoolQuery: '',
      selectedSchool: null,
      showHighschoolFields: false,
      student: student,
      months: [
        { value: '01', label: this.$translate('January') },
        { value: '02', label: this.$translate('February') },
        { value: '03', label: this.$translate('March') },
        { value: '04', label: this.$translate('April') },
        { value: '05', label: this.$translate('May') },
        { value: '06', label: this.$translate('June') },
        { value: '07', label: this.$translate('July') },
        { value: '08', label: this.$translate('August') },
        { value: '09', label: this.$translate('September') },
        { value: '10', label: this.$translate('October') },
        { value: '11', label: this.$translate('November') },
        { value: '12', label: this.$translate('December') }
      ],
      grade_data: null,
      graduation_object: null,
      semesters: [],
      remember_info: false,
      twilioFailure: '',
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropdown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      states: STATES
    };
  },

  computed: {
    fairIsVirtual() {
      if (this.form.fair) {
        let fair = this.upcomingFairs.find((item) => {
          return item.id === this.form.fair;
        });
        if (fair) {
          return fair.is_virtual;
        }
      }
      return false;
    }
  },
  watch: {
    state_to_query: stateToQueryWatcher,
    grade_data: gradeDataWatcher,
    graduation_object: graduationObjectWatcher,
    'form.graduation_year': graduationYearWatcher,
    'form.current_year_class': currentClassYearWatcher,
    'form.accept_tos': acceptTosWatcher,
    upcomingFairs: upcomingFairsWatcher,
    'form.fair': fairWatcher
  },

  created() {
    this.availableCountries = countries;
    this.relationshipTypes = relationshipTypes;
  },

  mounted() {
    if (typeof query != 'undefined') {
      this.filter_query = query;
    }
    var rawSemesters = [];

    let currentDate = new Date(),
      currentYear = currentDate.getFullYear();
    let offsetYear = null;

    let borderDate = new Date(`${currentYear}-06-01`);

    var spring = this.$translate('Spring');
    var fall = this.$translate('Fall');

    if (borderDate < currentDate) {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + 1 + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    } else {
      for (var i = 0; i < 6; i++) {
        offsetYear = currentYear + i;
        rawSemesters.push(spring + ' ' + offsetYear);
        rawSemesters.push(fall + ' ' + offsetYear);
      }
    }

    for (var sem in rawSemesters) {
      if (rawSemesters.hasOwnProperty(sem)) {
        this.semesters.push({
          value: rawSemesters[sem],
          text: rawSemesters[sem]
        });
      }
    }

    for (let i = 0; i <= 6; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();
      let offsetYear = null,
        upsetYear = null,
        strRepresentation = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + 1 + i;
        upsetYear = currentYear + 2 + i;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      } else {
        offsetYear = currentYear + i;
        upsetYear = currentYear + i + 1;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      }

      // Create Grade Item
      let classOf = offsetYear + (Math.abs(12 - this.grades[i]) - Math.abs(6 - this.grades[i]));

      let gradeItem = {
        grade: this.grades[i],
        classOf: classOf,
        label: `${classOf}`
      };

      this.gradeSelectors.unshift(gradeItem);

      this.start_university.push(strRepresentation);
    }

    this.gradeSelectors.push({ grade: null, classOf: null, label: this.$translate('Transfer Student') });

    let gradeYears = [
      {
        year: this.getGraduationYear(0),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Senior'), year: this.getGraduationYear(0) })
      },
      {
        year: this.getGraduationYear(1),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Junior'), year: this.getGraduationYear(1) })
      },
      {
        year: this.getGraduationYear(2),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Sophomore'), year: this.getGraduationYear(2) })
      },
      {
        year: this.getGraduationYear(3),
        type: 'year',
        label: this.$translate(':grade - Class of :year', { grade: this.$translate('Freshmen'), year: this.getGraduationYear(3) })
      },
      {
        year: 0,
        type: 'middle_school',
        label: this.$translate('Middle School')
      },
      {
        year: 0,
        type: 'transfer_student',
        label: this.$translate('Transfer Student')
      }
    ];

    this.gradeTypes = gradeYears;

    this.isSubmitDisabled = false;

    this.setStudentFromCookie();

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    this.getLocation();
    this.querySchoolApi('');
  },
  methods: {
    handleFairRadiusOnChanged: handleFairRadiusOnChangedMethod,
    setUpcomingFairs: setUpcomingFairsMethod,
    getFairsInRegion: getFairsInRegionMethod,
    validate_email: validateEmailMethod,
    validate_email_confirmation: validateConfirmEmailMethod,
    validate_parent_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = this.$translate('The email must be a valid email address.');
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { parent_email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('parent_email', error_message);
      return true;
    },
    validate_parent_email_confirmation(value) {
      let error_message = this.$translate('The email confirmation does not match.');
      if (value !== this.form.parent_email) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { parent_email_confirmation: [error_message] });
        return false;
      }
      this.remove_form_field_errors('parent_email_confirmation', error_message);
      return true;
    },
    validate_college_start_semester: validateCollegeStartSemesterMethod,
    validate_birthdate: validateBirthdateMethod,
    validate_high_school: validateHighSchoolMethod,
    validate_high_school_city: validateHighSchoolCityMethod,
    validate_grad_type: validateGradTypeMethod,
    querySchoolApi: querySchoolApiMethod,
    selectSchool: selectSchoolMethod,
    signup() {
      if (this.phone) {
        let intlPhoneInput = $('#intl-phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.parent_phone_country_code = countryData.iso2.toUpperCase();
        this.form.parent_phone = number;
        this.form.phone = number;
      }

      if (this.fairIsVirtual && this.remember_info) {
        let rememberForm = {};
        Object.assign(rememberForm, this.form);
        delete rememberForm['fair'];
        delete rememberForm['accept_tos'];
        delete rememberForm['authorize_cis'];
        this.$cookies.set(this.cookie_name, JSON.stringify(rememberForm), '1d');
      }

      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      this.isSubmitDisabled = true;

      let url = '/parents';
      let method = 'post';

      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      } else if (typeof currentLocale !== 'undefined' && currentLocale !== 'en') {
        url += `/${currentLocale}`;
      }

      Spark[method](url, this.form)
        .then((response) => {
          const url = new URL(response.redirect);
          let searchParams = url.searchParams;

          if (this.redirectTo) {
            searchParams.set('redirectTo', this.redirectTo);
          }

          url.search = searchParams.toString();

          const newUrl = url.toString();
          window.location = newUrl;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropdown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
            this.upcomingFairs = fairs;
          },
          this.geolocationOptions
        );
      }
    },
    setStudentForm(student) {
      //parent
      this.form.parent_first_name = student.parent_first_name;
      this.form.parent_last_name = student.parent_last_name;
      this.form.parent_email = student.parent_email;
      this.form.parent_email_confirmation = student.parent_email;
      this.form.parent_relationship = student.parent_relationship;
      this.form.parent_phone = student.parent_phone;
      this.form.phone = student.parent_phone;
      this.form.parent_phone_country_code = student.parent_phone_country_code;
      // Popular required personal fields
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.form.email_confirmation = student.email;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);
      this.form.text_permission = student.text_permission;

      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      this.form.high_school = student.high_school;
      this.form.high_school_ceeb = student.CEEB;
      this.form.high_school_id = student.school_id;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_country_code = student.high_school_country;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      this.form.college_start_semester = student.college_start_semester;

      this.form.text_confirmation = student.notify_by_text;

      // Set Current Year / Grade Level
      this.form.grad_type = student.grad_type;
      this.form.graduation_year = student.graduation_year;

      // Set Grade and expected college start date.
      for (let i = 0; i < this.gradeTypes.length; i++) {
        if (student.graduation_year == this.gradeTypes[i].year) {
          this.graduation_object = this.gradeTypes[i];
        } else if (this.gradeTypes[i].year === 0 && student.grad_type === this.gradeTypes[i].type) {
          this.graduation_object = this.gradeTypes[i];
        }
      }

      this.form.accept_tos = true;

      // Set Phone number
      this.form.phone = student.phone_number.number;
      this.phone = student.phone_number.formatted_number;
      this.form.phone_country_code = student.phone_number.country_code;
      $(() => {
        iti.setCountry(student.phone_number.country_code);
        iti.setNumber(student.phone_number.number);
      });
    },
    setStudentFromCookie() {
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        for (let key in student) {
          if (key !== 'errors') {
            this.form[key] = student[key];
          }
        }
        //parent
        this.form.parent_first_name = student.parent_first_name;
        this.form.parent_last_name = student.parent_last_name;
        this.form.parent_email = student.parent_email;
        this.form.parent_email_confirmation = student.parent_email;
        this.form.parent_relationship = student.parent_relationship;
        this.form.parent_phone = student.parent_phone;
        this.form.phone = student.parent_phone;
        this.form.parent_phone_country_code = student.parent_phone_country_code;
        // Popular required personal fields
        this.form.first_name = student.first_name;
        this.form.last_name = student.last_name;
        this.form.email = student.email;
        this.form.email_confirmation = student.email;
        this.birthdate = moment(student.birthdate)
          .utc()
          .format('MM/DD/YYYY');
        this.form.birthdate = new Date(this.birthdate);
        this.form.text_permission = student.text_permission;

        // populate student information
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_id = student.high_school_id;
        this.form.high_school_ceeb = student.high_school_ceeb;

        if (student.school_ceeb_code === CEEB_UNLISTED) {
          this.showHighschoolFields = true;
        }

        if (student.school_ceeb_code === CEEB_UNLISTED || student.school_ceeb_code === CEEB_HOMESCHOOLED) {
          this.form.high_school_city = student.high_school_city;
          this.form.high_school_region = student.high_school_region;
          this.form.high_school_country_code = student.high_school_country;
        }

        this.form.accept_tos = true;

        // text permission
        this.form.text_permission = student.text_permission;

        // Set Current Year / Grade Level
        this.form.grad_type = student.grad_type;
        this.form.graduation_year = student.graduation_year;

        // Set Grade and expected college start date.
        for (let i = 0; i < this.gradeTypes.length; i++) {
          if (student.graduation_year == this.gradeTypes[i].year) {
            this.graduation_object = this.gradeTypes[i];
          } else if (this.gradeTypes[i].year === 0 && student.grad_type === this.gradeTypes[i].type) {
            this.graduation_object = this.gradeTypes[i];
          }
        }

        // this is a hack, to ensure we set the college_start_semester after other watches are triggered.
        if (student['college_start_semester']) {
          this.$nextTick().then(() => {
            this.form.college_start_semester = student['college_start_semester'];
          });
        }

        // Set Phone number
        this.form.phone = student.parent_phone;
        this.phone = student.parent_phone;
        this.form.phone_country_code = student.parent_phone_country_code;
        $(() => {
          iti.setCountry(student.parent_phone_country_code);
          iti.setNumber(student.parent_phone);
        });
      }
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=GLOBAL&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFairValidation: clearFairValidationMethod,
    schoolDataClear: schoolDataClearMethod,
    getGraduationYear(addYears = 0) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear(),
        offsetYear = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + addYears + 1;
      } else {
        offsetYear = currentYear + addYears;
      }

      return offsetYear;
    },
    positionError(error) {
      console.error(error);

      this.loading = false;
      this.ok_to_query_state = true;
    }
  }
});
