<template>
  <div>
    <div id="modal-event-guide-edit-institution" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="closeModal">
              &times;
            </button>
            <h3 v-if="isDeleting" class="modal-title">
              Delete Participant
            </h3>
            <h3 v-else-if="isCancelling" class="modal-title">
              <template v-if="!selectedItem.is_canceled">Mark as Canceled</template>
              <template v-else>Remove Cancellation</template>
            </h3>
            <h3 v-else class="modal-title">
              Edit
            </h3>
          </div>
          <div class="modal-body">
            <div class="panel-body">
              <div v-if="isDeleting" style="margin-bottom: 20px;">
                <p>
                  Are you sure you want to delete {{ selectedItem.name }}?<br /><br />
                  This action cannot be undone.
                </p>
              </div>
              <div v-else-if="isCancelling" style="margin-bottom: 20px;">
                <template v-if="!selectedItem.is_canceled">
                  Are you sure you want to mark {{ selectedItem.name }} as canceled? The institution will remain on the event guide with an indicator that they will not be in attendance. This action can be undone if needed.
                </template>
                <template v-else>
                  Are you sure you want to remove the cancellation for {{ selectedItem.name }}?
                </template>
              </div>
              <div v-else style="margin-bottom: 20px;" :class="{
                'has-error': form.errors.has('name')
              }">
                <p class="custom-margin">
                  <strong>Institution Name</strong>
                </p>
                <input required="required" type="text" class="form-control" v-model="form.name"
                  placeholder="Enter institution name" />
                <p v-if="form.errors.has('name')" class="text-danger" style="margin-top: 5px;">
                  <i class="fa fa-exclamation-circle"></i>
                  {{ form.errors.get('name') }}
                </p>
                <p class="custom-margin" style="margin-top: 20px;">
                  <strong>Location at Event (optional)</strong>
                </p>
                <input type="text" class="form-control" v-model="form.location"
                  placeholder="Enter institution location" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="isDeleting">
              <button class="btn btn-thertiary" @click="isDeleting = false">
                Back
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" @click="deleteParticipant">
                Delete
              </button>
            </div>
            <div v-else-if="isCancelling">
              <button class="btn btn-thertiary" @click="isCancelling = false">
                Back
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" @click="toggleCanceled">
                <template v-if="!selectedItem.is_canceled">Mark as Canceled</template>
                <template v-else>Remove Cancellation</template>
              </button>
            </div>
            <div v-else>
              <button class="btn btn-thertiary" style="float: left;" @click="isCancelling = true">
                <template v-if="!selectedItem.is_canceled">Mark as Canceled</template>
                <template v-else>Remove Cancellation</template>
              </button>
              <button class="btn btn-secondary" @click="isDeleting = true">
                Delete
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" @click="saveAndClose">
                Save
              </button>
              <div style="margin: 20px 0; text-align: left; padding: 0 9px;">
                <p class="custom-margin">
                  <strong>Representative Email</strong>
                </p>
                <input disabled="disabled" type="text" class="form-control" :value="selectedItem.email" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideEditInstitutionModal',

  props: {
    fair: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
        email: '',
        location: '',
        updated_at: null,
      })
    }
  },

  data() {
    return {
      form: new SparkForm({
        name: '',
        location: '',
      }),
      loading: true,
      isDeleting: false,
      isCancelling: false,
    };
  },

  computed: {
  },

  mounted() {
    $('#modal-event-guide-edit-institution').on('hidden.bs.modal', this.resetForm);
    $('#modal-event-guide-edit-institution').on('shown.bs.modal', this.setFormData);
  },

  methods: {
    closeModal() {
      $('#modal-event-guide-edit-institution').modal('hide');
    },
    saveAndClose() {
      this.form.startProcessing();

      axios.patch(`/api/dashboards/fair-participants/${this.selectedItem.id}`, this.form).then(
        () => {
          this.form.finishProcessing();
          Bus.$emit('updatedEventGuideParticipant');
          this.closeModal();
        }).catch(
          (error) => {
            this.form.setErrors(error.response.data);
          }
        );
    },
    setFormData() {
      this.form.name = this.selectedItem.name;
      this.form.city = this.selectedItem.city;
      this.form.state = this.selectedItem.state;
      this.form.country = this.selectedItem.country;
      this.form.location = this.selectedItem.location;
    },
    resetForm() {
      this.form = new SparkForm({
        name: '',
        city: '',
        state: '',
        country: '',
        location: '',
      });

      this.isDeleting = false;
      this.isCancelling = false;
    },
    toggleCanceled() {
      axios.post(`/api/dashboards/fair-participants/${this.selectedItem.id}/cancel`).then(
        () => {
          Bus.$emit('canceledEventGuideParticipant');
          this.closeModal();
        });
    },
    deleteParticipant() {
      axios.delete(`/api/dashboards/fair-participants/${this.selectedItem.id}`).then(
        () => {
          Bus.$emit('deletedEventGuideParticipant');
          this.closeModal();
        });
    },
  }
};
</script>

<style scoped>
.full-width-btn {
  width: 100%;
  border-radius: 54px;
}

.btn-secondary {
  border: 1px solid #662C8F;
  color: #662C8F;
  padding: 8px 14px;
  background: none;
}

.btn-thertiary {
  color: #662C8F;
  border: none;
  float: left;
  padding: 8px 14px;
  background: none;
}
</style>