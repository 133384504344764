import moment from 'moment';
import RegistrationsGraph from './dashboard/graphs/RegistrationsGraph.vue';
import ActivityGraph from './dashboard/graphs/ActivityGraph.vue';
import DashboardPieCharts from './dashboard/graphs/DashboardPieCharts.vue';
import Loader from './dashboard/Loader.vue';
import DashboardFairSchoolsTable from './dashboard/schools/table';
import DashboardFairInstitutionsTable from './dashboard/institutions/DashboardFairInstitutionsTable.vue';
import DashboardOverview from './dashboard/overview';
import DashboardCustomText from './dashboard/custom-text';
import AddUsersToDashboardModal from './dashboard/add-users/AddUsersToDashboardModal.vue';
import ViewExportsHistoryModal from './dashboard/exports/ViewExportsHistoryModal.vue';
import SchoolEngagementMap from './dashboard/maps/engagementPerSchool';
import InstitutionsEngagementMap from './dashboard/maps/engagementPerInstitutions';
import file from '../mixins/file';
import CustomDialog from './dashboard/CustomDialog.vue';

Vue.component('individual-fair-dashboard', {
  components: {
    RegistrationsGraph,
    Loader,
    DashboardFairSchoolsTable,
    DashboardPieCharts,
    DashboardOverview,
    DashboardCustomText,
    AddUsersToDashboardModal,
    CustomDialog,
    SchoolEngagementMap,
    ViewExportsHistoryModal,
    InstitutionsEngagementMap,
    ActivityGraph,
    DashboardFairInstitutionsTable
  },
  mixins: [file],
  props: {},
  data() {
    let fairInfo = typeof window.getFairInfo !== 'undefined' ? window.getFairInfo() : null;
    if (fairInfo == null) {
      throw 'getFairInfo is undefined!';
    }
    const fair = fairInfo ? { ...fairInfo } : {};

    return {
      fair: fair,
      timeUntilFairStart: this.generateTimeUntilText(fair),
      fairTitle: this.generateFairTitle(fair),
      fairHasPast: this.getFairHasPast(fair),
      lastRefreshedTime: '--',
      loading: true
    };
  },
  computed: {
    showCustomText() {
      return this.fair.dashboard_custom_text && this.fair.dashboard_custom_text.length > 0;
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.updateLastRefreshedTime();
    });
    let vm = this;
    setTimeout(() => {
      //TODO: https://projekt202.atlassian.net/browse/STRIVBE-797
      vm.loading = false;
    }, 2000);
  },
  methods: {
    generateFairTitle(fair) {
      let tags = [];

      for (let i = 0; i < fair.tags.length && i < 2; i++) {
        tags.push(fair.tags[i].title);
      }

      let resultName = '';
      tags.forEach((tag) => {
        resultName += tag + ' | ';
      });

      resultName += fair.name;
      return resultName;
    },
    getStartDate() {
      return this.getParsedFairDate(this.fair).toDate();
    },
    getParsedFairDate(fair) {
      return moment(fair.starts_at).tz(fair.starts_at_timezone);
    },
    getLocalDate(fair) {
      const now = new Date();
      return moment(now).tz(fair.starts_at_timezone);
    },
    getFairHasPast(fair) {
      const parsedFairDate = this.getParsedFairDate(fair);
      const localDate = this.getLocalDate(fair);
      return moment(parsedFairDate).isBefore(localDate);
    },
    generateTimeUntilText(fair) {
      if (this.getFairHasPast(fair)) {
        return '';
      }

      let dateDifference = moment.duration(this.getParsedFairDate(fair).diff(moment(this.getLocalDate(fair))));

      if (dateDifference.asMinutes() <= 60) {
        return 'Less than 1 hour until start!';
      }

      let result = '';
      result = this.appendTimeDifference(result, 'year', dateDifference.years());
      result = this.appendTimeDifference(result, 'month', dateDifference.months());
      result = this.appendTimeDifference(result, 'day', dateDifference.days());
      result = this.appendTimeDifference(result, 'hour', dateDifference.hours());

      result += ' until start!';
      return result;
    },
    appendTimeDifference(result, timeFrame, amount) {
      if (amount == 0) {
        return result;
      }

      if (result.length > 0) {
        result += ', ';
      }

      result += amount + ' ' + timeFrame + (amount > 1 ? 's' : '');
      return result;
    },
    updateLastRefreshedTime() {
      this.lastRefreshedTime = moment(new Date()).format('MM/DD/YY [at] h:mm A');
    },
    onShareClicked() {
      //TODO: https://projekt202.atlassian.net/browse/STRIVBE-816
      $('#modal-add-users-to-dashboard').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onRefreshClicked() {
      this.loading = true;
      Bus.$emit('refreshDashboard');
      setTimeout(() => {
        this.updateLastRefreshedTime();
        this.loading = false;
      }, 2000);
    },
    onExportClicked() {
      Bus.$emit('reloadExportHistory');
      $('#modal-view-exports-history').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    onManageEventGuideClicked() {
      window.location.href = `/dashboards/${this.fair.id}/manage-event-guide`;    
    }
  }
});