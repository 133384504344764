<template>
  <multiselect
    v-model="selected"
    :searchable="false"
    :options="options"
    :close-on-select="true"
    placeholder="Select Authentication"
    label="two_factor_authentication"
    :allow-empty="false"
    @select="onSelect"
  >
    <template slot="option" slot-scope="props">
      <span class="custom__tag">
        <strong>
          {{ props.option.name }}
        </strong>
        <br>
        <small>{{ props.option.text }}</small>
      </span>
    </template>

    <template slot="singleLabel" slot-scope="props">
      <strong>
        {{ props.option.name }}
      </strong>
      <br>
      <small v-html="props.option.text" />
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'TwoFactorSelect',
  components: { Multiselect },
  props: {
    value: {
      type: String, // Adjust the type according to your needs
      default: '' // Default value if none is provided
    }
  },
  data() {
    return {
      selected: null,
      channel: null,
      options: [
        {
          name: 'Authenticator App',
          text: 'For example: Google Authenticator, Microsoft Authenticator, Authy, Duo Mobile, etc.',
          value: 'totp'
        },
        {
          name: 'SMS',
          text:
            'Less secure. Message & data rates may apply. \nThis phone number will only be used for authentication and will not be shared.',
          value: 'sms'
        },
        { name: 'Email', text: 'This address will only be used for authentication and will not be shared.', value: 'email' }
      ]
    };
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
  methods: {
    onSelect(value) {
      this.selected = value;
      this.channel = value.value;
      this.$emit('input', this.channel);
    }
  }
};
</script>

<style scoped>
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.custom__tag {
  small {
    text-overflow: ellipsis;
    white-space: pre;
  }
}

.multiselect-container .multiselect__content-wrapper {
  z-index: 9999;
}
</style>
