import Vue from 'vue';
import Visit from './visit';
import Pagination from './pagination';
import VisitsNotEnabled from '../components/VisitsNotEnabled.vue';

export default Vue.extend({
  components: {
    Visit,
    VisitsNotEnabled,
    Pagination
  },
  props: ['occurrence', 'order', 'isVisitsEnabled', 'authUserId', 'listHash', 'query'],
  data() {
    return {
      visits: null,
      visitCreators: [],
      createdByFilterSelection: {
        first_name: 'View',
        last_name: 'All',
        id: 'any'
      },
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      fetching: false,
      errors: null,
      searching: false,
      searchInProgress: false,
      isActivePage: false
    };
  },
  computed: {
    hasVisits() {
      return this.visits !== null && this.visits.length > 0;
    },
    visitCreatorsList() {
      const creators = [
        {
          first_name: 'View',
          last_name: 'All',
          id: 'any'
        }
      ];

      if (this.visitCreators.filter((item) => item.id == this.authUserId).length) {
        creators.push({
          first_name: 'you',
          last_name: '',
          id: this.authUserId
        });
      }

      for (const i in this.visitCreators) {
        if (this.visitCreators.hasOwnProperty(i) && this.visitCreators[i].id != this.authUserId) {
          creators.push(this.visitCreators[i]);
        }
      }

      return creators;
    },
    createdByFilterSelectionName() {
      if (this.createdByFilterSelection.id === 'any') {
        return '';
      }

      let name = this.createdByFilterSelection.first_name;

      if (this.createdByFilterSelection.last_name.length) {
        name += ' ' + this.createdByFilterSelection.last_name;
      }

      return name;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchVisits();
    },
    query() {
      if (this.query == '') {
        this.searching = false;
        this.fetchVisits();
      }
    }
  },
  mounted() {
    const app = this;
    Bus.$on('visitCreated', function() {
      app.fetchVisits();
    });

    var self = this;
    Bus.$on('sparkHashChanged', function(hash, parameters) {
      self.isActivePage = hash === self.listHash;
      if (self.isActivePage) {
        if (self.visits === null) {
          self.fetching = true;
          self.error = null;

          self.fetchVisitCreatorsAsync().then(
            (success) => {
              self.visitCreators = success.data.data;
              self.fetchVisits();
            },
            (error) => {
              self.fetching = false;
              self.errors = error.data.errors;
            }
          );
        }
      }
    });
  },
  created() {
    const self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      if (self.isActivePage) {
        self.pagination.current_page = Number(pageNumber);
      }
    });

    Bus.$on('searchCompleted', function() {
      self.searchInProgress = false;
    });
  },
  methods: {
    fetchVisitCreatorsAsync() {
      return axios.get('/api/visits/creators');
    },
    fetchVisits() {
      this.fetching = true;
      this.error = null;
      let url =
        '/api/visits?limit=25' + '&occurrence=' + this.occurrence + '&page=' + this.pagination.current_page + '&order=' + this.order;

      if (this.createdByFilterSelection.id !== 'any') {
        url += '&created_by_user_id=' + this.createdByFilterSelection.id;
      }

      if (this.query) {
        url += '&search=' + this.query;
      }

      axios.get(url).then(
        (success) => {
          this.fetching = false;
          this.visits = success.data.data;
          this.pagination = success.data.meta.pagination;
        },
        (error) => {
          this.fetching = false;
          this.errors = error.data.errors;
        }
      );
    },
    performSearch() {
      if (this.query == '' || this.fetching || this.searchInProgress) {
        return;
      }

      this.searching = true;
      this.searchInProgress = true;
      Bus.$emit('performSearch', this.query);
    },
    clearSearch() {
      this.query = '';
      this.error = null;
    },
    filterCreatedBy(user) {
      this.createdByFilterSelection = user;

      if (this.query !== '') {
        this.$nextTick(() => {
          this.searching = true;
          this.searchInProgress = true;
          Bus.$emit('performSearch', this.query);
        });
      } else {
        this.$nextTick(() => {
          this.fetchVisits();
        });
      }
    }
  }
});
