<template>
  <div>
    <input ref="telInput" type="tel" :value="displayValue" class="form-control custom-input" name="phone" @input="onInput">
  </div>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

export default {
  name: 'IntlTelInput',
  props: ['value'], // { country_code: 'US', number: '', country_number: '1' }
  data() {
    return {
      iti: null,
      utilsLoaded: false
    };
  },
  computed: {
    displayValue() {
      // Only format the number if the utils are loaded and the iti instance is ready
      if (this.utilsLoaded && this.iti) {
        return this.iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL) || '';
      }
      return this.value.number || '';
    }
  },
  watch: {
    'value.number': function(newVal, oldVal) {
      if (this.utilsLoaded && newVal !== oldVal) {
        this.iti.setNumber(`+${this.value.country_number}${newVal}`);
      }
    },
    'value.country_number': function(newVal, oldVal) {
      if (this.utilsLoaded && newVal !== oldVal) {
        this.iti.setNumber(`+${newVal}${this.value.number}`);
      }
    }
  },
  mounted() {
    const input = this.$refs.telInput;
    this.iti = intlTelInput(input, {
      initialCountry: 'auto',
      nationalMode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
    });

    // Check if utils are loaded every 100ms
    const utilsInterval = setInterval(() => {
      if (window.intlTelInputUtils) {
        clearInterval(utilsInterval);
        this.utilsLoaded = true;
        // Set number once utils are loaded
        this.value.number = this.value.number.replace(`+${this.value.country_number}`, '');
        this.iti.setNumber(`+${this.value.country_number}${this.value.number}`);
      }
    }, 100);
  },
  beforeDestroy() {
    if (this.iti) {
      this.iti.destroy();
    }
  },
  methods: {
    onInput() {
      const fullNumber = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
      if (fullNumber) {
        const country_code = this.iti.getSelectedCountryData().iso2.toUpperCase();
        const country_number = this.iti.getSelectedCountryData().dialCode;
        const number = fullNumber.replace(`+${country_number}`, '');
        this.$emit('input', { country_code, number, country_number });
      }
    }
  }
};
</script>

<style>
.intl-tel-input {
  z-index: 99;
}
</style>
