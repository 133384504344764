import dateDisplay from './../../../mixins/dateDisplay';
import Template from './index.html';
import Loader from '../Loader.vue';

export default Vue.extend({
  components: {
    Loader
  },
  mixins: [dateDisplay],
  props: {
    fair: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      registered: this.parseDashboardInfo(null),
      attended: this.parseDashboardInfo(null),
      connections: this.parseDashboardInfo(null),
      scans: this.parseDashboardInfo(null)
    };
  },
  computed: {
    fairDisplayDate() {
      const DATE_FORMAT = 'dddd, MMMM DD, YYYY';
      const TIME_FORMAT = 'hh:mm A';
      const fair = this.fair;
      const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
      const end = moment(fair.ends_at).tz(fair.starts_at_timezone);
      let date = null;
      let time = null;
      if (start.isSame(end, 'day')) {
        date =  start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT);
      } else {
        date =  start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT);
      }

      if (start.isSame(end, 'day')) {
        time =  start.format(TIME_FORMAT) + ' - ' + end.format('hh:mm A z');
      } else {
        time =  start.format(TIME_FORMAT) + ' - ' + end.format(TIME_FORMAT + ' z');
      }

      return {date, time};
    },
    locationName() {
      return this.fair.venue.name;
    },
    streetAddress() {
      return this.fair.venue.address.line_1;
    },
    region() {
      return this.fair.venue.address.city + ', ' + this.fair.venue.address.region + ' ' + this.fair.venue.address.postal_code;
    }
  },
  mounted() {
    const app = this;
    Bus.$on('dashboard-info-fetch-' + this.fair.id + '-completed', function(dashboardInfoData) {
      app.updateDashboardInfo(dashboardInfoData);
    });

    Bus.$on('dashboard-info-fetch-' + this.fair.id + '-loading', function() {
      app.loading = true;
    });

    Bus.$on('dashboard-info-fetch-' + this.fair.id + '-error', function() {
      app.loading = false;
    });
  },
  methods: {
    updateDashboardInfo(dashboardInfoData) {
      this.registered = this.parseDashboardInfo(dashboardInfoData.registered);
      this.attended = this.parseDashboardInfo(dashboardInfoData.attended);
      this.connections = this.parseDashboardInfo(dashboardInfoData.connections);
      this.scans = this.parseDashboardInfo(dashboardInfoData.scans);
      this.loading = false;
    },
    parseDashboardInfo(dashboardInfo) {
      if (!dashboardInfo) {
        const now = new Date();
        const baseYear = now.getFullYear() + now.getMonth() >= 5 ? 1 : 0;
        dashboardInfo = {
          total: 0,
          seniors: 0,
          juniors: 0,
          sophomores: 0,
          freshman: 0,
          parent: 0,
          professional: 0,
          middle_school: 0,
          transfer_student: 0,
          seniors_graduation_year: baseYear,
          juniors_graduation_year: baseYear + 1,
          sophomores_graduation_year: baseYear + 2,
          freshman_graduation_year: baseYear + 3,
          unnamed_total: 1,
          unnamed_graduation_years: {}
        };
      }
      return {
        total: dashboardInfo.total,
        students:
          dashboardInfo.seniors +
          dashboardInfo.juniors +
          dashboardInfo.sophomores +
          dashboardInfo.freshman +
          dashboardInfo.middle_school +
          dashboardInfo.transfer_student +
          dashboardInfo.unnamed_total +
          dashboardInfo.other,
        parents: dashboardInfo.parent,
        professionals: dashboardInfo.professional
      };
    }
  },
  template: Template
});
