import Template from './ConnectionsInstitutionList.html';
import InstitutionCard from './InstitutionCard';
import PaginationVariant from '../PaginationVariant.vue';
import EmptyState from '../ListEmptyState.vue';

export default {
  template: Template,
  components: { InstitutionCard, PaginationVariant, EmptyState },
  props: {
    hashCreatedAt: {
      type: String,
      default: null
    },
    hashStudentId: {
      type: String,
      default: null
    },
    fairId: {
      type: Number,
      default: null
    },
    regions: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      hasLoadedInstitutions: false,
      institutions: null,
      pagination: {
        current_page: 1,
        page_count: 1,
        per_page: 100,
        total_count: 1
      },
      fetching: false,
      errors: null,
      query: '',
      input: '',
      paginationContextKey: 'institutions_list'
    };
  },
  computed: {
    hasInstitutions() {
      return this.institutions.length > 0;
    },
    noInstitutionsMessage() {
      return this.$translate('There are no schools that match your search criteria');
    }
  },
  mounted() {
    this.fetchInstitutions();
    this.get_string_for_locale();
  },
  created() {
    var self = this;
    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = Number(pageNumber);
    });
  },
  methods: {
    get_string_for_locale(field) {
      // Thus far this is the only JS -level localization we're needing. Hardcode for now, but if we end up doing more true localization, we should import an appropriate localization library.
      document.getElementById('institution-search-bar').placeholder = this.$translate('Search by name or location');
    },
    fetchInstitutions() {
      this.fetching = true;
      var url = '/api/connections/' + this.hashCreatedAt + '/' + this.hashStudentId + '/' + this.fairId;
      url += '?page=' + this.pagination.current_page + '&limit=' + this.pagination.per_page + '&query=' + this.query;

      axios
        .get(url)
        .then((success) => {
          this.fetching = false;
          this.hasLoadedInstitutions = true;
          this.pagination = success.data.meta.pagination;
          this.institutions = success.data.data;
        })
        .catch((error) => {
          this.fetching = false;
        });
    },
    triggerSearch() {
      this.query = this.input;
      if (this.pagination.current_page !== 1) {
        this.pagination.current_page = 1;
      } else {
        this.fetchInstitutions();
      }
    }
  },
  watch: {
    input: function() {
      if (this.input === '' && this.query !== '') {
        this.triggerSearch();
      }
    },
    'pagination.current_page': function() {
      this.fetchInstitutions();
    }
  }
};
