module.exports = {
  props: ['user', 'team', 'billableType', 'roles'],

  /**
   * The component's data.
   */
  data() {
    return {
      plans: [],

      form: new SparkForm({
        email: '',
        role: 'owner'
      })
    };
  },

  computed: {
    isEnabled() {
      const team = this.user.teams[0];
      return team && team.pivot.role === 'owner';
    },
    /**
     * Get the active subscription instance.
     */
    activeSubscription() {
      if (!this.billable) {
        return;
      }

      const subscription = _.find(this.billable.subscriptions, (subscription) => subscription.name == 'default');

      if (typeof subscription !== 'undefined') {
        return subscription;
      }
    },

    /**
     * Get the active plan instance.
     */
    activePlan() {
      if (this.activeSubscription) {
        return _.find(this.plans, (plan) => {
          return plan.id == this.activeSubscription.provider_plan;
        });
      }
    },

    /**
     * Check if there's a limit for the number of team members.
     */
    hasTeamMembersLimit() {
      if (!this.activePlan) {
        return false;
      }

      return !!this.activePlan.attributes.teamMembers;
    },

    /**
     *
     * Get the remaining team members in the active plan.
     */
    remainingTeamMembers() {
      return this.activePlan ? this.activePlan.attributes.teamMembers - this.$parent.team.users.length : 0;
    },

    /**
     * Check if the user can invite more team members.
     */
    canInviteMoreTeamMembers() {
      if (!this.hasTeamMembersLimit) {
        return true;
      }
      return this.remainingTeamMembers > 0;
    }
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    this.getPlans();

    var self = this;

    Bus.$on('resetInvitationForm', function() {
      self.resetInvitationForm();
    });
  },

  methods: {
    /**
     * Send a new team invitation or add an existing user to the team.
     */
    send() {
      axios.get(`/settings/${Spark.pluralTeamString}/${this.team.id}/members/${this.form.email}`).then((response) => {
        if (response.data['data'] === null || response.data['data'] === undefined || !response.data['data']) {
          this.sendTeamInvitationForNewUser();
        } else {
          this.addExistingUserToTeam({ user: response.data['data'], data: this.form });
        }
      });
    },

    /**
     * Send a team invitation for a new user.
     */
    sendTeamInvitationForNewUser() {
      Spark.post(`/settings/${Spark.pluralTeamString}/${this.team.id}/invitations`, this.form).then(() => {
        this.resetInvitationForm();
        this.$parent.$emit('updateInvitations');
      });
    },

    /**
     * Reset invitation form.
     */
    resetInvitationForm() {
      this.form.email = '';
      this.form.role = 'owner';
    },

    /**
     * Emit 'addExistingUserToTeam' to team event.
     */
    addExistingUserToTeam(payload) {
      Bus.$emit('addExistingUserToTeam', payload);
    },

    /**
     * Get all the plans defined in the application.
     */
    getPlans() {
      axios.get('/spark/plans').then((response) => {
        this.plans = response.data;
      });
    }
  }
};
