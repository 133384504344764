export function getInterests() {
  return [
    {
      main: window.$translate('Undecided'),
      children: [window.$translate('Undecided')]
    },
    {
      main: window.$translate('Agriculture, Agriculture Operations, and Related Sciences'),
      children: [window.$translate('Agricultural Business and Management'), window.$translate('Animal Sciences')]
    },
    {
      main: window.$translate('Architecture and Related Services'),
      children: [
        window.$translate('Architecture'),
        window.$translate('City/Urban, Community, and Regional Planning'),
        window.$translate('Landscape Architecture')
      ]
    },
    {
      main: window.$translate('Area, Ethnic, Cultural, and Gender Studies'),
      children: [window.$translate('Area Studies'), window.$translate('Ethnic, Cultural Minority, and Gender Studies')]
    },
    {
      main: window.$translate('Biological and Biomedical Sciences'),
      children: [
        window.$translate('Biology/Biological Sciences, General'),
        window.$translate('Biochemistry'),
        window.$translate('Biophysics'),
        window.$translate('Biotechnology'),
        window.$translate('Cell/Cellular Biology and Anatomical Sciences'),
        window.$translate('Ecology'),
        window.$translate('Genetics'),
        window.$translate('Marine Biology and Biological Oceanography'),
        window.$translate('Microbiological Sciences and Immunology'),
        window.$translate('Molecular Biology'),
        window.$translate('Zoology/Animal Biology')
      ]
    },
    {
      main: window.$translate('Business Management, Marketing, and Related Support Services'),
      children: [
        window.$translate('Accounting and Related Services'),
        window.$translate('Actuarial Science'),
        window.$translate('Business Administration, Management and Operations'),
        window.$translate('Finance and Financial Management Services'),
        window.$translate('Hospitality Administration/Management'),
        window.$translate('Human Resources Management and Services'),
        window.$translate('International Business'),
        window.$translate('Management Information Systems and Services'),
        window.$translate('Marketing/Marketing Management, General')
      ]
    },
    {
      main: window.$translate('Communication, Journalism, and Related Programs'),
      children: [
        window.$translate('Advertising'),
        window.$translate('Communication and Media Studies'),
        window.$translate('Digital Communication and Media/Multimedia'),
        window.$translate('Journalism'),
        window.$translate('Public Relations/Image Management'),
        window.$translate('Radio and Television')
      ]
    },
    {
      main: window.$translate('Computer and Information Sciences and Support Services'),
      children: [window.$translate('Computer Science'), window.$translate('Information Science/Studies')]
    },
    {
      main: window.$translate('Education'),
      children: [
        window.$translate('Early Childhood Education and Teaching'),
        window.$translate('Elementary Education and Teaching'),
        window.$translate('Secondary Education and Teaching'),
        window.$translate('Special Education and Teaching')
      ]
    },
    {
      main: window.$translate('Engineering'),
      children: [
        window.$translate('Aerospace, Aeronautical and Astronautical Engineering'),
        window.$translate('Agricultural/Biological Engineering and Bioengineering'),
        window.$translate('Architectural Engineering'),
        window.$translate('Biomedical/Medical Engineering'),
        window.$translate('Chemical Engineering'),
        window.$translate('Civil Engineering'),
        window.$translate('Computer Engineering, General'),
        window.$translate('Electrical, Electronics and Communications Engineering'),
        window.$translate('Engineering Physics'),
        window.$translate('Engineering Science'),
        window.$translate('Environmental/Environmental Health Engineering'),
        window.$translate('Geological/Geophysical Engineering'),
        window.$translate('Industrial Engineering'),
        window.$translate('Materials Engineering'),
        window.$translate('Mechanical Engineering'),
        window.$translate('Mining and Mineral Engineering'),
        window.$translate('Nuclear Engineering'),
        window.$translate('Petroleum Engineering'),
        window.$translate('Materials Science'),
        window.$translate('Polymer/Plastics Engineering')
      ]
    },
    {
      main: window.$translate('Engineering Technologies/Technicians'),
      children: [
        window.$translate('Computer Engineering Technology/Technician'),
        window.$translate('Drafting/Design Engineering Technologies/Technician'),
        window.$translate('Telecommunications Technology/Technician')
      ]
    },
    {
      main: window.$translate('English Language and Literature/Letters'),
      children: [window.$translate('Creative Writing')]
    },
    {
      main: window.$translate('Family and Consumer Sciences/Human Sciences'),
      children: [window.$translate('Foods, Nutrition and Wellness Studies, General')]
    },
    {
      main: window.$translate('Foreign Languages, Literatures, and Linguistics'),
      children: [
        window.$translate('Classics and Classical Languages, Literatures, and Linguistics'),
        window.$translate('Comparative Literature'),
        window.$translate('East Asian Languages, Literatures, and Linguistics'),
        window.$translate('French Language and Literature'),
        window.$translate('German Language and Literature'),
        window.$translate('Linguistics'),
        window.$translate('Russian Language and Literature'),
        window.$translate('Spanish Language and Literature')
      ]
    },
    {
      main: window.$translate('Health Professions and Related Clinical Services'),
      children: [
        window.$translate('Allied Health Diagnostic, Intervention, and Treatment Professions'),
        window.$translate('Athletic Training/Trainer'),
        window.$translate('Clinical/Medical Laboratory Science and Allied Professions'),
        window.$translate('Communication Disorders Sciences and Services'),
        window.$translate('Dental Hygiene/Hygienist'),
        window.$translate('Dietetics and Clinical Nutrition Services'),
        window.$translate('Nursing'),
        window.$translate('Occupational Therapy/Therapist'),
        window.$translate('Physical Therapy/Therapist'),
        window.$translate('Pre-Dentistry Studies'),
        window.$translate('Pre-Medicine/Pre-Medical Studies'),
        window.$translate('Pre-Pharmacy Studies'),
        window.$translate('Pre-Veterinary Studies')
      ]
    },
    {
      main: window.$translate('History'),
      children: [window.$translate('History')]
    },
    {
      main: window.$translate('Legal Professions and Studies'),
      children: [window.$translate('Pre-Law Studies')]
    },
    {
      main: window.$translate('Liberal Arts and Sciences, General Studies, and Humanities'),
      children: [window.$translate('Humanities/Humanistic Studies'), window.$translate('Liberal Arts and Sciences/Liberal Studies')]
    },
    {
      main: window.$translate('Mathematics and Statistics'),
      children: [window.$translate('Applied Mathematics'), window.$translate('Mathematics'), window.$translate('Statistics')]
    },
    {
      main: window.$translate('Multi/Interdisciplinary Studies'),
      children: [
        window.$translate('Biological and Physical Sciences'),
        window.$translate('International/Global Studies'),
        window.$translate('Mathematics and Computer Science'),
        window.$translate('Neuroscience')
      ]
    },
    {
      main: window.$translate('Natural Resources and Conservation'),
      children: [
        window.$translate('Environmental Science'),
        window.$translate('Environmental Studies'),
        window.$translate('Fishing and Fisheries Sciences and Management'),
        window.$translate('Forestry'),
        window.$translate('Wildlife and Wildlands Science and Management')
      ]
    },
    {
      main: window.$translate('Parks, Recreation, Leisure, and Fitness Studies'),
      children: [
        window.$translate('Parks, Recreation, and Leisure Facilities Management'),
        window.$translate('Sport and Fitness Administration/Management')
      ]
    },
    {
      main: window.$translate('Personal and Culinary Services'),
      children: [window.$translate('Personal and Culinary Services')]
    },
    {
      main: window.$translate('Philosophy and Religious Studies'),
      children: [window.$translate('Philosophy'), window.$translate('Religion/Religious Studies')]
    },
    {
      main: window.$translate('Physical Sciences'),
      children: [
        window.$translate('Astronomy'),
        window.$translate('Astrophysics'),
        window.$translate('Atmospheric Sciences and Meteorology'),
        window.$translate('Chemistry'),
        window.$translate('Geological and Earth Sciences/Geosciences'),
        window.$translate('Physics')
      ]
    },
    {
      main: window.$translate('Psychology'),
      children: [window.$translate('Psychology')]
    },
    {
      main: window.$translate('Public Administration and Social Service Professions'),
      children: [
        window.$translate('Human Services, General'),
        window.$translate('Public Administration'),
        window.$translate('Public Policy Analysis'),
        window.$translate('Social Work')
      ]
    },
    {
      main: window.$translate('Security and Protective Services'),
      children: [window.$translate('Criminal Justice/Law Enforcement Administration'), window.$translate('Forensic Science and Technology')]
    },
    {
      main: window.$translate('Social Sciences'),
      children: [
        window.$translate('Anthropology'),
        window.$translate('Archaeology'),
        window.$translate('Economics'),
        window.$translate('Geography'),
        window.$translate('International Relations and Affairs'),
        window.$translate('Political Science and Government'),
        window.$translate('Sociology')
      ]
    },
    {
      main: window.$translate('Theology and Religious Vocations'),
      children: [window.$translate('Theology and Religious Vocations')]
    },
    {
      main: window.$translate('Visual and Performing Arts'),
      children: [
        window.$translate('Art History, Criticism, and Conservation'),
        window.$translate('Dance'),
        window.$translate('Drama and Dramatics, Theatre Arts, General'),
        window.$translate('Fashion/Apparel Design'),
        window.$translate('Film/Video and Photographic Arts'),
        window.$translate('Fine and Studio Art'),
        window.$translate('Graphic Design'),
        window.$translate('Interior Design'),
        window.$translate('Music'),
        window.$translate('Technical Theatre/Theatre Design and Technology')
      ]
    },
    {
      main: window.$translate('Other'),
      children: [window.$translate('Other')]
    }
  ];
}
