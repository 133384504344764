import Vue from 'vue';
import student from './student';
import Pagination from './pagination';

export default Vue.extend({
  components: { student, Pagination },
  props: {
    occurrence: {
      type: String,
      default: null
    },
    order: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      students: null,
      pagination: {
        current_page: 1,
        total_pages: 1
      },
      fetching: false,
      errors: null,
      searching: false,
      searchInProgress: false
    };
  },
  computed: {
    hasStudents() {
      return this.students !== null && this.students.length > 0;
    }
  },
  watch: {
    'pagination.current_page': function() {
      this.fetchStudents();
    },
    query() {
      if (this.query == '') {
        this.searching = false;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.students === null) {
        this.fetchStudents();
      }
    });
  },
  created() {
    var self = this;

    Bus.$on('currentPageUpdated', function(pageNumber) {
      self.pagination.current_page = Number(pageNumber);
    });

    Bus.$on('searchCompleted', function() {
      self.searchInProgress = false;
    });
  },
  methods: {
    fetchStudents() {
      this.fetching = true;
      this.error = null;
      axios
        .get('/api/students?limit=25&occurrence=' + this.occurrence + '&page=' + this.pagination.current_page + '&order=' + this.order)
        .then(
          (success) => {
            this.fetching = false;
            this.students = success.data.data;
            this.pagination = success.data.meta.pagination;
          },
          (error) => {
            this.fetching = false;
            this.errors = error.data.errors;
          }
        );
    },
    performSearch() {
      if (this.query == '' || this.fetching || this.searchInProgress) {
        return;
      }

      this.searching = true;
      this.searchInProgress = true;
      Bus.$emit('performSearch', this.query);
    },
    clearSearch() {
      this.query = '';
      this.error = null;
    }
  }
});
