<template>
  <div>
    <div id="modal-2fa" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="closeVerificationModal">
              &times;
            </button>
            <h4 class="modal-title">
              ENTER VERIFICATION CODE
            </h4>
          </div>

          <div class="modal-body">
            <div v-if="form" class="panel-body">
              <div class="row">
                <div class="col-xs-12 col-sm-4">
                  <div class="form-group" :class="{ 'has-error': form.errors.has('code') }">
                    <label class="control-label">CODE:</label>
                    <input v-model="form.code" type="text" class="form-control" name="code" minlength="6" maxlength="6">
                    <span v-show="form.errors.has('code')" class="help-block"> @{{ form.errors.get('code') }} </span>
                  </div>
                </div>
              </div>
              <button class="btn btn-lg btn-primary" @click="sendPin(true)">
                <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin" />Loading </span>

                <span v-else> Validate Code </span>
              </button>
            </div>
          </div>

          <!-- Modal Actions -->
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeVerificationModal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: new SparkForm({
        code: '',
        two_factor_channel: 'email',
        two_factor_email: '',
        uses_two_factor_auth: false,
        two_factor_phone: {
          number: '',
          country_code: 'US'
        }
      })
    };
  },
  mounted() {
    Bus.$on('check-two-factor', (user) => {
      this.checkTwoFactor(user);
    });
  },
  methods: {
    closeVerificationModal() {
      $('#modal-2fa').modal('hide');
      this.errorAlert('Authentication method not verified.');
    },
    successAlert(title, message = '') {
      swal(title, message, 'success');
    },
    errorAlert(title, message) {
      swal(title, message, 'error');
      this.form.errors[0] = message;
    },
    checkTwoFactor(user) {
      this.form.two_factor_channel = user.two_factor_channel;
      this.form.uses_two_factor_auth = user.uses_two_factor_auth;
      if (this.form.two_factor_channel === 'sms') {
        this.form.two_factor_phone.number = user.two_factor_recipient;
        iti.setNumber(user.two_factor_recipient);
        let countryData = iti.getSelectedCountryData();
        this.form.two_factor_phone.country_code = countryData.iso2.toUpperCase();
      } else {
        this.form.two_factor_email = user.two_factor_recipient;
      }
      this.sendPin();
    },
    sendPin(verify = false) {
      this.form.busy = true;
      axios
        .post('/api/auth/two-factor', this.form)
        .then((response) => {
          this.form.busy = false;
          $('#modal-2fa').modal('hide');
          this.successAlert('Account verified');
          this.form.code = '';
          this.$emit('verified');
        })
        .catch((error) => {
          this.form.busy = false;
          this.isSubmitDisabled = false;
          if (error.response && error.response.data && error.response.data.errors) {
            if (error.response.data.errors.status === '403') {
              if (!verify) {
                $('#modal-2fa').modal({ backdrop: 'static', keyboard: false });
              } else {
                this.errorAlert('Incorrect Code', 'Please check the code and try again.');
                this.$emit('failed');
              }
            } else {
              this.errorAlert('Error', error.response.data.errors.detail);
              this.$emit('failed');
            }
          }
        });
    }
  }
};
</script>
