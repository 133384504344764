<template>
  <div>
    <loader v-if="fetching" />
    <canvas v-show="data.length > 0 && !fetching" id="RegistrationsGraph" width="400" height="250" />
    <bar-graph-empty-state
      v-if="data.length === 0 && !fetching"
      title="No one has registered yet."
      subtitle="Data will display after the first person registers."
    >
      <span class="material-icons">alarm</span>
    </bar-graph-empty-state>
  </div>
</template>

<script>
import Loader from '../Loader.vue';
import BarGraphEmptyState from './BarGraphEmptyState.vue';
import { Api } from '../../../dashboard/dashboard-api';

const options = {
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false
        },
        type: 'time',
        ticks: {
          stepSize: 1,
          min: 0,
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        },
        time: {
          isoWeekday: true,
          displayFormats: {
            week: 'MMM DD'
          },
          tooltipFormat: 'MMM DD, YYYY',
          unit: 'week'
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          borderDash: [8, 4],
          color: '#E5E7EB'
        }
      }
    ]
  },
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 10,
      fontSize: 14,
      fontColor: '#181B26'
    },
    onClick: (e) => e.stopPropagation()
  },
  title: {
    display: false
  },
  tooltips: {
    callbacks: {
      title: function(tooltipItem, dataset) {
        let result = tooltipItem[0].xLabel;
        if (tooltipItem[0].datasetIndex === 1) {
          const forcedStartOfWeek = moment(result)
            .startOf('isoweek')
            .format('MMM DD');
          result = 'Week of ' + forcedStartOfWeek;
        }
        return result;
      }
    }
  }
};

export default {
  name: 'RegistrationsGraph',
  components: { BarGraphEmptyState, Loader },
  data() {
    return {
      fetching: true,
      data: [],
      chart: null
    };
  },
  mounted() {
    this.fetchGraphData();
    Bus.$on('refreshDashboard', this.fetchGraphData);
  },
  methods: {
    fetchGraphData() {
      this.fetching = true;
      Api.fairs(window['fair_id'])
        .dashboard.registered(1440)
        .then(
          (response) => {
            this.fetching = false;
            this.data = response;
            const ctx = document.getElementById('RegistrationsGraph');
            let daily = {};
            let labels = {};
            let weekly = [];
            let weeklySum = 0;
            let lastDayMoment = null;
            this.data.forEach((dayData) => {
              const currentMoment = moment(dayData.starts_at);
              const day = moment(dayData.starts_at)
                .startOf('day')
                .toDate();

              daily[day] = dayData.count;

              if (lastDayMoment == null || currentMoment.isAfter(lastDayMoment)) {
                lastDayMoment = currentMoment;
              }

              const monday = moment(dayData.starts_at)
                .startOf('isoweek')
                .startOf('day')
                .toDate();
              if (monday in labels) {
                labels[monday] += dayData.count;
              } else {
                labels[monday] = dayData.count;
              }
            });

            if (lastDayMoment) {
              const monday = moment(lastDayMoment)
                .startOf('isoweek')
                .startOf('day')
                .toDate();
              const lastWeekValue = labels[monday];
              delete labels[monday];

              const lastDay = moment(lastDayMoment)
                .startOf('isoweek')
                .startOf('day')
                .toDate();
              labels[lastDay] = lastWeekValue;
            }
            daily = Object.entries(daily).map(([key, value]) => {
              return { x: new Date(key), y: value };
            });
            Object.entries(labels).forEach(([key, value]) => {
              weeklySum += value;
              weekly.push({ x: new Date(key), y: weeklySum });
            });

            this.chart = new Chart(ctx, {
              type: 'bar',
              data: {
                datasets: [
                  {
                    label: 'Daily Registrations',
                    data: daily,
                    backgroundColor: '#027FBD',
                    order: 2
                  },
                  {
                    label: 'Total Registrations',
                    data: weekly,
                    type: 'line',
                    fill: false,
                    cubicInterpolationMode: 'monotone',
                    backgroundColor: '#181B26',
                    borderColor: '#181B26',
                    order: 1
                  }
                ]
              },
              options
            });
          },
          () => {
            this.fetching = false;
          }
        );
    }
  }
};
</script>
