import moment from 'moment';

export default {
  methods: {
    fairDateDisplay(fair) {
      const DATE_FORMAT = 'dddd, MMMM DD, YYYY';
      const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
      return start.format(DATE_FORMAT);
    },
    fairTimeDisplay(fair) {
      const DATE_FORMAT = 'hh:mm A';
      const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
      if (this.fairHasValidEndDate(fair)) {
        const end = moment(fair.ends_at).tz(fair.starts_at_timezone);
        if (start.isSame(end, 'day')) {
          return start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT + ' z');
        } else {
          return start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT + ' z');
        }
      }
      return start.format(DATE_FORMAT + ' z');
    },
    fairDateTimeDisplay(fair) {
      const DATE_FORMAT = 'MMMM DD, YYYY hh:mm A';
      const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
      if (this.fairHasValidEndDate(fair)) {
        const end = moment(fair.ends_at).tz(fair.starts_at_timezone);

        if (start.isSame(end, 'day')) {
          return start.format(DATE_FORMAT) + ' - ' + end.format('hh:mm A z');
        } else {
          return start.format(DATE_FORMAT) + ' - ' + end.format(DATE_FORMAT + ' z');
        }
      }
      return start.format(DATE_FORMAT + ' z');
    },
    fairHasValidEndDate(fair) {
      if (fair && fair.ends_at) {
        const start = moment(fair.starts_at).tz(fair.starts_at_timezone);
        const end = moment(fair.ends_at).tz(fair.starts_at_timezone);
        return end.isValid() && end.isAfter(start);
      }

      return false;
    }
  }
};
