<template>
  <a href="#0">
    <div>
      <strong>{{ item.school }}</strong>
      <br>
      <small>{{ item.city }} - {{ item.state }}</small>
    </div>
  </a>
</template>

<script>
export default {
  name: 'SchoolSelectSlot',
  props: {
    item: {
      type: Object
    }
  }
};
</script>
