Vue.component('update-student-contact-information', {
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      form: $.extend(
        true,
        new SparkForm({
          title: '',
          contact_email: '',
          phone: '',
          phone_country_code: '',
          facebook: '',
          twitter: '',
          youtube: '',
          instagram: '',
          snapchat: ''
        }),
        Spark.forms.updateContactInformation
      )
    };
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.form.title = this.user.title;
    this.form.contact_email = this.user.contact_email;
    this.form.phone = this.user.phone;
    this.form.phone_country_code = this.user.phone_country_code;
    this.form.facebook = this.user.facebook;
    this.form.twitter = this.user.twitter;
    this.form.youtube = this.user.youtube;
    this.form.instagram = this.user.instagram;
    this.form.snapchat = this.user.snapchat;

    $(() => {
      itiUserProfile.setCountry(this.form.phone_country_code);
      itiUserProfile.setNumber(this.form.phone);
    });
  },

  methods: {
    update() {
      if (this.form.phone) {
        let intlPhoneInput = $('#update-contact-phone');
        let number = intlPhoneInput.val(),
          countryData = itiUserProfile.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
      }
      Spark.put('/settings/student-contact', this.form).then(() => {
        Bus.$emit('updateUser');
      });
    }
  }
});
