<template>
  <div class="participant-data" :style="{
    'max-height': student !== null ? '80vh' : 'auto',
  }">
    <div v-if="student !== null" class="participant-header">
      <div class="participant-image" :style="{
        backgroundImage: `url(${participant.image_url})`,
      }" alt="avatar"></div>
      <div class="participant-headline">
        <h2>{{ participant.name }}</h2>
        <div class="participant-subhead">
          <div class="participant-address">
            {{ participant.city ? participant.city + ', ' : '' }}{{ participant.state }}<br />
            {{ participant.location }}
          </div>
          <div class="participant-favorite">
            <template v-if="!participant.is_favorite">
              <button @click="toggleFavorite(participant.id)" class="button-add">
                {{ $translate('Add to List') }}
                <md-icon slot="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.41137 1.87716C6.8228 1.90573 8.38994 2.57716 10.0028 4.10573C11.6128 2.58145 13.1785 1.91145 14.5885 1.8843C16.0742 1.85573 17.3285 2.54145 18.2114 3.57002C19.9514 5.59859 20.3114 9.07002 18.1285 11.2514L18.1271 11.2529L12.0599 17.2643C10.9028 18.41 9.1028 18.41 7.94565 17.2643L1.87994 11.2529C-0.31006 9.06145 0.0456541 5.59002 1.78565 3.55859C2.66708 2.53002 3.92137 1.8443 5.40994 1.87573L5.41137 1.87716Z"
                      fill="white" />
                  </svg>
                </md-icon>
              </button>
            </template>
            <template v-else>
              <button @click="toggleFavorite(participant.id)" class="button-remove">
                {{ $translate('Remove from List') }}
                <md-icon slot="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.49369 2.25247C8.18741 2.28676 10.068 3.09247 12.0034 4.92676C13.9354 3.09761 15.8143 2.29361 17.5063 2.26104C19.2891 2.22676 20.7943 3.04961 21.8537 4.2839C23.9417 6.71818 24.3737 10.8839 21.7543 13.5016L21.7526 13.5033L14.472 20.717C13.0834 22.0919 10.9234 22.0919 9.53483 20.717L2.25598 13.5033C-0.372024 10.8736 0.0548337 6.7079 2.14283 4.27018C3.20055 3.0359 4.70569 2.21304 6.49198 2.25076L6.49369 2.25247Z"
                      fill="#AA0070" />
                  </svg>
                </md-icon>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="participant-body" :class="{
      'participant-body--no-student': student === null,
    }">
      <template v-if="participant.college_scorecard_dataset">
        <div class="participant-key">
          <h3>{{ $translate('Key Stats') }}</h3>
          <div class="participant-specialties">
            <ul>
              <li v-for="specialty in specialties"
                v-if="getNestedProperty(participant.college_scorecard_dataset.data, specialty.value) === 'Yes'">
                {{ specialty.label }}
              </li>
            </ul>
          </div>
          <div class="participant-stats">
            <div v-for="stat in stats" v-if="
              getNestedProperty(participant.college_scorecard_dataset.data, stat.value) !== undefined ||
              (
                stat.hasOwnProperty('fallback') &&
                getNestedProperty(participant.college_scorecard_dataset.data, stat.fallback) === 'Yes'
              )" class="participant-stat">
              <h4>{{ stat.label }}</h4>
              <div>{{ 
                getNestedProperty(participant.college_scorecard_dataset.data, stat.value) === null ? 
                $translate('Not Available') : 
                (stat.modifier ? 
                  stat.modifier(getNestedProperty(participant.college_scorecard_dataset.data, stat.value), 
                    stat.hasOwnProperty('fallback') ? 
                    getNestedProperty(participant.college_scorecard_dataset.data, stat.fallback) : null) :
                  getNestedProperty(participant.college_scorecard_dataset.data, stat.value)) 
              }}</div>
            </div>
          </div>
        </div>
        <div class="participant-links">
          <a :href="participant.map_url" target="_blank">
            {{ $translate('Map') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V5C4.16667 4.54167 4.54167 4.16667 5 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10.8333C17.5 10.375 17.125 10 16.6667 10C16.2083 10 15.8333 10.375 15.8333 10.8333V15C15.8333 15.4583 15.4583 15.8333 15 15.8333ZM11.6667 3.33333C11.6667 3.79167 12.0417 4.16667 12.5 4.16667H14.6583L7.05 11.775C6.725 12.1 6.725 12.625 7.05 12.95C7.375 13.275 7.9 13.275 8.225 12.95L15.8333 5.34167V7.5C15.8333 7.95833 16.2083 8.33333 16.6667 8.33333C17.125 8.33333 17.5 7.95833 17.5 7.5V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H12.5C12.0417 2.5 11.6667 2.875 11.6667 3.33333Z"
                fill="#562782" />
            </svg>
          </a>
          <a v-if="participant.url" :href="getUrl(participant.url)" target="_blank">
            {{ $translate('Website') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V5C4.16667 4.54167 4.54167 4.16667 5 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10.8333C17.5 10.375 17.125 10 16.6667 10C16.2083 10 15.8333 10.375 15.8333 10.8333V15C15.8333 15.4583 15.4583 15.8333 15 15.8333ZM11.6667 3.33333C11.6667 3.79167 12.0417 4.16667 12.5 4.16667H14.6583L7.05 11.775C6.725 12.1 6.725 12.625 7.05 12.95C7.375 13.275 7.9 13.275 8.225 12.95L15.8333 5.34167V7.5C15.8333 7.95833 16.2083 8.33333 16.6667 8.33333C17.125 8.33333 17.5 7.95833 17.5 7.5V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H12.5C12.0417 2.5 11.6667 2.875 11.6667 3.33333Z"
                fill="#562782" />
            </svg>
          </a>
        </div>
        <div class="participant-details">
          <div class="participant-details-header" @click="toggleDetails()">
            <h4>{{ $translate('Participant Details') }}</h4>
            <div v-if="student !== null" class="expand">
              <button class="expand-button">
                <span class="expand-button-text" v-if="showDetails">{{ $translate('Close All') }}</span>
                <span class="expand-button-text" v-else>{{ $translate('Open All') }}</span>
                <md-icon v-if="showDetails" slot="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M9.40833 7.2584L5.58333 11.0834C5.25833 11.4084 5.25833 11.9334 5.58333 12.2584C5.90833 12.5834 6.43333 12.5834 6.75833 12.2584L10 9.02507L13.2333 12.2584C13.5583 12.5834 14.0833 12.5834 14.4083 12.2584C14.7333 11.9334 14.7333 11.4084 14.4083 11.0834L10.5833 7.2584C10.2667 6.9334 9.73333 6.9334 9.40833 7.2584Z"
                      fill="#050505" />
                  </svg>
                </md-icon>
                <md-icon v-else slot="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M13.2333 7.74155L9.99998 10.9749L6.76665 7.74155C6.44165 7.41655 5.91665 7.41655 5.59165 7.74155C5.26665 8.06655 5.26665 8.59155 5.59165 8.91655L9.41665 12.7416C9.74165 13.0666 10.2667 13.0666 10.5917 12.7416L14.4166 8.91655C14.7416 8.59155 14.7416 8.06655 14.4166 7.74155C14.0916 7.42489 13.5583 7.41655 13.2333 7.74155Z"
                      fill="#050505" />
                  </svg>
                </md-icon>
              </button>
            </div>
          </div>
          <div v-for="(section, index) in details" :key="index" class="participant-details-section" v-if="hasContent(section)">
            <div class="participant-details-section-header" @click="toggleSection(index)">
              <div class="section-header-content">
                <md-icon v-html="section.icon" slot="icon"></md-icon>
                <h5>{{ section.label }}</h5>
              </div>
              <div class="expand">
                <button class="expand-button">
                  <md-icon v-if="section.isExpanded" slot="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M9.40833 7.2584L5.58333 11.0834C5.25833 11.4084 5.25833 11.9334 5.58333 12.2584C5.90833 12.5834 6.43333 12.5834 6.75833 12.2584L10 9.02507L13.2333 12.2584C13.5583 12.5834 14.0833 12.5834 14.4083 12.2584C14.7333 11.9334 14.7333 11.4084 14.4083 11.0834L10.5833 7.2584C10.2667 6.9334 9.73333 6.9334 9.40833 7.2584Z" fill="#050505"/>
                    </svg>
                  </md-icon>
                  <md-icon v-else slot="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M13.2333 7.74155L9.99998 10.9749L6.76665 7.74155C6.44165 7.41655 5.91665 7.41655 5.59165 7.74155C5.26665 8.06655 5.26665 8.59155 5.59165 8.91655L9.41665 12.7416C9.74165 13.0666 10.2667 13.0666 10.5917 12.7416L14.4166 8.91655C14.7416 8.59155 14.7416 8.06655 14.4166 7.74155C14.0916 7.42489 13.5583 7.41655 13.2333 7.74155Z" fill="#050505"/>
                    </svg>
                  </md-icon>
                </button>
              </div>
            </div>
            <div v-if="section.isExpanded" class="participant-details-section-body">
              <template v-for="content in section.content">
                <template
                  v-if="content.hasOwnProperty('isSpecialty') && getNestedProperty(participant.college_scorecard_dataset.data, content.value) === 'Yes'">
                  <div class="participant-details-section-content">
                    <strong>{{ content.label }}</strong>
                  </div>
                </template>
                <template
                  v-else-if="!content.hasOwnProperty('isSpecialty') && getNestedProperty(participant.college_scorecard_dataset.data, content.value)">
                  <div class="participant-details-section-content" :class="{
                    'participant-details-section-content--html': content.isHtml,
                  }">
                    <div>{{ content.label }}:</div>
                    <div v-if="content.isHtml" v-html="content.modifier ?
                      content.modifier(getNestedProperty(participant.college_scorecard_dataset.data,
                        content.value)) :
                      getNestedProperty(participant.college_scorecard_dataset.data, content.value)"></div>
                    <div v-else>
                      <strong>
                        {{ content.modifier ?
                          content.modifier(getNestedProperty(participant.college_scorecard_dataset.data,
                            content.value)) :
                          getNestedProperty(participant.college_scorecard_dataset.data, content.value) }}
                      </strong>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div v-if="section.hasOwnProperty('links')" class="participant-details-section-links">
              <template v-for="link in section.links">
                <a :href="link.href" target="_blank">
                  {{ link.label }}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V5C4.16667 4.54167 4.54167 4.16667 5 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10.8333C17.5 10.375 17.125 10 16.6667 10C16.2083 10 15.8333 10.375 15.8333 10.8333V15C15.8333 15.4583 15.4583 15.8333 15 15.8333ZM11.6667 3.33333C11.6667 3.79167 12.0417 4.16667 12.5 4.16667H14.6583L7.05 11.775C6.725 12.1 6.725 12.625 7.05 12.95C7.375 13.275 7.9 13.275 8.225 12.95L15.8333 5.34167V7.5C15.8333 7.95833 16.2083 8.33333 16.6667 8.33333C17.125 8.33333 17.5 7.95833 17.5 7.5V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H12.5C12.0417 2.5 11.6667 2.875 11.6667 3.33333Z"
                      fill="#562782" />
                  </svg>
                </a>
              </template>
            </div>
          </div>
          <div v-if="student !== null" class="participant-disclaimer">
            {{ $translate('Data is provided by the') }} <a
              :href="'https://collegescorecard.ed.gov/school/?' + participant.college_scorecard_dataset.id"
              target="_blank">{{ $translate("U.S. Department of Education's College Scorecard") }}</a> 
              {{ $translate('and includes information reported by the institution Integrated Postsecondary Education Data system (IPEDS).') }}
          </div>
          <div v-else class="participant-disclaimer-large">
            <div class="participant-disclaimer-image">
              <img src="/img/dept-ed.png" />
            </div>
            <div class="participant-disclaimer-text">
              {{ $translate('Data is provided by the') }} <a
                :href="'https://collegescorecard.ed.gov/school/?' + participant.college_scorecard_dataset.id"
                target="_blank">{{ $translate("U.S. Department of Education's College Scorecard") }}</a> {{
                  $translate('and includes information reported by the institution Integrated Postsecondary Education Data System (IPEDS).') }}
            </div>
          </div>
        </div>
      </template>
      <div v-if="student !== null" class="participant-notes">
        <h4>{{ $translate('My Notes') }}</h4>
        <textarea v-model="notes" :placeholder="$translate('Add your private notes here. They will not be shared.')"></textarea>
        <button @click="saveNotes" class="button-save" :class="{
          'button-save--disabled': notes === participant.notes,
        }">{{ $translate('Save') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { MdIcon } from '@material/web/icon/icon.js';

export default {
  props: {
    participant: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: false,
      default: null,
    },
  },
  beforeMount() {
    this.specialties = [
      {
        label: this.$translate('Alaska Native and Native Hawaiian-serving Institution'),
        value: 'latest.school.minority_serving.annh',
      },
      {
        label: this.$translate('Asian American and Native American-Pacific Islander-serving Institution'),
        value: 'latest.school.minority_serving.aanipi',
      },
      {
        label: this.$translate('Hispanic-serving Institution (HSI)'),
        value: 'latest.school.minority_serving.hispanic',
      },
      {
        label: this.$translate('Historically Black College and University (HBCU)'),
        value: 'latest.school.minority_serving.historically_black',
      },
      {
        label: this.$translate('Men-only'),
        value: 'latest.school.men_only',
      },
      {
        label: this.$translate('Native American Non-Tribal Institution'),
        value: 'latest.school.minority_serving.nant',
      },
      {
        label: this.$translate('Predominantly Black Institution'),
        value: 'latest.school.minority_serving.predominantly_black',
      },
      {
        label: this.$translate('Tribal College or University'),
        value: 'latest.school.minority_serving.tribal',
      },
      {
        label: this.$translate('Women-only'),
        value: 'latest.school.women_only',
      },
    ];

    this.details = [
      {
        label: this.$translate('Students'),
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.016 0.730011H5.6888C3.94601 0.730011 2.5332 2.14282 2.5332 3.88561V22.8192C2.5332 23.0682 2.73504 23.27 2.984 23.27H19.2128C19.5598 23.27 19.7767 22.8943 19.6032 22.5938C19.5227 22.4543 19.3738 22.3684 19.2128 22.3684H3.4348V21.9176C3.4348 20.6727 4.44391 19.6636 5.6888 19.6636H21.016C21.265 19.6636 21.4668 19.4618 21.4668 19.2128V1.18081C21.4668 0.931845 21.265 0.730011 21.016 0.730011ZM20.5652 18.762H5.6888C4.8403 18.7617 4.0276 19.1039 3.4348 19.711V3.88561C3.4348 2.64076 4.44395 1.63161 5.6888 1.63161H20.5652V18.762Z" fill="#562782"/>
              </svg>`,
        isExpanded: true,
        content: [
          {
            label: this.$translate('Size'),
            value: 'latest.student.size',
            modifier: this.getSize,
          },
          {
            label: this.$translate('Undergraduate Enrollment'),
            value: 'latest.student.size',
            modifier: this.getNumber,
          },
          ...this.specialties.map(
            (specialty) => {
              return {
                label: this.$translate(specialty.label),
                value: specialty.value,
                isSpecialty: true,
              };
            }
          ),
          {
            label: this.$translate('Religious Affiliation'),
            value: 'latest.school.religious_affiliation',
          },
        ],
      },
      {
        label: this.$translate('Campus'),
        icon: `<?xml version="1.0" encoding="UTF-8"?>
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                    stroke: #562782;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <path class="cls-1" d="M13.92,21.58v-3.83c0-.51-.2-1-.56-1.36-.36-.36-.85-.56-1.36-.56s-1,.2-1.36.56c-.36.36-.56.85-.56,1.36v3.83"/>
              <path class="cls-1" d="M17.75,10.08l3.83,1.92v7.67c0,.51-.2,1-.56,1.36-.36.36-.85.56-1.36.56H4.33c-.51,0-1-.2-1.36-.56-.36-.36-.56-.85-.56-1.36v-7.67l3.83-1.92"/>
              <path class="cls-1" d="M17.75,5.29v16.29"/>
              <path class="cls-1" d="M4.33,6.25l7.67-3.83,7.67,3.83"/>
              <path class="cls-1" d="M6.25,5.29v16.29"/>
              <path class="cls-1" d="M10.08,9.12c0,.51.2,1,.56,1.36.36.36.85.56,1.36.56s1-.2,1.36-.56c.36-.36.56-.85.56-1.36s-.2-1-.56-1.36c-.36-.36-.85-.56-1.36-.56s-1,.2-1.36.56c-.36.36-.56.85-.56,1.36Z"/>
            </svg>`,
        isExpanded: true,
        content: [
          {
            label: this.$translate('Region'),
            value: 'latest.school.region_id',
          },
          {
            label: this.$translate('Setting'),
            value: 'latest.school.locale',
          },
        ],
      },
      {
        label: this.$translate('Financial'),
        icon: `<?xml version="1.0" encoding="UTF-8"?>
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .cls-1, .cls-2 {
                    fill: none;
                    stroke: #562782;
                  }

                  .cls-2 {
                    stroke-linecap: round;
                  }
                </style>
              </defs>
              <path class="cls-1" d="M1.52,12c0,8.07,8.74,13.11,15.73,9.08,3.24-1.87,5.24-5.33,5.24-9.08C22.48,3.93,13.75-1.11,6.76,2.92,3.51,4.79,1.52,8.25,1.52,12Z"/>
              <path class="cls-2" d="M12,5.71v12.58"/>
              <path class="cls-2" d="M15.14,9.38c0-1.45-1.41-2.62-3.15-2.62s-3.15,1.17-3.15,2.62,1.41,2.62,3.15,2.62,3.15,1.17,3.15,2.62-1.41,2.62-3.15,2.62-3.15-1.17-3.15-2.62"/>
            </svg>`,
        isExpanded: true,
        content: [
          {
            label: this.$translate('Average Cost'),
            value: this.participant.college_scorecard_dataset?.data.latest.school.ownership === 'Public' ? 'latest.cost.avg_net_price.public' : 'latest.cost.avg_net_price.private',
            modifier: this.getPrice,
          },
          {
            label: this.$translate('In-State Tuition and Fees'),
            value: 'latest.cost.tuition.in_state',
            modifier: this.getPrice,
          },
          {
            label: this.$translate('Out-State Tuition and Fees'),
            value: 'latest.cost.tuition.out_of_state',
            modifier: this.getPrice,
          },
          {
            label: this.$translate('Tuition and Fees for Program-Year'),
            value: 'latest.cost.tuition.program_year',
            modifier: this.getPrice,
          }
        ],
        links: [
          {
            label: this.$translate('Net Price Calculator'),
            href: this.getUrl(this.participant.college_scorecard_dataset?.data.latest.school.price_calculator_url),
          }
        ]
      },
      {
        label: this.$translate('Academics'),
        icon: `<?xml version="1.0" encoding="UTF-8"?>
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs>
                <style>
                  .cls-1, .cls-2 {
                    fill: none;
                    stroke: #562782;
                  }

                  .cls-2 {
                    stroke-linecap: round;
                  }
                </style>
              </defs>
              <path class="cls-1" d="M9.68,3.09c1.49-.7,3.16-.7,4.65,0l7.01,3.29c1.53.72,1.53,3.19,0,3.91l-7.01,3.29c-1.49.7-3.16.7-4.65,0l-7.01-3.29c-1.53-.72-1.53-3.19,0-3.91l7.01-3.29Z"/>
              <path class="cls-2" d="M1.52,8.33v5.77"/>
              <path class="cls-2" d="M19.34,11.48v5.37c0,1.06-.53,2.05-1.45,2.56-1.54.85-4,2.03-5.89,2.03s-4.35-1.18-5.89-2.03c-.92-.51-1.45-1.5-1.45-2.56v-5.37"/>
            </svg>`,
        isExpanded: true,
        content: [
          {
            label: this.$translate('Graduation Rate'),
            value: this.participant.college_scorecard_dataset?.data.latest.completion.rate_suppressed.lt_four_year ? 'latest.completion.rate_suppressed.lt_four_year' : 'latest.completion.rate_suppressed.four_year_200percent',
            modifier: (value) => `${Math.floor(value * 100)}%`,
          },
          {
            label: this.$translate('Student-to-Faculty Ratio'),
            value: 'latest.student.demographics.student_faculty_ratio',
            modifier: (value) => `${value}:1`,
          },
          {
            label: this.$translate('Programs / Majors'),
            value: 'latest.programs.cip_4_digit',
            isHtml: true,
            modifier: (value) => {
              return '<ul>' + value.map((item) => {
                return `<li>${item}</li>`;
              }).join('') + '</ul>';
            }
          }
        ]
      }
    ];
  },
  data() {
    return {
      showDetails: true,
      scrollPosition: 0,
      notes: this.participant?.notes,
      stats: [
        {
          label: this.$translate('Institution Type'),
          value: 'latest.school.ownership',
        },
        {
          label: this.$translate('Level of Institution'),
          value: 'latest.school.institutional_characteristics.level',
        },
        {
          label: this.$translate('Admission Rate'),
          value: 'latest.admissions.admission_rate.overall',
          fallback: 'latest.school.open_admissions_policy',
          modifier: this.getAdmissionRate,
        },
        {
          label: this.$translate('Campus Setting'),
          value: 'latest.school.locale',
          modifier: this.getLocale,
        },
        {
          label: this.$translate('Size'),
          value: 'latest.student.size',
          modifier: this.getSize,
        }
      ],
      details: [],
      specialties: [],
    };
  },
  methods: {
    getUrl(value) {
      // add https:// if missing from value
      if (value && !value.match(/^https?:\/\//i)) {
        value = `https://${value}`;
      }

      return value;
    },
    getNumber(value) {
      return `${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    },
    getPrice(value) {
      return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    },
    getAdmissionRate(value, fallback) {
      if (value === null && fallback === 'Yes') {
        return this.$translate('Open Admissions');
      }
      return `${Math.floor(value * 100)}%`;
    },
    getLocale(value) {
      return value.split(':')[0]
    },
    getSize(value) {
      if (value < 2000) {
        return this.$translate('Small');
      } else if (value < 15000) {
        return this.$translate('Medium');
      }

      return this.$translate('Large');
    },
    getNestedProperty(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    saveNotes() {
      axios.post(`/api/fair_participants/${this.participant.id}/notes`, {
        hash: this.student.hashed_created_at,
        student_id: this.student.id,
        notes: this.notes,
      }).then((response) => {
        Bus.$emit('notesSaved');
        this.participant.notes = this.notes;
      });
    },
    toggleFavorite(participantId) {
      axios.post(`/api/fair_participants/${participantId}/favorite`, {
        hash: this.student.hashed_created_at,
        student_id: this.student.id,
      }).then((response) => {
        this.participant.is_favorite = !this.participant.is_favorite;
      });
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
      this.details.forEach((detail, i) => {
        this.$set(this.details[i], 'isExpanded', this.showDetails);
      });
    },
    toggleSection(index) {
      this.$set(this.details[index], 'isExpanded', !this.details[index].isExpanded);
    },
    hasContent(section) {
      return section.content.some(content => {
        if (content.hasOwnProperty('isSpecialty')) {
          return this.getNestedProperty(this.participant.college_scorecard_dataset.data, content.value) === 'Yes';
        } else {
          return this.getNestedProperty(this.participant.college_scorecard_dataset.data, content.value);
        }
      });
    }
  },
}
</script>

<style scoped>
.participant-data {
  font-family: Quicksand;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .participant-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    .participant-image {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .participant-headline {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin: 0;
        text-align: left;
      }

      .participant-subhead {
        display: flex;
        flex-direction: column;
        gap: 8px;
        line-height: 19px;

        .participant-address {
          font-size: 14px;
        }

        .participant-favorite {
          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 4px 24px;
            justify-content: center;
            border-radius: 100px;
            border: 1px solid #562782;
          }

          button.button-add {
            background: #562782;
            color: white;
          }

          button.button-remove {
            background: none;
          }
        }
      }
    }
  }

  .participant-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    max-height: 90vh;

    &.participant-body--no-student {
      max-height: inherit;
      overflow: visible;
    }

    .participant-key {
      display: flex;
      padding: 20px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      background: #F4F2F6;

      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
        text-align: left;
      }

      .participant-specialties {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 8px;

          li {
            list-style-type: none;
            background: #C1FFD6;
            border-radius: 100px;
            padding: 4px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            border-radius: 4px;
          }
        }
      }

      .participant-stats {
        display: flex;
        flex-direction: row;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: flex-start;

        .participant-stat {
          width: calc(100% / 3 - 16px);
          display: flex;
          flex-direction: column;
          gap: 4px;

          h4 {
            color: #562782;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: 0.24px;
            margin: 0;
            text-align: left;
          }

          div {
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            text-align: left;
          }
        }
      }
    }

    .participant-links {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        gap: 4px;
      }
    }

    .participant-details {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .participant-details-header {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          margin: 0;
          text-align: left;
        }
      }

      .expand {
        display: flex;
        align-items: end;

        .expand-button {
          background: none;
          border: none;
          font-family: Quicksand;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #562782;

          .expand-button-text {
            text-transform: uppercase;
          }
        }
      }

      .participant-details-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);

        .participant-details-section-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .section-header-content {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;

            h5 {
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              margin: 0;
              text-align: left;
            }
          }
        }

        .participant-details-section-body {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .participant-details-section-content {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }

          .participant-details-section-content--html {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }

        .participant-details-section-links {
          display: flex;
          flex-direction: row;
          gap: 16px;

          a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            gap: 4px;
          }
        }
      }
    }

    .participant-disclaimer {
      color: #4B4B4B;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
    }

    .participant-disclaimer-large {
      display: flex;
      flex-direction: row;
      background-color: #F4F2F6;
      border-radius: 16px;
      padding: 16px;
      gap: 16px;
      align-items: center;

      .participant-disclaimer-image img {
        width: 80px;
        height: 80px;
      }

      .participant-disclaimer-text {
        color: #4B4B4B;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  .participant-notes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.16);

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
      text-align: left;
    }

    textarea {
      display: flex;
      padding: 8px 12px;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.48);
      background: #FFFFFF;
      color: #050505;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      min-height: 50px;
    }

    .button-save {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 8px 18px;
      justify-content: center;
      border-radius: 100px;
      border: 1px solid #562782;
      background: #562782;
      color: #FFFFFF;

      &.button-save--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .participant-data {
    .participant-header {
      .participant-headline {
        .participant-subhead {
          .participant-favorite {
            button {
              font-size: 10px;
            }
          }
        }
      }
    }

    .participant-body {
      gap: 10px;

      .participant-details {
        gap: 10px;
      }

      .participant-key {
        .participant-stats {
          .participant-stat {
            div {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>