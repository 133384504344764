import { Api } from '../../../../dashboard/dashboard-api';
import Template from './index.html';
import Pagination from '../../../PaginationVariant.vue';
import EmptyState from '../../../ListEmptyState.vue';
import Loader from '../../Loader.vue';
import ShowDetailsModal from '../SchoolDetailsModal.vue';
import AttendanceChart from './attendanceChart';
import { find } from 'lodash';

const DEFAULT_PAGE_SIZE = 20;

export default Vue.extend({
  components: {
    pagination: Pagination,
    'empty-state': EmptyState,
    loader: Loader,
    'attendance-chart': AttendanceChart,
    'show-details-modal': ShowDetailsModal
  },
  props: {
    fairId: {
      type: Number,
      default: null
    },
    fairStartTime: {
      type: Date,
      default: null
    },
    canViewPrintBarcode: {
      type: Number,
      default: 0,
    },
    schoolPermissions: {
      type: Array,
      default: null
    },
    isAdmin: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      alertSuccess: false,
      alertMessage: '',
      schools: null,
      order: 'desc',
      orderBy: this.orderBy,
      graphRange: 0,
      pagination: {
        current_page: 1,
        page_count: 1,
        per_page: DEFAULT_PAGE_SIZE,
        total_count: 1
      },
      fetching: false,
      errors: null,
      isActivePage: false,
      input: '',
      query: '',
      selectedSchool: { name: '' }
    };
  },
  computed: {
    hasSchools() {
      return this.schools !== null && this.schools.length > 0;
    },
    isSearching() {
      return this.query && this.query.length > 0;
    },
    hasStarted() {
      return this.fairStartTime <= new Date();
    },
    contextKey() {
      return 'schools_table';
    },
    currentSortArrow() {
      if (!this.fetching && this.hasSchools) {
        return `${this.orderBy}-${this.order}`;
      }
      return 'none';
    }
  },
  watch: {
    input: function() {
      if (this.input === '' && this.query !== '') {
        this.triggerSearch();
      }
    },
    'pagination.current_page': function() {
      this.fetchSchools();
    }
  },
  mounted() {
    //The Bus.$on('sparkHasChanged') construct used in other places to suppress loading
    //when the component is not displayed is dependent on the tabs UI which is not
    //present on this page
    this.orderBy = this.fairStartTime <= new Date() ? 'attended' : 'registered';
    Bus.$on('refreshDashboard', this.fetchSchools);
    this.fetchSchools();
  },
  created() {
    Bus.$on('currentPageUpdated', (pageNumber, context) => {
      if (this.contextKey === context) {
        this.pagination.current_page = Number(pageNumber);
      }
    });
  },
  methods: {
    fetchSchools() {
      this.fetching = true;
      Api.fairs(this.fairId)
        .dashboard.schools({
          page: this.pagination.current_page,
          limit: this.pagination.per_page,
          order: this.order,
          orderBy: this.orderBy,
          query: this.query
        })
        .then(
          (success) => {
            this.fetching = false;
            this.schools = success.data.data.map((s) => {
              return {
                id: s.id,
                name: s.name || s.school,
                uniqueSchoolId: s.uniqueSchoolId,
                municipality: s.municipality,
                region: s.region,
                registered: s.registered,
                attended: s.attended,
                range: success.data.meta.graph_range,
                ceeb: s.CEEB,
                muni_region: [s.municipality, s.region]
                  .join(', ')
                  .replace(/^\s+/, '')
                  .replace(/\s+$/, '')
                  .replace(/^,\s+/, '')
                  .replace(/,$/, '')
              };
            });
            this.pagination = success.data.meta.pagination;
          },
          (error) => {
            this.fetching = false;
            this.errors = error.data.errors;
          }
        );
    },
    toggleSort(orderBy) {
      if (orderBy === this.orderBy) {
        this.toggleSortOrder();
      }
      this.orderBy = orderBy;
      this.fetchSchools();
    },
    toggleSortOrder() {
      if (this.order === 'desc') {
        this.order = 'asc';
      } else {
        this.order = 'desc';
      }
    },
    showDetails(school) {
      this.selectedSchool = school;
      $('#modal-school-details-dashboard').modal({
        backdrop: 'static',
        keyboard: false
      });
    },
    triggerSearch() {
      this.query = this.input;
      if (this.pagination.current_page !== 1) {
        // there is a watch on `pagination.current_page` that calls this.fetchSchools();
        this.pagination.current_page = 1;
      } else {
        this.fetchSchools();
      }
    },
    printBarcode(fair_id, school_id, unique_school_id) {
      this.alertSuccess = false;

      const schoolIds = [];
      const uniqueSchoolIds = [];

      if (school_id > 0) {
        schoolIds.push(school_id);
      } else if (school_id < 0) {
        uniqueSchoolIds.push(unique_school_id);
      }

      axios.post('/api/v2/barcode/dashboard', {
        fairId: fair_id,
        schoolIds,
        uniqueSchoolIds
      }).then(
        (success) => {
          this.alertSuccess = true;
          this.alertMessage = 'Barcode export in progress and will be emailed to ' + window.Spark.state.user.email + '.';
        },
        (error) => {
          this.alertSuccess = false;
          this.alertMessage = 'Barcode export failed to export';
        }
      )
    },
    findSchoolById(fairUserData, schoolId) {
      for (const item of fairUserData) {
        const school = _.find(item.schools, { id: schoolId });
        if (school) {
          return school;
        }
      }
      return null; // Return null if no matching school is found
    },
    canPrintBarcode(school_id) {
    const filteredData = this.schoolPermissions.filter(i => i.user.email === window.Spark.state.user.email);
     return this.findSchoolById(filteredData, school_id);
    }
  },
  template: Template
});
