// List from https://www.ndangira.net/list-of-canada-provinces-and-territories/#Canada_Provinces_in_Alphabetical_order

const PROVINCES = [
  {
    name: 'Alberta',
    abbreviation: 'AB'
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC'
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB'
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB'
  },
  {
    name: 'New Foundland and Labrador',
    abbreviation: 'NL'
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS'
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT'
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU'
  },
  {
    name: 'Ontario',
    abbreviation: 'ON'
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE'
  },
  {
    name: 'Quebec',
    abbreviation: 'QC'
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK'
  },
  {
    name: 'Yukon',
    abbreviation: 'YK'
  }
];
export default PROVINCES;
