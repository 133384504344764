import swal from 'sweetalert';

Vue.component('post-login', {
  data() {
    return {
      userEmail,
      mfa_code: '',
      mfa_value: '',
      recovery_mode: false,
      recovery_code: ''
    };
  },
  mounted() {
    this.$nextTick(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.mfa_value = urlParams.get('sent-to');

      if (error) {
        const err = swal('Incorrect Code', 'Please check code and try again.', 'error');
      }
    });
  },
  methods: {
    async handleResendCode() {
      const url = `/api/auth/two-factor/resend`;
      try {
        let result = await axios.post(url, { email: this.userEmail });
        let alertComponent = await swal('Verification Code Resent', '', 'success');
      } catch (e) {
        const err = swal('Error Sending Verification Code', '', 'error');
      }
    },
    handleEnterRecoveryCode() {
      this.recovery_mode = true;
    },
    cancelRecovery() {
      this.recovery_mode = false;
    },
    async handleAccountRecovery() {
      const url = `/api/totp/recovery`;
      try {
        let result = await axios.post(url, { recovery_code: this.recovery_code });
        let alertComponent = await swal('Account Recovery Complete. Redirecting to login in 3 seconds.', '', 'success');
        setTimeout(function() {
          window.location.href = '/login';
        }, 3000);
      } catch (e) {
        const err = swal('Account Recovery Failed', '', 'error');
      }
    }
  }
});
