import VueBootstrapTypeahead from './../vue-bootstrap-typeahead/VueBootstrapTypeahead.vue';
import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import BlurValidationMixin from './validation/blurValidation';
import { PulseLoader } from '@saeris/vue-spinners';
import ExtendedPulseLoader from '../components/ExtendedPulseLoader.vue';
import SchoolSelectWithTypeAhead from '../components/SchoolSelectWithTypeAhead.vue';
import SchoolSearchWithTypeAhead from './SchoolSearchWithTypeAhead.vue';

const CEEB_UNLISTED = '000003';
const CEEB_HOMESCHOOLED = '970000';

Vue.component('student-signup-linden', {
  components: {
    VueBootstrapTypeahead,
    PulseLoader,
    ExtendedPulseLoader,
    SchoolSelectWithTypeAhead,
    SchoolSearchWithTypeAhead
  },
  mixins: [BlurValidationMixin],

  data() {
    return {
      filter_query: null,
      google: require('google-maps-api')(google_api_key, ['places']),
      countries: [],
      fields_to_validate: {
        fair: ['required'],
        first_name: ['required'],
        last_name: ['required'],
        email: ['required', 'custom'],
        country_code: ['required'],
        phone: ['required'],
        phone_country_code: ['required'],
        birthdate: ['required'],
        birthdate_month: ['required'],
        birthdate_day: ['required'],
        birthdate_year: ['required'],
        address_line_1: ['required'],
        address_municipality: ['required'],
        address_country: ['required'],
        address_country_code: ['required'],
        high_school: ['custom'],
        high_school_city: ['custom'],
        current_year_class: ['required'],
        college_start_semester: ['custom'],
        act_score: ['custom'],
        sat_score: ['custom'],
        toefl_score: ['custom'],
        ielts_score: ['custom']
      },
      form: new SparkForm({
        first_name: '',
        last_name: '',
        fair: '',
        email: '',
        country_code: '',
        phone: '',
        phone_country_code: 'US',
        text_permission: false,
        birthdate: '',
        address_line_1: '',
        address_line_2: '',
        address_locality: '',
        address_municipality: '',
        address_region: '',
        address_postal_code: '',
        address_country: '',
        address_country_code: '',
        gender: '',
        gender_specify: '',
        grade_level: '',
        high_school: '',
        high_school_id: '',
        high_school_city: '',
        college_start_semester: '',
        area_of_interest_1: '',
        area_of_interest_2: '',
        area_of_interest_3: '',
        country_of_interest_1: '',
        country_of_interest_2: '',
        country_of_interest_3: '',
        email_confirmation: true,
        text_confirmation: true,
        authorize_cis: false,
        accept_tos: false,
        high_school_ceeb: '',
        high_school_country_code: '',
        high_school_region: '',
        birthdate_day: '',
        birthdate_month: '',
        birthdate_year: '',
        gpa: '',
        gpa_max: '',
        sat_score: '',
        act_score: '',
        toefl_score: '',
        ielts_score: '',
        current_year_class: null,
        graduation_year: '',
        has_parent_permission: false
      }),
      google_autocomplete: null,
      autocompleted: false,
      citizenships: [],
      maxCitizenships: 2,
      grades: [6, 7, 8, 9, 10, 11, 12],
      gradeSelectors: [],
      start_university: [],
      selectedInterests: [],
      interestItems: [],
      interestQuery: '',
      interests: [
        {
          main: 'Undecided',
          children: ['Undecided']
        },
        {
          main: 'Agriculture, Agriculture Operations, and Related Sciences',
          children: ['Agricultural Business and Management', 'Animal Sciences']
        },
        {
          main: 'Architecture and Related Services',
          children: ['Architecture', 'City/Urban, Community, and Regional Planning', 'Landscape Architecture']
        },
        {
          main: 'Area, Ethnic, Cultural, and Gender Studies',
          children: ['Area Studies', 'Ethnic, Cultural Minority, and Gender Studies']
        },
        {
          main: 'Biological and Biomedical Sciences',
          children: [
            'Biology/Biological Sciences, General',
            'Biochemistry',
            'Biophysics',
            'Biotechnology',
            'Cell/Cellular Biology and Anatomical Sciences',
            'Ecology',
            'Genetics',
            'Marine Biology and Biological Oceanography',
            'Microbiological Sciences and Immunology',
            'Molecular Biology',
            'Zoology/Animal Biology'
          ]
        },
        {
          main: 'Business Management, Marketing, and Related Support Services',
          children: [
            'Accounting and Related Services',
            'Actuarial Science',
            'Business Administration, Management and Operations',
            'Finance and Financial Management Services',
            'Hospitality Administration/Management',
            'Human Resources Management and Services',
            'International Business',
            'Management Information Systems and Services',
            'Marketing/Marketing Management, General'
          ]
        },
        {
          main: 'Communication, Journalism, and Related Programs',
          children: [
            'Advertising',
            'Communication and Media Studies',
            'Digital Communication and Media/Multimedia',
            'Journalism',
            'Public Relations/Image Management',
            'Radio and Television'
          ]
        },
        {
          main: 'Computer and Information Sciences and Support Services',
          children: ['Computer Science', 'Information Science/Studies']
        },
        {
          main: 'Education',
          children: [
            'Early Childhood Education and Teaching',
            'Elementary Education and Teaching',
            'Secondary Education and Teaching',
            'Special Education and Teaching'
          ]
        },
        {
          main: 'Engineering',
          children: [
            'Aerospace, Aeronautical and Astronautical Engineering',
            'Agricultural/Biological Engineering and Bioengineering',
            'Architectural Engineering',
            'Biomedical/Medical Engineering',
            'Chemical Engineering',
            'Civil Engineering',
            'Computer Engineering, General',
            'Electrical, Electronics and Communications Engineering',
            'Engineering Physics',
            'Engineering Science',
            'Environmental/Environmental Health Engineering',
            'Geological/Geophysical Engineering',
            'Industrial Engineering',
            'Materials Engineering',
            'Mechanical Engineering',
            'Mining and Mineral Engineering',
            'Nuclear Engineering',
            'Petroleum Engineering',
            'Materials Science',
            'Polymer/Plastics Engineering'
          ]
        },
        {
          main: 'Engineering Technologies/Technicians',
          children: [
            'Computer Engineering Technology/Technician',
            'Drafting/Design Engineering Technologies/Technician',
            'Telecommunications Technology/Technician'
          ]
        },
        {
          main: 'English Language and Literature/Letters',
          children: ['Creative Writing']
        },
        {
          main: 'Family and Consumer Sciences/Human Sciences',
          children: ['Foods, Nutrition and Wellness Studies, General']
        },
        {
          main: 'Foreign Languages, Literatures, and Linguistics',
          children: [
            'Classics and Classical Languages, Literatures, and Linguistics',
            'Comparative Literature',
            'East Asian Languages, Literatures, and Linguistics',
            'French Language and Literature',
            'German Language and Literature',
            'Linguistics',
            'Russian Language and Literature',
            'Spanish Language and Literature'
          ]
        },
        {
          main: 'Health Professions and Related Clinical Services',
          children: [
            'Allied Health Diagnostic, Intervention, and Treatment Professions',
            'Athletic Training/Trainer',
            'Clinical/Medical Laboratory Science and Allied Professions',
            'Communication Disorders Sciences and Services',
            'Dental Hygiene/Hygienist',
            'Dietetics and Clinical Nutrition Services',
            'Nursing',
            'Occupational Therapy/Therapist',
            'Physical Therapy/Therapist',
            'Pre-Dentistry Studies',
            'Pre-Medicine/Pre-Medical Studies',
            'Pre-Pharmacy Studies',
            'Pre-Veterinary Studies'
          ]
        },
        {
          main: 'History',
          children: ['History']
        },
        {
          main: 'Legal Professions and Studies',
          children: ['Pre-Law Studies']
        },
        {
          main: 'Liberal Arts and Sciences, General Studies, and Humanities',
          children: ['Humanities/Humanistic Studies', 'Liberal Arts and Sciences/Liberal Studies']
        },
        {
          main: 'Mathematics and Statistics',
          children: ['Applied Mathematics', 'Mathematics', 'Statistics']
        },
        {
          main: 'Multi/Interdisciplinary Studies',
          children: ['Biological and Physical Sciences', 'International/Global Studies', 'Mathematics and Computer Science', 'Neuroscience']
        },
        {
          main: 'Natural Resources and Conservation',
          children: [
            'Environmental Science',
            'Environmental Studies',
            'Fishing and Fisheries Sciences and Management',
            'Forestry',
            'Wildlife and Wildlands Science and Management'
          ]
        },
        {
          main: 'Parks, Recreation, Leisure, and Fitness Studies',
          children: ['Parks, Recreation, and Leisure Facilities Management', 'Sport and Fitness Administration/Management']
        },
        {
          main: 'Personal and Culinary Services',
          children: ['Personal and Culinary Services']
        },
        {
          main: 'Philosophy and Religious Studies',
          children: ['Philosophy', 'Religion/Religious Studies']
        },
        {
          main: 'Physical Sciences',
          children: [
            'Astronomy',
            'Astrophysics',
            'Atmospheric Sciences and Meteorology',
            'Chemistry',
            'Geological and Earth Sciences/Geosciences',
            'Physics'
          ]
        },
        {
          main: 'Psychology',
          children: ['Psychology']
        },
        {
          main: 'Public Administration and Social Service Professions',
          children: ['Human Services, General', 'Public Administration', 'Public Policy Analysis', 'Social Work']
        },
        {
          main: 'Security and Protective Services',
          children: ['Criminal Justice/Law Enforcement Administration', 'Forensic Science and Technology']
        },
        {
          main: 'Social Sciences',
          children: [
            'Anthropology',
            'Archaeology',
            'Economics',
            'Geography',
            'International Relations and Affairs',
            'Political Science and Government',
            'Sociology'
          ]
        },
        {
          main: 'Theology and Religious Vocations',
          children: ['Theology and Religious Vocations']
        },
        {
          main: 'Visual and Performing Arts',
          children: [
            'Art History, Criticism, and Conservation',
            'Dance',
            'Drama and Dramatics, Theatre Arts, General',
            'Fashion/Apparel Design',
            'Film/Video and Photographic Arts',
            'Fine and Studio Art',
            'Graphic Design',
            'Interior Design',
            'Music',
            'Technical Theatre/Theatre Design and Technology'
          ]
        },
        {
          main: 'Other',
          children: ['Other']
        }
      ],
      maxInterests: 3,
      selectedCountries: [],
      maxCountries: 3,
      countryItems: [],
      isSubmitDisabled: false,
      birthdate: '',
      phone: '',
      upcomingFairs: [],
      isHomeSchooled: false,
      schoolItems: [],
      schoolQuery: '',
      showHighschoolFields: false,
      availableCountries: [],
      student: student,
      months: [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
      ],
      requiresParent: false,
      requiresParentUnderAge: 16,
      grade_data: null,
      twilioFailure: '',
      fairRadius: 'unknown',
      lat: 0,
      lng: 0,
      showDistanceDropDown: false,
      geolocationOptions: {
        enableHighAccuracy: false,
        timeout: 60000,
        maximumAge: 300000
      },
      loading: false
    };
  },
  watch: {
    grade_data: function() {
      this.form.current_year_class = this.grade_data.label;
      this.form.graduation_year = this.grade_data.classOf;

      if (!this.grade_data || (this.grade_data && this.grade_data.grade === null)) return;

      let selected = Math.abs(this.grade_data.grade - 12);
      this.form.college_start_semester = this.start_university[selected];
    },
    'form.current_year_class': function() {
      let app = this;
      let gradeItem = this.gradeSelectors.filter(function(item) {
        return item.label === app.form.current_year_class;
      });

      this.form.graduation_year = gradeItem.firstObject().classOf;

      let selected = Math.abs(gradeItem.firstObject().grade - 12);
      this.form.college_start_semester = this.start_university[selected];
    },
    'form.accept_tos': function() {
      let error_message = 'You must agree before continuing.';
      if (!this.form.accept_tos) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { accept_tos: [error_message] });
      } else {
        this.remove_form_field_errors('accept_tos', error_message);
      }
    },
    'form.has_parent_permission': function() {
      let error_message = 'You must agree that you have permission before continuing.';
      if (this.requiresParent && !this.form.has_parent_permission) {
        this.isSubmitDisabled = true;
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { has_parent_permission: [error_message] });
      } else {
        this.isSubmitDisabled = false;
        this.remove_form_field_errors('has_parent_permission', error_message);
      }
    },
    'form.birthdate_day': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    'form.birthdate_month': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    'form.birthdate_year': function() {
      this.birthdate = this.formatBirthdate();
      this.validateAge();
    },
    upcomingFairs: function() {
      if (this.upcomingFairs.length === 1) {
        this.form.fair = this.upcomingFairs[0].id;
      }
    },
    autocompleted: function() {
      if (this.autocompleted) {
        this.validate('address_line_1', this.form.address_line_1);
        this.validate('address_line_2', this.form.address_line_2);
        this.validate('address_municipality', this.form.address_municipality);
        this.validate('address_region', this.form.address_region);
        this.validate('address_postal_code', this.form.address_postal_code);
        this.validate('address_country_code', this.form.address_country_code);
      }
    },
    'form.fair': function() {
      $(() => {
        let selectedFair;

        this.querySchoolApi('');

        if (!this.student) {
          selectedFair = this.upcomingFairs.filter((f) => {
            return f.id === this.form.fair;
          });
        } else {
          let fairData = {
            id: this.student.fair.id,
            name: this.student.fair.name,
            country: this.student.fair.venue.address.country_code
          };
          selectedFair = [fairData];
        }

        if (!selectedFair) return;

        let selectedCountry = this.countries.filter((c) => {
          return c.alpha2 === selectedFair[0].country;
        });

        if (selectedCountry.length) {
          this.form.address_country_code = selectedCountry[0].alpha2;
          this.form.address_country = selectedCountry[0].name;

          if (!this.form.phone_country_code || (iti && !iti.getNumber(itiNumberFormats.NATIONAL))) {
            this.form.phone_country_code = selectedCountry[0].alpha2;
            iti.setCountry(selectedCountry[0].alpha2);
          }
        }
      });
    }
  },

  created() {
    this.countries = countries;
    this.availableCountries = countries;
    this.fairTagFilter = 'linden-university';
  },

  mounted() {
    let app = this;
    app.google().then(function(maps) {
      app.google_autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['geocode'],
        fields: ['address_components']
      });
      app.google_autocomplete.setFields(['address_components', 'name']);
      app.google_autocomplete.addListener('place_changed', app.fillInAddress);
    });

    if (typeof query != 'undefined') {
      this.filter_query = query;
    }

    $('#gender_specify_input').on('click', function() {
      $('#gender_specify').click();
    });

    $(() => {
      $('#intl-phone').on('countrychange', (e, countryData) => {
        if (countryData && countryData.iso2) {
          this.form.phone_country_code = countryData.iso2.toUpperCase();
        }
        this.validate('phone_country_code', this.form.phone_country_code);
      });
    });

    for (let i = 0; i <= 6; i++) {
      let currentDate = new Date(),
        currentYear = currentDate.getFullYear();
      let offsetYear = null,
        upsetYear = null,
        strRepresentation = null;

      let borderDate = new Date(`${currentYear}-06-01`);

      if (borderDate < currentDate) {
        offsetYear = currentYear + 1 + i;
        upsetYear = currentYear + 2 + i;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      } else {
        offsetYear = currentYear + i;
        upsetYear = currentYear + i + 1;
        strRepresentation = `${offsetYear} - ${upsetYear}`;
      }

      // Create Grade Item
      let classOf = offsetYear + (Math.abs(12 - this.grades[i]) - Math.abs(6 - this.grades[i]));

      let gradeItem = {
        grade: this.grades[i],
        classOf: classOf,
        label: `Year ${this.grades[i]} - Class of ${classOf}`
      };

      this.gradeSelectors.push(gradeItem);

      this.start_university.push(strRepresentation);
    }

    this.gradeSelectors.push({ grade: null, classOf: null, label: 'Transfer Student (Undergraduate)' });
    this.gradeSelectors.push({ grade: null, classOf: null, label: "Graduate Student (Master's)" });
    this.gradeSelectors.push({ grade: null, classOf: null, label: 'Graduate Student (Ph.D.)' });

    if (this.student) {
      this.setStudentForm(this.student);
    } else {
      this.upcomingFairs = fairs;
    }

    this.isSubmitDisabled = false;

    this.resetInterests();
    this.resetCountries();
    this.getLocation();
  },

  methods: {
    handleFairRadiusOnChanged(event) {
      this.fairRadius = event.target.value;

      if (this.lat && this.lng) {
        let lat, lng;
        lat = this.lat;
        lng = this.lng;
        this.setUpcomingFairs({ lat, lng });
      }
    },
    getLocation() {
      var urlParams = new URLSearchParams(window.location.search);

      if (navigator.geolocation && (!urlParams.has('id') && !urlParams.has('state'))) {
        let lat, lng, options;

        if(urlParams.has('tag')) {
          options = {tag: urlParams.get('tag')};
        }

        // Location call takes a bit to show up
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.lat = pos.coords.latitude;
            this.lng = pos.coords.longitude;
            lat = pos.coords.latitude;
            lng = pos.coords.longitude;
            this.showDistanceDropDown = true;
            this.setUpcomingFairs({ ...options, lat, lng });
          },
          (err) => {
            console.error(err);
          },
          this.geolocationOptions
        );
      }
    },
    setUpcomingFairs(query) {
      this.loading = true;
      query.student_type = 'LINDEN';

      if (query.lat && query.lng) {
        query.radius = this.fairRadius;
      }

      query.occurrence = 'upcoming';
      query.limit = '999';

      let url = '/api/v2/fairs/students/index';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        url += `?${queryString}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          this.upcomingFairs = data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }

          if (this.fairRadius === 'unknown' && data.meta && data.meta.radius) {
            let radius = data.meta.radius;
            if (radius <= 50) {
              this.fairRadius = 50;
            } else if (radius > 50 && radius <= 100) {
              this.fairRadius = 100;
            } else if (radius > 100 && radius <= 200) {
              this.fairRadius = 200;
            } else {
              this.fairRadius = 'any';
            }
          }
        })
        .catch((error) => {
          this.upcomingFairs = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = 'The email must be a valid email address.';
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('email', error_message);
      return true;
    },
    validate_guidance_counselor_email(value) {
      let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
      let error_message = 'The email must be a valid email address.';
      if (value && !regex.test(value.toLowerCase())) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { guidance_counselor_email: [error_message] });
        return false;
      }
      this.remove_form_field_errors('guidance_counselor_email', error_message);
      return true;
    },
    validate_sat_score(value) {
      let error_message = 'The SAT score must be between 200 and 1600';
      if (value && (value < 200 || value > 1600)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { sat_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('sat_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_act_score(value) {
      let error_message = 'The ACT score must be between 1 and 36.';
      if (value && (value < 1 || value > 36)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { act_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('act_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_toefl_score(value) {
      let error_message = 'The TOEFL score must be between 0 and 120.';
      if (value && (value < 0 || value > 120)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { toefl_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('toefl_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_ielts_score(value) {
      let error_message = 'The IELTS score must be between 0 and 9.';
      if (value && (value < 0 || value > 9)) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { ielts_score: [error_message] });
        return false;
      }
      this.remove_form_field_errors('ielts_score', error_message); // todo: This should be kicked out to component
      return true;
    },
    validate_college_start_semester(value) {
      let error_message = 'The university start semester field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { college_start_semester: [error_message] });
        return false;
      }
      this.remove_form_field_errors('college_start_semester', error_message);
      return true;
    },
    validate_high_school(value) {
      let error_message = 'The school field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school: ['The school field is required.'] });
        return false;
      }
      this.remove_form_field_errors('high_school', error_message);
      return true;
    },
    validate_high_school_city(value) {
      let error_message = 'The school city field is required.';
      if (!value) {
        this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school_city: ['The school city field is required.'] });
        return false;
      }
      this.remove_form_field_errors('high_school_city', error_message);
      return true;
    },
    fillInAddress() {
      let place = this.google_autocomplete.getPlace();

      let fields = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        postal_code: 'long_name',
        postal_code_suffix: 'long_name',
        country: 'long_name'
      };

      for (let i = 0; i < place.address_components.length; i++) {
        let address_type = place.address_components[i].types[0];

        fields[address_type] = place.address_components[i][fields[address_type]];
      }

      let street_number = fields.street_number !== 'short_name' ? fields.street_number : '';
      let route_name = fields.route !== 'long_name' ? fields.route : '';
      let street_number_and_route_name = '';
      street_number_and_route_name =
        street_number && route_name ? street_number + ' ' + route_name : street_number ? street_number : route_name;
      document.getElementById('autocomplete').value = street_number_and_route_name;
      this.form.address_line_1 = street_number_and_route_name;

      let locality = fields.locality !== 'long_name' ? fields.locality : '';
      if (!locality) {
        locality = fields.sublocality_level_1 !== 'long_name' ? fields.sublocality_level_1 : '';
      }
      document.getElementById('locality').value = locality;
      this.form.address_locality = locality;
      this.form.address_municipality = locality;

      let administrative_area_level_1 = fields.administrative_area_level_1 !== 'short_name' ? fields.administrative_area_level_1 : '';
      document.getElementById('administrative_area_level_1').value = administrative_area_level_1;
      this.form.address_region = administrative_area_level_1;

      let postal_code = fields.postal_code !== 'long_name' ? fields.postal_code : '';
      let postal_code_suffix = fields.postal_code_suffix !== 'long_name' ? fields.postal_code_suffix : '';
      let post_code_and_suffix =
        postal_code && postal_code_suffix ? postal_code + '-' + postal_code_suffix : postal_code ? postal_code : postal_code_suffix;
      document.getElementById('address_postal_code').value = post_code_and_suffix;
      this.form.address_postal_code = post_code_and_suffix;

      let country_code = '';
      place.address_components.filter((ac) => {
        if (ac.types[0] === 'country') {
          country_code = ac.short_name;
        }
      });

      this.form.address_country_code = country_code;

      this.autocompleted = true;
    },
    resetCountriesList() {
      let countries = window.countries;

      countries = differenceBy(countries, this.citizenships, 'name');

      this.countries = countries;
    },
    resetCountryOfCitizenshipPositions() {
      this.form.country_of_citizenship_1 = this.citizenships[0] ? this.citizenships[0].alpha2 : '';
      this.form.country_of_citizenship_2 = this.citizenships[1] ? this.citizenships[1].alpha2 : '';
    },
    queryCountry(query) {
      let countryArr = [];

      this.countries.filter((country) => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
          countryArr.push(country);
        } else {
          this.resetCountriesList();
        }
      });

      if (query === null) this.resetCountriesList();

      this.countries = countryArr;
    },
    selectCountryOfCitizenship(selected, index) {
      if (selected === null && index === -1) {
        this.resetCountriesList();
      }

      if (selected && this.citizenships.length < this.maxCitizenships) {
        this.citizenships.push(selected);
        let input = $('#citizenship-country-search input');
        input.val(null);
        input.blur();

        if (this.citizenships.length >= this.maxCitizenships) input.hide();

        this.resetCountriesList();
      }

      this.resetCountryOfCitizenshipPositions();
    },
    removeCitizenship(index) {
      this.citizenships.splice(index, 1);
      this.resetCountriesList();

      let input = $('#citizenship-country-search input');

      if (this.citizenships.length < this.maxCitizenships) {
        input.show();
      }

      this.resetCountryOfCitizenshipPositions();
    },
    resetInterests() {
      let interests = [];

      this.interests.forEach((i, index) => {
        i.children.forEach((ii) => {
          interests.push({
            value: ii
          });
        });
      });

      // Computed differences so they won't show up again when selected
      interests = differenceBy(interests, this.selectedInterests, 'value');

      this.interestItems = interests;

      if (this.$refs.interestRef) {
        this.$refs.interestRef.inputValue = '';
      }
    },
    resetInterestPositions() {
      this.form.area_of_interest_1 = this.selectedInterests[0] ? this.selectedInterests[0].value : '';
      this.form.area_of_interest_2 = this.selectedInterests[1] ? this.selectedInterests[1].value : '';
      this.form.area_of_interest_3 = this.selectedInterests[2] ? this.selectedInterests[2].value : '';
    },
    queryInterests(query) {
      let interests = [];

      this.resetInterests();

      this.interestItems.filter((str) => {
        if (str.value.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          interests.push({
            value: str.value
          });
        }
      });

      this.interestItems = interests;
    },
    selectInterest(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedInterests.length < this.maxInterests) {
        this.selectedInterests.push(selectedObj);
        let input = $('#interests-search input');
        input.val('');
        input.blur();

        if (this.selectedInterests.length >= 3) {
          input.hide();
        }

        this.resetInterestPositions();
      }

      this.resetInterests();
    },
    removeInterest(index) {
      this.selectedInterests.splice(index, 1);
      this.resetInterestPositions();

      this.resetInterests();

      let input = $('#interests-search input');

      if (this.selectedInterests.length < 3) {
        input.show();
      }
    },
    removeCountries(index) {
      this.selectedCountries.splice(index, 1);
      this.resetCountryPosition();

      let input = $('#countries-search input');

      if (this.selectedCountries.length < 3) {
        input.show();
      }
    },
    resetCountries() {
      let countries = this.studyCountries;

      countries = differenceBy(countries, this.selectedCountries, 'name');

      this.countryItems = countries;
    },
    resetCountryPosition() {
      this.form.country_of_interest_1 = this.selectedCountries[0] ? this.selectedCountries[0].alpha2 : '';
      this.form.country_of_interest_2 = this.selectedCountries[1] ? this.selectedCountries[1].alpha2 : '';
      this.form.country_of_interest_3 = this.selectedCountries[2] ? this.selectedCountries[2].alpha2 : '';
    },
    queryCountries(query) {
      let countries = [];

      this.resetCountries();

      this.countryItems.filter((item) => {
        if (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          countries.push({
            name: item.name,
            alpha2: item.alpha2
          });
        }
      });

      this.countryItems = countries;
    },
    selectCountry(selectedObj) {
      if (selectedObj && selectedObj.value !== null && this.selectedCountries.length < this.maxCountries) {
        this.selectedCountries.push(selectedObj);
        let input = $('#countries-search input');
        input.val('');
        input.blur();

        if (this.selectedCountries.length >= this.maxCountries) {
          input.hide();
        }

        this.resetCountryPosition();
      }
    },
    querySchoolApi(search) {
      let query, queryURL;
      query = { query: search };

      if (this.form.fair) {
        query['fair_id'] = this.form.fair;
      }

      this.schoolQuery = search;

      queryURL = '/api/v2/schools';
      const queryString = Object.entries(query)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');
      if (queryString) {
        queryURL += `?${queryString}`;
      }

      axios.get(queryURL).then(
        ({ data }) => {
          this.schoolItems = data; // data expected to be an array
        },
        (err) => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selected = school;
        this.isHomeSchooled = false;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school_id = school.id;

        if (school.CEEB === CEEB_UNLISTED) {
          this.form.high_school = this.schoolQuery || '';
          this.form.high_school_city = '';
          this.form.high_school_region = '';
          this.form.high_school_country_code = this.form.address_country_code;

          if (student) {
            this.form.high_school = student.high_school;
            this.form.high_school_city = student.high_school_city;
            this.form.high_school_region = student.high_school_region;
            this.form.high_school_country_code = student.high_school_country ? student.high_school_country : this.form.address_country_code;
          }

          this.showHighschoolFields = true;
        } else if (school.CEEB === CEEB_HOMESCHOOLED) {
          this.form.high_school = school.school;
          this.form.high_school_city = this.form.address_municipality;
          this.form.high_school_region = this.form.address_region;
          this.form.high_school_country_code = this.form.address_country_code;
          this.showHighschoolFields = false;
          this.isHomeSchooled = true;
        } else {
          this.form.high_school = school.school;
          this.form.high_school_city = school.city;
          this.form.high_school_region = school.state;
          this.form.high_school_country_code = school.address.country_code;
          this.showHighschoolFields = false;
          this.validate('high_school', this.form.high_school);
        }
      }
    },
    signup() {
      if (this.birthdate) {
        this.form.birthdate = moment.utc(this.birthdate, 'MM/DD/YYYY').toISOString();
      }

      if (this.phone) {
        let intlPhoneInput = $('#intl-phone');
        let number = intlPhoneInput.val(),
          countryData = iti.getSelectedCountryData();
        this.form.phone_country_code = countryData.iso2.toUpperCase();
        this.form.phone = number;
      }

      this.isSubmitDisabled = true;

      let url = '/linden-university';
      let method = 'post';
      if (this.student) {
        url = url + '/update/' + this.student.hashed_created_at + '/' + this.student.id;
        method = 'put';
      }
      Spark[method](url, this.form)
        .then((response) => {
          window.location = response.redirect;
        })
        .catch((error) => {
          this.isSubmitDisabled = false;

          if (error.errors) {
            let twilioError = error.errors.filter((e) => {
              return e.title === 'twilio';
            });

            if (twilioError) {
              this.hasQRSendError = true;
              this.twilioFailure = twilioError[0].detail;
            }
          }

          Vue.nextTick(() => {
            const offset = $('.has-error:first').offset();

            if (offset) {
              $('body, html').animate({
                scrollTop: offset.top
              });
            }
          });
        });
    },
    setStudentForm(student) {
      this.autocompleted = true;

      // Popular required personal fields
      this.form.first_name = student.first_name;
      this.form.last_name = student.last_name;
      this.form.email = student.email;
      this.birthdate = moment(student.birthdate)
        .utc()
        .format('MM/DD/YYYY');
      this.form.birthdate = new Date(this.birthdate);
      this.form.text_permission = student.text_permission;
      this.form.has_parent_permission = student.has_parent_permission;

      this.form.address_line_1 = student.student_address.line1;
      this.form.address_line_2 = student.student_address.line2;
      this.form.address_locality = student.student_address.locality;
      this.form.address_municipality = student.student_address.municipality;
      this.form.address_postal_code = student.student_address.postal_code;
      this.form.address_country_code = student.student_address.country_code;
      this.form.address_region = student.student_address.region;

      this.upcomingFairs = [student.fair];
      this.form.fair = student.fair.id;

      this.form.high_school = student.high_school;
      this.form.high_school_ceeb = student.CEEB;
      this.form.high_school_id = student.school_id;
      this.form.high_school_city = student.high_school_city;
      this.form.high_school_region = student.high_school_region;
      this.form.high_school_country_code = student.high_school_country;
      this.form.grade_level = student.grade_level;

      if (student.school_ceeb_code === CEEB_UNLISTED) {
        this.showHighschoolFields = true;
      }

      this.form.college_start_semester = student.college_start_semester;

      // pre-populate Academic interests
      let interests_array = [];
      if (student.area_of_interest_1) {
        interests_array.push({ value: student.area_of_interest_1 });
      }

      if (student.area_of_interest_2) {
        interests_array.push({ value: student.area_of_interest_2 });
      }

      if (student.area_of_interest_3) {
        interests_array.push({ value: student.area_of_interest_3 });
      }
      this.selectedInterests = interests_array;

      if (interests_array.length >= 3) {
        let input = $('#interests-search input');
        input.hide();
      }

      this.resetInterestPositions();
      this.resetInterests();

      this.form.text_confirmation = student.notify_by_text;

      // Set Birthdate
      let splitBirthdate = this.birthdate.split('/');
      this.form.birthdate_month = splitBirthdate[0];
      this.form.birthdate_day = splitBirthdate[1];
      this.form.birthdate_year = splitBirthdate[2];

      // GPA / ACT / SAT / IELTS / TOEFL
      if (student.gpa > 0) {
        this.form.gpa = parseFloat(student.gpa).toFixed(2);
      }

      if (student.gpa_max > 0) {
        this.form.gpa_max = parseFloat(student.gpa_max).toFixed(2);
      }

      if (student.sat_score > 0) {
        this.form.sat_score = student.sat_score;
      }

      if (student.act_score > 0) {
        this.form.act_score = student.act_score;
      }

      if (student.toefl_score > 0) {
        this.form.toefl_score = student.toefl_score;
      }

      if (student.ielts_score > 0) {
        this.form.ielts_score = student.ielts_score;
      }

      // Set Current Year / Grade Level
      let currentYearGradeLevel = this.gradeSelectors.filter((item) => {
        return item.label === student.current_year_class;
      });
      this.grade_data = currentYearGradeLevel[0];

      this.validateAge();
      if (this.requiresParent) {
        this.form.has_parent_permission = true;
      }
      this.form.accept_tos = true;

      // Set Phone number
      this.form.phone = student.phone_number.number;
      this.phone = student.phone_number.formatted_number;
      this.form.phone_country_code = student.phone_number.country_code;
      $(() => {
        iti.setCountry(student.phone_number.country_code);
        iti.setNumber(student.phone_number.number);
      });
    },
    formatBirthdate() {
      return `${this.form.birthdate_month}/${this.form.birthdate_day}/${this.form.birthdate_year}`;
    },
    validateAge() {
      let isValidBirthdate = moment(this.birthdate, ['MM/D/YYYY', 'MM/DD/YYYY'], true).isValid();
      if (this.form.birthdate_day && this.form.birthdate_month && this.form.birthdate_year && isValidBirthdate) {
        const age = moment().diff(new Date(this.birthdate), 'years');
        this.requiresParent = false;
        if (age < this.requiresParentUnderAge) {
          this.requiresParent = true;
          this.isSubmitDisabled = !this.form.has_parent_permission;
        } else {
          this.isSubmitDisabled = false;
        }
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.isHomeSchooled = false;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';

      this.schoolQuery = '';
      this.querySchoolApi('');
    },
    showAllFairs() {
      this.loading = true;
      axios
        .get('/api/v2/fairs/students/index?occurrence=upcoming&order=asc&student_type=LINDEN&limit=999')
        .then((success) => {
          this.upcomingFairs = success.data.data;

          if (this.student) {
            this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFairValidation() {
      this.$nextTick(() => {
        this.remove_form_field_errors('fair', 'The fair field is required.');
      });
    }
  }
});
