export const handleFairRadiusOnChangedMethod = function(event) {
  this.fairRadius = event.target.value;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let options = {};

  if(urlParams.has('tag')) {
    options = {...options, tag: urlParams.get('tag')};
  }

  if (this.lat && this.lng) {
    let lat, lng;
    lat = this.lat;
    lng = this.lng;
    this.setUpcomingFairs({ ...options, lat, lng });
  }
};

export const validateSATScoreMethod = function(value) {
  let error_message = window.$translate('The :test score must be between :min and :max.', {
    test: window.$translate('SAT'),
    min: 200,
    max: 1600
  });
  if (value && (value < 200 || value > 1600)) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { sat_score: [error_message] });
    return false;
  }
  this.remove_form_field_errors('sat_score', error_message); // todo: This should be kicked out to component
  return true;
};

export const validateACTScoreMethod = function(value) {
  let error_message = window.$translate('The :test score must be between :min and :max.', {
    test: window.$translate('ACT'),
    min: 1,
    max: 36
  });
  if (value && (value < 1 || value > 36)) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { act_score: [error_message] });
    return false;
  }
  this.remove_form_field_errors('act_score', error_message); // todo: This should be kicked out to component
  return true;
};

export const validateEmailMethod = function(value) {
  let regex = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
  let error_message = window.$translate('The email must be a valid email address.');
  if (typeof this.validate_confirm_email === 'function') {
    this.validate_confirm_email();
  }
  if (value && !regex.test(value.toLowerCase())) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email: [error_message] });
    return false;
  }
  this.remove_form_field_errors('email', error_message);
  return true;
};

export const validateConfirmEmailMethod = function(value) {
  let error_message = window.$translate('The email confirmation does not match.');
  if (value !== this.form.email) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { email_confirmation: [error_message] });
    return false;
  }
  this.remove_form_field_errors('email_confirmation', error_message);
  return true;
};

export const validateBirthdateMethod = function(value) {
  let regex = /(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}/;
  let error_message = window.$translate('Birthdate must be in the format `MM/DD/YYYY`');

  this.form.birthdate = '';

  if (value && !regex.test(value)) {
    if (this.form.errors.errors['birthdate']) {
      this.form.errors.errors['birthdate'].push(error_message);
    } else {
      this.form.errors.errors = Object.assign({}, this.form.errors.errors, { birthdate: [error_message] });
    }
    return false;
  } else if (value) {
    this.form.birthdate = new Date(value);
  }
  this.remove_form_field_errors('birthdate', error_message);
  return true;
};

export const validateGradTypeMethod = function(value) {
  const error_message = window.$translate('The :field field is required.', { field: window.$translate('grade') });

  if (!value) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { grad_type: [error_message] });
    return false;
  }

  return true;
};

export const clearFairValidationMethod = function() {
  this.$nextTick(() => {
    const error_message = window.$translate('The :field field is required.', { field: window.$translate('fair') });
    this.remove_form_field_errors('fair', error_message);
  });
};

export const schoolDataClearMethod = function() {
  this.selectedSchool = null;
  this.isHomeSchooled = false;
  this.showHighschoolFields = false;
  this.form.high_school_ceeb = 0;
  this.form.high_school_id = '';
  this.form.high_school = '';
  this.form.high_school_city = '';
  this.form.high_school_region = '';

  this.schoolQuery = '';
  this.querySchoolApi('');
};

export const getFairsInRegionMethod = function(position) {
  let coordinates = position.coords;
  let lat = coordinates.latitude;
  let lng = coordinates.longitude;

  this.showDistanceDropdown = true;

  if (lat && lng) {
    this.lat = lat;
    this.lng = lng;
    this.setUpcomingFairs({ lat, lng });
  } else {
    this.upcomingFairs = [];
  }
};

export const validateCollegeStartSemesterMethod = function(value) {
  let error_message = window.$translate('The :field field is required.', { field: window.$translate('university start semester') });
  if (!value) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { college_start_semester: [error_message] });
    return false;
  }
  this.remove_form_field_errors('college_start_semester', error_message);
  return true;
};

export const validateHighSchoolMethod = function(value) {
  const error_message = window.$translate('The :field field is required.', { field: window.$translate('school') });
  if (!value) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school: [error_message] });
    return false;
  }
  this.remove_form_field_errors('high_school', error_message);
  return true;
};

export const validateHighSchoolCityMethod = function(value) {
  const error_message = window.$translate('The :field field is required.', { field: window.$translate('school city') });
  if (!value) {
    this.form.errors.errors = Object.assign({}, this.form.errors.errors, { high_school_city: [error_message] });
    return false;
  }
  this.remove_form_field_errors('high_school_city', error_message);
  return true;
};

export const querySchoolApiMethod = function(search) {
  let query, queryURL;
  query = { query: search };

  if (this.form.fair) {
    query['fair_id'] = this.form.fair;
  }

  this.schoolQuery = search;

  queryURL = '/api/schools';
  const queryString = Object.entries(query)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');
  if (queryString) {
    queryURL += `?${queryString}`;
  }

  axios.get(queryURL).then(
    ({ data }) => {
      this.schoolItems = data; // data expected to be an array
      let student = this.$cookies.get(this.cookie_name);
      if (student) {
        let school = data.find((item) => {
          return item.id === student.high_school_id;
        });
        this.selectSchool(school);
      }
    },
    (err) => {
      this.schoolItems = [];
    }
  );
};

export const selectSchoolMethod = function(school) {
  const CEEB_UNLISTED = '000003';
  const CEEB_HOMESCHOOLED = '970000';

  if (school) {
    this.selectedSchool = school;
    this.isHomeSchooled = false;
    this.form.high_school_ceeb = school.CEEB;
    this.form.high_school_id = school.id;

    if (school.CEEB === CEEB_UNLISTED) {
      this.form.high_school = this.schoolQuery || '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';
      this.form.high_school_country_code = this.form.address_country_code;

      if (student) {
        this.form.high_school = student.high_school;
        this.form.high_school_city = student.high_school_city;
        this.form.high_school_region = student.high_school_region;
        this.form.high_school_country_code = student.high_school_country ? student.high_school_country : this.form.address_country_code;
      }

      this.showHighschoolFields = true;
    } else if (school.CEEB === CEEB_HOMESCHOOLED) {
      this.form.high_school = school.school;
      this.form.high_school_city = this.form.address_municipality;
      this.form.high_school_region = this.form.address_region;
      this.form.high_school_country_code = this.form.address_country_code;
      this.showHighschoolFields = false;
      this.isHomeSchooled = true;
    } else {
      this.form.high_school = school.school;
      this.form.high_school_city = school.city;
      this.form.high_school_region = school.state;
      this.form.high_school_country_code = school.address.country_code;
      this.showHighschoolFields = false;
      this.validate('high_school', this.form.high_school);
    }
  }
};

export const setUpcomingFairsMethod = function(query) {
  this.loading = true;
  query.student_type = this.studentType;

  if (query.lat && query.lng) {
    query.radius = this.fairRadius;
  }

  query.occurrence = 'upcoming';
  query.limit = '999';

  let url = '/api/v2/fairs/students/index';
  const queryString = Object.entries(query)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');
  if (queryString) {
    url += `?${queryString}`;
  }

  axios
    .get(url)
    .then(({ data }) => {
      this.upcomingFairs = data.data;

      if (this.student) {
        this.upcomingFairs = [this.student.fair, ...this.upcomingFairs];
      }

      if (this.fairRadius === 'unknown' && data.meta && data.meta.radius) {
        let radius = data.meta.radius;
        for (let i = 0; i < this.radiusLimits.length; i++) {
          if (radius <= this.radiusLimits[i]) {
            this.fairRadius = this.radiusLimits[i];
            break;
          }
        }
        if (this.fairRadius === 'unknown') {
          this.fairRadius = 'any';
        }
      }
    })
    .catch((error) => {
      this.upcomingFairs = [];
    })
    .finally(() => {
      this.loading = false;
    });
};