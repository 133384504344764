// import Pagination from './pagination';
import Pagination from './PaginationVariant.vue';

Vue.component('credits-transaction-log', {
    components: { Pagination },

    props: {
        user: Object || null,
        user_mode: {
            type: [Number, String, Boolean],
            default: false
        },
        team: Object || null,
    },

    data() {
        return {
            isLoading: true,
            date: null,
            type: null,
            transactions: [],
            showModal: true,
            currentTransaction: {
                notes: '',
            },
            pagination: {
                current_page: 1,
                page_count: 1,
                per_page: 20,
                total_count: 1
              },
            form: new SparkForm({
                type: 'add',
                team_id: null,
                note: null,
                amount: 0,
            }),
            fields_to_validate: {
                team_id: ['required'],
                note: ['required'],
                amount: ['required'],
            },
        };
    },

    computed: {
        hasTransactions() {
            return this.transactions.length > 0 && this.transactions !== null;
        }
    },

    watch: {
        'pagination.current_page': function() {
            this.loadData();
        },
        date: function() {
            this.loadData();
        },

        type: function() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData(); 
    },

    created() {
        var self = this;
        Bus.$on('currentPageUpdated', function(pageNumber) {
            self.pagination.current_page = Number(pageNumber);
        });
    },

    methods: {
        loadData() {
            const isUserMode = this.user_mode == 1;

            let url = '/api/v2/credits-transaction-log?page=' + this.pagination.current_page;
            if(this.user && isUserMode) {
                url = `/api/v2/credits-transaction-log/${this.user.id}?page=` + this.pagination.current_page;
            }

            if (this.date) {
                url += `&date=${this.date}`;
            }

            if (this.type) {
                url += `&type=${this.type}`;
            }

            axios
                .get(url)
                .then(({ data }) => {
                    if (data.data) {
                        this.transactions = data.data;
                        this.pagination = data.meta.pagination;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlert('Error', 'Failed to load Credit Transaction Log!');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        hasReceipt(transaction) {
            return transaction.stripe_receipt_url !== null;
        },

        hasNotes(transaction) {
            return transaction.notes !== null;
        },

        showNotes(transaction) {
            this.currentTransaction = transaction;
            $('#modal-credits-transaction-notes').modal('show');
        },

        getType(transaction) {
            switch(transaction.type) {
                case 'purchase':
                    return 'Purchase';
                case 'unlock':
                case 'unlock_free':
                case 'unlock_api':
                    return 'Unlock';
                case 'relock': 
                    return 'Relock';
                case 'credit':
                    return 'Add';
                case 'consume':
                    return 'Deduct';
                default:
                    return 'Unknown';
            }
        },

        getAmount(transaction) {
            let prefix = '';

            switch(transaction.type) {
                case 'relock': 
                case 'credit':
                case 'purchase':
                    prefix = '+';
                    break;
                case 'unlock':
                case 'unlock_api':
                case 'consume':
                    prefix = '-';
                    break;
                case 'unlock_free':
                default:
                    prefix = '';
                    break;
            }

            return `${prefix}${transaction.purchased_amount ? transaction.purchased_amount : Math.abs((transaction.new_balance - transaction.previous_balance))}`;
        },
        
        getFormattedDate(transaction) {
            const centralTime = moment(transaction.created_at)
                .tz('America/Chicago') // Use the correct timezone
                .utcOffset(-5, true); // UTC-6 for CST, UTC-5 for CDT

            return centralTime.format('MM-DD-YY');
        },

        getFairURL(transaction) {
            if(!transaction.fair_id || !transaction.fair)
                return '';
            return `<a class="credits-transaction-log-fair-name target="_blank" href="/fairs/${transaction.fair_id}">${transaction.fair.name}</a>`;
        },

        getUserURL(transaction) {
            if(this.user_mode) {
                if(transaction.type == 'added' || transaction.type == 'consume' || transaction.type == 'credit') {
                    return 'StriveScan';
                }
                return transaction.user.name;
            }
            return transaction.user.name;
        },

        changeType(event) {
            this.type = event.target.value;
        },

        successAlert(title, message) {
            swal(title, message, 'success');
        },

        errorAlert(title, message) {
            swal(title, message, 'error');
        },

        showModalView() {
            this.resetForm();
            $('#modal-add-consume-credits').modal('show');
            if(this.team && this.team.id) {
                this.form.team_id = this.team.id;
            }
        },

        resetForm() {
            this.form.type = 'add';
            this.form.team_id = null;
            this.form.note = null;
            this.form.amount = 0;
        },

        runModalAction() {
            let url = '/api/credits/';
            if(this.form.type === 'add') {
                url += 'add';
            }
            if(this.form.type === 'consume') {
                url += 'consume';
            }

            Spark
                .post(url, this.form)
                .then(
                    () => {
                        this.successAlert('Success', 'The transaction was successfully completed.');
                        this.loadData();
                        $('#modal-add-consume-credits').modal('hide');
                    },
                    () => {
                        this.errorAlert('Error', 'Failed to perform action!');
                    }
                );
        },
    },
});