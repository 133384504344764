import dashboardFairList from '../components/dashboard/index/dashboardFairList';
import dashboardRegistrationForm from '../components/dashboard/registration/dashboardRegistrationForm';
import CardDashboardError from '../components/dashboard/CardDashboardError.vue';
import Snackbar from '../components/Snackbar.vue';
import UpdateDashboardProfileInfo from '../components/dashboard/settings/update-dashboard-profile-info';

Vue.component('dashboard-fair-list', dashboardFairList);
Vue.component('dashboard-registration-form', dashboardRegistrationForm);
Vue.component('dashboard-error-card', CardDashboardError);
Vue.component('snackbar', Snackbar);
Vue.component('update-dashboard-profile-info', UpdateDashboardProfileInfo);
