<template>
  <div>
    <div id="modal-event-guide-delete-all" class="modal fade dashboard-modal" tabindex="-1" role="dialog">
      <div>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" aria-hidden="true" @click="closeModal">
                &times;
              </button>
              <h3 class="modal-title">
                Delete All Institutions
              </h3>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete <strong>all institutions</strong>?</p>
              <p>This action cannot be undone.</p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" style="background: none;" @click="closeModal">
                CANCEL
              </button>
              <button class="btn btn-primary" style="margin-left: 10px;" @click="deleteAndClose">
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideDeleteAllModal',

  props: {
    fair: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  methods: {
    closeModal() {
      $('#modal-event-guide-delete-all').modal('hide');
    },
    deleteAndClose() {
      axios.delete(`/api/dashboards/fairs/${this.fair.id}/fair-participants`).then(
        () => {
          $('#modal-event-guide-delete-all').modal('hide');
          Bus.$emit('deleteAllEventGuideParticipants');
        },
      );
    },
  }
};
</script>