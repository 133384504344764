export class ApiSurface {
  /**
   *
   * @param {AxiosRequestConfig} request
   * @returns {Promise<undefined>} async
   */
  _request(request) {
    return this._axios.request(this.appendTokenHeaders(request));
  }
  appendTokenHeaders(request) {
    if (request.headers === undefined) {
      request.headers = {};
    }
    if (this._csrfToken) {
      request.headers['X-CSRF-TOKEN'] = this._csrfToken;
    }
    if (this._xsrfToken) {
      request.headers['X-XSRF-TOKEN'] = this._xsrfToken;
    }
    return request;
  }
  constructor(axiosHttp) {
    this._axios = axiosHttp;
    this._xsrfToken = window.Cookies.get('XSRF-TOKEN');
    this._csrfToken = window.Spark.csrfToken;
  }
}
