Vue.filter('phone', {
  // model -> view
  // formats the value when updating the input element.
  read: function(val) {
    if (val === null || val === undefined || val.length <= 3) {
      return val;
    } else if (val.length > 3 && val.length <= 7) {
      return val.slice(0, 3) + '-' + val.slice(3);
    } else {
      return '(' + val.slice(0, 3) + ') ' + val.slice(3, 6) + '-' + val.slice(6);
    }
  },
  // view -> model
  // formats the value when writing to the data.
  write: function(val, oldVal) {
    if (val === '') {
      return '';
    }
    var number = +val.replace(/[\(\)\- A-z]/g, '');
    return isNaN(number) ? '' : number.toFixed(0);
  }
});
