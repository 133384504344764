<template>
  <div class="dashboard-error-container">
    <div class="icon-container">
      <slot name="icon" />
    </div>
    <p class="dashboard-error-title">
      {{ title }}
    </p>
    <p class="dashboard-error-subtitle">
      {{ subtitle }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardDashboardError',
  props: ['title', 'subtitle']
};
</script>

<style scoped>
.dashboard-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: white;
  padding: 24px;
}
.icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.dashboard-error-title {
  font-weight: 500;
  font-size: 29px;
  line-height: 32px;
}

.dashboard-error-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(17, 24, 39, 0.7);
}

.material-icons {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.6);
}

.dashboard-error-card-info {
  background: #f3f4f6;
  margin-top: 40px;
  padding: 12px 16px;
  color: rgba(17, 24, 39, 0.7);
  border-radius: 4px;
}

.dashboard-error-card-info a {
  font-weight: 700;
  color: #662c8f;
}
</style>
