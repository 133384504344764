<template>
  <div class="participants-list">
    <div class="container">
      <h2>{{ $translate('Participants') }} ({{ participants.length }})</h2>
      <event-guide-mapbox v-if="mapParticipants.length > 0" :participants="mapParticipants"></event-guide-mapbox>
      <div v-for="participant in participants" :key="participant.id" class="info-row">
        <div class="left-content">
          <div class="participant-image" :style="{
            backgroundImage: `url(${participant.image_url})`,
          }"></div>
          <div>{{ participant.name }}</div>
          <div class="right-content-small">{{ participant.city ? participant.city + ', ' : '' }}{{ participant.state }}</div>
        </div>
        <div class="right-content">
          {{ participant.city ? participant.city + ', ' : '' }}{{ participant.state }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventGuideMapbox from './EventGuideMapbox.vue';

export default {
  name: 'EventGuideParticipantsList',
  components: {
    EventGuideMapbox,
  },
  props: {
    participants: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    mapParticipants() {
      return this.participants.filter((participant) => participant.lat && participant.lng);
    },
  },
  methods: {
  },
};
</script>

<style scoped>
.participants-list {
  width: 100%;
  background-color: #2B1341;
  border-radius: 40px 40px 0 0;

  .container {
    padding: 20px;
  }

  h2 {
    color: #FFF;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.12px;
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }

  .info-row {
    align-items: center;
    color: #FFF;
    display: flex;
    flex-direction: row;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 10px;

    .left-content {
      display: flex;
      align-items: center;
      gap: 10px;

      .participant-image {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .right-content-small {
        display: none;
      }
    }

    .right-content {
      text-align: right;
    }
  }

  @media screen and (max-width: 768px) {
    .info-row {
      .left-content {
        .right-content-small {
          display: block;
        }
      }

      .right-content {
          display: none;
      }
    }
  }
}
</style>