<template>
  <div class="participants-search">
    <div class="container">
      <h2>{{ $translate('Participants') }} <span class="count">({{ count }})</span></h2>
      <div class="tab-bar" role="tablist">
        <md-tabs ref="tabs">
          <md-secondary-tab id="search-tab" aria-controls="search-panel" inline-icon>
            <md-icon slot="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4 4.5C3.44771 4.5 3 4.94772 3 5.5C3 6.05228 3.44771 6.5 4 6.5C4.55228 6.5 5 6.05228 5 5.5C5 4.94772 4.55228 4.5 4 4.5ZM7.5 5C7.22386 5 7 5.22386 7 5.5C7 5.77614 7.22386 6 7.5 6H16.5C16.7761 6 17 5.77614 17 5.5C17 5.22386 16.7761 5 16.5 5H7.5ZM3 10C3 9.44772 3.44771 9 4 9C4.55228 9 5 9.44772 5 10C5 10.5523 4.55228 11 4 11C3.44771 11 3 10.5523 3 10ZM7.5 9.5C7.22386 9.5 7 9.72386 7 10C7 10.2761 7.22386 10.5 7.5 10.5H16.5C16.7761 10.5 17 10.2761 17 10C17 9.72386 16.7761 9.5 16.5 9.5H7.5ZM3 14.5C3 13.9478 3.44769 13.5 4 13.5C4.55231 13.5 5 13.9478 5 14.5C5 15.0522 4.55231 15.5 4 15.5C3.44769 15.5 3 15.0522 3 14.5ZM7.5 14C7.22386 14 7 14.2239 7 14.5C7 14.7761 7.22386 15 7.5 15H16.5C16.7761 15 17 14.7761 17 14.5C17 14.2239 16.7761 14 16.5 14H7.5Z"
                  fill="white" />
              </svg>
            </md-icon>
            {{ $translate('List View') }}
          </md-secondary-tab>
          <md-secondary-tab id="map-tab" aria-controls="map-panel" inline-icon>
            <md-icon slot="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 4C7.82514 4 6 5.82514 6 8C6 8.63033 6.23869 9.32462 6.63891 10.0361C7.03654 10.743 7.57349 11.4314 8.12165 12.0405C8.66836 12.648 9.21669 13.166 9.62906 13.5325C9.77118 13.6589 9.89674 13.7668 10 13.8539C10.1033 13.7668 10.2288 13.6589 10.3709 13.5325C10.7833 13.166 11.3316 12.648 11.8784 12.0405C12.4265 11.4314 12.9635 10.743 13.3611 10.0361C13.7613 9.32462 14 8.63033 14 8C14 5.82514 12.1749 4 10 4ZM10 14.5C9.69303 14.8947 9.69293 14.8946 9.69282 14.8945L9.6925 14.8943L9.69157 14.8935L9.68847 14.8911L9.6775 14.8825L9.63712 14.8504C9.60228 14.8225 9.55196 14.7819 9.48836 14.7294C9.3612 14.6244 9.18073 14.472 8.96469 14.28C8.53331 13.8965 7.95665 13.352 7.37836 12.7095C6.80152 12.0686 6.21347 11.3195 5.76734 10.5264C5.32381 9.73788 5 8.86967 5 8C5 5.27286 7.27286 3 10 3C12.7271 3 15 5.27286 15 8C15 8.86967 14.6762 9.73788 14.2327 10.5264C13.7865 11.3195 13.1985 12.0686 12.6216 12.7095C12.0434 13.352 11.4667 13.8965 11.0353 14.28C10.8193 14.472 10.6388 14.6244 10.5116 14.7294C10.448 14.7819 10.3977 14.8225 10.3629 14.8504L10.3225 14.8825L10.3115 14.8911L10.3084 14.8935L10.3075 14.8943L10.3072 14.8945C10.3071 14.8946 10.307 14.8947 10 14.5ZM10 14.5L10.307 14.8947C10.1264 15.0351 9.87359 15.0351 9.69303 14.8947L10 14.5ZM8 8C8 6.89543 8.89543 6 10 6C11.1046 6 12 6.89543 12 8C12 9.10457 11.1046 10 10 10C8.89543 10 8 9.10457 8 8ZM10 7C9.44771 7 9 7.44771 9 8C9 8.55229 9.44771 9 10 9C10.5523 9 11 8.55229 11 8C11 7.44771 10.5523 7 10 7ZM5 12.5C4.79999 12.5 4.61922 12.6192 4.54043 12.803L3.04043 16.303C2.97422 16.4575 2.99006 16.6349 3.08258 16.7752C3.1751 16.9156 3.33193 17 3.5 17H16.5C16.6681 17 16.8249 16.9156 16.9174 16.7752C17.0099 16.6349 17.0258 16.4575 16.9596 16.303L15.4596 12.803C15.3808 12.6192 15.2 12.5 15 12.5H14.0773C13.8012 12.5 13.5773 12.7239 13.5773 13C13.5773 13.2761 13.8012 13.5 14.0773 13.5H14.6703L15.7417 16H4.25827L5.3297 13.5H5.9227C6.19884 13.5 6.4227 13.2761 6.4227 13C6.4227 12.7239 6.19884 12.5 5.9227 12.5H5Z"
                  fill="#C8C0D3" />
              </svg>
            </md-icon>
            {{ $translate('Map View') }}
          </md-secondary-tab>
        </md-tabs>
      </div>
    </div>
    <div class="container content">
      <div v-if="tab === 0" id="search-panel" class="search-panel" role="tabpanel" aria-labelledby="search-tab">
        <div class="search-bar">
          <div class="input-group participant-search">
            <input type="text" placeholder="Search" id="institution_search" v-model="search" class="form-control">
            <span class="input-group-btn">
              <button type="button" class="btn btn-primary search-button">
                <i class="fa fa-search fa-lg"></i>
              </button>
            </span>
          </div>
          <div class="second-row">
            <div class="search-filters-button-small">
              <button class="search-filters-small" @click="showSearchSmall = !showSearchSmall">
                {{ $translate('filters') }} {{ filterCount > 0 ? `(${filterCount})` : '' }}
                <md-icon slot="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="iconWrapper">
                      <path id="Union"
                        d="M9.16667 15H10.8333C11.2917 15 11.6667 14.625 11.6667 14.1667C11.6667 13.7083 11.2917 13.3333 10.8333 13.3333H9.16667C8.70833 13.3333 8.33333 13.7083 8.33333 14.1667C8.33333 14.625 8.70833 15 9.16667 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM5.83333 10.8333H14.1667C14.625 10.8333 15 10.4583 15 10C15 9.54167 14.625 9.16667 14.1667 9.16667H5.83333C5.375 9.16667 5 9.54167 5 10C5 10.4583 5.375 10.8333 5.83333 10.8333Z"
                        fill="#C8C0D3" />
                    </g>
                  </svg>
                </md-icon>
              </button>
            </div>
            <div class="favorites-toggle">
              <button class="favorites-button" @click="showFavorites = !showFavorites" :class="{
                'active': showFavorites,
              }">
                <md-icon v-if="!showFavorites" slot="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="iconWrapper">
                      <path id="Union" fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.4833 10.645C2.48357 10.6453 2.48384 10.6455 2.4841 10.6458L8.54992 16.6555C8.54993 16.6555 8.54993 16.6555 8.54994 16.6555C9.3724 17.4703 10.634 17.4703 11.4565 16.6555C11.4565 16.6555 11.4565 16.6555 11.4565 16.6555L17.5223 10.6458C17.5226 10.6455 17.5229 10.6452 17.5232 10.6449C19.3226 8.8445 19.0744 5.89291 17.5601 4.12676C16.8186 3.26191 15.798 2.71743 14.605 2.74093C13.407 2.76453 11.9507 3.36306 10.3567 4.95704C10.1615 5.1523 9.8449 5.1523 9.64964 4.95704C8.05258 3.35998 6.59391 2.75924 5.39433 2.73434C4.19988 2.70955 3.17884 3.25341 2.43757 4.11796C0.923981 5.88328 0.677312 8.83803 2.4833 10.645ZM10.0032 3.90938C8.40676 2.42976 6.84233 1.76418 5.41508 1.73456C3.87751 1.70265 2.58377 2.41113 1.67841 3.46706C-0.101036 5.54245 -0.475189 9.10068 1.77699 11.3529L1.77864 11.3545L7.84613 17.3659L7.84614 17.3659C9.05836 18.5668 10.948 18.5668 12.1603 17.3659L12.1603 17.3659L18.2278 11.3545L18.2294 11.3529C20.4765 9.1059 20.098 5.55043 18.3193 3.47586C17.4141 2.4202 16.1214 1.71086 14.5853 1.74112C13.1594 1.76921 11.597 2.43264 10.0032 3.90938Z"
                        fill="#C8C0D3" />
                    </g>
                  </svg>
                </md-icon>
                <md-icon v-else slot="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="iconWrapper">
                      <path id="Union" fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.49372 2.25247C8.18744 2.28676 10.068 3.09247 12.0034 4.92676C13.9354 3.09761 15.8143 2.29361 17.5063 2.26104C19.2892 2.22676 20.7943 3.04961 21.8537 4.2839C23.9417 6.71818 24.3737 10.8839 21.7543 13.5016L21.7526 13.5033L14.472 20.717C13.0834 22.0919 10.9234 22.0919 9.53486 20.717L2.25601 13.5033C-0.371993 10.8736 0.0548642 6.7079 2.14286 4.27018C3.20058 3.0359 4.70572 2.21304 6.49201 2.25076L6.49372 2.25247Z"
                        fill="#FFFFFF" />
                    </g>
                  </svg>
                </md-icon>
                {{ $translate('My List') }}
              </button>
            </div>
          </div>
        </div>
        <div class="search-box">
          <div class="search-filters" :class="{
            'show-small': showSearchSmall,
            'hide-small': !showSearchSmall,
          }">
            <div class="filters-buttons">
              <button type="button" class="button" style="color: #662C8F;background: #FFF;border: 1px solid #562782;" @click="clearAll">{{ $translate('Clear') }}</button>
              <button type="button" class="button" style="color: #FFF;background: #662C8F;border: none;" @click="applyFilters">{{ $translate('Apply') }}</button>
            </div>
            <div class="filters-header">
              <h3>{{ $translate('Filters')}} ({{ filterCount }} {{ $translate('applied') }})</h3>
              <div class="clear">
                <button v-if="!showSearchSmall" @click="clearAll">{{ $translate('Clear All') }}</button>
                <button v-else @click="showSearchSmall = false" class="close-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18.3002 5.70997C17.9102 5.31997 17.2802 5.31997 16.8902 5.70997L12.0002 10.59L7.11022 5.69997C6.72022 5.30997 6.09021 5.30997 5.70021 5.69997C5.31021 6.08997 5.31021 6.71997 5.70021 7.10997L10.5902 12L5.70021 16.89C5.31021 17.28 5.31021 17.91 5.70021 18.3C6.09021 18.69 6.72022 18.69 7.11022 18.3L12.0002 13.41L16.8902 18.3C17.2802 18.69 17.9102 18.69 18.3002 18.3C18.6902 17.91 18.6902 17.28 18.3002 16.89L13.4102 12L18.3002 7.10997C18.6802 6.72997 18.6802 6.08997 18.3002 5.70997Z" fill="#8C90A1"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="filters-content">
              <div v-for="filter in filters" v-if="filter.options.length > 0" class="filters-section">
                <div class="filters-section-header" @click="toggleFilter(filter)">
                  <h4>{{ filter.label }}</h4>
                  <div class="expand">
                    <button class="expand-button">
                      <md-icon v-if="filter.expanded" slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M9.40833 7.2584L5.58333 11.0834C5.25833 11.4084 5.25833 11.9334 5.58333 12.2584C5.90833 12.5834 6.43333 12.5834 6.75833 12.2584L10 9.02507L13.2333 12.2584C13.5583 12.5834 14.0833 12.5834 14.4083 12.2584C14.7333 11.9334 14.7333 11.4084 14.4083 11.0834L10.5833 7.2584C10.2667 6.9334 9.73333 6.9334 9.40833 7.2584Z"
                            fill="#050505" />
                        </svg>
                      </md-icon>
                      <md-icon v-else slot="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M13.2333 7.74155L9.99998 10.9749L6.76665 7.74155C6.44165 7.41655 5.91665 7.41655 5.59165 7.74155C5.26665 8.06655 5.26665 8.59155 5.59165 8.91655L9.41665 12.7416C9.74165 13.0666 10.2667 13.0666 10.5917 12.7416L14.4166 8.91655C14.7416 8.59155 14.7416 8.06655 14.4166 7.74155C14.0916 7.42489 13.5583 7.41655 13.2333 7.74155Z"
                            fill="#050505" />
                        </svg>
                      </md-icon>
                    </button>
                  </div>
                </div>
                <div v-if="!filter.expanded && filter.value?.length > 0" class="filters-section-content">
                  <div>
                    {{ selectedFilters(filter) }}
                  </div>
                </div>
                <div v-if="filter.expanded" class="filters-section-content">
                  <template v-if="filter.type === 'checkbox'">
                    <div v-if="filter.label === $translate('Programs/Majors')" class="program-search">
                      <div class="program-search-wrapper">
                        <input 
                          type="text" 
                          v-model="programSearch" 
                          :placeholder="$translate('Search programs...')"
                          class="program-search-input"
                        />
                        <button 
                          v-if="programSearch" 
                          class="program-search-clear" 
                          @click="programSearch = ''"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.25 4.75C14.925 4.425 14.4 4.425 14.075 4.75L10 8.825L5.925 4.75C5.6 4.425 5.075 4.425 4.75 4.75C4.425 5.075 4.425 5.6 4.75 5.925L8.825 10L4.75 14.075C4.425 14.4 4.425 14.925 4.75 15.25C5.075 15.575 5.6 15.575 5.925 15.25L10 11.175L14.075 15.25C14.4 15.575 14.925 15.575 15.25 15.25C15.575 14.925 15.575 14.4 15.25 14.075L11.175 10L15.25 5.925C15.567 5.608 15.567 5.075 15.25 4.75Z" fill="#8C90A1"/>
                          </svg>
                        </button>
                      </div>
                      <div class="program-match-mode">
                        <span>{{ $translate('Match') }}:</span>
                        <div class="program-match-options">
                          <div class="match-option">
                            <input 
                              type="radio" 
                              id="match-any" 
                              value="any" 
                              v-model="programMatchMode"
                            />
                            <label for="match-any">{{ $translate('Any') }}</label>
                          </div>
                          <div class="match-option">
                            <input 
                              type="radio" 
                              id="match-all" 
                              value="all" 
                              v-model="programMatchMode"
                            />
                            <label for="match-all">{{ $translate('All of these') }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-for="option in getFilteredOptions(filter)" 
                        :key="typeof option.value === 'string' ? option.value : JSON.stringify(option.value)" 
                        class="filter-option-container">
                      <template v-if="option.type === 'parent'">
                        <div class="filter-option">
                          <input 
                            type="checkbox" 
                            :id="option.label" 
                            :value="option.value" 
                            :checked="option.options.every(child => filter.value.includes(child.value))"
                            @change="e => {
                              if (e.target.checked) {
                                filter.value = [...new Set([...filter.value, ...option.value])];
                              } else {
                                filter.value = filter.value.filter(val => !option.value.includes(val));
                              }
                            }"
                          />
                          <label :for="option.label">{{ $translate(option.label) }}</label>
                        </div>
                        
                        <div class="sub-filters-section-content">
                          <div v-for="childOption in option.options" class="sub-filter-option">
                            <input 
                              type="checkbox" 
                              :id="childOption.value" 
                              :value="childOption.value" 
                              v-model="filter.value"
                            />
                            <label :for="childOption.value">{{ $translate(childOption.label) }}</label>
                          </div>
                        </div>
                      </template>
                      
                      <template v-else>
                        <div class="filter-option">
                          <input type="checkbox" :id="option.value" :value="option.value" v-model="filter.value" />
                          <label :for="option.value" v-if="filter.label === $translate('Programs/Majors') && programSearch">
                            <span v-html="highlightMatch(option.label, programSearch)"></span>
                          </label>
                          <label :for="option.value" v-else>{{ $translate(option.label) }}</label>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-if="filter.type === 'radio'" v-for="option in filter.options">
                    <div class="filter-option" v-if="option.label === $translate('Distance') || option.options?.length > 0">
                      <input type="radio" :id="option.label" :value="option.label" v-model="filter.value" />
                      <label :for="option.label">{{ $translate(option.label) }}</label>
                    </div>
                    <div v-if="option.label === $translate('Distance') && filter.value === $translate('Distance')"
                      class="sub-filters-section-content">
                      <h5 for="distance">{{ $translate('Postal / Zip Code') }}</h5>
                      <input type="text" v-model="option.value" />

                      <div v-if="postalCodeGeolocation" class="distance-options">
                        <h5 for="distance">{{ $translate('How Far?') }}</h5>
                        <div v-for="distance in option.sub.options" class="sub-filter-option">
                          <input type="radio" :id="distance.value" :value="distance.value" v-model="option.sub.value" />
                          <label :for="distance.value">{{ $translate(distance.label) }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="filter.value === option.label" class="sub-filters-section-content">
                      <div v-for="opt in option.options" class="sub-filter-option">
                        <input type="checkbox" :id="opt.value" :value="opt.value" v-model="option.value" />
                        <label :for="opt.value">{{ $translate(opt.label) }}</label>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="search-results">
            <div class="no-results" v-if="searchParticipants.length === 0">
              <h3>{{ $translate('No Results') }}</h3>
            </div>
            <div class="search-participant" v-for="participant in searchParticipants" :key="participant.id" :class="{
              'is-canceled': participant.is_canceled,
            }">
              <div class="left-content">
                <div class="participant-image" :style="{
                  backgroundImage: `url(${participant.image_url})`,
                }"></div>
              </div>
              <div class="center-content">
                <span :class="{ 'strikethrough': participant.is_canceled }" style="font-weight: 600;">{{ participant.name }}</span>
                <span style="color: #4B4B4B;">
                  {{ participant.city ? participant.city + ', ' : '' }}{{ participant.state }}
                </span>
                <template v-if="!participant.is_canceled">
                  <span style="color: #4B4B4B;">{{ participant.location }}</span>
                </template>
                <template v-else>
                  <span class="canceled">{{ $translate('Canceled') }}</span>
                </template>
                <br />
                <button class="view-details" @click="viewParticipantDetails(participant)">
                  {{ $translate('View Details') }}
                  <md-icon slot="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M4.16667 10.8333H13.475L9.40834 14.8999C9.08334 15.2249 9.08334 15.7583 9.40834 16.0833C9.73334 16.4083 10.2583 16.4083 10.5833 16.0833L16.075 10.5916C16.4 10.2666 16.4 9.7416 16.075 9.4166L10.5917 3.9166C10.2667 3.5916 9.74167 3.5916 9.41667 3.9166C9.09167 4.2416 9.09167 4.7666 9.41667 5.0916L13.475 9.1666H4.16667C3.70834 9.1666 3.33334 9.5416 3.33334 9.99994C3.33334 10.4583 3.70834 10.8333 4.16667 10.8333Z"
                        fill="#562782" />
                    </svg>
                  </md-icon>
                </button>
              </div>
              <div class="right-content">
                <button class="favorite-participant" @click="toggleFavorite(participant.id)">
                  <md-icon v-if="!participant.is_favorite" slot="icon">
                    <svg class="heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90942 12.8449C2.90969 12.8451 2.90996 12.8454 2.91022 12.8457L10.1895 20.0577C10.1895 20.0577 10.1896 20.0577 10.1896 20.0577C11.2155 21.074 12.7922 21.074 13.8182 20.0577L21.0975 12.8457C21.0978 12.8454 21.0981 12.8451 21.0984 12.8448C23.3025 10.6397 22.9917 7.03728 21.148 4.88703C20.2419 3.83013 18.9899 3.16027 17.524 3.18915C16.0531 3.21812 14.2824 3.95278 12.3574 5.87775C12.1621 6.07301 11.8455 6.07301 11.6503 5.87775C9.72167 3.94913 7.94806 3.21181 6.47529 3.18124C5.00763 3.15078 3.75511 3.81987 2.84918 4.87648C1.00627 7.0259 0.697531 10.632 2.90942 12.8449ZM12.0039 4.828C10.077 3.01957 8.19777 2.21677 6.49603 2.18146C4.68526 2.14388 3.16004 2.97759 2.09002 4.22557C-0.0187511 6.68506 -0.454972 10.8946 2.20311 13.5528L2.20476 13.5544L9.48574 20.768L9.48576 20.7681C10.9014 22.1706 13.1063 22.1706 14.5219 20.7681L14.522 20.768L21.8029 13.5544L21.8046 13.5528C24.4564 10.901 24.0153 6.6948 21.9072 4.23613C20.8374 2.98843 19.3133 2.1537 17.5043 2.18934C15.8043 2.22283 13.9274 3.02304 12.0039 4.828Z" fill="#C40081"/>
                    </svg>
                  </md-icon>
                  <md-icon v-else slot="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.49369 2.25247C8.18741 2.28676 10.068 3.09247 12.0034 4.92676C13.9354 3.09761 15.8143 2.29361 17.5063 2.26104C19.2891 2.22676 20.7943 3.04961 21.8537 4.2839C23.9417 6.71818 24.3737 10.8839 21.7543 13.5016L21.7526 13.5033L14.472 20.717C13.0834 22.0919 10.9234 22.0919 9.53483 20.717L2.25598 13.5033C-0.372024 10.8736 0.0548337 6.7079 2.14283 4.27018C3.20055 3.0359 4.70569 2.21304 6.49198 2.25076L6.49369 2.25247Z"
                        fill="#AA0070" />
                    </svg>
                  </md-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab === 1" id="map-panel" role="tabpanel" aria-labelledby="map-tab">
        <event-guide-mapbox v-if="mapParticipants.length > 0" :participants="mapParticipants"></event-guide-mapbox>
      </div>
    </div>
    <event-guide-participant-modal v-if="viewingParticipant" :participant="participant" :student="student" @close="viewingParticipant = false" />
  </div>
</template>

<script>
import EventGuideMapbox from './EventGuideMapbox.vue';
import EventGuideParticipantModal from './EventGuideParticipantModal.vue';
import '@material/web/tabs/secondary-tab.js';
import { MdTabs } from '@material/web/tabs/tabs.js';
import { MdIcon } from '@material/web/icon/icon.js';

export default {
  name: 'EventGuideParticipantsSearch',
  components: {
    EventGuideMapbox,
    EventGuideParticipantModal,
  },
  props: {
    participants: {
      type: Array,
      required: true,
      default: () => [],
    },
    student: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    states: {
      type: Array,
      required: true,
      default: () => [],
    },
    countries: {
      type: Array,
      required: true,
      default: () => [],
    },
    fair: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      participant: null,
      viewingParticipant: false,
      localParticipants: JSON.parse(JSON.stringify(this.participants)),
      showSearchSmall: false,
      tabs: null,
      tab: 0,
      search: '',
      showFavorites: false,
      admissionRateCategories: [
        { label: this.$translate('Most Selective (0-10%)'), value: 'most-selective', min: 0, max: 10 },
        { label: this.$translate('Very Selective (11-25%)'), value: 'very-selective', min: 11, max: 25 },
        { label: this.$translate('Selective (26-50%)'), value: 'selective', min: 26, max: 50 },
        { label: this.$translate('Less Selective (51-75%)'), value: 'less-selective', min: 51, max: 75 },
        { label: this.$translate('Not Very Selective (76-100%)'), value: 'not-very-selective', min: 76, max: 100 }
      ],
      sizeCategories: [
        { label: this.$translate('Small (<2,000)'), value: 'small', min: 0, max: 1999 },
        { label: this.$translate('Medium (2,000-15,000)'), value: 'medium', min: 2000, max: 14999 },
        { label: this.$translate('Large (15,000+)'), value: 'large', min: 15000, max: Infinity }
      ],
      campusSettingCategories: [
        { label: this.$translate('City'), value: 'City' },
        { label: this.$translate('Suburban'), value: 'Suburb' },
        { label: this.$translate('Town'), value: 'Town' },
        { label: this.$translate('Rural'), value: 'Rural' }
      ],
      specialtyCategories: [
        {
          label: this.$translate('Alaska Native and Native Hawaiian-serving Institution'),
          value: 'latest.school.minority_serving.annh',
        },
        {
          label: this.$translate('Asian American and Native American-Pacific Islander-serving Institution'),
          value: 'latest.school.minority_serving.aanipi',
        },
        {
          label: this.$translate('Hispanic-serving Institution (HSI)'),
          value: 'latest.school.minority_serving.hispanic',
        },
        {
          label: this.$translate('Historically Black College and University (HBCU)'),
          value: 'latest.school.minority_serving.historically_black',
        },
        {
          label: this.$translate('Men-only'),
          value: 'latest.school.men_only',
        },
        {
          label: this.$translate('Native American Non-Tribal Institution'),
          value: 'latest.school.minority_serving.nant',
        },
        {
          label: this.$translate('Predominantly Black Institution'),
          value: 'latest.school.minority_serving.predominantly_black',
        },
        {
          label: this.$translate('Tribal College or University'),
          value: 'latest.school.minority_serving.tribal',
        },
        {
          label: this.$translate('Women-only'),
          value: 'latest.school.women_only',
        },
      ],
      typeHierarchyMap: {
        'College/University': {
          label: this.$translate('College/University'),
          value: 'College or University in the United States',
          options: [
            { label: this.$translate('Public'), value: 'Public', field: 'latest.school.ownership' },
            { label: this.$translate('Private nonprofit'), value: 'Private nonprofit', field: 'latest.school.ownership' },
            { label: this.$translate('Private for-profit'), value: 'Private for-profit', field: 'latest.school.ownership' }
          ]
        },
        'College or University outside of the United States': { label: this.$translate('College or University outside of the United States'), value: 'College or University outside of the United States' },
        'Business': { label: this.$translate('Business'), value: 'Business' },
        'Organization': { label: this.$translate('Organization'), value: 'Organization' },
        'Trade/Trade School': { label: this.$translate('Trade/Trade School'), value: 'Trade/Trade School' },
        'Military': { label: this.$translate('Military'), value: 'Military' },
        'Other': { label: this.$translate('Other'), value: 'Other' }
      },
      filters: [],
      postalCode: '',
      postalCodeGeolocation: null,
      locationFilter: null,
      debounceTimeout: null,
      programSearch: '',
      programMatchMode: 'any',
    };
  },
  computed: {
    count() {
      if (this.filterCount > 0) {
        return `${this.searchParticipants.length} of ${this.localParticipants.length}`;
      }

      return this.searchParticipants.length;
    },
    mapParticipants() {
      return this.localParticipants.filter((participant) => participant.lat && participant.lng);
    },
    filterCount() {
      let count = 0;

      const countValues = (filter) => {
        if (filter.type === 'checkbox') {
          count += filter.value.length;
        } else if (filter.type === 'radio' && filter.value !== null) {
          count += 1;
        }
      };

      this.filters.forEach((filter) => {
        if (filter.label === this.$translate('Location')) {
          filter.options.forEach((subFilter) => {
            if (subFilter.label === this.$translate('Distance') && subFilter.sub) {
              // Only count if one of the sub radio buttons is selected
              if (subFilter.sub.value !== null) {
                count += 1;
              }
            } else if (subFilter.type === 'checkbox') {
              // Only count values that are selected in the value array
              count += subFilter.value.length;
            }
          });
        } else {
          countValues(filter);
          if (filter.options && Array.isArray(filter.options)) {
            filter.options.forEach((subFilter) => {
              if (subFilter.sub) {
                countValues(subFilter.sub);
              }
              countValues(subFilter);
            });
          }
        }
      });

      return count;
    },
    searchParticipants() {
      let participants = this.search ? this.localParticipants.filter((participant) => {
        return participant.name.toLowerCase().includes(this.search.toLowerCase());
      }) : this.localParticipants;

      participants = this.showFavorites ? participants.filter((participant) => participant.is_favorite) : participants;

      if (this.filters.length === 0) {
        return participants;
      }

      this.filters.forEach((filter) => {
        if (filter.label === this.$translate('Type')) {
          // Handle parent-child relationship for Type filter
          const selectedValues = filter.value.reduce((acc, val) => {
            const parentOption = filter.options.find(opt => opt.type === 'parent' && Array.isArray(opt.value) && opt.value.includes(val));
            if (parentOption && parentOption.value.includes(val)) {
              // Only add the specific selected value
              return [...acc, val];
            }
            return [...acc, val];
          }, []);

          if (selectedValues.length === 0) {
            return participants;
          }

          participants = participants.filter((participant) => {
            return selectedValues.includes(participant.organization_type) 
              || (participant.college_scorecard_dataset?.data?.latest?.school?.ownership && selectedValues.includes(participant.college_scorecard_dataset.data.latest.school.ownership));
          });
        } else if (filter.label === this.$translate('Location')) {
          if (filter.value === this.$translate('Distance') && this.postalCodeGeolocation) {
            const distance = filter.options.find((option) => option.label === this.$translate('Distance')).sub.value;

            if (!distance) {
              return;
            }

            participants = this.filterParticipantsByDistance(participants, distance);
          } else if (filter.value === this.$translate('States & Territories (U.S.)') && filter.options[1].value.length > 0) {
            participants = participants.filter((participant) => {
              return filter.options[1].value.includes(participant.state);
            });
          } else if (filter.value === this.$translate('Countries') && filter.options[2].value.length > 0) {
            participants = participants.filter((participant) => {
              return filter.options[2].value.includes(participant.country);
            });
          }
        } else if (filter.type === 'checkbox' && filter.value.length > 0) {
          if (filter.label === this.$translate('Specialty')) {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset !== null;
            }).filter((participant) => {
              return filter.value.some((specialty) => {
                return this.getNestedProperty(participant.college_scorecard_dataset.data, specialty) === 'Yes';
              });
            });
          } else if (filter.label === this.$translate('Admission Rate')) {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset !== null;
            }).filter((participant) => {
              return filter.value.some((admissionRate) => {
                const category = this.admissionRateCategories.find((category) => category.value === admissionRate);
                const { min, max } = category;

                if (participant.college_scorecard_dataset.data.latest.admissions.admission_rate.overall === null) {
                  return max === 100 && participant.college_scorecard_dataset.data.latest.school.open_admissions_policy === 'Yes';
                }

                const rate = Math.floor(participant.college_scorecard_dataset.data.latest.admissions.admission_rate.overall * 100);
                return rate >= min && rate <= max;
              });
            });
          } else if (filter.label === this.$translate('Size (undergraduates)')) {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset !== null;
            }).filter((participant) => {
              return filter.value.some((sizeCategory) => {
                const minSize = this.sizeCategories.find((category) => category.value === sizeCategory).min;
                const maxSize = this.sizeCategories.find((category) => category.value === sizeCategory).max;
                const size = participant.college_scorecard_dataset.data.latest.student.size;
                return size >= minSize && size <= maxSize;
              });
            });
          } else if (filter.label === this.$translate('Campus Setting')) {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset !== null;
            }).filter((participant) => {
              const locale = participant.college_scorecard_dataset.data.latest.school.locale;
              return typeof locale === 'string' && locale.startsWith(filter.value);
            });
          } else if (filter.label === this.$translate('Programs/Majors')) {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset !== null;
            }).filter((participant) => {
              if (this.programMatchMode === 'any') {
                return filter.value.some((program) => {
                  return participant.college_scorecard_dataset.data.latest.programs.cip_4_digit.includes(program);
                });
              } else {
                return filter.value.every((program) => {
                  return participant.college_scorecard_dataset.data.latest.programs.cip_4_digit.includes(program);
                });
              }
            });
          } else {
            participants = participants.filter((participant) => {
              return participant.college_scorecard_dataset?.data && filter.value.includes(this.getNestedProperty(participant.college_scorecard_dataset.data, filter.field));
            });
          }
        }
      });

      return participants;
    },
    filteredProgramOptions() {
      const filter = this.filters.find(f => f.label === this.$translate('Programs/Majors'));
      if (!filter || !this.programSearch) return filter?.options || [];
      
      // Get all selected options
      const selectedOptions = filter.options.filter(option => 
        filter.value.includes(option.value)
      );
      
      // Get filtered options based on search
      const searchedOptions = filter.options.filter(option => 
        option.label.toLowerCase().includes(this.programSearch.toLowerCase())
      );
      
      // Combine selected and searched options, removing duplicates
      return [...new Set([...selectedOptions, ...searchedOptions])]
        .sort((a, b) => {
          // Sort selected items to the top
          const aSelected = filter.value.includes(a.value);
          const bSelected = filter.value.includes(b.value);
          if (aSelected && !bSelected) return -1;
          if (!aSelected && bSelected) return 1;
          return a.label.localeCompare(b.label);
        });
    }
  },
  mounted() {
    this.tabs = new MdTabs(this.$refs.tabs);

    this.$refs.tabs.addEventListener('change', (event) => {
      this.tab = event.target.activeTabIndex;
    });

    this.filters = [
      {
        label: this.$translate('Type'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.school.ownership',
        options: this.typeOptions(),
      },
      {
        label: this.$translate('Location'),
        expanded: false,
        type: 'radio',
        value: null,
        options: [
          {
            label: this.$translate('Distance'),
            type: 'text',
            value: null,
            sub: {
              label: this.$translate('How Far?'),
              type: 'radio',
              value: null,
              options: [
                {
                  label: this.$translate('20 mi / 30 km (Short Drive)'),
                  value: '20',
                },
                {
                  label: this.$translate('50 mi / 80 km (Moderate Drive)'),
                  value: '50',
                },
                {
                  label: this.$translate('200 mi / 300 km (Long Drive)'),
                  value: '200',
                },
                {
                  label: this.$translate('500 mi / 800 km (Short Flight)'),
                  value: '500',
                },
                {
                  label: this.$translate('2000 mi / 3200 km (Long Fight)'),
                  value: '2000',
                },
              ],
            },
          },
          {
            label: this.$translate('States & Territories (U.S.)'),
            type: 'checkbox',
            value: [],
            options: this.stateOptions(),
          },
          {
            label: this.$translate('Countries'),
            type: 'checkbox',
            value: [],
            options: this.countryOptions(),
          },
        ],
      },
      {
        label: this.$translate('Admission Rate'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.admissions.admission_rate.overall',
        options: this.admissionRateOptions(),
      },
      {
        label: this.$translate('Size (undergraduates)'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.student.size',
        options: this.sizeOptions(),
      },
      {
        label: this.$translate('Campus Setting'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.school.locale',
        options: this.campusSettingOptions(),
      },
      {
        label: this.$translate('Specialty'),
        expanded: false,
        type: 'checkbox',
        value: [],
        options: this.specialtyOptions(),
      },
      {
        label: this.$translate('Religious Affiliation'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.school.religious_affiliation',
        options: this.religiousOptions(),
      },
      {
        label: this.$translate('Programs/Majors'),
        expanded: false,
        type: 'checkbox',
        value: [],
        field: 'latest.programs.cip_4_digit',
        options: this.programOptions(),
      }
    ];
  },
  watch: {
    filters: {
      handler(newVal) {
        const newPostalCodeValue = newVal[1]?.options[0]?.value;
        if (newPostalCodeValue !== this.postalCode) {
          this.postalCode = newPostalCodeValue;
          this.debounceGeocodePostalCode();
        }

        const newLocationFilter = newVal[1]?.value;
        if (newLocationFilter !== this.locationFilter) {
          if (newLocationFilter === this.$translate('Distance')) {
            this.filters[1].options[0].value = this.fair.venue.address.postal_code;
          } else if (this.locationFilter === this.$translate('Distance')) {
            this.filters[1].options[0].value = null;
            this.filters[1].options[0].sub.value = null;
            this.postalCodeGeolocation = null;
            this.postalCode = '';
          }
          this.locationFilter = newLocationFilter;
        }
      },
      deep: true
    },
    showSearchSmall(newVal) {
      if (newVal) {
        // Prevent body scrolling when filter modal is open
        document.body.style.overflow = 'hidden';
      } else {
        // Restore body scrolling when filter modal is closed
        document.body.style.overflow = 'auto';
      }
    }
  },
  methods: {
    selectedFilters(filter) {
      if (filter.type === 'checkbox') {
        // Map the values to their corresponding labels
        const selectedValues = filter.value.map(value => {
          // Handle parent-child relationship for Type filter
          if (filter.label === this.$translate('Type')) {
            const parentOption = filter.options.find(opt => 
              opt.type === 'parent' && opt.options?.some(child => child.value === value)
            );
            if (parentOption) {
              return {
                parent: this.$translate(parentOption.label),
                child: this.$translate(parentOption.options.find(child => child.value === value)?.label || value)
              };
            }
          }
          
          // For all other filters, find the matching option and return its label
          const option = filter.options.find(opt => opt.value === value);
          return this.$translate(option?.label || value);
        });

        // Group by parent for Type filter
        if (filter.label === this.$translate('Type')) {
          const grouped = selectedValues.reduce((acc, val) => {
            if (typeof val === 'object') {
              if (!acc[val.parent]) {
                acc[val.parent] = [];
              }
              acc[val.parent].push(val.child);
            } else {
              if (!acc['other']) {
                acc['other'] = [];
              }
              acc['other'].push(val);
            }
            return acc;
          }, {});

          return Object.entries(grouped)
            .map(([parent, children]) => {
              if (parent === 'other') {
                return children.join(this.$translate(', '));
              }
              return `${parent}: ${children.join(this.$translate(', '))}`;
            })
            .join(this.$translate('; '));
        }

        return selectedValues.join(this.$translate(', '));
      } else if (filter.type === 'radio') {
        if (filter.label === this.$translate('Location')) {
          if (filter.value === this.$translate('Distance')) {
            const distanceOption = filter.options.find(opt => opt.label === this.$translate('Distance'));
            const zipCode = distanceOption?.value;
            const distance = distanceOption?.sub?.value;
            
            if (zipCode && distance) {
              const distanceLabel = this.$translate(distanceOption.sub.options.find(opt => opt.value === distance)?.label);
              return `${this.$translate('Distance')}: ${zipCode} - ${distanceLabel}`;
            } else if (zipCode) {
              return `${this.$translate('Distance')}: ${zipCode}`;
            }
          } else if (filter.value === this.$translate('States & Territories (U.S.)')) {
            const states = filter.options[1].value;
            if (states.length) {
              return `${this.$translate('States & Territories (U.S.)')}: ${states.map(state => 
                this.$translate(this.states.find(s => s.abbreviation.toLowerCase() === state.toLowerCase())?.region_name || state)
              ).join(this.$translate(', '))}`;
            }
          } else if (filter.value === this.$translate('Countries')) {
            const countries = filter.options[2].value;
            if (countries.length) {
              return `${this.$translate('Countries')}: ${countries.map(country => 
                this.$translate(this.countries.find(c => c.alpha2.toLowerCase() === country.toLowerCase())?.name || country)
              ).join(this.$translate(', '))}`;
            }
          }
        }
        return this.$translate(filter.value);
      }
    },
    applyFilters() {
      this.showSearchSmall = false;
    },
    filterParticipantsByDistance(participants, maxDistance) {
      const R = 3959; // Radius of the Earth in miles
      return participants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).filter((participant) => {
        const dLat = this.deg2rad(participant.lat - this.postalCodeGeolocation.lat);
        const dLng = this.deg2rad(participant.lng - this.postalCodeGeolocation.lng);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(this.postalCodeGeolocation.lat)) * Math.cos(this.deg2rad(participant.lat)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in miles
        return distance <= maxDistance;
      });
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    geocodePostalCode() {
      if (this.filters.find((filter) => filter.label === this.$translate('Location')).value === this.$translate('Distance')) {
        const postalCode = this.filters.find((filter) => filter.label === this.$translate('Location')).options.find((option) => option.label === this.$translate('Distance')).value;

        if (!postalCode || postalCode.length < 5 || postalCode.length > 10) {
          this.postalCodeGeolocation = null;
          return;
        }

        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${postalCode}.json?access_token=${window['MAPBOX_ACCESS_TOKEN']}`)
          .then((response) => response.json())
          .then((data) => {
            this.postalCodeGeolocation = {
              lng: data.features[0].center[0],
              lat: data.features[0].center[1]
            };
          });
      }
    },
    debounceGeocodePostalCode() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.geocodePostalCode();
      }, 500);
    },
    getNestedProperty(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    toggleFilter(filter) {
      filter.expanded = !filter.expanded;
    },
    typeOptions() {
      // Get unique types from participants
      const types = this.localParticipants.reduce((acc, participant) => {
        if (participant.organization_type) {
          const organizationType = participant.organization_type;
          if (organizationType === 'College or University in the United States') {
            if (!acc.includes('College/University')) {
              acc.push('College/University');
            }
          } else if (!acc.includes(organizationType)) {
            acc.push(organizationType);
          }
        }
        return acc;
      }, []);

      // Return options in the same order as typeHierarchyMap
      return Object.entries(this.typeHierarchyMap)
        .filter(([key]) => types.includes(key))
        .map(([type, config]) => {
          if (type === 'College/University') {
            const collegeOptions = config.options.filter(option => 
              this.localParticipants.some(participant => 
                participant.college_scorecard_dataset?.data?.latest?.school?.ownership === option.value
              )
            );
            
            if (collegeOptions.length > 0) {
              return {
                label: config.label,
                value: collegeOptions.map(opt => opt.value),
                type: 'parent',
                options: collegeOptions
              };
            }
          } else {
            return {
              label: config.label,
              value: type
            };
          }
        })
        .filter(Boolean); // Remove any undefined entries
    },
    stateOptions() {
      return this.localParticipants.reduce((acc, participant) => {
        if (participant.state && 
            this.states.find(state => state.abbreviation === participant.state.trim()) && 
            !acc.find(state => state.value === participant.state.trim())) {
          acc.push({
            label: this.states.find(state => state.abbreviation === participant.state.trim()).region_name,
            value: participant.state.trim(),
          });
        }
        acc.sort((a, b) => a.label.localeCompare(b.label));
        return acc;
      }, []);
    },
    countryOptions() {
      return this.localParticipants.reduce((acc, participant) => {
        if (participant.country && !acc.find((country) => country.value === participant.country.trim())) {
          acc.push({
            label: this.countries.find(c => c.alpha2.toLowerCase() === participant.country.trim().toLowerCase())?.name || participant.country.trim(),
            value: participant.country.trim(),
          });
        }
        return acc;
      }, []);
    },
    religiousOptions() {
      const options = this.localParticipants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).reduce((acc, participant) => {
        const affiliation = participant.college_scorecard_dataset.data.latest.school.religious_affiliation;
        // Check if affiliation exists and is not already in acc
        if (affiliation && !acc.find((religion) => religion.value === affiliation.trim())) {
          acc.push({
            label: affiliation.trim(),
            value: affiliation.trim(),
          });
        }
        // Add "No religious affiliation" option if affiliation is explicitly null
        if (affiliation === null && !acc.find((religion) => religion.value === null)) {
          acc.push({
            label: this.$translate('No Religious Affiliation'),
            value: null,
          });
        }
        return acc;
      }, []);
      
      return options.sort((a, b) => a.label.localeCompare(b.label));
    },
    programOptions() {
      return this.localParticipants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).reduce((acc, participant) => {
        const programs = participant.college_scorecard_dataset.data.latest.programs.cip_4_digit;
        if (Array.isArray(programs)) {
          programs.forEach((program) => {
            const trimmedProgram = program.trim();
            if (!acc.find((p) => p.value === trimmedProgram)) {
              acc.push({
                label: trimmedProgram.replace(/\./g, ''),
                value: trimmedProgram,
              });
            }
          });
        }
        return acc;
      }, []);
    },
    getAdmissionRateParticipants(minRate, maxRate) {
      return this.localParticipants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).filter((participant) => {
        if (participant.college_scorecard_dataset.data.latest.admissions.admission_rate.overall === null) {
          if (maxRate === 100 && participant.college_scorecard_dataset.data.latest.school.open_admissions_policy === 'Yes') {
            return true;
          }
          return false;
        }
        const rate = Math.floor(participant.college_scorecard_dataset.data.latest.admissions.admission_rate.overall * 100);
        return rate >= minRate && rate <= maxRate;
      });
    },
    admissionRateOptions() {
      return this.admissionRateCategories.filter(category =>
        this.getAdmissionRateParticipants(category.min, category.max).length > 0
      ).map(category => ({
        label: category.label,
        value: category.value
      }));
    },
    getSizeParticipants(minSize, maxSize) {
      return this.localParticipants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).filter((participant) => {
        const size = participant.college_scorecard_dataset.data.latest.student.size;
        return size >= minSize && size <= maxSize;
      });
    },
    sizeOptions() {
      return this.sizeCategories.filter(category =>
        this.getSizeParticipants(category.min, category.max).length > 0
      ).map(category => ({
        label: category.label,
        value: category.value
      }));
    },
    getCampusSettingParticipants(setting) {
      return this.localParticipants.filter((participant) => {
        return participant.college_scorecard_dataset !== null;
      }).filter((participant) => {
        const locale = participant.college_scorecard_dataset.data.latest.school.locale;
        return typeof locale === 'string' && locale.startsWith(setting);
      });
    },
    campusSettingOptions() {
      return this.campusSettingCategories.filter(setting =>
        this.getCampusSettingParticipants(setting.value).length > 0
      ).map(setting => ({
        label: setting.label,
        value: setting.value
      }));
    },
    specialtyOptions() {
      return this.specialtyCategories.filter(setting =>
        this.localParticipants.filter((participant) => {
          return participant.college_scorecard_dataset !== null;
        }).find(participant => this.getNestedProperty(participant.college_scorecard_dataset.data, setting.value) === 'Yes')
      ).map(setting => ({
        label: setting.label,
        value: setting.value
      }));
    },
    clearAll() {
      this.search = '';
      this.postalCode = '';
      this.postalCodeGeolocation = null;
      this.showFavorites = false;
      this.locationFilter = null;

      this.filters.forEach((filter) => {
        if (filter.type === 'checkbox') {
          filter.value = [];
        } else if (filter.type === 'radio') {
          filter.value = null;
          filter.options.forEach((option) => {
            if (option.type === 'checkbox') {
              option.value = [];
            } else if (option.type === 'text') {
              option.value = null;
              option.sub.value = null;
            }
          });
        }
      });

      if (this.showSearchSmall) {
        this.showSearchSmall = false;
      }
    },
    toggleFavorite(participantId) {
      axios.post(`/api/fair_participants/${participantId}/favorite`, {
        hash: this.student.hashed_created_at,
        student_id: this.student.id,
      }).then((response) => {
        const participant = this.localParticipants.find((participant) => participant.id === participantId);
        participant.is_favorite = !participant.is_favorite;
      });
    },
    viewParticipantDetails(participant) {
      this.participant = participant;
      this.viewingParticipant = true;
    },
    getFilteredOptions(filter) {
      if (filter.label === this.$translate('Programs/Majors')) {
        // Get all selected options
        const selectedOptions = filter.options.filter(option => 
          filter.value.includes(option.value)
        ).map(option => ({
          ...option,
          label: this.$translate(option.label) // Translate selected options
        })).sort((a, b) => a.label.localeCompare(b.label));
        
        // Get filtered options based on search that aren't already selected
        const searchedOptions = filter.options.filter(option => 
          !filter.value.includes(option.value) && // Exclude selected options
          this.$translate(option.label).toLowerCase().includes(this.programSearch.toLowerCase()) // Search in translated text
        ).map(option => ({
          ...option,
          label: this.$translate(option.label) // Translate filtered options
        })).sort((a, b) => a.label.localeCompare(b.label));
        
        // Return selected options first, followed by filtered unselected options
        return [...selectedOptions, ...searchedOptions];
      }
      return filter.options;
    },
    highlightMatch(text, search) {
      if (!search) return text;
      const regex = new RegExp(`(${search})`, 'gi');
      return text.replace(regex, '<span style="color: #562782; font-weight: 700;">$1</span>');
    },
  },
};
</script>

<style scoped>
.participants-search {
  width: 100%;
  background-color: #2B1341;
  border-radius: 40px 40px 0 0;

  .container {
    padding: 20px 20px 0 20px;
    display: flex;

    .count {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .container.content {
    padding: 0 20px 20px 20px;
    display: flex;
  }

  .tab-bar {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
    text-transform: uppercase;

    /* System tokens */
    --md-sys-color-surface: none;
    --md-sys-color-primary: #FFF;
    --md-sys-color-on-surface: #FFF;

    /* Component tokens */
    --md-secondary-tab-label-text-font: 'Quicksand', sans-serif;
    --md-secondary-tab-label-text-size: 14px;
    --md-secondary-tab-label-text-weight: 700;
    --md-secondary-tab-label-text-color: #FFF;
    --md-secondary-tab-active-indicator-color: #FFF;
    --md-secondary-tab-icon-color: #FFF;
    --md-secondary-tab-active-label-text-color: #FFF;
    --md-secondary-tab-focus-label-text-color: #FFF;
    --md-secondary-tab-hover-label-text-color: #FFF;
  }

  h2 {
    color: #FFF;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.12px;
    text-align: left;
    margin-top: 20px;
    flex: 1;
  }

  .info-row {
    align-items: center;
    color: #FFF;
    display: flex;
    flex-direction: row;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 700;
    gap: 10px;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 10px;

    .left-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .right-content {
      text-align: right;
    }
  }

  #map-panel {
    width: 100%;
  }

  .search-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Quicksand', sans-serif;

    h3 {
      font-family: Quicksand;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      text-align: left;
      margin-top: 20px;
      flex: 1;
    }

    .search-bar {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .participant-search {
        flex: 1;

        input {
          background: #FFFFFF;
        }
      }

      .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .search-filters-button-small {
          display: none;

          .search-filters-small {
            background: none;
            border: none;
            border-radius: 20px;
            color: #FFF;
            font-family: Quicksand;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            padding: 5px 10px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #C8C0D3;
          }

          .search-filters-small:hover {
            color: #FFF;

            svg path {
              fill: #FFF;
            }
          }
        }
      }

      .favorites-toggle {
        display: flex;
        margin-left: 20px;

        .favorites-button {
          background: none;
          border: none;
          border-radius: 20px;
          color: #FFF;
          font-family: Quicksand;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          padding: 5px 10px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #C8C0D3;
        }

        .favorites-button.active {
          color: #FFF;
        }

        .favorites-button:hover {
          color: #FFF;

          svg path {
            fill: #FFF;
          }
        }
      }
    }

    .search-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .search-filters {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border-radius: 14px;
        flex: 1;
        padding: 10px 20px;
        height: 850px;

        .filters-buttons {
          display: none;
        }

        .filters-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 10px;

          h3 {
            margin: 0;
            font-family: Quicksand;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
          }

          .clear {
            display: flex;
            align-items: center;

            button {
              background: none;
              border: none;
              color: #562782;
              font-family: Quicksand;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
              text-transform: uppercase;
              padding: 0;
              display: flex;
              align-items: center;
            }

            .close-button {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px;
            }

            button:hover {
              color: #C8C0D3;
            }

            button:active {
              color: #562782;
            }
          }
        }

        .filters-content {
          overflow: scroll;
          height: 800px;

          .filters-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);

            .filters-section-header {
              cursor: pointer;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              h4 {
                display: flex;
                font-family: Quicksand;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.14px;
              }

              .expand {
                display: flex;
                align-items: end;

                .expand-button {
                  background: none;
                  border: none;
                  font-family: Quicksand;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 16px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
              }
            }

            .filters-section-content {
              display: flex;
              flex-direction: column;
              padding: 10px 0;

              input {
                margin: 2px 0 0 2px;
              }

              input[type="checkbox"],
              input[type="radio"] {
                accent-color: #662C8F;
              }

              label {
                font-family: Quicksand;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.14px;
                margin: 0 0 0 10px;
                font-weight: 500;
                margin-left: 10px;
              }

              .filter-option {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin-bottom: 10px;
              }

              .sub-filters-section-content {
                display: flex;
                flex-direction: column;
                padding: 0 0 10px 25px;

                h5 {
                  display: flex;
                  font-family: Quicksand;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16px;
                  letter-spacing: 0.14px;
                  margin-top: 0;
                }

                .distance-options {
                  h5 {
                    margin-top: 10px;
                  }
                }

                .sub-filter-option {
                  display: flex;
                  flex-direction: row;
                  align-items: baseline;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }

      .search-results {
        display: flex;
        flex-direction: column;
        flex: 3;
        margin-left: 20px;

        .no-results {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          color: white;
        }

        .search-participant {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: #FFFFFF;
          border-radius: 14px;
          padding: 16px;
          margin-bottom: 20px;

          .left-content {
            .participant-image {
              width: 50px;
              height: 50px;
              border-radius: 4px;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          &.is-canceled {
            background-color: #D9D9D9;
          }

          .center-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 0 20px;

            .strikethrough {
              text-decoration: line-through;
            }

            .canceled {
              color: #8B005B;
            }

            .view-details {
              background: none;
              border: none;
              color: #562782;
              font-family: Quicksand;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              gap: 4px;
            }

            .view-details:hover {
              color: #C8C0D3;
            }
          }

          .right-content {
            .favorite-participant {
              background: none;
              border: none;
              border-radius: 4px;
              font-family: Quicksand;
              font-size: 14px;
              font-weight: 700;
              line-height: 16px;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              gap: 8px;
              color: #C8C0D3;
              padding: 6px;
              border: 1px solid white;
            }

            .favorite-participant:hover {
              background-color: #FAF2F5;
            }

            .favorite-participant:active {
              background-color: #E3B4CA;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 30px 30px 0 30px;
    }

    .container.content {
      padding: 0 30px 30px 30px;
    }

    .tab-bar {
      flex: 1;
      justify-content: center;
    }

    .tab-bar md-tabs {
      width: 100%;
    }

    .tab-bar md-secondary-tab {
      font-size: 12px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .search-panel {
      .search-bar {
        flex-direction: column;

        .participant-search {
          flex: auto;
        }

        .second-row {
          margin-top: 10px;
          justify-content: space-between;
          width: 100%;

          .search-filters-button-small {
            display: flex;
            flex: 1;
          }

          .favorites-toggle {
            flex: 1;
            justify-content: flex-end;
          }
        }
      }

      .search-box {
        .show-small {
          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1000;
          flex-direction: column;
          padding: 20px;
          overflow-y: auto;
          border-radius: 0;
          background-color: #FFFFFF; /* Ensure background is solid white */
          -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
        }

        .hide-small {
          display: none;
        }
        
        .search-results {
          margin-left: 0;
        }

        .search-filters {
          height: 100%;

          .filters-buttons {
            display: flex;
            justify-content: space-between;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #FFFFFF;
            padding: 10px 20px;
            box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
            z-index: 1000;
            gap: 10px;

            .button {
              display: flex;
              height: 32px;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              flex: 1 0 0;
              border-radius: 100px;
            }
          }

          .filters-content {
            overflow: auto;
            height: auto;

            .filters-section:last-child {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}

.program-search {
  margin-bottom: 10px;

  .program-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .program-search-input {
      width: 100%;
      padding: 8px 30px 8px 12px;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      font-family: Quicksand;
      font-size: 14px;
    }

    .program-search-clear {
      position: absolute;
      right: 8px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover svg path {
        fill: #562782;
      }
    }
  }
}

.program-match-mode {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.program-match-mode span {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 500;
  color: #4B4B4B;
  white-space: nowrap;
}

.program-match-options {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 0;
}

.match-option {
  display: flex;
  align-items: center;
}

.match-option input[type="radio"] {
  accent-color: #662C8F;
  margin: 0;
}

.match-option label {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 500;
  color: #4B4B4B;
  margin: 0;
}
</style>