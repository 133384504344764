const CEEB_UNLISTED = '000003';
import SchoolSearchWithTypeAhead from '../../../components/SchoolSearchWithTypeAhead.vue';

export default {
  components: { SchoolSearchWithTypeAhead },
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  /**
   * The component's data.
   */
  data() {
    return {
      form: $.extend(
        true,
        new SparkForm({
          first_name: '',
          last_name: '',
          email: '',
          professional_type: null,
          professional_type_text: null,
          high_school_ceeb: 0,
          high_school_id: null,
          high_school: null,
          high_school_city: null,
          high_school_region: null,
          organization: null,
          title: ''
        }),
        Spark.forms.updateContactInformation
      ),
      professionalTypes: [
        'Secondary/High School - Public',
        'Secondary/High School - Private',
        'College/University - 4 year',
        'College/University - 2 year',
        'Independent Educational Consultant',
        'Organization',
        'Business',
        'Retired',
        'Student',
        'N/A',
        'Other'
      ],
      schoolItems: [],
      initialSchoolValue: null,
      selectedSchool: null,
      showHighschoolFields: false,
      alertSuccess: false,
      previewStyle: this.generatePreviewStyle()
    };
  },
  computed: {
    isProfessionSchoolType() {
      const schoolTypes = ['Secondary/High School - Public', 'Secondary/High School - Private', 'College/University - 2 year'];
      return schoolTypes.indexOf(this.form.professional_type) !== -1;
    }
  },

  /**
   * Bootstrap the component.
   */
  mounted() {
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    this.form.email = this.user.email;
    this.form.title = this.user.title;
    this.form.organization = this.user.organization;
    if (this.user.professional_type) {
      if (this.professionalTypes.includes(this.user.professional_type)) {
        this.form.professional_type = this.user.professional_type;
      } else {
        this.form.professional_type = 'Other';
        this.form.professional_type_text = this.user.professional_type;
      }
    }
    if (this.isProfessionSchoolType && this.user.school) {
      this.initialSchoolValue = {
        school_ceeb_code: this.user.school.CEEB,
        school_name: this.user.school.school,
        school_municipality: this.user.school.city,
        school_region: this.user.school.state
      };

      this.selectSchool({
        school: this.user.school.school,
        id: this.user.school.id,
        CEEB: this.user.school.CEEB,
        city: this.user.school.city,
        state: this.user.school.state
      });
    }
  },
  methods: {
    generatePreviewStyle() {
      let url;
      if (this.$refs.photo && this.$refs.photo.files && this.$refs.photo.files.length) {
        url = URL.createObjectURL(this.$refs.photo.files[0]);
      } else {
        url = this.user.photo_url;
      }
      return `background-image: url(${url})`;
    },
    querySchoolApi(query) {
      axios.get(`/api/schools?query=${encodeURIComponent(query)}`).then(
        ({ data }) => {
          this.schoolItems = data;
        },
        () => {
          this.schoolItems = [];
        }
      );
    },
    selectSchool(school) {
      if (school) {
        this.selectedSchool = school;
        this.form.high_school_ceeb = school.CEEB;
        this.form.high_school = school.school;
        this.form.high_school_id = school.id;
        this.form.high_school_city = school.city;
        this.form.high_school_region = school.state;
        this.showHighschoolFields = school.CEEB === CEEB_UNLISTED;
      }
    },
    schoolDataClear() {
      this.selectedSchool = null;
      this.showHighschoolFields = false;
      this.form.high_school_ceeb = 0;
      this.form.high_school_id = '';
      this.form.high_school = '';
      this.form.high_school_city = '';
      this.form.high_school_region = '';
      this.querySchoolApi('');
    },
    updatePhoto() {
      this.previewStyle = this.generatePreviewStyle();
    },
    /**
     * Gather the form data for the photo upload.
     */
    gatherPhotoFormData() {
      const data = new FormData();

      data.append('photo', this.$refs.photo.files[0]);

      return data;
    },
    /**
     * Update the user's profile information.
     */
    updateInfo() {
      if (this.$refs.photo.files.length) {
        var self = this;

        this.form.startProcessing();

        // We need to gather a fresh FormData instance with the profile photo appended to
        // the data so we can POST it up to the server. This will allow us to do async
        // uploads of the profile photos. We will update the user after this action.
        axios.post('/settings/photo', this.gatherPhotoFormData()).then(
          () => {
            Bus.$emit('updateUser');

            self.form.finishProcessing();
            this.updateFormInfo();
          },
          (error) => {
            self.form.setErrors(error.response.data.errors);
          }
        );
      } else {
        this.updateFormInfo();
      }
    },
    updateFormInfo() {
      Spark.put('/settings/dashboard/update', this.form).then(() => {
        Bus.$emit('updateUser');
      });
    }
  }
};
