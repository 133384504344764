<template>
  <div>
    <div
      id="school-textarea"
      tabindex="0"
      class="school-textarea"
      @click="schoolFocusInput"
      @focus="schoolFocusInput"
      @focusout="schoolFocusOutInput"
    >
      <div v-for="(school, idx) in schools" :key="school + idx" class="pill">
        <div class="school-container">
          {{ getSchoolName(school) }}
        </div>
        <button
          type="button"
          class="close"
          @click="
            () => {
              removeSchool(idx);
            }
          "
        >
          &times;
        </button>
      </div>
      <input id="school-invisible-input" v-model="schoolInput" class="school-invisible-input" @keyup="keyupschoolInput">
    </div>
    <ul v-show="showSearchResults" class="school-items-list">
      <li
        v-for="(searchResult, index) in searchResults"
        :key="searchResult.id"
        class="school-items"
        @click.prevent="selectSchool"
        @mousemove="setCurrentSchoolSearchResultIndex(index)"
      >
        <strong>{{ searchResult.school }}</strong>
        <br>
        <small> {{ searchResult.city }} - {{ searchResult.state }} </small>
      </li>
    </ul>
  </div>
</template>
<script>
function resizable(el, factor) {
  var int = Number(factor) || 7.7;
  function resize() {
    el.style.width = (el.value.length + 1) * int + 'px';
  }
  var e = 'keyup,keypress,focus,blur,change'.split(',');
  for (var i in e) el.addEventListener(e[i], resize, false);
  resize();
}

const UNLISTED_CEEB = '000003';
const HOMESCHOOLED_CEEB = '970000';

export default {
  name: 'AddUsersschool',
  components: {},

  props: {
    schools: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {
      currentSearchSelectionIndex: -1,
      schoolInput: '',
      showSearchResults: false,
      searchResults: []
    };
  },

  watch: {
    schools: function() {
      this.$emit('on-update-schools', this.schools);
    },
    searchResults: function() {
      this.showSearchResults = this.searchResults.length > 0;
    }
  },

  mounted() {
    resizable(document.getElementById('school-invisible-input'), 9);
  },

  methods: {
    querySchoolApi(query) {
      axios.get(`/api/schools?query=${encodeURIComponent(query)}`).then(
        ({ data }) => {
          this.searchResults = data;
        },
        () => {
          this.searchResults = [];
        }
      );
    },

    queryDebounced: _.debounce(function(queryString) {
      if (this.isSchoolSearchDisabled) {
        return;
      }
      this.querySchoolApi(queryString);
    }, 400),

    setCurrentSchoolSearchResultIndex(index) {
      this.currentSearchSelectionIndex = index;
    },

    getSchoolName(school) {
      if (school.CEEB === HOMESCHOOLED_CEEB || school.CEEB === UNLISTED_CEEB) {
        return school.school;
      } else {
        let schoolName = `${school.school} - ${school.city}, ${school.state}`;
        return schoolName;
      }
    },

    selectSchool() {
      if (this.currentSearchSelectionIndex == -1) {
        return;
      }
      let selectedSchool = this.searchResults[this.currentSearchSelectionIndex];
      this.searchResults = [];
      this.schools.push(selectedSchool);
      this.schoolInput = '';
    },

    schoolFocusInput() {
      document.getElementById('school-invisible-input').focus();
      document.getElementById('school-textarea').classList.add('focused');
    },

    schoolFocusOutInput() {
      document.getElementById('school-textarea').classList.remove('focused');
      document.getElementById('school-invisible-input').style.width = '10px';
    },

    keyupschoolInput(e) {
      this.queryDebounced(this.schoolInput);
    },

    removeSchool(idx) {
      this.schools.splice(idx, 1);
    }
  }
};
</script>

<style>
.school-textarea {
  width: 100%;
  min-height: 56px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  padding: 13px 16px;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
}

.school-invisible-input {
  border: none;
  margin: 7px 0;
  min-width: 10px;
}

.school-invisible-input:focus {
  outline: none;
}

.school-container {
  max-width: 300px;
  overflow-wrap: break-word;
}

.school-items-list {
  max-height: 300px;
  width: 100%;
  list-style: none;
  position: relative;
  left: 0;
  margin: 0;
  padding: 0;
  background: #ffffff;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  border: 1px solid #d3e0e9;
  border-radius: 4px;
}

.school-items-list > li {
  cursor: pointer;
  user-select: none;
  font-weight: bold;

  &:hover {
    background: #642c8f;
    color: #ffffff;

    a {
      color: #ffffff;
    }
  }
}

.school-items-list > li > a {
  font-weight: normal;
  line-height: 1.6;
  color: #636b6f;
  text-decoration: none;

  &:hover {
    color: white !important;
  }
}

.school-items {
  padding: 3px 20px;
}
</style>
