const BASE_URL = '/api/';

export const FAIR_DASHBOARD_LIST = BASE_URL + 'fairs/dashboards';
export const FAIR_DASHBOARD_REGISTERED_TIMES = (fairId) => {
  return BASE_URL + 'fairs/' + fairId + '/dashboards/registered';
};

export const FAIR_TAGS = BASE_URL + 'fair-tags';

/**
 * The fair dashboard details api
 * @typedef {Object} DashboardDetailApiSurface
 * @property {string} schools - the url for the fair dashboard / schools endpoint
 * @property {string} exportHistory - the url for the fair dashboard / export-history endpoint
 * @property {string} institutions - the url for the fair dashboard / institutions endpoint
 * @property {string} registered - the url for the fair dashboard / registered endpoint
 * @property {string} scanned - the url for the fair dashboard / schools endpoint
 * @property {string} users - the url for the fair dashboard / schools endpoint
 */

export const ApiPaths = {
  /**
   * The fairs dashboard api surface
   * @param {number} fair_id - the id of the fair
   * @property {string} dashboards - the fair dashboard list url
   * @return {DashboardDetailApiSurface} the api surface of the dashboard details
   */
  fairs: Object.assign(
    (fair_id) => {
      const dashboard_base = `/api/fairs/${encodeURIComponent(fair_id)}/dashboards`;
      const exports_base = `/api/exports/fairs/${encodeURIComponent(fair_id)}`;
      const fairUser_base = `/api/fair-users`;
      return {
        dashboards: {
          refresh: `${dashboard_base}/refresh`,
          uploadPhoto: `${dashboard_base}/upload-photo`,
          schools: Object.assign(`${dashboard_base}/schools`, {
            featureService: `${dashboard_base}/schools/engagment-features`
          }),
          exportHistory: `${dashboard_base}/export-history`,
          institutions: Object.assign(`${dashboard_base}/institutions`, {
            featureService: `${dashboard_base}/institutions/engagment-features`
          }),
          registered: `${dashboard_base}/registered`,
          scanned: `${dashboard_base}/scanned`,
          users: Object.assign(`${dashboard_base}/users`, {
            delete: (user_id) => `${dashboard_base}/users/${encodeURIComponent(user_id)}`,
            index: `${dashboard_base}/users`,
            indexFairUsers: `${dashboard_base}/users/fair-users`,
            indexSchools: (user_id) => `${dashboard_base}/users/${encodeURIComponent(user_id)}/schools`,
            deleteSchool: (user_id, school_id) =>
              `${dashboard_base}/users/${encodeURIComponent(user_id)}/schools/${encodeURIComponent(school_id)}`,
            resendInvite: (user_id) => `${dashboard_base}/users/${encodeURIComponent(user_id)}/resend-invite`
          }),
          permissions: `${dashboard_base}/permissions`,
          info: `${dashboard_base}/dashboard_info`,
          deleteFairUser: (fair_user_id) => `${fairUser_base}/${encodeURIComponent(fair_user_id)}`,
          emailRegistered: `${exports_base}/user-fair-students/dashboards/registrations/email`,
          emailScannedByAttendee: `${exports_base}/user-fair-students/dashboards/attendee/email`,
          emailScannedByInstitution: `${exports_base}/user-fair-students/dashboards/institutions/email`,
          emailConnectionsByAttendee: `${exports_base}/connections/dashboards/attendee/email`,
          emailConnectionsByInstitution: `${exports_base}/connections/dashboards/institution/email`
        }
      };
    },
    {
      dashboards: FAIR_DASHBOARD_LIST
    }
  )
};