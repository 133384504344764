<template>
  <div class="panel-body">
    <p>
      Visits is not active for your institution
    </p>
    <p>
      <a href="https://www.strivescan.com/visits" target="_blank" class="visit-help-link">Use StriveScan for your High School Visits</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'VisitsNotEnabled'
};
</script>

<style></style>
