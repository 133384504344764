<template>
  <nav :aria-label="navAriaLabel">
    <div class="pagination-variant-container">
      <p>{{ firstItemIndex }} - {{ lastItemIndex }} of {{ totalItems }}</p>
      <ul class="pagination-variant">
        <li :class="{ disabled: onFirstPage, 'first-page': true }" @click="goToFirstPage">
          <a aria-label="First">
            <span class="material-icons">first_page</span>
          </a>
        </li>
        <li :class="{ disabled: onFirstPage }" @click="goToPreviousPage">
          <a aria-label="Previous">
            <span class="material-icons">navigate_before</span>
          </a>
        </li>
        <li class="current-page-container">
          {{ $translate('Page') }} &nbsp;<input class="page-input" :value="currentPage" @blur="inputPage" @keyup.enter="inputPage"><span
            class="current-page"
          >{{ currentPage }}</span>&nbsp; {{ ofText }} &nbsp; {{ totalPages }}
        </li>
        <li :class="{ disabled: onLastPage }" @click="goToNextPage">
          <a aria-label="Next">
            <span class="material-icons">navigate_next</span>
          </a>
        </li>
        <li :class="{ disabled: onLastPage, 'last-page': true }" @click="goToLastPage">
          <a aria-label="Last">
            <span class="material-icons">last_page</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PaginationVariant',
  props: {
    totalPages: {
      type: Number,
      default: null
    },
    currentPage: {
      type: Number,
      default: null
    },
    perPage: {
      type: Number,
      default: null
    },
    totalItems: {
      type: Number,
      default: null
    },
    itemsOnPage: {
      type: Number,
      default: null
    },
    context: {
      type: String,
      default: 'global'
    },
    title: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {};
  },
  computed: {
    ofText() {
      if (typeof currentLocale !== 'undefined' && currentLocale == 'fr') {
        return 'de';
      } else {
        return 'of';
      }
    },
    navAriaLabel() {
      if (null !== this.title && '' !== this.title) {
        `Page navigation for ${this.title}`;
      }
      return 'Page navigation';
    },
    onFirstPage() {
      return this.currentPage == 1;
    },
    onLastPage() {
      return this.currentPage == this.totalPages;
    },
    firstItemIndex() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    lastItemIndex() {
      return (this.currentPage - 1) * this.perPage + this.itemsOnPage;
    }
  },
  methods: {
    goToFirstPage() {
      if (this.onFirstPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', 1, this.context);
    },
    goToLastPage() {
      if (this.onLastPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', this.totalPages, this.context);
    },
    goToPreviousPage() {
      if (this.onFirstPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', this.currentPage - 1, this.context);
    },
    goToNextPage() {
      if (this.onLastPage) {
        return;
      }

      Bus.$emit('currentPageUpdated', this.currentPage + 1, this.context);
    },
    inputPage(e) {
      const val = e.target.value;
      if (val > 0 && val <= this.totalPages) {
        Bus.$emit('currentPageUpdated', val, this.context);
      }
    }
  }
};
</script>

<style scoped>
.pagination-variant-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  margin: 30px 0;
}

.pagination-variant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
}

.pagination-variant input {
  max-width: 65px;
  padding: 6px 16px;
  border: 1px solid rgba(0, 0, 0, 0.48);
  border-radius: 4px;
}

.pagination-variant li {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.current-page-container {
  min-width: 90px;
}

.pagination-variant li a {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-variant li a:hover {
  color: rgba(0, 0, 0, 0.7);
}

.pagination-variant li.disabled a {
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
  pointer-events: all !important;
}

.material-icons {
  font-size: 20px;
}

.pagination-variant .current-page {
  display: none;
}

@media (max-width: 991px) {
  .pagination-variant .page-input {
    display: none;
  }

  .pagination-variant .first-page {
    display: none;
  }

  .pagination-variant .last-page {
    display: none;
  }
  .pagination-variant .current-page {
    display: block;
  }
}
</style>
