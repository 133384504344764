<template>
  <div>
    <nav class="guide-navbar" :class="{ 'demo': demo }">
      <div class="container">
        <div style="margin-bottom: 0; display: flex; justify-content: space-between;">
          <div class="landing-page-logos">
            <h1 class="text-center" style="margin: 0;">
              <img src="/img/logo-white.png">
            </h1>
          </div>
          <div class="right-content-small">
            <ul
              style="list-style: none; padding: 0; display: flex; gap: 10px; align-items: right; justify-content: right;">
              <li v-if="fair.photo_url_1">
                <img class="featured-institution-logo" :src="fair.photo_url_1" style="width: 50px; height: 50px" />
              </li>

              <li v-if="fair.photo_url_2">
                <img class="featured-institution-logo" :src="fair.photo_url_2" style="width: 50px; height: 50px" />
              </li>
            </ul>
          </div>
        </div>
        <div class="align-stretch">
          <div class="content-left">
            <div class="center-content-small">
              <h1 class="event-guide-title">{{ fair.display_name || fair.name }}</h1>
              <div v-if="student" class="welcome-banner">
                <span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.51631 12C1.51631 20.0704 10.2527 25.1143 17.2419 21.0792C20.4856 19.2065 22.4837 15.7455 22.4837 12C22.4837 3.92959 13.7473 -1.11431 6.75811 2.92084C3.51444 4.79352 1.51631 8.25454 1.51631 12Z"
                      stroke="#33FF94" />
                    <path d="M8.33073 12.5242L10.4275 14.6209L15.6693 9.37906" stroke="#33FF94" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  {{ student.student_type === 'PARENT' ? student.parent_first_name : student.first_name }} {{ student.student_type === 'PARENT' ? student.parent_last_name : student.last_name }}, {{ $translate('you\'re registered!') }}
                </span>
              </div>
              <div class="event-guide-header event-guide-header-data">
                <div class="column icon-column">
                  <span class="material-icons" style="color: white; font-size: 24px">event</span>
                </div>
                <div class="column content-column">
                  <h2>{{ startDate }}
                    <template v-if="startDate !== endDate">
                      {{ startTime }}
                      <br />- {{ endDate }} {{ endTime }}
                    </template>
                  </h2>
                  <div v-if="startDate === endDate" class="time-container">
                    <span>{{ startTime }}{{ startTime !== endTime ? ` - ${endTime}` : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="event-guide-header event-guide-header-data">
                <div class="column icon-column">
                  <span class="material-icons" style="color: white; font-size: 24px">place</span>
                </div>
                <div class="column content-column">
                  <h2>{{ venueName }}</h2>
                  <p>
                    <a :href="'https://maps.google.com/?q=' + formattedAddressLine + ', ' + formattedCityStateZip" target="_blank" style="color: white">
                      {{ formattedAddressLine }}<br />
                      {{ formattedCityStateZip }}
                    </a>
                  </p>
                </div>
              </div>
              <div class="event-guide-header event-guide-header-data small-screen-button">
                <div class="column button-column">
                  <a v-if="demo" :href="`/guide/demo/start/${fair.id}`" class="register-button" @click="handleDemoClick" :class="{ 'loading': isDemoLoading }">
                    <span v-if="!isDemoLoading">{{ $translate('Demo') }}</span>
                    <span v-else class="loading-content">
                      <span class="loading-text">{{ $translate('Creating New Demo') }}</span>
                      <span class="spinner"></span>
                    </span>
                  </a>
                  <template v-else>
                    <a v-if="!student" :href="registrationLink" class="register-button">
                      {{ $translate('Register') }}
                    </a>
                    <a v-if="student" :href="student.update_url" class="register-button register-button-outline">
                      {{ $translate('Update Registration Info') }}
                    </a>
                    <a v-if="student" href="#" @click="showBarcode" class="register-button">
                      {{ $translate('Show Barcode') }}
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <div class="right-content-small column button-column">
              <a v-if="demo" :href="`/guide/demo/start/${fair.id}`" class="register-button" @click="handleDemoClick" :class="{ 'loading': isDemoLoading }">
                <span v-if="!isDemoLoading">{{ $translate('Start Demo') }}</span>
                <span v-else class="loading-content">
                  <span class="loading-text">{{ $translate('Creating New Demo') }}</span>
                  <span class="spinner"></span>
                </span>
              </a>
              <template v-else>
                <a v-if="!student" :href="registrationLink" class="register-button">
                  {{ $translate('Register') }}
                </a>
                <a v-if="student" :href="student.update_url" class="register-button register-button-outline">
                  {{ $translate('Update Registration Info') }}
                </a>
                <a v-if="student" href="#" @click="showBarcode" class="register-button">
                  {{ $translate('Show Barcode') }}
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!student" class="fixed-register-button">
        <a :href="registrationLink" class="register-button small-screen-button">
          {{ $translate('Register') }}
        </a>
      </div>
    </nav>
    <div class="header-placeholder"></div>
    <div class="barcode-modal" v-if="showBarcodeModal">
      <div class="barcode-modal-content">
        <span class="close" @click="closeBarcode">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 22 22" fill="none">
            <path
              d="M17.3007 4.71C16.9107 4.32 16.2807 4.32 15.8907 4.71L11.0007 9.59L6.1107 4.7C5.7207 4.31 5.0907 4.31 4.7007 4.7C4.3107 5.09 4.3107 5.72 4.7007 6.11L9.5907 11L4.7007 15.89C4.3107 16.28 4.3107 16.91 4.7007 17.3C5.0907 17.69 5.7207 17.69 6.1107 17.3L11.0007 12.41L15.8907 17.3C16.2807 17.69 16.9107 17.69 17.3007 17.3C17.6907 16.91 17.6907 16.28 17.3007 15.89L12.4107 11L17.3007 6.11C17.6807 5.73 17.6807 5.09 17.3007 4.71Z"
              fill="#181B26" />
          </svg>
        </span>
        <h3>
          {{ $translate('Show this barcode to the representative to share your info') }}
        </h3>
        <img :src="student.barcode_url" alt="Barcode" />
        <p style="text-align: center;">{{ student.encoded_id }}</p>
        <div class="buttons">
            <a :href="`/passkit/${student.hashed_created_at}/${student.encoded_id}`" class="add-to-apple-wallet-button">
              <img src="/img/add-to-apple-wallet.svg" alt="Add to Apple Wallet">
            </a>
        </div>
      </div>
    </div>
    <div v-if="student" class="action-button">
      <a href="#" @click="showBarcode">
        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#050505"><path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM180-660h120v-120H180v120Zm-60 60v-240h240v240H120Zm60 420h120v-120H180v120Zm-60 60v-240h240v240H120Zm540-540h120v-120H660v120Zm-60 60v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z"/></svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGuideHeader',
  components: {},
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.handleKeydown);

    this.$nextTick(() => {
      const header = document.querySelector('.guide-navbar');
      const placeholder = document.querySelector('.header-placeholder');

      placeholder.style.height = `${header.offsetHeight}px`;
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keydown', this.handleKeydown);
  },
  props: {
    fair: {
      type: Object,
      required: false,
      default: () => null,
    },
    student: {
      type: [Object, null],
      required: false,
      default: () => null,
    },
    registrationLink: {
      type: String,
      required: false,
      default: () => null,
    },
    demo: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      showBarcodeModal: false,
      isDemoLoading: false,
    };
  },
  computed: {
    venueName() {
      const venue = this.fair.venue;
      if (venue == null) {
        return '';
      }
      return venue.name;
    },
    formattedAddressLine() {
      const venue = this.fair.venue;
      if (venue == null) {
        return '';
      }
      const address = venue.address;
      if (address == null) {
        return '';
      }
      let addressParts = [];
      if (address.line_1) addressParts.push(address.line_1);
      if (address.line_2) addressParts.push(address.line_2);
      return addressParts.join(', ');
    },
    formattedCityStateZip() {
      const venue = this.fair.venue;
      if (venue == null) {
        return '';
      }
      const address = venue.address;
      if (address == null) {
        return '';
      }

      let addressParts = [];
      if (address.city) addressParts.push(address.city);
      if (address.region) addressParts.push(address.region);
      if (address.postal_code) addressParts.push(address.postal_code);
      return addressParts.join(', ');
    },
    startDate() {
      return this.formatDate(this.fair.starts_at, this.fair.starts_at_timezone);
    },
    endDate() {
      return this.formatDate(this.fair.ends_at, this.fair.ends_at_timezone);
    },
    startTime() {
      return this.formatTime(this.fair.starts_at, this.fair.starts_at_timezone);
    },
    endTime() {
      return this.formatTime(this.fair.ends_at, this.fair.ends_at_timezone);
    },
  },
  methods: {
    formatDate(dateString, timezone) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: timezone };
      const locale = currentLocale === 'fr' ? 'fr-CA' : (currentLocale ? currentLocale + '-US' : 'en-US');
      return new Date(dateString).toLocaleDateString(locale, options);
    },
    formatTime(dateString, timezone) {
      const options = { hour: '2-digit', minute: '2-digit', timeZoneName: 'short', timeZone: timezone };
      const locale = currentLocale === 'fr' ? 'fr-CA' : (currentLocale ? currentLocale + '-US' : 'en-US');
      return new Date(dateString).toLocaleTimeString(locale, options);
    },
    handleScroll() {
      const header = document.querySelector('.guide-navbar');
      const placeholder = document.querySelector('.header-placeholder');
      const headerDataElements = document.querySelectorAll('.event-guide-header-data');
      const fixedButton = document.querySelector('.fixed-register-button');
      const actionButton = document.querySelector('.action-button');
      const contentRight = document.querySelector('.content-right');

      let modifier = 0;

      if (window.scrollY > 0) {
        modifier = 40;

        if (fixedButton) {
          setTimeout(() => {
            fixedButton.classList.add('fixed-button');
          }, 500);
        }

        if (headerDataElements) {
          headerDataElements.forEach(element => {
            element.classList.add('display-none');
          });
        }

        if (actionButton) {
          actionButton.classList.add('fixed-action-button');
        }

        if (!!this.student) {
          contentRight.classList.add('display-none');
        }
      } else {
        if (fixedButton) {
          setTimeout(() => {
            fixedButton.classList.remove('fixed-button');
          }, 500);
        }

        if (headerDataElements) {
          headerDataElements.forEach(element => {
            element.classList.remove('display-none');
          });
        }

        if (actionButton) {
          actionButton.classList.remove('fixed-action-button');
        }

        if (!!this.student) {
          contentRight.classList.remove('display-none');
        }
      }

      placeholder.style.height = `${header.offsetHeight + modifier}px`;
    },
    showBarcode(event) {
      event.preventDefault();
      this.showBarcodeModal = true;
    },
    closeBarcode() {
      this.showBarcodeModal = false;
    },
    handleKeydown(event) {
      if (event.key === 'Escape' && this.showBarcodeModal) {
        this.closeBarcode();
      }
    },
    handleDemoClick(event) {
      event.preventDefault();
      if (this.isDemoLoading) return;
      
      this.isDemoLoading = true;
      window.location.href = `/guide/demo/start/${this.fair.id}`;
    },
  },
};
</script>

<style scoped>
.guide-navbar {
  width: 100%;
  min-height: 128px;
  background: url('/img/student-microsite-header-texture.png') no-repeat top right, linear-gradient(77.23deg, #1F003C 37.57%, #B070E7 106.63%);
  background-size: cover;
  border-radius: 0px 0px 40px 40px;
  margin: 0 auto;
  color: #FFF;
  padding: 20px 10px;
  overflow-anchor: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;

  &.demo {
    border-radius: 0px 0px 0px 0px;
  }
}

h1 {
  font-family: Quicksand;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0px;
}

h2 {
  color: #FFF;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  text-align: left;
}

p {
  font-family: Quicksand;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.container>div:not(:last-child) {
  margin-bottom: 15px;
}

.header-info-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8px;
}

.time-container {
  margin-top: 10px;
}

.time-container span {
  background: var(--Primary-700, #4A2171);
  border-radius: 4px;
  font-family: var(--font-family, Quicksand);
  font-size: var(--Body-2-size, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Body-2-line-height, 24px);
  padding: 2px 6px;
  text-align: center;
}

.featured-institution-logo {
  width: 50px;
  height: 50px;
  background-color: #FFF;
}

.landing-page-logos {
  margin-bottom: 0;
  margin-left: 0;
  float: left;
}

.landing-page-logos img {
  height: 60px;
}

.welcome-banner {
  display: flex;
  margin: 5px 0 15px 0;
  width: 100%;
}

.welcome-banner span {
  display: flex;
  gap: 4px;
  padding: 2px 6px;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  background: rgba(255, 255, 255, 0.20);
}

.header-placeholder {
  height: 0px;
}

.event-guide-title {
  align-items: center;
  width: 100%;
}

.event-guide-header {
  display: flex;
  align-items: top;
  gap: 1rem;
  margin-top: 1rem;
}

.column {
  display: flex;
  align-items: left;
  justify-content: left;
}

.icon-column {
  flex: 0 0 auto;
  /* Takes only as much space as the content */
}

.center-content-small {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-column {
  flex-direction: column;
  flex: 1;
  /* Takes the remaining space */
}

.content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 1rem 0;
  flex: 1;
  /* Take the full width */
}

.content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  padding: 1rem;
}

.button-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.register-button {
  background-color: #33FF94;
  border-radius: 24px;
  color: #050505;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  letter-spacing: 0.64px;
  line-height: 24px;
  padding: 12px 16px;
  text-transform: uppercase;
  width: 100%;
  min-width: 300px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  &.loading {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(5, 5, 5, 0.3);
  border-radius: 50%;
  border-top-color: #050505;
  animation: spin 1s ease-in-out infinite;
  vertical-align: middle;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  font-size: 1.4rem;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.register-button-outline {
  background-color: transparent;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  margin-bottom: 10px;
  padding: 10px 16px;
}

.fixed-register-button {
  display: none;
  background: #1F003C;
  padding: 10px 20px 20px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  /* border radius on top left and right only */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.action-button {
  /* floating action button like the android */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: none;

  a {
    background-color: #33FF94;
    border-radius: 50%;
    color: #050505;
    font-size: 24px;
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.fixed-action-button {
  display: block;
}

.align-stretch {
  display: flex;
  align-items: stretch;
  /* Ensure all columns stretch to the same height */
}

.small-screen-button {
  display: none;
}

.display-none {
  display: none !important;
}

.barcode-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.barcode-modal-content {
  background-color: #fefefe;
  padding: 15px;
  border: 1px solid #888;
  position: relative;
  display: inline-block;
  border-radius: 24px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 20px;
    text-align: center;
  }

  .buttons {
    margin-bottom: 20px;
  }
}

.close {
  background-color: #33FF94;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
  /* circular shape */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .guide-navbar h1 {
    font-size: 2.4rem;
  }

  .guide-navbar h2 {
    font-size: 1.6rem;
  }

  .register-button,
  .guide-navbar .time-container span {
    font-size: 1.4rem;
  }

  .welcome-banner {
    font-size: 1.2rem;
  }

  .landing-page-logos {
    margin-left: 0px;
  }

  .landing-page-logos img {
    height: 35px;
  }

  .content-right {
    display: none;
  }

  .content-left {
    flex-direction: row;
  }

  .center-content-small {
    display: block;
  }

  .small-screen-button {
    margin-top: 10px;
    display: block;
  }

  .center-content-small {
    margin: 0;
  }

  .fixed-button {
    display: block;
  }
}
</style>