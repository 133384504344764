<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Navigation from "./Navigation";
import CustomBackgroundWrapper from "./CustomBackgroundWrapper";

export default {
  components: {
    Cropper,
    Navigation,
    CustomBackgroundWrapper,
  },
  props: {
    img: {
      type: [String, File, Object],
    },
  },
  data() {
    return {
      zoom: 0,
    };
  },
  methods: {
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: 360,
        height: 360,
      };
    },
    async onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }

        Bus.$emit('cropped', this.getCroppedImage());
      }
    },
    getCroppedImage() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const result = cropper.getResult();
        if (result) {
          const canvas = result.canvas;
          const ctx = canvas.getContext('2d');
          
          // Store the current composite operation
          const previousCompositeOperation = ctx.globalCompositeOperation;
          
          // Set a white background
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          
          // Restore previous composite operation
          ctx.globalCompositeOperation = previousCompositeOperation;
          
          // Change from 'image/png' to 'image/jpeg' and optionally specify quality (0.0 to 1.0)
          const dataUrl = canvas.toDataURL('image/jpeg', 0.9);

          if (dataUrl === 'data:,') {
            return '';
          }

          return dataUrl;
        }
      }

      return '';
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
  },
};
</script>


<template>
  <div>
    <cropper
      ref="cropper"
      class="twitter-cropper"
      background-class="twitter-cropper__background"
      foreground-class="twitter-cropper__foreground"
      image-restriction="stencil"
      :stencil-size="stencilSize"
      :stencil-props="{
        lines: {},
        handlers: {},
        movable: false,
        scalable: false,
        aspectRatio: 1,
        previewClass: 'twitter-cropper__stencil',
      }"
      :background-wrapper-component="
        $options.components.CustomBackgroundWrapper
      "
      :default-size="defaultSize"
      :transitions="false"
      :canvas="true"
      :debounce="false"
      :min-width="360"
      :min-height="360"
      :src="img"
      @change="onChange"
    />
    <navigation :zoom="zoom" @change="onZoom" />
  </div>
</template>

<style lang="scss">
.twitter-cropper {
  height: 521px;
  &__background {
    background-color: #ffffff;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px #704e89;
  }
}
</style>